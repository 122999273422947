import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { uploadBatchesMarg, uploadLedgerMarg, uploadProductsMarg } from "../../../reducers/Slices/onboardingSlice";
import { http } from "../../../service/http";

const FileUploadOnboarding = ({ migrationSoftware, setDisableFinishStepButton }) => {
  const dispatch = useDispatch();

  const [productFile, setProductFile] = useState(null);
  const [batchesFile, setBatchesFile] = useState(null);
  const [ledgerFile, setLedgerFile] = useState(null);
  const [isUploadingProduct, setIsUploadingProduct] = useState(false);
  const [isUploadingBatches, setIsUploadingBatches] = useState(false);
  const [isUploadingLedger, setIsUploadingLedger] = useState(false);
  const [isProductUploaded, setIsProductUploaded] = useState(false);

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    const maxSizeInMB = 15; // 10 MB
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file && file?.size > maxSizeInBytes) {
      toast.error(`File size should be less than ${maxSizeInMB} MB`);
      return;
    }

    if (fileType === "product") setProductFile(file);
    else if (fileType === "batches") setBatchesFile(file);
    else if (fileType === "ledger") setLedgerFile(file);
  };

  //generate presigned url
  const generatePresignedURL = async (filename) => {
    if (filename) {
      try {
        const response = await http.post(
          `/storage/`,
          { file_name: `onboarding/${filename}` },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response?.data?.data?.url) {
          return response.data.data.url;
        } else {
          return null;
        }
      } catch (error) {
        toast.error("Some error occurred while generating url try again");
        return null;
      }
    }
  };

  //Upload to presignedURL
  async function uploadToPresignedURL(presignedURL, productFile) {
    try {
      const response = await axios.put(presignedURL, productFile, {
        headers: {
          "Content-Type": productFile.type,
        },
      });

      if (response.status === 200) {
        console.log("File uploaded successfully");
        return true;
      } else {
        console.error("Failed to upload file:", response.status, response.statusText);
        return false;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return false;
    }
  }

  const handleUploadProduct = async () => {
    if (!productFile) {
      toast.error("Please select a Product file.");
      return;
    }

    setIsUploadingProduct(true);

    const presignedURL = await generatePresignedURL(productFile.name);

    if (!presignedURL) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingProduct(false);
      return;
    }

    const fileUploadedToR2 = await uploadToPresignedURL(presignedURL, productFile);

    if (!fileUploadedToR2) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingProduct(false);
      return;
    }

    try {
      await dispatch(uploadProductsMarg(`onboarding/${productFile?.name}`));
      toast.success("Product file uploaded successfully!");
      setIsProductUploaded(true);
      setDisableFinishStepButton(false);
    } catch (error) {
      console.error("Error uploading Product file:", error);
      toast.error("Failed to upload Product file. Please try again.");
    } finally {
      setIsUploadingProduct(false);
    }
  };

  const handleUploadBatches = async () => {
    if (!batchesFile) {
      toast.error("Please select a Batches file.");
      return;
    }

    setIsUploadingBatches(true);

    const presignedURL = await generatePresignedURL(batchesFile.name);

    if (!presignedURL) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingBatches(false);
      return;
    }

    const fileUploadedToR2 = await uploadToPresignedURL(presignedURL, batchesFile);

    if (!fileUploadedToR2) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingBatches(false);
      return;
    }

    try {
      await dispatch(uploadBatchesMarg(`onboarding/${batchesFile?.name}`));
      toast.success("Batches file uploaded successfully!");
      setDisableFinishStepButton(false);
    } catch (error) {
      console.error("Error uploading Batches file:", error);
      toast.error("Failed to upload Batches file. Please try again.");
    } finally {
      setIsUploadingBatches(false);
    }
  };

  const handleUploadLedger = async () => {
    if (!ledgerFile) {
      toast.error("Please select a Ledger file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", ledgerFile);

    try {
      setIsUploadingLedger(true);
      await axios.post(`/${migrationSoftware}/ledger`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      await dispatch(uploadLedgerMarg(formData));
      toast.success("Ledger file uploaded successfully!");
      setDisableFinishStepButton(false);
    } catch (error) {
      console.error("Error uploading Ledger file:", error);
      toast.error("Failed to upload Ledger file. Please try again.");
    } finally {
      setIsUploadingLedger(false);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl pb-[50px] font-semibold max-w-[500px] text-center">
        Upload Excel Files From Previous Steps.
      </h2>

      {/* Product Excel Upload */}
      <div className="flex justify-center items-center gap-[10px] mb-4">
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => handleFileChange(e, "product")}
          className="border border-gray-300 rounded px-[20px] py-[10px]"
        />
        <button
          onClick={handleUploadProduct}
          disabled={isUploadingProduct || !productFile}
          className={`px-[20px] py-[10px] font-semibold text-white rounded ${
            isUploadingProduct ? "bg-gray-400 cursor-not-allowed" : "bg-black hover:bg-stone-800"
          }`}
        >
          {isUploadingProduct ? "Uploading..." : "Upload Product File"}
        </button>
      </div>

      {/* Batches Excel Upload */}
      <div className="flex justify-center items-center gap-[10px] mb-4">
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => handleFileChange(e, "batches")}
          className="border border-gray-300 rounded px-[20px] py-[10px]"
          disabled={!isProductUploaded} // Disable input until product file is uploaded
        />
        <button
          onClick={handleUploadBatches}
          disabled={!isProductUploaded || isUploadingBatches || !batchesFile}
          className={`px-[20px] py-[10px] font-semibold text-white rounded ${
            !isProductUploaded || isUploadingBatches ? "bg-gray-400 cursor-not-allowed" : "bg-black hover:bg-stone-800"
          }`}
        >
          {isUploadingBatches ? "Uploading..." : "Upload Batches File"}
        </button>
      </div>

      {/* Ledger Excel Upload */}
      <div className="flex justify-center items-center gap-[10px] mb-4">
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => handleFileChange(e, "ledger")}
          className="border border-gray-300 rounded px-[20px] py-[10px]"
        />
        <button
          onClick={handleUploadLedger}
          disabled={isUploadingLedger || !ledgerFile}
          className={`px-[20px] py-[10px] font-semibold text-white rounded ${
            isUploadingLedger ? "bg-gray-400 cursor-not-allowed" : "bg-black hover:bg-stone-800"
          }`}
        >
          {isUploadingLedger ? "Uploading..." : "Upload Ledger File"}
        </button>
      </div>
    </div>
  );
};

export default FileUploadOnboarding;
