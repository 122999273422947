export const isSubscriptionExpired = (subscriptionExpiry) => {
  if (!subscriptionExpiry) {
    // If subscription expiry is null or undefined
    return null;
  }

  const currentDate = new Date();
  const expiryDate = new Date(subscriptionExpiry);

  // Check if the subscription is expired
  return expiryDate < currentDate;
};
