import React, { useState } from "react";

// Sections
import Statements from "./ReportSections/Statements";
import Analytics from "./ReportSections/Analytics";
import Taxation from "./ReportSections/Taxation";
import Forecast from "./ReportSections/Forecast";
import TDS_TCS from "./ReportSections/TDS_TCS";

const Reports = () => {
  // Sections - Statements, Analytics, Taxation, Forecast, TDS/TCS Complaince
  const [selectedSection, setSelectedSection] = useState("Statements");

  const renderReportsSection = () => {
    switch (selectedSection) {
      case "Statements":
        return <Statements />;
      case "Analytics":
        return <Analytics />;
      case "Taxation":
        return <Taxation />;
      case "Forecast":
        return <Forecast />;
      case "TDS_TCS":
        return <TDS_TCS />;
      default:
        return <Statements />;
    }
  };

  return (
    <>
      {/* Context Switcher Section - Statements, Analytics, Taxation, Forecast, TDS/TCS Complaince */}
      <div className="py-[10px] px-[20px] flex justify-between border-b-2 border-neutral-200">
        <div className="flex gap-[15px]">
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "Statements" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("Statements")}
          >
            Statements
          </button>
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "Analytics" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("Analytics")}
          >
            Analytics
          </button>
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "Taxation" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("Taxation")}
          >
            Taxation
          </button>
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "Forecast" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("Forecast")}
          >
            Forecast
          </button>
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "TDS_TCS" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("TDS_TCS")}
          >
            TDS/TCS Complaince
          </button>
        </div>
      </div>
      <div className="h-[82vh]">{renderReportsSection()}</div>
    </>
  );
};

export default Reports;
