import React, { useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { useDispatch } from "react-redux";

const LineChartForInventory = ({ data, type }) => {
  return (
    <div style={{ position: "relative" }}>
      <LineChart
        height={400}
        sx={{ padding: "30px" }}
        // width={650}
        series={[
          {
            data: data?.current_month_data,
            label: "This month",
            showMark: ({ index }) => index % 1 !== 0,
            color: "#277494",
          },
          {
            data: data?.previous_month_data,
            label: "Previous month",
            showMark: ({ index }) => index % 1 !== 0,
            color: "orange",
          },
        ]}
        xAxis={[{ scaleType: "point", data: data?.x_labels }]}
      />
      <div
        style={{
          position: "absolute",
          bottom: "-10px",
          width: "100%",
          textAlign: "center",
          fontWeight: "normal",
          fontSize: "16px",
          color: "gray",
        }}
      >
        {(type ?? null) === "vendor" ? "Date of Purchases" : "Date of Sales"}
      </div>
    </div>
  );
};

export default LineChartForInventory;
