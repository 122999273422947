import React, { useState, useEffect } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { updateWarehouse } from "../../../reducers/Slices/warehouseSlice"; // Adjust the import based on your project structure
import { searchZipcode } from "../../../reducers/Slices/userSlice";

function EditWarehouse({ warehouse }) {
  const dispatch = useDispatch();

  const handleSearchZipcode = (e) => {
    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            state: action.payload.state,
            city: action.payload.city,
            address1: action.payload.address1,
            state_code:
              `${action.payload.state_code}`.length > 1 ? action.payload.state_code : `0${action.payload.state_code}`,
            country_code: action.payload.country_code,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const [formData, setFormData] = useState({
    id: warehouse?.id || 0,
    name: warehouse?.name || "",
    address1: warehouse?.address1 || "",
    address2: warehouse?.address2 || "",
    zipcode: warehouse?.zipcode || "",
    city: warehouse?.city || "",
    state: warehouse?.state || "",
    contact: warehouse?.contact || "",
  });

  const [showModal, setShowModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  // Reset warehouse values on change
  useEffect(() => {
    setFormData({
      id: warehouse?.id,
      name: warehouse?.name,
      address1: warehouse?.address1,
      address2: warehouse?.address2,
      zipcode: warehouse?.zipcode,
      city: warehouse?.city,
      state: warehouse?.state,
      contact: warehouse?.contact || "",
    });
  }, [warehouse]);

  const handleSubmit = () => {
    handleEditWarehouse();
  };

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const handleEditWarehouse = () => {
    setDisableSubmitButton(true);
    dispatch(updateWarehouse(formData))
      .then((action) => {
        if (action.payload) {
          setShowModal(!showModal);
          setDisableSubmitButton(false);
          toast.success("Warehouse updated successfully!");
        } else {
          toast.error("Warehouse entry editing failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err.message || "An error occurred");
        setDisableSubmitButton(false);
      });
  };

  const handleEditWarehouseClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                <div className="mb-5">
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                    Warehouse Name<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    value={formData.name}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter warehouse name"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="address1" className="block mb-2 text-sm font-medium text-gray-900">
                    Address 1<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="address1"
                    onChange={(e) => setFormData({ ...formData, address1: e.target.value })}
                    value={formData.address1}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter address 1"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="address2" className="block mb-2 text-sm font-medium text-gray-900">
                    Address 2
                  </label>
                  <input
                    type="text"
                    id="address2"
                    onChange={(e) => setFormData({ ...formData, address2: e.target.value })}
                    value={formData.address2}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter address 2"
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="address2" className="block mb-2 text-sm font-medium text-gray-900">
                    Manager Contact No.<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={10}
                    id="address2"
                    onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
                    value={formData.contact}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter Phone Number"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Zipcode<span className="pl-1 text-red-500">*</span>
                  </label>
                  <div className="relative z-0 flex gap-[10px] mb-5 group">
                    <input
                      type="text"
                      id="zipcode"
                      onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                      value={formData.zipcode}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Enter zipcode"
                      required
                    />
                    <button
                      type="button"
                      onClick={handleSearchZipcode}
                      className="bg-black text-white px-[20px] py-[10px] rounded-lg"
                    >
                      Find
                    </button>
                  </div>
                </div>
                <div className="mb-5">
                  <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900">
                    City<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                    value={formData.city}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter city"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900">
                    State<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="state"
                    onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                    value={formData.state}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter state"
                    required
                  />
                </div>
                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit Warehouse
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <button
        className="py-4 border-2 border-black h-[44px] w-full flex items-center justify-center rounded-full text-nowrap px-[10px]"
        onClick={handleEditWarehouseClick}
      >
        Edit Warehouse
      </button>
    </>
  );
}

export default EditWarehouse;
