// import { FilledInput, FormControl, InputAdornment } from "@material-ui/core";
import { Autocomplete, FormControl, InputAdornment, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendors, fetchMoreVendors } from "../../reducers/Slices/vendorSlice";
import { createPurchaseInvoice } from "../../reducers/Slices/purchaseInvoiceSlice";
import { changeDateFormat } from "../../utils/dateFormat";
import { fetchHSNData, fetchProducts } from "../../reducers/Slices/productSlice";
import moment from "moment";

//UI Components
import IconWrapper from "../MicroComponents/IconWrapper";
import { X as Cross } from "react-feather";
import toast from "react-hot-toast";
import { debounce } from "lodash";

function CreatePurchaseInvoiceOther() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [items, setItems] = useState([{}]);
  const [hSNTaxRates, sethSNTaxRates] = useState([]);

  const [eBillValue, setEBillValue] = useState(null);
  const [invoiceIdValue, setInvoiceIdValue] = useState(null);
  const [billDate, setBillDate] = useState(moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //Amount value for display
  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);

  //Rate value for display
  const [purchaseRates, setPurchaseRates] = useState([0]);

  //business details
  const businessDetails = useSelector((state) => state.user);

  const params = useParams();

  useEffect(() => {
    dispatch(fetchVendors());
  }, [dispatch]);

  //Fetch both customer and vendor based on ID provided
  const vendorsNext = useSelector((state) => state.vendor.vendors.next);
  const vendor = useSelector((state) => state.vendor.vendors.data.find((v) => v.id === parseInt(params.id)));

  const [isIgst, setIsIgst] = useState(false);

  useEffect(() => {
    if (vendor?.gst_number?.length > 1 && businessDetails?.gst_number?.length > 1) {
      if (vendor?.gst_number?.slice(0, 2) === businessDetails?.gst_number?.slice(0, 2)) {
        setIsIgst(false);
      } else {
        setIsIgst(true);
      }
    } else {
      setIsIgst(false);
    }
  }, [vendor, businessDetails?.gst_number]);

  // Manually let user change IGST
  const handleIGSTDropdownChange = (event) => {
    setIsIgst(event.target.value === "true");
  };

  //Fetch vendors till end - this is bad code practice needs to be changed - pHaniver
  useEffect(() => {
    if (vendorsNext) {
      dispatch(fetchMoreVendors(vendorsNext));
    }
  }, [vendorsNext, dispatch]);

  //userType with initializer function to calculate userType on render
  const [formData, setFormData] = useState(() => {
    return { vendor: parseInt(params.id), payment_option: "cash", payment_type: "paid", description: null };
  });

  //Payment Options
  const paymentOptions = [
    { label: "Cash", value: "cash" },
    { label: "UPI", value: "upi" },
    { label: "Debit", value: "debit" },
    { label: "Credit", value: "credit" },
  ];

  useEffect(() => {
    handleCalculate();
    // eslint-disable-next-line
  }, [items, hSNTaxRates]);

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        order_date_time: billDate,
        ewaybill_number: eBillValue,
        invoice_counter: invoiceIdValue,
      };
    });
  }, [billDate, eBillValue, invoiceIdValue]);

  const handleCalculate = () => {
    const { total, discount, tax } = items.reduce(
      (accumulator, currentItem, index) => {
        if (purchaseRates[index]) {
          const quantity = parseInt(currentItem?.remaining_quantity ?? 0);
          let item_total = (purchaseRates[index] || 0) * quantity;
          let item_discount = item_total * (currentItem?.discount ? currentItem?.discount / 100 : 0);

          let item_after_discount = item_discount ? item_total - item_discount : item_total;
          let item_tax = item_after_discount * (hSNTaxRates[index] ? hSNTaxRates[index] / 100 : 0);

          accumulator.total += item_total;
          accumulator.discount += item_discount;
          accumulator.tax += item_tax;
        }
        return accumulator;
      },
      { total: 0, discount: 0, tax: 0 }
    );

    setFormData({
      ...formData,
      sub_total: total.toFixed(2),
      discount: discount.toFixed(2),
      grand_total: (total - discount + tax).toFixed(3).slice(0, -1),
      tax: tax.toFixed(3),
      paid_amount: (total - discount + tax).toFixed(3).slice(0, -1),
      remaining_total: 0.0,
      product_and_quantity: items,
    });
  };

  const handleRateChange = (value, index) => {
    const updatedPurchaseRates = purchaseRates;
    updatedPurchaseRates[index] = parseFloat(value);
    setPurchaseRates(updatedPurchaseRates);
    updateItemFields(index, items);
  };

  const handleFieldChange = (value, index, field) => {
    const updatedItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });

    setItems(updatedItems);
    updateItemFields(index, updatedItems);
  };

  const updateItemFields = (index, updatedItems) => {
    const rate = purchaseRates[index] ?? 0;

    const quantity = parseInt(
      updatedItems[index]?.remaining_quantity ? parseInt(updatedItems[index]?.remaining_quantity) : 0
    );

    const deal = parseInt(updatedItems[index]?.deal_quantity ?? 0);

    const discountPercent = parseFloat((updatedItems[index]?.discount ?? 0) / 100);
    const discount = rate * discountPercent;

    const total_quantity = deal + quantity;
    const purchase_price = ((rate - discount) * quantity) / total_quantity;

    setItems((prevItems) => {
      const nextItems = prevItems.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            amount: handleCalculateAmount(index, null, updatedItems),
            purchase_price,
            total_quantity,
          };
        }
        return item;
      });
      return nextItems;
    });
  };

  const handleCalculateAmount = (index, value, updatedItems) => {
    if (value || value === 0 || value === "0" || value === "") {
      setCalculatedAmountValue((prevItems) => {
        const updatedCalculatedAmount = prevItems.map((item, i) => {
          if (i === index) {
            return { calculated_amount: value ? parseFloat(value) : "" };
          }
          return item;
        });
        return updatedCalculatedAmount;
      });

      setItems((prevItems) => {
        const nextItems = prevItems.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              amount: value ? parseFloat(value) : "",
            };
          }
          return item;
        });
        return nextItems;
      });
      return 0;
    }

    const rate = parseFloat(purchaseRates[index] ?? 0);
    const quanitity = parseInt(updatedItems[index]?.remaining_quantity ?? 0);

    const discountPercent = parseFloat((updatedItems[index]?.discount ?? 0) / 100);
    const discount = rate * discountPercent;

    const taxPercent = parseFloat((hSNTaxRates[index] ?? 0) / 100);
    const tax = (rate - discount) * taxPercent;

    console.log(taxPercent, tax, "this is the tax percent and tax here");

    const amount = (rate + tax - discount) * quanitity;

    //Going the extra mile because toFixed(2) is rounding numbers resulting in wrong value.
    const truncatedAmount = (Math.floor(amount * 1000) / 1000).toFixed(3).slice(0, -1);

    setCalculatedAmountValue((prevItems) => {
      const updatedCalculatedAmount = prevItems.map((item, i) => {
        if (i === index) {
          return { calculated_amount: truncatedAmount };
        }
        return item;
      });
      return updatedCalculatedAmount;
    });

    return amount;
  };

  const handleRemoveItem = (index) => {
    if (items.length > 1) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }
    if (items.length > 1) {
      setCalculatedAmountValue((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }
    if (items.length > 1) {
      setPurchaseRates((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }
  };

  function validateProductAndQuantity(data) {
    const requiredFields = ["product_name", "batch_number", "mrp_price", "tax", "hsn_number"];

    return data.product_and_quantity.every((product) => {
      const { purchase_price, amount, remaining_quantity } = product;

      if (purchase_price === null || parseFloat(purchase_price) === 0) {
        toast.error("purchase price cannot be zero!");
        return false;
      }

      if (remaining_quantity === null || parseInt(remaining_quantity) === 0) {
        toast.error("remaining quantity cannot be zero!");
        return false;
      }

      if (amount == null) {
        toast.error("fill amount value");
        return false;
      }

      // Check if each required field in product exists and is not empty
      return requiredFields.every((field) => product[field] != null && product[field] !== "");
    });
  }

  const handleSubmit = () => {
    let updatedFormData = formData;
    if (formData.payment_type === "pay_later") {
      updatedFormData = {
        ...formData,
        remaining_total: formData.grand_total,
        paid_amount: "0",
      };
    }

    if (!validateProductAndQuantity(updatedFormData)) {
      toast.error("fill all fields which are marked with *");
      return;
    }

    console.log(updatedFormData, "this is the updated form data");
    setDisableSubmitButton(false);
    // dispatch(createPurchaseInvoice(updatedFormData))
    //   .then((action) => {
    //     if (action.payload) {
    //       setDisableSubmitButton(false);
    //       // dispatch(toggleAlert({ message: "Invoice created successfully!", type: "success", showAlert: true }));
    //       navigate(-1);
    //     } else {
    //       toast.error("sorry, Purchase Invoice Cannot be created");
    //       setDisableSubmitButton(false);
    //     }
    //   })
    //   .catch((err) => {
    //     toast.error("error", err);
    //     setDisableSubmitButton(false);
    //   });
  };

  //Move to next field on enter button
  const inputRefs = useRef([]);
  const submitButton = useRef(null);
  const addAnotherItemRef = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items?.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        if (focusedElement === submitButton.current) {
          submitButton.current.click();
          return;
        }
        if (focusedElement === addAnotherItemRef.current) {
          addAnotherItemRef.current.click();

          setTimeout(() => {
            const lastRowIndex = inputRefs.current.length - 1;
            // After clicking, shift focus to the first element of the last row
            if (inputRefs.current[lastRowIndex] && inputRefs.current[lastRowIndex][0]) {
              inputRefs.current[lastRowIndex][0].querySelector("input").focus();
            }
          }, 300); // Adding a 300ms delay before shifting focus
          return;
        }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row
              // if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
              //   submitButton.current.focus(); // Focus on the submit button
              // }
              if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
                if (window.confirm("want to add another item?")) {
                  addAnotherItemRef.current.focus(); // Focus on add another item button
                } else {
                  submitButton.current.focus(); //Focus on submit button
                }
              }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [items]);

  //Fetch initial HSN data;
  useEffect(() => {
    dispatch(fetchHSNData());
  }, []);

  //HSN codes
  const HSN_data = useSelector((state) => state.product.HSNData.data);

  const handleHSNNumberChange = (value, index) => {
    handleFieldChange(value, index, "hsn_number");

    const hsnData = getHSNDataByHSNCode(value);

    const taxPercent = parseFloat(hsnData?.cgst_rate) + parseFloat(hsnData?.sgst_rate);

    const updatedHsnTaxRates = hSNTaxRates;

    //Change tax rate
    updatedHsnTaxRates[index] = taxPercent;

    sethSNTaxRates([...updatedHsnTaxRates]);
  };

  const [hSNSearchTerm, sethSNSearchTerm] = useState("");

  useEffect(() => {
    if (hSNSearchTerm.length > 0) {
      dispatch(fetchHSNData(`?hsn_code=${hSNSearchTerm}`));
    }
  }, [hSNSearchTerm]);

  const setDebouncedHSNSearchTerm = debounce((value) => sethSNSearchTerm(value), 1000);

  useEffect(() => {
    console.log(hSNTaxRates, "this is the HSN tax rate");
  }, [hSNTaxRates]);

  const getHSNDataByHSNCode = (hsn_number) => {
    return HSN_data.find((HSNData) => HSNData?.hsn_code === hsn_number);
  };

  return (
    <>
      <div>
        <div className="mx-[20px] my-[20px]">
          <h1 className="text-2xl font-bold"> New Business Purchase Invoice </h1>
          <div className="mt-8 w-full flex md:justify-start border-b-2 border-neutral-200">
            <div className="md:w-3/6 w-full">
              <h3 className="font-semibold text-xl text-black">{vendor?.vendor_name}</h3>
              <span className="font-semibold text-neutral-500">{`${vendor?.address} ${vendor?.zipcode}`}</span>

              <div className="flex text-base gap-2 my-5">
                <h3 className="font-semibold text-black">GST Number: </h3>
                <span className="font-semibold text-neutral-500">
                  {vendor?.gst_number ? vendor?.gst_number?.toUpperCase() : "Not Provided"}
                </span>
              </div>
              {/* Optional E-Bill ID */}
              <div className="flex gap-2 items-center my-5">
                <label htmlFor="Ebill" className="font-semibold text-black w-[100px]">
                  <Tooltip title="Optional Field: If you have purchase invoice from vendor and want same Invoice ID">
                    <span className="border-b border-dotted border-black pb-[0.8px]">Invoice Id: </span>
                  </Tooltip>
                </label>
                <input
                  type="text"
                  className="border border-neutral-300 px-[20px] py-[10px] rounded-md w-[200px]"
                  value={invoiceIdValue}
                  onChange={(e) => setInvoiceIdValue(e.target.value)}
                />
              </div>
              {/* Optional E-Bill ID */}
              <div className="flex gap-2 items-center my-5">
                <label htmlFor="Ebill" className="font-semibold text-black w-[100px]">
                  <Tooltip title="Optional Field: E-bill ID">
                    <span className="border-b border-dotted border-black pb-[0.8px]">E-WayBill Id: </span>
                  </Tooltip>
                </label>
                <input
                  type="text"
                  className="border border-neutral-300 px-[20px] py-[10px] rounded-md w-[200px]"
                  value={eBillValue}
                  onChange={(e) => setEBillValue(e.target.value)}
                />
              </div>
              <div className="flex gap-2 items-center my-5">
                <label htmlFor="date" className="font-semibold text-black w-[100px]">
                  Bill Date:
                </label>
                <input
                  type="date"
                  className="border border-neutral-300 px-[20px] py-[10px] rounded-md w-[200px]"
                  value={changeDateFormat(billDate)}
                  onChange={(e) => setBillDate(moment(new Date(e.target.value)).format("YYYY-MM-DD hh:mm:ss"))}
                  // onChange={(e) => setBillDate(e.target.value)}
                  max={changeDateFormat(Date.now())}
                />
              </div>
              <div className="flex gap-2 items-center my-5">
                <label htmlFor="igst-dropdown" className="font-semibold text-black w-[100px]">
                  Is IGST:
                </label>
                <select
                  id="igst-dropdown"
                  value={isIgst}
                  onChange={handleIGSTDropdownChange}
                  className="border bg-white px-[20px] py-[10px]"
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-[20px] overflow-x-scroll">
              <table cellPadding={5} cellSpacing={10} className="3xl:w-[100%] w-[180%]">
                <tr>
                  <th>
                    Product Name<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>
                    Batch Number<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>
                    HSN/SAC<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>
                    Qty<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>Deal / Free</th>
                  <th>
                    MRP<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>
                    Rate<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>
                    Tax %<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>Discount % </th>
                  <th>
                    Amount<span className="pl-1 text-red-500">*</span>
                  </th>
                </tr>
                {items?.map((item, index) => (
                  <tr key={item?.productId || index} className="mb-4">
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        value={items[index]?.batch?.product_name}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "product_name");
                        }}
                        // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                        type="text"
                        variant="outlined"
                        required
                      />
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        value={items[index]?.batch?.batch_number}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "batch_number");
                        }}
                        // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                        type="text"
                        variant="outlined"
                        required
                      />
                    </td>
                    <td>
                      <Autocomplete
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][2] = el; // Assign the ref if both checks pass
                          }
                        }}
                        disablePortal
                        id="hsn-number"
                        options={HSN_data || null}
                        style={{ borderColor: "black", minWidth: "200px" }}
                        // value={getHSNDataByHSNCode(addProductFormData?.hsn_number)}
                        getOptionLabel={(option) => option?.hsn_code}
                        onChange={(event, value) => {
                          handleHSNNumberChange(value?.hsn_code, index);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            onChange={(e) => setDebouncedHSNSearchTerm(e.target.value)}
                          />
                        )}
                      />
                    </td>

                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][3] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                            maxWidth: "20ch",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "remaining_quantity");
                        }}
                        value={items[index]?.batch?.remaining_quantity}
                        type="number"
                        variant="outlined"
                        required
                      />
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][4] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                            maxWidth: "20ch",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "deal_quantity");
                        }}
                        value={items[index]?.batch?.deal_quantity}
                        defaultValue={0}
                        type="number"
                        variant="outlined"
                      />
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][5] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "mrp_price");
                        }}
                        value={items[index]?.batch?.mrp_price}
                        type="number"
                        variant="outlined"
                        required
                      />
                    </td>
                    <td>
                      <TextField
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][6] = el; // Assign the ref if both checks pass
                          }
                        }}
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          handleRateChange(e.target.value, index);
                        }}
                        value={purchaseRates[index]}
                        type="number"
                        variant="outlined"
                      />
                    </td>

                    <td>
                      <TextField
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        type="number"
                        className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "tax");
                        }}
                        value={hSNTaxRates[index]}
                        disabled
                        required
                      />
                    </td>

                    <td>
                      <TextField
                        id="outlined-basic"
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][7] = el; // Assign the ref if both checks pass
                          }
                        }}
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                            maxWidth: "12ch",
                          },
                        }}
                        onChange={(e) => {
                          handleFieldChange(e.target.value, index, "discount");
                        }}
                        value={items[index]?.batch?.discount}
                        type="number"
                        variant="outlined"
                      />
                    </td>

                    <td>
                      <TextField
                        id="outlined-basic"
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][8] = el; // Assign the ref if both checks pass
                          }
                        }}
                        inputProps={{
                          style: {
                            height: "20px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => handleCalculateAmount(index, e.target.value)}
                        value={calculatedAmountValue[index]?.calculated_amount || 0}
                        type="text"
                        variant="outlined"
                      />
                    </td>

                    <td
                      onClick={() => {
                        handleRemoveItem(index);
                      }}
                    >
                      <IconWrapper>
                        <Cross className="text-white z-10" />
                      </IconWrapper>
                    </td>
                  </tr>
                ))}
              </table>
              <button
                ref={addAnotherItemRef}
                className="text-black p-2 cursor-pointer pb-[20px]"
                onClick={() => {
                  setItems((prev) => [...prev, {}]);
                  setCalculatedAmountValue((prev) => [...prev, {}]);
                  setPurchaseRates((prev) => [...prev, 0]);
                }}
              >
                + Add another item
              </button>
            </div>
            <div>
              <div className="w-full flex border-t-2 border-neutral-200">
                <div className="w-full">
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">SubTotal</h3>
                    <h3 className="font-semibold text-gray-500">{formData.sub_total || 0}</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Discount</h3>
                    <h3 className="font-semibold text-gray-500">{formData.discount || 0}</h3>
                  </div>
                  {!isIgst && (
                    <>
                      <div className="flex justify-between my-5">
                        <h3 className="font-semibold text-gray-500">SGST @tax%</h3>
                        <h3 className="font-semibold text-gray-500">
                          {(formData.tax / 2).toFixed(3).slice(0, -1) || 0}
                        </h3>
                      </div>
                      <div className="flex justify-between my-5">
                        <h3 className="font-semibold text-gray-500">CGST @tax%</h3>
                        <h3 className="font-semibold text-gray-500">
                          {(formData.tax / 2).toFixed(3).slice(0, -1) || 0}
                        </h3>
                      </div>
                    </>
                  )}
                  {isIgst && (
                    <div className="flex justify-between my-5">
                      <h3 className="font-semibold text-gray-500">IGST @tax%</h3>
                      <h3 className="font-semibold text-gray-500"> {formData?.tax?.slice(0, -1) || 0}</h3>
                    </div>
                  )}
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Total</h3>
                    <h3 className="font-semibold text-gray-500">{formData?.grand_total || 0}</h3>
                  </div>

                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Payment Options</h3>
                    <h3 className="font-semibold text-gray-500">
                      <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white sm:flex">
                        {paymentOptions.map((option, index) => (
                          <li key={option.value} className="w-full">
                            <div className="flex items-center ml-2">
                              <input
                                id="horizontal-list-radio-id"
                                type="radio"
                                value={option.value}
                                onChange={(e) => {
                                  let payment_type = "paid";
                                  if (e.target.value === "debit") {
                                    payment_type = "pay_later";
                                  }
                                  setFormData(() => {
                                    const updatedData = {
                                      ...formData,
                                      payment_option: e.target.value,
                                      payment_type,
                                    };
                                    return updatedData;
                                  });
                                }}
                                checked={formData.payment_option === option.value}
                                name="list-radio"
                                className="w-4 h-4 text-blue-600"
                              />
                              <label for="horizontal-list-radio-id" className="w-full ms-2 px-1 text-base font-medium">
                                {option.label}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Description</h3>
                    <h3 className="font-semibold text-gray-500">
                      <textarea
                        className="w-full min-w-[300px] h-full min-h-[80px] p-2 border-2"
                        placeholder=""
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        value={formData.description}
                      ></textarea>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="w-full flex items-center justify-center my-4">
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={disableSubmitButton}
                  ref={submitButton}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  className="fixed bottom-[5vh] text-white bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg min-[1124px]:text-sm text-xs px-5 py-2.5 text-center"
                >
                  Create Purchase Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePurchaseInvoiceOther;
