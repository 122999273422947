import React, { useState, useEffect } from "react";
import { formatIndianNumber } from "../../../../utils/fomatIndianNumber";
// import "./SharedStyles.scss";

const CommonLists = ({ title, type, data, labels }) => {
  // const [seeAll, setSeeAll] = useState(false);
  const [list, setList] = useState(data);

  useEffect(() => {
    setList(data);
  }, [setList, data]);

  return (
    <>
      <div className="border-2 rounded-[20px] max-h-[26vh] xl:max-h-[28vh] overflow-y-scroll">
        <div className="text-center text-neutral-500 text-sm py-[20px]">{title}</div>
        <div>
          <div className="px-[20px] flex w-full text-sm justify-between border-neutral-200 border-b-2">
            {labels?.map((label, index) => (
              <span key={index} className="text-neutral-500">
                {label}
              </span>
            ))}
          </div>
          {list.length === 0 && <div className="text-sm text-center py-[10px]">No data found!</div>}
          {list.map((name, index) => {
            return (
              <div key={index} className="flex justify-between mx-[20px] border-b-2 last:border-b-0 py-2">
                <div className="text-sm">{index + 1}.</div>
                <div className="text-sm text-nowrap">
                  {" "}
                  {name.name.length > 15 ? name.name.slice(0, 15) + "..." : name.name}{" "}
                </div>
                <div className="text-sm">
                  {" "}
                  {type === "TSI"
                    ? name?.total_selling_quantity
                    : `₹${formatIndianNumber(Math.round(name?.amount || 0))}`}
                </div>
              </div>
            );
          })}
        </div>
        {/* <div
          className="list-see-all-btn"
          onClick={() => {
            setSeeAll(!seeAll);
          }}
        >
          {seeAll ? "See less" : "See more..."}
        </div> */}
      </div>
      {/* <div></div> */}
    </>
  );
};

export default CommonLists;
