import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import { changeDateFormat } from "../../utils/dateFormat";
import axios from "axios";

// fetchEmployee - fetch all employees - or search employee
export const fetchEmployees = createAsyncThunk("employee/fetchEmployee", async (data, extra) => {
  const response = await http.get(`/employee${data ?? "/"}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data;
  }
});

//fetchMoreEmployees - fetch more employees - react infinity scroll
export const fetchMoreEmployees = createAsyncThunk("employee/fetchMoreEmployees", async (url, extra) => {
  const res = await axios.get(url, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// createEmployee - create new employee
export const createEmployee = createAsyncThunk("employee/createEmployee", async (employeeData, extra) => {
  const response = await http.post(`/employee/`, employeeData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    //Create new attendance for new employee
    const attendanceData = {
      employee: response.data.data.id,
      date: changeDateFormat(new Date()),
      check_in_time: "09:00:00",
      check_out_time: "17:00:00",
      status: "Absent",
    };

    extra.dispatch(createAttendance(attendanceData));
    return response.data;
  }
});

//updateEmployee - update a employee
export const updateEmployee = createAsyncThunk("employee/updateEmployee", async (data, extra) => {
  const response = await http.put(`/employee/${data.id}/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

// deleteEmployee - delete a particular employee
export const deleteEmployee = createAsyncThunk("employee/deleteEmployee", async (data, extra) => {
  const response = await http.delete(`/employee/${data}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.status === 204) {
    return data;
  }
});

// getEmployeeById - get particular employee by ID
export const getEmployeeById = createAsyncThunk("employee/getEmployeeById", async (data, extra) => {
  const response = await http.get(`/employee/${data.id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

// fetchAttendances  - fetch all attendances
export const fetchAttendances = createAsyncThunk("employee/attendances", async (data, extra) => {
  const response = await http.get(
    `/employee/attendance${data?.date ? `?start_date=${data.date}&end_date=${data.date}` : "/"}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (response.data.status === "success") {
    return response.data;
  }
});

//fetchMoreEmployees - fetch more employees - react infinity scroll
export const fetchMoreAttendances = createAsyncThunk("employee/fetchMoreAttendances", async (url, extra) => {
  const res = await axios.get(url, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// createAttendance - create attendance
export const createAttendance = createAsyncThunk("employee/createAttendance", async (data, extra) => {
  const response = await http.post("/employee/attendance/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

// updateAttendance - update attendance based on employee id
export const updateAttendance = createAsyncThunk("employee/updateAttendance", async (data, extra) => {
  const response = await http.put(`/employee/attendance/${data.employee}/${data.id}/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

// create attendance by date / return all today's attendance
export const createAllAttendances = createAsyncThunk("employee/createAllAttendances", async (data, extra) => {
  const response = await http.post(`/employee/attendance/mark-all-attendance/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

// export const deleteAttendance = createAsyncThunk("employee/deleteAttendance", async (data, extra) => {
//   const IdByEmployee = extra
//     .getState()
//     .employee.attendances.find((attendance) => attendance.employee === data.employee).id;

//   if (IdByEmployee) {
//     const response = await http.delete(`/employee/attendance/${data.employee}/${IdByEmployee}/`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     });
//     //Fix it after API change
//     return IdByEmployee;
//   }
// });

// employee slice
const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employees: { data: [], next: "" },
    employeeById: [],
    attendances: { data: [], next: "" },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmployees.fulfilled, (state, action) => {
      return {
        ...state,
        employees: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreEmployees.fulfilled, (state, action) => {
      return {
        ...state,
        employees: { data: [...state.employees.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(createEmployee.fulfilled, (state, action) => {
      return {
        ...state,
        employees: { data: [...state.employees.data, action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      return {
        ...state,
        employees: {
          ...state.employees,
          data: state.employees.data.map((employee) => (employee.id === action.payload.id ? action.payload : employee)),
        },
      };
    });
    builder.addCase(getEmployeeById.fulfilled, (state, action) => {
      return {
        ...state,
        employeeById: [action.payload],
      };
    });
    builder.addCase(deleteEmployee.fulfilled, (state, action) => {
      return {
        ...state,
        employees: {
          ...state.employees,
          data: state.employees.data.filter((employee) => employee.id !== action.payload),
        },
      };
    });
    builder.addCase(fetchAttendances.fulfilled, (state, action) => {
      return {
        ...state,
        attendances: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreAttendances.fulfilled, (state, action) => {
      return {
        ...state,
        attendances: { data: [...state.attendances.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(createAttendance.fulfilled, (state, action) => {
      return {
        ...state,
        attendances: { ...state.attendances, data: [...state.attendances.data, action.payload] },
      };
    });
    builder.addCase(updateAttendance.fulfilled, (state, action) => {
      return {
        ...state,
        attendances: {
          ...state.attendances,
          data: state.attendances.data.map((attendance) =>
            attendance.id === action.payload.id ? action.payload : attendance
          ),
        },
      };
    });
    builder.addCase(createAllAttendances.fulfilled, (state, action) => {
      return {
        ...state,
        attendances: {
          data: action.payload,
          next: null,
        },
      };
    });
    // builder.addCase(deleteAttendance.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     attendances: state.attendances.filter((attendance) => attendance.id !== action.payload),
    //   };
    // });
  },
});

export default employeeSlice.reducer;
