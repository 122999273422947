import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";

function DraggableCalculator({ setShowCalculator }) {
  const [input, setInput] = useState("");

  const calculate = (input) => {
    const numbers = input.split(/[\+\-\*\/]/).map(Number);
    const operators = input.split(/[0-9.]+/).filter(Boolean);

    let result = numbers[0];

    for (let i = 0; i < operators.length; i++) {
      const operator = operators[i];
      const nextNumber = numbers[i + 1];

      switch (operator) {
        case "+":
          result += nextNumber;
          break;
        case "-":
          result -= nextNumber;
          break;
        case "X":
        case "*":
          result *= nextNumber;
          break;
        case "/":
          result /= nextNumber;
          break;
        default:
          break;
      }
    }

    return result;
  };

  const handleButtonClick = (value) => {
    setInput((prevInput) => {
      let updatedInput = prevInput;

      if (value === "=") {
        if (updatedInput) {
          try {
            const result = calculate(updatedInput);
            updatedInput = result;
          } catch (error) {
            updatedInput = "Error";
          }
        }
      } else if (value === "C") {
        updatedInput = ""; // Clear the input
      } else if (value === "+-") {
        updatedInput = updatedInput.startsWith("-") ? updatedInput.slice(1) : "-" + updatedInput;
      } else {
        // Append the button value (concatenating it to the input string)
        updatedInput = updatedInput + (value === "X" ? "*" : value);
      }

      return updatedInput;
    });
  };

  const handleKeyPress = (event) => {
    event.preventDefault();
    const keyMap = {
      Enter: "=",
      Backspace: "C", // Clear on backspace
      Escape: "C", // Clear on escape
      0: 0,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      ".": ".",
      "+": "+",
      "-": "-",
      "*": "X", // Use "X" for multiplication
      "/": "/",
      "%": "%",
    };

    const keyValue = keyMap[event.key];
    if (keyValue !== undefined) {
      handleButtonClick(keyValue);
    }
  };

  // Add event listener for keydown
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const buttons = [
    ["C", "+-", "%", "/"],
    [7, 8, 9, "X"],
    [4, 5, 6, "-"],
    [1, 2, 3, "+"],
    [0, ".", "="],
  ];

  return (
    <Draggable
      bounds={{
        bottom: 0,
        right: 0,
        top: -(window.innerHeight - window.innerHeight * 0.56),
        left: -(window.innerWidth - window.innerWidth * 0.2),
      }}
    >
      <div className="absolute bottom-[40px] right-[20px] p-4 rounded cursor-move shadow-md bg-white border border-neutral-200">
        <div className="w-full flex justify-end -mt-2">
          <button className="pb-2 text-red-500" onClick={() => setShowCalculator(false)}>
            Close X
          </button>
        </div>
        <div className="mb-2">
          <input
            type="text"
            value={input || 0}
            readOnly
            className="p-2 pr-[10px] py-5 w-full border border-neutral-200 rounded text-end"
          />
        </div>
        <div className="grid grid-cols-4 gap-1">
          {buttons.flat().map((value) => (
            <button
              key={value}
              onClick={() => {
                handleButtonClick(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent the default behavior for "Enter" key
                }
              }}
              className={`p-4 rounded hover:bg-gray-300 ${
                ["C", "+-", "%"].includes(value)
                  ? "bg-orange-500"
                  : ["/", "X", "-", "+", "="].includes(value)
                  ? "bg-[#90bed1]"
                  : "bg-gray-200"
              } ${value === 0 ? "col-span-2" : ""}`}
            >
              {value === "X" ? "*" : value} {/* Display "X" as "*" */}
            </button>
          ))}
        </div>
      </div>
    </Draggable>
  );
}

export default DraggableCalculator;
