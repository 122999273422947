import React, { useState, useEffect } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
// import { updateExpense } from "../../../reducers/Slices/expenseSlice";
import { getCashRegisterAnalytics, updateCashRegistery } from "../../../reducers/Slices/cashRegisterSlice";
import { debounce } from "lodash";
import { fetchVendors } from "../../../reducers/Slices/vendorSlice";
import { fetchCustomers } from "../../../reducers/Slices/customerSlice";
import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";

function EditCashRegistery({ cashRegistery }) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    id: cashRegistery?.id || 0,
    date: cashRegistery?.date || "",
    name: cashRegistery?.name || "",
    customer: cashRegistery?.customer?.id,
    vendor: cashRegistery?.vendor?.id,
    amount: cashRegistery?.amount || 0,
    type: cashRegistery?.type || "payment", // Default type set to "payment"
    note: cashRegistery?.note || "",
  });
  const [showModal, setShowModal] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //switch between customer and vendor
  const [selectedType, setSelectedType] = useState("customer");

  //adjust based on cash register entry
  //make other one null when it is changed
  const handleTypeChange = (event) => {
    const type = event.target.value;
    let updatedFormData = formData;
    setSelectedType(type);

    //based on user type - change type of payment (receipt | payment)
    if (type === "normal" || type === "customer") {
      updatedFormData = { ...updatedFormData, type: "receipt" };
    } else {
      updatedFormData = { ...updatedFormData, type: "payment" };
    }
    setFormData({
      ...updatedFormData,
      customer: type !== "normal" ? (type === "customer" ? formData.customer : null) : null,
      vendor: type !== "normal" ? (type === "vendor" ? formData.vendor : null) : null,
      name: type !== "normal" ? "" : formData.name,
    });
  };

  // Reset cash registery values on change
  useEffect(() => {
    setFormData({
      id: cashRegistery?.id,
      date: cashRegistery?.date,
      name: cashRegistery?.name,
      customer: cashRegistery?.customer?.id,
      vendor: cashRegistery?.vendor?.id,
      amount: cashRegistery?.amount,
      type: cashRegistery?.type || "payment",
      note: cashRegistery?.note,
    });
  }, [cashRegistery]);

  const handleSubmit = () => {
    handleEditCashRegistery();
  };

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const handleEditCashRegistery = () => {
    setDisableSubmitButton(true);
    dispatch(updateCashRegistery(formData))
      .then((action) => {
        if (action.payload) {
          dispatch(getCashRegisterAnalytics());
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("Cash registery entry editing failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const handleEditCashRegisteryClick = () => {
    dispatch(fetchCustomers());
    dispatch(fetchVendors());
    setShowModal(!showModal);
  };

  //vendor operations
  const vendors = useSelector((state) => state.vendor.vendors.data);

  const [vendorSearchTerm, setVendorSearchTerm] = useState("");

  const setDebouncedVendorSearchTerm = debounce((value) => setVendorSearchTerm(value), 500);

  useEffect(() => {
    if (vendorSearchTerm.length > 0) {
      dispatch(fetchVendors(`?search=${vendorSearchTerm}`));
    }

    //eslint-disable-next-line
  }, [vendorSearchTerm]);

  const [selectedVendor, setSelectedVendor] = useState(null);

  // const getVendorById = (id) => {
  //   return vendors.find((vendor) => vendor.id === id);
  // };

  //customer operations
  const customers = useSelector((state) => state.customer.customers.data);

  const [customerSearchTerm, setCustomerSearchTerm] = useState("");

  const setDebouncedCustomerSearchTerm = debounce((value) => setCustomerSearchTerm(value), 500);

  useEffect(() => {
    if (customerSearchTerm.length > 0) {
      dispatch(fetchCustomers(`?search=${customerSearchTerm}`));
    }
    //eslint-disable-next-line
  }, [customerSearchTerm]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  //get customer by id
  // const getCustomerById = async (id) => {
  //   const customerFound = customers.find((customer) => customer.id === id);
  // };

  useEffect(() => {
    if (cashRegistery?.customer !== null) {
      setSelectedCustomer(cashRegistery.customer);
      setSelectedType("customer");
    } else if (cashRegistery?.vendor !== null) {
      setSelectedVendor(cashRegistery.vendor);
      setSelectedType("vendor");
    } else {
      setSelectedType("normal");
    }
    //eslint-disable-next-line
  }, [cashRegistery]);

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                <div className="mb-5">
                  <label className="block mb-2 text-lg text-neutral-500 text-center">Select Type of Party</label>
                  <div className="flex justify-center ">
                    <RadioGroup row value={selectedType} onChange={handleTypeChange}>
                      <FormControlLabel value="customer" control={<Radio />} label="Customer" />
                      <FormControlLabel value="vendor" control={<Radio />} label="Vendor" />
                      <FormControlLabel value="normal" control={<Radio />} label="Normal" />
                    </RadioGroup>
                  </div>
                </div>

                {selectedType === "normal" && (
                  <div className="mb-5">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                      Name<span className="pl-1 text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      value={formData.name}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Enter name"
                      required
                    />
                  </div>
                )}

                {/* Vendor Autocomplete */}
                {selectedType === "vendor" && (
                  <div className="mb-5">
                    <label htmlFor="vendor" className="block mb-2 text-sm font-medium text-gray-900">
                      Vendor<span className="pl-1 text-red-500">*</span>
                    </label>
                    <Autocomplete
                      disablePortal
                      id="vendor"
                      options={vendors}
                      // value={getVendorById(formData?.vendor) || null}
                      value={selectedVendor}
                      getOptionLabel={(option) => option.vendor_name}
                      onChange={(event, value) => {
                        setFormData({ ...formData, vendor: value ? value.id : null });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          onChange={(e) => setDebouncedVendorSearchTerm(e.target.value)}
                        />
                      )}
                    />
                  </div>
                )}

                {/* Customer Autocomplete */}
                {selectedType === "customer" && (
                  <div className="mb-5">
                    <label htmlFor="customer" className="block mb-2 text-sm font-medium text-gray-900">
                      Customer<span className="pl-1 text-red-500">*</span>
                    </label>
                    <Autocomplete
                      disablePortal
                      id="customer"
                      options={customers}
                      // value={getCustomerById(formData?.customer) || null}
                      value={selectedCustomer}
                      getOptionLabel={(option) => option.customer_name}
                      onChange={(event, value) => setFormData({ ...formData, customer: value ? value.id : null })}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          onChange={(e) => setDebouncedCustomerSearchTerm(e.target.value)}
                        />
                      )}
                    />
                  </div>
                )}
                <div className="mb-5">
                  <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">
                    Date<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    id="date"
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    value={formData.date}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">
                    Amount<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    id="amount"
                    value={formData.amount}
                    onChange={(e) => setFormData({ ...formData, amount: parseFloat(e.target.value) })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter amount"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900">
                    Type<span className="pl-1 text-red-500">*</span>
                  </label>
                  <select
                    id="type"
                    value={formData.type}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  >
                    <option value="payment">Payment</option>
                    <option value="receipt">Receipt</option>
                  </select>
                </div>
                <div className="mb-5">
                  <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900">
                    Note
                  </label>
                  <textarea
                    id="note"
                    rows="4"
                    onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                    className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    value={formData.note}
                    placeholder="Enter note"
                  ></textarea>
                </div>
                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit Cash Entry
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <button
        className="py-4 border-2 border-black h-[44px] w-full flex items-center justify-center rounded-full"
        onClick={handleEditCashRegisteryClick}
      >
        Edit Entry
      </button>
    </>
  );
}

export default EditCashRegistery;
