import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { subDays } from "date-fns";
import { changeDateFormat } from "../../utils/dateFormat";

//UI components
import IconWrapper from "../MicroComponents/IconWrapper";
import DetailContainer from "../MicroComponents/DetailContainer";
import { Search, Filter } from "react-feather";
import DateRangeSelector from "./OnlineOrderComponents/DateRangeSelector";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import convertTimestamp from "../../utils/convertTimeStamp";
import {
  changeOnlineOrderStatus,
  fetchMoreOnlineOrders,
  fetchOnlineOrders,
  getOrderDetailsByID,
} from "../../reducers/Slices/onlineOrderSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import Joyride from "react-joyride";

function OnlineOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedOrder, setSelectedOrder] = useState({});

  const orderDetails = useSelector((state) => state.onlineOrder.orderDetails);

  const [showMenu, setShowMenu] = useState(false);

  //handle Filter button Menu
  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  //Base string for fetchCustomerAndVendor Reducer API
  const [stringQuery, setStringQuery] = useState("/");

  //Days filter
  const [daysFilter, setDaysFilter] = useState("last_7_days");

  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //from date and to date
  const [fromDate, setFromDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - 7);
    return changeDateFormat(pastDate);
  });

  const [toDate, setToDate] = useState(() => {
    return changeDateFormat(new Date());
  });

  //Order search functionality
  useEffect(() => {
    setStringQuery(() => {
      let initialString = "/";

      //Guard Clause
      if (searchTerm.length === 0) {
        return `/?status=${filter}&startDate=${fromDate}&endDate=${toDate}`;
      }

      //Setting search term
      if (searchTerm.length > 0) {
        initialString += `?search=${searchTerm}`;
      }

      //Setting filter string
      if (searchTerm.length === 0) {
        initialString += `?status=${filter}`;
      } else if (filter.length > 0) {
        initialString += `&status=${filter}`;
      }

      return initialString + `&startDate=${fromDate}&endDate=${toDate}`;
    });
  }, [filter, searchTerm, fromDate, toDate]);

  //Debounced SearchTerm
  const debouncedSetSearchTerm = debounce((term) => {
    setSearchTerm(term);
  }, 1000);

  //Search using API
  useEffect(() => {
    dispatch(fetchOnlineOrders(stringQuery))
      .then((action) => {
        if (action.payload) {
        }
      })
      .catch((err) => {
        toast.error("Failed to fetch products!");
      });
  }, [stringQuery, dispatch]);

  //side effect based on date range change - custom date setFromDate and setToDate
  useEffect(() => {
    setFromDate(changeDateFormat(dateRange[0].startDate));
    setToDate(changeDateFormat(dateRange[0].endDate));
  }, [dateRange]);

  //side effect to set date based on filter selected
  useEffect(() => {
    const today = new Date();
    const pastDate = new Date();

    if (daysFilter === "custom") {
      return;
    } else if (daysFilter === "last_7_days") {
      pastDate.setDate(today.getDate() - 7);
    } else if (daysFilter === "last_30_days") {
      pastDate.setDate(today.getDate() - 30);
    }

    setFromDate(changeDateFormat(pastDate));
    setToDate(changeDateFormat(today));
  }, [daysFilter]);

  //Fake order data
  // const orders = [
  //   {
  //     id: 1,
  //     order_details: {
  //       order_status: "Pending",
  //       ordered_on: "20/10/2024",
  //       total_amount: "₹20,000",
  //       type: "Delivery",
  //       store_id: 1,
  //       name: "Imagine LTD",
  //       contact_number: "+91-8290194551",
  //       address: "357 Maplewood Drive, Greenfield, IL 62704, USA",
  //       payment_method: "Cash on Delivery",
  //       slot_selected: "7 AM to 7 PM",
  //       ordered_products: [
  //         {
  //           product_name: "Oneplus 6T",
  //           product_brand: "Oneplus",
  //           product_batch: "IQT28M",
  //           quantity: 10,
  //         },
  //         {
  //           product_name: "Iphone 13",
  //           product_brand: "Apple",
  //           product_batch: "PMT28M",
  //           quantity: 24,
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 2,
  //     order_details: {
  //       order_status: "Fulfilled",
  //       ordered_on: "20/10/2024",
  //       total_amount: "₹20,000",
  //       type: "Pick Up",
  //       store_id: 10,
  //       name: "Moritus LTD",
  //       contact_number: "+91-8290194551",
  //       address: "357 Maplewood Drive, Greenfield, IL 62704, USA",
  //       payment_method: "UPI",
  //       slot_selected: "7 AM to 7 PM",
  //       ordered_products: [
  //         {
  //           product_name: "Oneplus 6T",
  //           product_brand: "Oneplus",
  //           product_batch: "IQT28M",
  //           quantity: 10,
  //         },
  //         {
  //           product_name: "Iphone 13",
  //           product_brand: "Apple",
  //           product_batch: "PMT28M",
  //           quantity: 24,
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 3,
  //     order_details: {
  //       order_status: "Cancelled",
  //       ordered_on: "20/10/2024",
  //       total_amount: "₹20,000",
  //       type: "Pick Up",
  //       store_id: 2,
  //       name: "Malobar LTD",
  //       contact_number: "+91-8230194551",
  //       address: "357 Maplewood Drive, Greenfield, IL 62704, USA",
  //       payment_method: "Cash On Delivery",
  //       slot_selected: "6 AM to 4 PM",
  //       ordered_products: [
  //         {
  //           product_name: "Oneplus 6T",
  //           product_brand: "Oneplus",
  //           product_batch: "IQT28M",
  //           quantity: 10,
  //         },
  //         {
  //           product_name: "Iphone 13",
  //           product_brand: "Apple",
  //           product_batch: "PMT28M",
  //           quantity: 24,
  //         },
  //       ],
  //     },
  //   },
  // ];

  const orders = useSelector((state) => state.onlineOrder.orders.data);
  const ordersNext = useSelector((state) => state.onlineOrder.orders.next);

  const [hasMoreOrders, setHasMoreOrders] = useState(() => (ordersNext ? true : false));

  const fetchMoreOrders = () => {
    if (ordersNext) {
      dispatch(fetchMoreOnlineOrders(ordersNext));
    }
  };
  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (ordersNext) {
      setHasMoreOrders(true);
    } else {
      setHasMoreOrders(false);
    }
  }, [ordersNext]);

  //Side effect to select first order on render
  useEffect(() => {
    setSelectedOrder(orders[0]);
  }, [orders]);

  useEffect(() => {
    if (!selectedOrder?.id) {
      return;
    }
    // setTimerEnd(selectedOrder?.order_details?.timerStatus);
    dispatch(getOrderDetailsByID(selectedOrder?.id));
  }, [selectedOrder, dispatch]);

  const orderStatusColor = (orderStatus) => {
    if (orderStatus === "Fulfilled" || orderStatus === "fulfilled") {
      return "text-green-500";
    } else if (orderStatus === "Cancelled" || orderStatus === "cancelled") {
      return "text-neutral-500";
    } else if (orderStatus === "Pending" || orderStatus === "pending") {
      return "text-red-500";
    } else if (orderStatus === "Pickup" || orderStatus === "pickup") {
      return "text-green-500";
    } else if (orderStatus === "Delivered" || orderStatus === "delivered") {
      return "text-green-500";
    }
  };

  //Handle Order Status Change
  const handleOrderStatusChange = (order, orderStatus) => {
    if (orderStatus === "Fulfilled") {
      toast.error("Click on convert to invoice to mark this order as fulfilled");
      return;
    }

    dispatch(changeOnlineOrderStatus({ order_id: order?.id, status: orderStatus.toLowerCase() }))
      .then((action) => {
        if (action.payload) {
          toast.success("Order status changed successfully!");
        } else {
          toast.error("Order status change failed!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
    // toast.success("order status changed to", orderStatus);
  };

  //Handle order convert to invoice
  const handleConvertToInvoice = () => {
    if (orderDetails && orderDetails.customer) {
      navigate(`/invoice/create/customer/${orderDetails.customer.id}/0/${orderDetails.id}`);
    }
  };

  //Function to keep track of status
  const statusChangable = (status) => {
    if (status === "pickup" || status === "Pickup" || status === "delivered" || status === "Delivered") {
      return false;
    }
    return true;
  };

  //React Joyride - Guide user on how to use app
  const steps = [
    {
      target: "#joyride-step-1",
      content: "Search orders by order ID, product, or customer here.",
    },
    {
      target: "#joyride-step-2",
      content: "Filter orders by their status: pending, fulfilled, or cancelled.",
    },
    {
      target: "#joyride-step-3",
      content: "This is the order list. Select an order to view its details.",
    },
    {
      target: "#joyride-step-4",
      content: "Here you can view detailed information about the selected order.",
    },
    {
      target: "#joyride-step-5",
      content: "Use this dropdown to change the status of the order.",
    },
    {
      target: "#joyride-step-6",
      content: "This section lists the products included in the order.",
    },
    {
      target: "#joyride-step-7",
      content: "Click 'Convert to Invoice' to navigate to the invoice page and create a sales invoice for this order.",
    },
  ];

  const [localStorageOnlineOrderStatus, setLocalStorageOnlineOrderStatus] = useState(
    localStorage.getItem("showed_EWayBill_guide") || false
  );

  //handle till final steps
  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      localStorage.setItem("showed_EWayBill_guide", true);
      setLocalStorageOnlineOrderStatus(false);
    }
  };

  return (
    <>
      {!localStorageOnlineOrderStatus && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          disableBeacon={true}
          callback={handleJoyrideCallback}
          styles={{ options: { primaryColor: "#6366f1" } }}
        />
      )}
      <div className="flex">
        {/* Order list, search and filter section*/}
        <div className="w-1/3 border-r-2 border-neutral-200 h-[91.3vh]">
          {/* Order Search and Order Filter */}
          <div className="border-b-2 border-neutral-200 flex p-[15px] gap-[5px]">
            {/* Search Bar and Search Button */}
            <div className="w-full flex" id="joyride-step-1">
              <input
                type="text"
                className="border-2 rounded-full h-[44px] w-full p-[10px]"
                placeholder="Search Orders, Store, Products or Customer"
                onChange={(e) => debouncedSetSearchTerm(e.target.value)}
              />
              <button className="h-[44px] -ml-[42px]">
                <IconWrapper>
                  <Search className="text-white z-10" />
                </IconWrapper>
              </button>
            </div>

            {/* Filter Button and Functionality */}
            <div className="relative">
              <button className="h-[44px]" onClick={() => handleShowMenu("settings")} id="joyride-step-2">
                <IconWrapper>
                  <Filter className="text-white z-10" />
                </IconWrapper>
              </button>
              <div
                id="dropdownInformation"
                onMouseLeave={() => handleShowMenu("settings")}
                className={`${
                  showMenu !== "settings" ? "hidden" : ""
                } translate-x-[-50%] left-[50%] mt-[10px] absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
              >
                <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                  <li>
                    <button onClick={() => setFilter("")} className="text-lg py-2 w-full border-b-2 border-neutral-200">
                      All
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setFilter("Pending")}
                      className="text-lg py-2 w-full border-b-2 border-neutral-200"
                    >
                      Pending Only
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setFilter("Fulfilled")}
                      className="text-lg pt-2 border-b-2 w-full border-neutral-200"
                    >
                      Fulfilled Only
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setFilter("Cancelled")} className="text-lg pt-2 w-full">
                      Cancelled Only
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Order List section*/}
          <div>
            {/* Days Filter */}
            <div className="flex px-[10px] py-[20px] justify-between">
              <label htmlFor="days_filter" className="sr-only">
                Select Date
              </label>
              <select
                name="days_filter"
                id="days_filter"
                value={daysFilter}
                onChange={(e) => setDaysFilter(e.target.value)}
                className="px-[20px] py-[10px] bg-white border-[1px] border-black rounded-full max-w-fit"
              >
                <option value="last_7_days">Last 7 Days</option>
                <option value="last_30_days">Last 30 Days</option>
                <option value="custom">Custom</option>
              </select>

              {daysFilter === "custom" && <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />}
            </div>
            {/* Order List */}
            <InfiniteScroll
              dataLength={orders.length}
              next={fetchMoreOrders}
              hasMore={hasMoreOrders}
              height={"66vh"}
              loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
            >
              <div className="flex flex-col px-[10px] gap-[10px] bg-white" id="joyride-step-3">
                {orders?.map((order) => (
                  <div
                    key={order.id}
                    className={`flex justify-between items-center border-2 ${
                      selectedOrder?.id === order?.id ? "border-black" : "border-neutral-200"
                    } rounded-[20px] p-[20px]`}
                    onClick={() => setSelectedOrder(order)}
                  >
                    <h3 className="text-xl">Order {order.id}</h3>
                    <div className="flex flex-col items-end">
                      <span>
                        Order Status:{" "}
                        <span className={`${orderStatusColor(order.status)} capitalize`}>{order?.status}</span>
                      </span>
                      {/* <span>
                    Ordered On: <span className="text-neutral-500">{order?.order_details?.ordered_on}</span>
                    </span> */}
                      <span>
                        Time Slot: <span className="text-neutral-500">{convertTimestamp(order?.time_slot)}</span>
                      </span>
                      <span>
                        Total Amount: <span className="text-neutral-500">{order?.total_amount}</span>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
        {/* selected order and order details section */}
        <div className="w-1/3 border-r-2 border-neutral-200 h-[91.3vh] bg-white" id="joyride-step-4">
          {/* Selected order description */}
          <div className="h-[8.3vh] border-b-2 border-neutral-200 flex justify-between px-[20px] items-center">
            <h3 className="text-2xl text-neutral-500">{`Order ${selectedOrder?.id}`}</h3>
            {!statusChangable(selectedOrder?.status) && (
              <span className="flex gap-[10px] items-center bg-white" id="joyride-step-5">
                Status:{" "}
                <span className={`${orderStatusColor(selectedOrder?.status)} capitalize`}>{selectedOrder?.status}</span>
              </span>
            )}
            {statusChangable(selectedOrder?.status) && (
              <span className="flex gap-[10px] items-center bg-white" id="joyride-step-5">
                Status:
                <label htmlFor="orderStatus" className="sr-only">
                  Select Order Status
                </label>
                <select
                  name="orderStatus"
                  id="orderStatus"
                  value={`${selectedOrder?.status?.charAt(0).toUpperCase()}${selectedOrder?.status?.slice(1)}`}
                  onChange={(e) => handleOrderStatusChange(selectedOrder, e.target.value)}
                  className={`${orderStatusColor(
                    selectedOrder?.status
                  )} bg-white border-2 border-black px-[20px] py-[10px] rounded-full`}
                >
                  <option value="Fulfilled" className="text-green-500">
                    Fulfilled
                  </option>
                  <option value="Pending" className="text-red-500">
                    Pending
                  </option>
                  <option value="Cancelled" className="text-neutral-500">
                    Cancelled
                  </option>
                </select>
              </span>
            )}
          </div>
          {/* Order details section */}
          <div>
            <span className="text-neutral-500 py-[20px] text-center w-full block">Order Details</span>
            <div className="flex flex-col px-[10px] gap-[10px]">
              <DetailContainer title="Order type" value={orderDetails?.order_type} />
              {/* Todo - addition of Store Id in order details API*/}
              {/* <DetailContainer title="Store ID" value={orderDetails?.id} /> */}
              <DetailContainer title="Name" value={orderDetails?.customer?.customer_name} />
              <DetailContainer title="Contact Number" value={orderDetails?.customer?.phone_number} />
              <DetailContainer title="Address" value={orderDetails?.customer?.address} />
              {(selectedOrder?.status === "Fulfilled" || selectedOrder?.status === "fulfilled") && (
                <DetailContainer title="Payment Method" value={orderDetails?.payment_option?.payment_method} />
              )}
              <DetailContainer title="Total Amount" value={orderDetails?.total_amount} />
              {(selectedOrder?.status === "Fulfilled" || selectedOrder?.status === "fulfilled") && (
                <DetailContainer title="Ordered On" value={convertTimestamp(orderDetails?.invoice?.order_date_time)} />
              )}
              <DetailContainer title="Slot Selected" value={convertTimestamp(orderDetails?.time_slot)} />
            </div>
          </div>
        </div>
        {/* ordered products sections */}
        <div className="relative w-1/3 h-[91.3vh]">
          {/* ordered products */}
          <div className="h-[8.3vh] border-b-2 border-neutral-200 text-xl flex items-center justify-center gap-[10px]">
            Total Ordered Products:
            <span className="text-neutral-500">{orderDetails?.items?.length}</span>
          </div>
          {/* ordered products list */}
          <div className="px-[10px] flex flex-col gap-[10px] bg-white" id="joyride-step-6">
            {orderDetails?.items?.length > 0 &&
              orderDetails?.items?.map((item, index) => (
                <div
                  key={`${item?.product?.product_name}${index}`}
                  className="flex flex-col border-2 border-neutral-200 rounded-[20px] p-[20px] first:mt-[20px]"
                >
                  <span className="text-xl block py-[10px]">{item?.product?.product_name}</span>
                  <span>
                    Brand: <span className="text-neutral-500">{item?.product?.brand}</span>
                  </span>
                  {/* <span>
                  Batch: <span className="text-neutral-500">{product?.product_batch}</span>
                  </span> */}
                  <span>
                    Quantity: <span className="text-neutral-500">{item?.quantity}</span>
                  </span>
                </div>
              ))}
          </div>
          {(selectedOrder?.status === "Pending" || selectedOrder?.status === "pending") && (
            <div className="absolute bottom-0 left-0 w-full flex justify-center py-[10vh]">
              <button
                onClick={() => handleConvertToInvoice()}
                className="py-[10px] px-[20px] text-white rounded-full bg-black "
                id="joyride-step-7"
              >
                Convert to Invoice
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default OnlineOrders;
