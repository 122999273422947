import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { fetchExpenses, fetchMoreExpenses } from "../../reducers/Slices/expenseSlice";
import {
  fetchCashRegisterEntries,
  fetchMoreCashRegisterEntries,
  getCashRegisterAnalytics,
} from "../../reducers/Slices/cashRegisterSlice";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { changeDateFormat, humanDateFormat } from "../../utils/dateFormat";

//Functional Components
// import AddExpense from "./ExpenseComponents/AddExpense";
import AddCashRegistery from "./CashRegisterComponents/AddCashRegistery";
// import EditExpense from "./ExpenseComponents/EditExpense
import EditCashRegistery from "./CashRegisterComponents/EditCashRegistery";
// import RemoveExpense from "./ExpenseComponents/RemoveExpense";
import RemoveCashRegistery from "./CashRegisterComponents/RemoveCashRegistery";

//UI imports
import IconWrapper from "../MicroComponents/IconWrapper";
import { Search } from "react-feather";
import DateRangeSelector from "../OnlineOrders/OnlineOrderComponents/DateRangeSelector";
import { subDays } from "date-fns";

import Joyride from "react-joyride";
import { formatIndianNumber } from "../../utils/fomatIndianNumber";

const CashRegister = () => {
  const dispatch = useDispatch();

  const cashRegistery = useSelector((state) => state.cashRegister.cash_register.data);
  const cashRegisteryNext = useSelector((state) => state.cashRegister.cash_register.next);

  const cashRegisterAnalytics = useSelector((state) => state.cashRegister.cash_analytics);

  const [hasMoreCashRegisteryEntries, setHasMoreCashRegisteryEntries] = useState(() =>
    cashRegisteryNext ? true : false
  );

  //Days filter
  const [daysFilter, setDaysFilter] = useState("last_7_days");

  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //from date and to date
  const [fromDate, setFromDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - 7);
    return changeDateFormat(pastDate);
  });

  const [toDate, setToDate] = useState(() => {
    return changeDateFormat(new Date());
  });

  //side effect based on date range change - custom date setFromDate and setToDate
  useEffect(() => {
    setFromDate(changeDateFormat(dateRange[0].startDate));
    setToDate(changeDateFormat(dateRange[0].endDate));
  }, [dateRange]);

  //side effect to set date based on filter selected
  useEffect(() => {
    const today = new Date();
    const pastDate = new Date();

    if (daysFilter === "custom") {
      return;
    } else if (daysFilter === "last_7_days") {
      pastDate.setDate(today.getDate() - 7);
    } else if (daysFilter === "last_30_days") {
      pastDate.setDate(today.getDate() - 30);
    }

    setFromDate(changeDateFormat(pastDate));
    setToDate(changeDateFormat(today));
  }, [daysFilter]);

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (cashRegisteryNext) {
      setHasMoreCashRegisteryEntries(true);
    } else {
      setHasMoreCashRegisteryEntries(false);
    }
  }, [cashRegisteryNext]);

  //fetch more expenses on scroll if available
  const fetchNextCashRegisteryEntries = () => {
    if (cashRegisteryNext) {
      dispatch(fetchMoreCashRegisterEntries(cashRegisteryNext));
    }
  };

  useEffect(() => {
    dispatch(fetchCashRegisterEntries());
    dispatch(getCashRegisterAnalytics());
  }, [dispatch]);

  const [selectedCashRegisteryEntry, setSelectedCashRegisteryEntry] = useState({});
  const [searchCashRegistery, setSearchCashRegistery] = useState("");

  // Debounce expense search to throttle api calls to backend
  const debouncedCashRegisterySearch = debounce((value) => setSearchCashRegistery(value), 1000);

  useEffect(() => {
    if (searchCashRegistery.length > 0) {
      dispatch(fetchCashRegisterEntries(`?search=${searchCashRegistery}&start_date=${fromDate}&end_date=${toDate}`));
    } else {
      dispatch(fetchCashRegisterEntries(`?start_date=${fromDate}&end_date=${toDate}`));
    }
  }, [searchCashRegistery, dispatch, fromDate, toDate]);

  //Default selected expense as first expense during first render
  useEffect(() => {
    setSelectedCashRegisteryEntry(cashRegistery[0]);
  }, [cashRegistery]);

  //React Joyride - Guide user on how to use app
  const steps = [
    {
      target: "#joyride-step-1",
      content: "Search for cash register entries here.",
    },
    {
      target: "#joyride-step-2",
      content: "Add new cash register entries from this Add Cash Registery button.",
    },
    {
      target: "#joyride-step-3",
      content:
        "View all your cash register entries here. Filter by the last 7 days, last 30 days, or choose a custom date range.",
    },
    {
      target: "#joyride-step-4",
      content: "This section displays your overall cash analytics, resetting daily to show cash inflows and outflows.",
    },
    {
      target: "#joyride-step-5",
      content: "Here are the details of the selected cash entry.",
    },
    {
      target: "#joyride-step-6",
      content: "Edit or delete a specific cash register entry with Edit Entry or Remove Entry",
    },
  ];

  const [localStorageCashRegisterStatus, setLocalStorageCashRegisterStatus] = useState(
    localStorage.getItem("showed_cashRegister_guide") || false
  );

  //handle till final steps
  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      localStorage.setItem("showed_cashRegister_guide", true);
      setLocalStorageCashRegisterStatus(false);
    }
  };

  return (
    <>
      {!localStorageCashRegisterStatus && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          disableBeacon={true}
          callback={handleJoyrideCallback}
          styles={{ options: { primaryColor: "#6366f1" } }}
        />
      )}
      <div className="flex h-[100vh-80px]">
        {/* employee list section*/}
        <div className="flex-1 max-w-[30%] border-r-2 border-neutral-200 h-[91.3vh]">
          <div className="flex gap-[10px] border-b-2 border-neutral-200 p-[15px]">
            {/* //Search Bar */}
            <div className="flex w-full" id="joyride-step-1">
              <input
                type="text"
                className="h-[44px] w-full rounded-full border-2 border-neutral-200 text-neutral-500 px-[15px]"
                placeholder="Search Cash Registery..."
                onChange={(e) => debouncedCashRegisterySearch(e.target.value)}
              />
              <button className="-ml-[40px]">
                <IconWrapper>
                  <Search className="z-10 text-white"></Search>
                </IconWrapper>
              </button>
            </div>

            {/* add cash registery button */}
            <span id="joyride-step-2">
              <AddCashRegistery />
            </span>
          </div>

          <div className="bg-white" id="joyride-step-3">
            {/* Days Filter */}
            <div className="flex px-[10px] pt-[20px] pb-[10px] justify-between">
              <label htmlFor="days_filter" className="sr-only">
                Select Date
              </label>
              <select
                name="days_filter"
                id="days_filter"
                value={daysFilter}
                onChange={(e) => setDaysFilter(e.target.value)}
                className="px-[20px] py-[10px] bg-white border-[1px] border-black rounded-full max-w-fit"
              >
                <option value="last_7_days">Last 7 Days</option>
                <option value="last_30_days">Last 30 Days</option>
                <option value="custom">Custom</option>
              </select>

              {daysFilter === "custom" && <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />}
            </div>
            {/* registery Container */}
            {cashRegistery?.length === 0 && <div className="text-center py-10 font-semibold">No Entries found!</div>}
            {cashRegistery?.length > 0 && (
              <div className="overflow-y-scroll">
                <InfiniteScroll
                  dataLength={cashRegistery.length}
                  next={fetchNextCashRegisteryEntries}
                  hasMore={hasMoreCashRegisteryEntries}
                  height={"66vh"}
                  loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center", paddingTop: "10px" }}>
                      <b>Cash Registery List End</b>
                    </p>
                  }
                >
                  <ul className="flex flex-col p-[15px] gap-[10px]">
                    {cashRegistery.map((cashRegisteryEntry, index) => (
                      <li
                        key={index}
                        className={`w-full flex justify-between p-[12px] border-2 ${
                          selectedCashRegisteryEntry?.id === cashRegisteryEntry.id
                            ? "border-black"
                            : "border-neutral-200 "
                        } rounded-full cursor-pointer`}
                        onClick={() => setSelectedCashRegisteryEntry(cashRegisteryEntry)}
                      >
                        <span>
                          {/* <span>{index + 1}.</span> */}
                          <span className="ml-[5px]">
                            {!cashRegisteryEntry?.name || cashRegisteryEntry?.name?.length === 0
                              ? cashRegisteryEntry?.vendor === null
                                ? cashRegisteryEntry?.customer?.customer_name
                                : cashRegisteryEntry?.vendor?.vendor_name
                              : cashRegisteryEntry?.name}
                          </span>
                        </span>
                        <span>
                          {cashRegisteryEntry.type === "payment" ? "payment of" : "received"} ₹
                          {formatIndianNumber(cashRegisteryEntry?.amount || 0)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
        {/* Cash Registery Details */}
        <div className="flex-1">
          <div className="flex-1 px-[40px] py-[10px] border-b-2 border-neutral-200 bg-white" id="joyride-step-4">
            <h1 className="text-2xl mt-[10px]">Overall Cash Analytics</h1>
            <div className="flex gap-[10px] mt-[20px] text-xl">
              <h3>Total Cash Inflow Today : </h3>
              <span className="text-neutral-500">
                ₹{formatIndianNumber(cashRegisterAnalytics?.total_receipts || 0)}
              </span>
            </div>
            <div className="flex gap-[10px] mt-[10px] text-xl">
              <h3>Total Cash Outflow Today : </h3>
              <span className="text-neutral-500">
                ₹{formatIndianNumber(cashRegisterAnalytics?.total_payments || 0)}
              </span>
            </div>
            <div className="flex gap-[10px] mt-[10px] mb-[10px] text-xl">
              <h3>Net Cash Today: </h3>
              <span className="text-neutral-500">₹{formatIndianNumber(cashRegisterAnalytics?.net_value || 0)}</span>
            </div>
          </div>
          {!selectedCashRegisteryEntry && (
            <div className="px-[40px] py-[20px] font-semibold" id="joyride-step-5">
              Start adding entries to see this panel.
            </div>
          )}
          {selectedCashRegisteryEntry && (
            <div className="flex-1 px-[40px] py-[20px] bg-white" id="joyride-step-5">
              <div>
                <h1 className="text-2xl">Name</h1>
                <span className="text-neutral-500">
                  {!selectedCashRegisteryEntry?.name || selectedCashRegisteryEntry?.name?.length === 0
                    ? selectedCashRegisteryEntry?.vendor === null
                      ? selectedCashRegisteryEntry?.customer?.customer_name
                      : selectedCashRegisteryEntry?.vendor?.vendor_name
                    : selectedCashRegisteryEntry?.name}
                </span>
              </div>
              <div>
                <h3 className="mt-[10px]">Date</h3>
                <span className="text-neutral-500">{humanDateFormat(selectedCashRegisteryEntry?.date)}</span>
              </div>
              <div className="mt-[10px]">
                <h3 className="text-lg">Amount</h3>
                <span className="text-neutral-500">₹{formatIndianNumber(selectedCashRegisteryEntry?.amount || 0)}</span>
              </div>
              <div className="mt-[10px]">
                <h3 className="text-lg">Type</h3>
                <span className="text-neutral-500">{selectedCashRegisteryEntry?.type}</span>
              </div>
              <div className="mt-[10px]">
                <h3 className="text-lg">Note</h3>
                <div>
                  <p className="text-body text-neutral-500 max-w-[500px]">{selectedCashRegisteryEntry?.note}</p>
                </div>
              </div>
            </div>
          )}
          {/* Operation Button */}
          {selectedCashRegisteryEntry && (
            <div className="mt-[40px] px-[40px] flex max-w-[500px] gap-[10px] bg-white" id="joyride-step-6">
              <EditCashRegistery cashRegistery={selectedCashRegisteryEntry} />
              <RemoveCashRegistery cashRegistery={selectedCashRegisteryEntry} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CashRegister;
