import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import axios from "axios";

//Create return voucher - currently no addcase builder only download pdf operation
export const createReturnVoucher = createAsyncThunk("expiryBreakage/createReturnVoucher", async (data, extra) => {
  const res = await http.post("/return_breakage/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.pdf_url;
  }
});

//Get return voucher by ID - currently not being used but here if needed
// GET - /return_breakage/<id int here>/

//fetch return voucher list
export const fetchReturnVouchers = createAsyncThunk("expiryBreakage/fetchReturnVouchers", async (data, extra) => {
  const res = await http.get(`/return_breakage/${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//fetch more return voucher list - infinity scroll
export const fetchMoreReturnVouchers = createAsyncThunk(
  "expiryBreakage/fetchMoreReturnVouchers",
  async (url, extra) => {
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//update return voucher - currently not being used but here if needed
// PUT -  /return_breakage/8/

//Delete return voucher - currently not being used but here if needed
// Delete - /return_breakage/<id int here>/

//Create Breakage Log
export const createBreakageLog = createAsyncThunk("expirtReturn/createBreakageLog", async (data, extra) => {
  const res = await http.post("/return_breakage/breakage-logs/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//update breakage log
export const updateBreakageLog = createAsyncThunk("expirtReturn/updateBreakageLog", async (data, extra) => {
  const res = await http.put(`/return_breakage/breakage-logs/${data.id}/`, data.data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//fetch breakage log list
export const fetchBreakageLogs = createAsyncThunk("expirtReturn/fetchBreakageLogs", async (data, extra) => {
  const res = await http.get(`/return_breakage/breakage-logs/${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//fetch more breakage logs for react infinity scroll
export const fetchMoreBreakageLogs = createAsyncThunk("expiryReturn/fetchMoreBreakageLogs", async (url, extra) => {
  const res = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//delete breakage log
export const deleteBreakageLog = createAsyncThunk("expiryReturn/deleteBreakageLog", async (data, extra) => {
  const res = await http.delete(`/return_breakage/breakage-logs/${data}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return data;
  }
});

//Fetch expired product and batches
export const fetchExpiredProductAndBatches = createAsyncThunk(
  "expiryReturn/fetchExpiredProductAndBatches",
  async (data, extra) => {
    const res = await http.get(`/inventory/api/product/batches/expired/${data ?? ""}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//Fetch more expired product and batches
export const fetchMoreExpiredProductsAndBatches = createAsyncThunk(
  "expiryReturn/fetchMoreExpiryProductAndBatches",
  async (url, extra) => {
    const res = await http.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//fetch expired products only
// export const fetchExpiredProducts = createAsyncThunk("expiryReturn/fetchProducts", async (data, extra) => {
//   const res = await http.get(`/inventory/api/product/batches/expired${data ?? ""}`, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

// //fetch more expired products
// export const fetchMoreExpiredProducts = createAsyncThunk("expiryReturn/fetchMoreProducts", async (url, extra) => {
//   const res = await axios.get(url, {
//     headers: {
//       Accept: "application/json",
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

//fetch expired products
export const fetchExpiredProducts = createAsyncThunk("expiryReturn/fetchExpiredProducts", async (data, extra) => {
  const res = await http.get(`/inventory/api/products/expired${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//fetch more expired products
export const fetchMoreExpiredProducts = createAsyncThunk(
  "expiryReturn/fetchMoreExpiredProducts",
  async (url, extra) => {
    const res = await axios.get(url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//fetch breakage products
export const fetchBreakageProducts = createAsyncThunk("expiryReturn/fetchBreakageProducts", async (data, extra) => {
  const res = await http.get(`/return_breakage/breakage-logs/products/${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//fetch more breakage products
export const fetchMoreBreakageProducts = createAsyncThunk(
  "expiryReturn/fetchMoreBreakageProducts",
  async (url, extra) => {
    const res = await axios.get(url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

export const expiryBreakageSlice = createSlice({
  name: "expiryBreakage",
  initialState: {
    breakageLogs: { data: [], next: null },
    expiredProductAndBatches: { data: [], next: null },
    returnVouchers: { data: [], next: null },
    expiredProducts: { data: [], next: null },
    breakageProducts: { data: [], next: null },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReturnVouchers.fulfilled, (state, action) => {
      return {
        ...state,
        returnVouchers: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreReturnVouchers.fulfilled, (state, action) => {
      return {
        ...state,
        returnVouchers: { data: [...state.returnVouchers.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(createBreakageLog.fulfilled, (state, action) => {
      return {
        ...state,
        breakageLogs: { ...state.breakageLogs, data: [...state.breakageLogs.data, action.payload] },
      };
    });
    builder.addCase(updateBreakageLog.fulfilled, (state, action) => {
      const updatedBreakageLogs = state.breakageLogs.data.map((log) => {
        if (log.id === action.payload.id) {
          return action.payload;
        } else {
          return log;
        }
      });
      return {
        ...state,
        breakageLogs: { ...state.breakageLogs, data: updatedBreakageLogs },
      };
    });
    builder.addCase(fetchBreakageLogs.fulfilled, (state, action) => {
      return {
        ...state,
        breakageLogs: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreBreakageLogs.fulfilled, (state, action) => {
      return {
        ...state,
        breakageLogs: { data: [...state.breakageLogs.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(deleteBreakageLog.fulfilled, (state, action) => {
      const filteredBreakageLogs = state.breakageLogs.data.filter((log) => log.id !== action.payload);
      return {
        ...state,
        breakageLogs: { ...state.breakageLogs, data: filteredBreakageLogs },
      };
    });
    builder.addCase(fetchExpiredProductAndBatches.fulfilled, (state, action) => {
      return {
        ...state,
        expiredProductsAndBatches: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreExpiredProductsAndBatches.fulfilled, (state, action) => {
      return {
        ...state,
        expiredProductAndBatches: {
          data: [...state.expiredProductAndBatches.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchExpiredProducts.fulfilled, (state, action) => {
      return {
        ...state,
        expiredProducts: {
          ...state.expiredProducts,
          data: action.payload.data.map((item) => ({
            ...item,
            label: item.product_name,
          })),
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchMoreExpiredProducts.fulfilled, (state, action) => {
      return {
        ...state,
        expiredProducts: {
          ...state.expiredProducts,
          data: [...state.expiredProducts.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchBreakageProducts.fulfilled, (state, action) => {
      return {
        ...state,
        breakageProducts: {
          ...state.breakageProducts,
          data: action.payload.data.map((item) => ({
            ...item,
            label: item.product_name,
          })),
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchMoreBreakageProducts.fulfilled, (state, action) => {
      return {
        ...state,
        breakageProducts: {
          ...state.breakageProducts,
          data: [...state.breakageProducts.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
  },
});

export default expiryBreakageSlice.reducer;
// export const { login, logout } = authSlice.actions;
