import React from "react";
import InvoiceContainer from "./TransactionComponent/InvoiceContainer";

const SalesBook = () => {
  return (
    <div className="p-[10px]">
      <div className="border-2 border-neutral-200 rounded-[20px] p-[20px]">
        <InvoiceContainer infoType={"customer"} />
      </div>
    </div>
  );
};

export default SalesBook;
