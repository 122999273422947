import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

//API reducer calls
import {
  fetchAttendances,
  fetchEmployees,
  updateAttendance,
  fetchMoreEmployees,
  fetchMoreAttendances,
  createAllAttendances,
} from "../../reducers/Slices/employeeSlice";

//Component Import
import AddEmployee from "./EmployeesComponents/AddEmployee";
import RemoveEmployee from "./EmployeesComponents/RemoveEmployee";
import EditEmployee from "./EmployeesComponents/EditEmployee";
import CheckBox from "@mui/material/Checkbox";

//UI components
import IconWrapper from "../MicroComponents/IconWrapper";
import DetailContainer from "../MicroComponents/DetailContainer";
import { Search, Edit2 } from "react-feather";
import { changeDateFormat, humanDateFormat } from "../../utils/dateFormat";
import AttendanceHeatmap from "./EmployeesComponents/AttendanceHeatmap";
import Joyride from "react-joyride";
import toast from "react-hot-toast";

const Employees = () => {
  const dispatch = useDispatch();

  const employees = useSelector((state) => state.employee.employees.data);
  const employeesNext = useSelector((state) => state.employee.employees.next);
  const attendances = useSelector((state) => state.employee.attendances.data);
  const attendancesNext = useSelector((state) => state.employee.attendances.next);

  const [selectedDate, setSelectedDate] = useState(() => changeDateFormat(Date.now()));
  const [createDateButton, setCreateDateButton] = useState(false);
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

  const [hasMoreEmployees, setHasMoreEmployees] = useState(() => (employeesNext ? true : false));
  const [hasMoreAttendances, setHasMoreAttendances] = useState(() => (attendancesNext ? true : false));

  const [disableDateRecordsButton, setDisableDateRecordsButton] = useState(false);

  //Tabs to switch between employee details
  const [showAttendanceTab, setShowAttendanceTab] = useState(true);

  //Use effect to fetch more employees based on value of employeesNext
  useEffect(() => {
    if (employeesNext) {
      setHasMoreEmployees(true);
    } else {
      setHasMoreEmployees(false);
    }
  }, [employeesNext]);

  //fetch more employees on scroll if available
  const fetchNextEmployees = () => {
    if (employeesNext) {
      dispatch(fetchMoreEmployees(employeesNext));
    }
  };

  //Use effect to fetch more attedances based on value of attendancesNext
  useEffect(() => {
    if (attendancesNext) {
      setHasMoreAttendances(true);
    } else {
      setHasMoreAttendances(false);
    }
  }, [attendancesNext]);

  //fetch more attendances on scroll if available
  const fetchNextAttendances = () => {
    if (attendancesNext) {
      dispatch(fetchMoreAttendances(attendancesNext));
    }
  };

  //Fetch all employee and attendances
  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  //Fetch all attendance and attendance for particular date
  useEffect(() => {
    dispatch(fetchAttendances({ date: selectedDate })).then((action) => {
      if (action.payload?.data?.length === 0) {
        // //Today's date
        // let todayDate = changeDateFormat(new Date());
        // if (localStorage.getItem("employeeRecordFetchDate") !== todayDate) {
        setCreateDateButton(true);
        // }
      } else if (action.payload?.data?.length > 0) {
        setCreateDateButton(false);
      }
    });
  }, [dispatch, selectedDate, employees]);

  const [showDateEditor, setShowDateEditor] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});

  //Set seleted employee to employee one on first render
  useEffect(() => {
    setSelectedEmployee(() => {
      if (employees && employees.length > 0) {
        return employees[0];
      } else {
        return {};
      }
    });
  }, [setSelectedEmployee, employees]);

  const [searchEmployee, setSearchEmployee] = useState("");

  // Debounce employee search to throttle api calls to backend
  const debouncedEmployeeSearch = debounce((value) => setSearchEmployee(value), 1000);

  useEffect(() => {
    if (searchEmployee.length > 0) {
      dispatch(fetchEmployees(`/?search=${searchEmployee}`));
    } else {
      dispatch(fetchEmployees());
    }
  }, [searchEmployee, dispatch]);

  // const testingNameList = [
  //   { name: "Evelyn Stone", status: "Present" },
  //   { name: "Jasper Finch", status: "Absent" },
  //   { name: "Isla Hawthorne", status: "Leave" },
  //   { name: "Leo Mercer", status: "Absent" },
  //   { name: "Clara Whitfield", status: "Present" },
  //   { name: "Max Donovan", status: "Present" },
  //   { name: "Harper Ellington", status: "Present" },
  //   { name: "Felix Winters", status: "Present" },
  //   { name: "Amelia Frost", status: "Present" },
  //   { name: "Owen Lancaster", status: "Present" },
  // ];

  function handleDateChange(date) {
    setSelectedDate(changeDateFormat(date));
    setShowDateEditor(!showDateEditor);
  }

  function handleUpdateAttendance(value, attendance) {
    const status = attendance.status;
    let updatedStatus;

    if (status === "Present") {
      updatedStatus = "Absent";
    } else {
      updatedStatus = "Present";
    }
    const updatedAttendance = {
      ...attendance,
      employee: attendance?.employee?.id || attendance?.employee,
      date: selectedDate,
      status: updatedStatus,
    };
    dispatch(updateAttendance(updatedAttendance));
  }

  function handleCreateDateRecords() {
    setDisableDateRecordsButton(true);
    if (!selectedDate) {
      setDisableDateRecordsButton(false);
      return;
    }

    dispatch(createAllAttendances({ date: selectedDate })).then((action) => {
      if (action.payload?.length > 0) {
        setDisableDateRecordsButton(false);
        setCreateDateButton(false);
      }
    });
  }

  //Get employee by ID
  // function findEmployeeNameById(id) {
  //   if (employees.length > 0 && id) {
  //     const employee = employees.filter((employee) => employee.id === id);
  //     return employee[0]?.name;
  //   } else {
  //     return "loading...";
  //   }
  // }

  const attendanceData = [
    { date: "2024-10-01", present: true },
    { date: "2024-10-02", present: false },
    { date: "2024-10-03", present: true },
    // More attendance data
  ];

  //React Joyride - Guide user on how to use app
  const steps = [
    {
      target: "#joyride-step-1",
      content: "Search employees by name from this section.",
    },
    {
      target: "#joyride-step-2",
      content: "Add a new employee by clicking the 'Add Employee' button.",
    },
    {
      target: "#joyride-step-3",
      content: "This is the employee list displaying their name and role.",
    },
    {
      target: "#joyride-step-4",
      content: "This section shows the selected employee's personal details.",
    },
    {
      target: "#joyride-step-5",
      content: "Edit or remove the selected employee's details using the 'Edit Employee' or 'Remove Employee' buttons.",
    },
    {
      target: "#joyride-step-6",
      content:
        "Switch between the 'Attendance' tab to mark attendance or the 'Salary & Records' tab to view salary structure and attendance calendar.",
    },
  ];

  const [localStorageEmployeeStatus, setLocalStorageEmployeeStatus] = useState(
    localStorage.getItem("showed_employee_guide") || false
  );

  //handle till final steps
  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      localStorage.setItem("showed_employee_guide", true);
      setLocalStorageEmployeeStatus(false);
    }
  };

  return (
    <>
      {!localStorageEmployeeStatus && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          dsisableBeacon={true}
          callback={handleJoyrideCallback}
          styles={{ options: { primaryColor: "#6366f1" } }}
        />
      )}
      <div className="flex max-h-[100vh-78px]">
        {/* employee list section*/}
        <div className="flex-1 max-w-[30%] border-r-2">
          <div className="flex gap-[10px] border-b-2 border-neutral-200 p-[15px]">
            {/* //Search Bar */}
            <div className="flex w-full" id="joyride-step-1">
              <input
                type="text"
                className="h-[44px] w-full rounded-full border-2 border-neutral-200 text-neutral-500 px-[15px]"
                placeholder="Search Employees..."
                onChange={(e) => debouncedEmployeeSearch(e.target.value)}
              />
              <button className="-ml-[40px]">
                <IconWrapper>
                  <Search className="z-10 text-white"></Search>
                </IconWrapper>
              </button>
            </div>

            {/* add employee button */}
            <span id="joyride-step-2">
              <AddEmployee />
            </span>
          </div>
          {/* Employee Container */}
          {employees?.length === 0 && <div className="text-center py-10 font-semibold">No Entries found!</div>}
          {employees?.length > 0 && (
            <div className="bg-white" id="joyride-step-3">
              <InfiniteScroll
                dataLength={employees.length}
                next={fetchNextEmployees}
                hasMore={hasMoreEmployees}
                height={"68vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Employee List End</b>
                  </p>
                }
              >
                <ul className="flex flex-col p-[15px] gap-[10px]">
                  {employees.map((employee, index) => (
                    <li
                      key={index}
                      className={`w-full ${
                        selectedEmployee?.id === employee.id ? "border-black" : "border-neutral-200"
                      } p-[12px] border-2  rounded-full cursor-pointer`}
                      onClick={() => setSelectedEmployee(employee)}
                    >
                      {/* <span>{index + 1}.</span> */}
                      <span className="ml-[5px]">
                        {employee.name} - {employee.role}
                      </span>
                    </li>
                  ))}
                </ul>
              </InfiniteScroll>
            </div>
          )}
        </div>
        {/* personal details section */}
        <div
          className="flex-1 h-[calc(100vh-80px)] max-w-[40%] border-r-2 border-neutral-200 bg-white"
          id="joyride-step-4"
        >
          <div className="h-[76px] border-b-2 flex items-center justify-center px-[15px]">
            <h2 className="text-xl">Personal Details</h2>
          </div>
          <div className="p-[15px] flex gap-[15px] border-b-2 border-neutral-200">
            {/* profile Picture container* - currently not being implemented */}
            {/* <div className="relative max-h-[250px] max-w-[200px]">
              <img
              className="rounded-[20px]"
              src="https://picsum.photos/400/400"
              alt={`profile of ${selectedEmployee.name}`}
              /> */}
            {/* Edit Image Button */}
            {/* <button
                className="absolute top-0 right-0 bg-black h-[35px] w-[35px] flex items-center justify-center rounded-full translate-x-[25%] -translate-y-[25%]"
                onClick={() => toast.error("edit button was clicked")}
                >
                <Edit2 className="text-white h-[20px] w-[20px]" />
                </button>
                </div> */}
            {/* Name, Role and Edit and Delete Button */}
            <div className="w-full flex flex-col gap-[10px] justify-center">
              <DetailContainer title={"Name"} value={selectedEmployee.name} />
              <DetailContainer title={"Role"} value={selectedEmployee.role} />
              {/* Edit and Delete Button container */}
              {employees?.length > 0 && (
                <div className="flex gap-[10px] mt-[10px] bg-white" id="joyride-step-5">
                  <EditEmployee employee={selectedEmployee} />
                  <RemoveEmployee employee={selectedEmployee} />
                </div>
              )}
            </div>
          </div>
          <div className="h-[55.9vh] overflow-y-scroll">
            <h3 className="text-neutral-400 w-full text-center my-[15px] font-semibold">
              Contact Information & Employee Details
            </h3>
            <div className="w-full overflow-y-scroll flex flex-col gap-[10px] px-[15px]">
              <DetailContainer
                title={"Gender"}
                value={selectedEmployee?.gender?.charAt(0)?.toUpperCase() + selectedEmployee?.gender?.slice(1)}
              />
              <DetailContainer title={"Location"} value={selectedEmployee.location} />
              <DetailContainer title={"Email"} value={selectedEmployee.email} />
              <DetailContainer title={"Contact Number"} value={selectedEmployee.contact_number} />
              <DetailContainer title={"Number of Holidays (Annual)"} value={selectedEmployee.holidays} />
              <DetailContainer title={"Address"} value={selectedEmployee.address} />
              <DetailContainer title={"Date of Joining"} value={selectedEmployee.date_of_joining} />
              <DetailContainer title={"Salary (Annual)"} value={selectedEmployee.salary} />
            </div>
          </div>
        </div>
        {/* Attendance Sheet and Employee Salary  */}
        <div className="flex-1 max-w-[30%]">
          {/* <div
            className="w-full h-[76px] flex px-[1vw] py-[20px] border-b-2 border-neutral-200 bg-white"
            id="joyride-step-6"
          >
            <button
              className={`flex-1 font-medium text-neutral-500 ${showAttendanceTab ? "!text-black font-semibold" : ""}`}
              onClick={() => setShowAttendanceTab(true)}
            >
              Attendance Tab
            </button>
            <span className="w-[2px] h-full block bg-neutral-200 text-neutral-200">|</span>
            <button
              className={`flex-1 font-medium text-neutral-500 ${!showAttendanceTab ? "!text-black font-semibold" : ""}`}
              onClick={() => setShowAttendanceTab(false)}
            >
              Salary & Records Tab
            </button>
          </div> */}
          {/* Salary Breakdown and attendance heatmap */}
          {!showAttendanceTab && (
            <div className="flex flex-col h-[83vh]">
              <div className="flex-1">Salary Breakdown Data</div>
              {/* Attendance Heatmap  */}
              <div className="border-b-2 border-neutral-200 pb-[40px]">
                <h1 className="py-[10px] text-lg border-y-2 mb-[20px] text-center text-neutral-500">
                  Employee Attendance Calendar
                </h1>

                <AttendanceHeatmap attendanceData={attendanceData} />
              </div>
            </div>
          )}
          {/* Attendance Sheet section*/}
          {showAttendanceTab && (
            <div className="flex flex-col h-full">
              <div className="h-[76px] border-b-2 border-neutral-200 flex justify-between px-[15px] items-center ">
                <span className="text-xl">Attendance Sheet</span>
                <div>
                  {showDateEditor && (
                    <input type="date" max={today} onChange={(event) => handleDateChange(event.target.value)} />
                  )}
                  {!showDateEditor && (
                    <span className="text-neutral-500" onClick={() => setShowDateEditor(!showDateEditor)}>
                      {humanDateFormat(selectedDate)}
                    </span>
                  )}
                </div>
              </div>
              {employees?.length === 0 && (
                <div className="text-center py-10 font-semibold">Start adding employees to see this panel!</div>
              )}
              {employees?.length > 0 && (
                <div>
                  {createDateButton && (
                    <div className="flex flex-col items-center my-[20px]">
                      <span>{`No Attendance records found for ${
                        selectedDate ? "date : " + humanDateFormat(selectedDate) : "today"
                      }`}</span>
                      <button
                        onClick={() => handleCreateDateRecords()}
                        disabled={disableDateRecordsButton}
                        className="my-[20px] py-[10px] px-[20px] text-white bg-black rounded-full"
                      >
                        Create Date Records
                      </button>
                    </div>
                  )}
                  {!createDateButton && (
                    <InfiniteScroll
                      dataLength={attendances.length}
                      next={fetchNextAttendances}
                      hasMore={hasMoreAttendances}
                      height={"42vh"}
                      loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                      endMessage={
                        <p style={{ textAlign: "center", paddingTop: "10px" }}>
                          <b>Attendance List End</b>
                        </p>
                      }
                    >
                      {attendances.map((attendance, index) => (
                        <div
                          key={attendance?.id}
                          className="w-full flex items-center justify-between px-[15px] border-b-2 border-neutral-200"
                        >
                          <span>
                            <span>{index + 1}. </span>
                            <span>{attendance?.employee?.name}</span>
                          </span>
                          <CheckBox
                            checked={attendance?.status === "Present" ? true : false}
                            onChange={(e) => handleUpdateAttendance(e.target.value, attendance)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          {/* <CheckBox checked={attendance.status === "Present" ? true : false} /> */}
                        </div>
                      ))}
                      <div className="flex justify-center py-[20px]">
                        <button
                          className="px-[20px] py-[10px] text-white bg-black rounded-full"
                          onClick={() => toast.success("Attendance Saved Successfully!")}
                        >
                          Save Attendance
                        </button>
                      </div>
                    </InfiniteScroll>
                  )}
                </div>
              )}
              <span className="block w-full text-center text-neutral-500 mt-auto pb-[35px]">
                Note: click on date next to Attendance sheet title to see previous records.
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Employees;
