import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreInvoiceByProductId, fetchMoreInvoicesCustomer } from "../../../reducers/Slices/invoiceSlice";
// import { fetchMoreInvoices } from "../../../reducers/Slices/invoiceSlice";
// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchInvoiceByCustomer } from "../../../reducers/Slices/invoiceSlice";
import moment from "moment";
import { fetchMoreInvoicesVendor } from "../../../reducers/Slices/purchaseInvoiceSlice";
import { formatIndianNumber } from "../../../utils/fomatIndianNumber";
import toast from "react-hot-toast";
import { http } from "../../../service/http";
// import { fetchMorePurchaseInvoices } from "../../../reducers/Slices/purchaseInvoiceSlice";

const InventoryList = ({ invoices, title, type }) => {
  const dispatch = useDispatch();
  const invoiceNext = invoices?.next;
  const [hasMoreInvoices, setHasMoreInvoices] = useState(() => (invoiceNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (invoiceNext) {
      setHasMoreInvoices(true);
    } else {
      setHasMoreInvoices(false);
    }
  }, [invoiceNext]);

  //fetch more expenses on scroll if available
  const fetchNextInvoices = () => {
    if (invoiceNext) {
      if (type === "product") {
        dispatch(fetchMoreInvoiceByProductId(invoiceNext));
      } else if (type === "customer") {
        dispatch(fetchMoreInvoicesCustomer(invoiceNext));
      } else if (type === "vendor") {
        dispatch(fetchMoreInvoicesVendor(invoiceNext));
      }
    }
  };

  //handleDownloadPDF
  const handleDownloadPDF = async (endpoint) => {
    if (!endpoint) {
      toast.error("No PDF available for this invoice!");
      return;
    }

    if (endpoint) {
      try {
        const response = await http.get(`/storage/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
        // Create a URL for the PNG blob
        const imageURL = URL.createObjectURL(response.data);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `${endpoint.split("/")[1]}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        toast.error("PDF not found!");
      }
    }
  };

  return (
    //   <InfiniteScroll
    //   dataLength={expenses.length}
    //   next={fetchNextExpenses}
    //   hasMore={hasMoreExpenses}
    //   height={"70vh"}
    //   loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
    //   endMessage={
    //     <p style={{ textAlign: "center", paddingTop: "10px" }}>
    //       <b>Expenses List End</b>
    //     </p>
    //   }
    // >
    //   <ul className="flex flex-col p-[15px] gap-[10px]">
    //     {expenses.map((expense, index) => (
    //       <li
    //         key={index}
    //         className="w-full flex justify-between p-[12px] border-2 border-neutral-200 rounded-full cursor-pointer"
    //         onClick={() => setSelectedExpense(expense)}
    //       >
    //         <span>
    //           <span>{index + 1}.</span>
    //           <span className="ml-[5px]">{expense.expense_name}</span>
    //         </span>
    //         <span>{expense.category}</span>
    //       </li>
    //     ))}
    //   </ul>
    // </InfiniteScroll>
    <div className="p-[15px] overflow-y-scroll">
      <div className="text-center text-sm">
        Previous {type === "vendor" ? "Purchase from" : "Sales of"} <span>{title}</span>
      </div>
      <InfiniteScroll
        dataLength={invoices.data.length}
        next={fetchNextInvoices}
        hasMore={hasMoreInvoices}
        height={"80vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
        endMessage={
          <p style={{ textAlign: "center", paddingTop: "10px" }}>
            {type === "user" && <b>User Invoice List End</b>}
            {type === "product" && <b>Product Invoice List End</b>}
          </p>
        }
      >
        {invoices?.data?.map((item, index) => {
          return (
            <div key={index} className="border-2 border-neutral-200 hover:border-black rounded-[20px] my-[10px]">
              <div className="p-[20px]">
                <div className="flex flex-col gap-[10px] 2xl:flex-row 2xl:gap-0 justify-between items-center ">
                  <div className="text-center">
                    <div className="font-semibold">Invoice Number</div>
                    <div className="text-neutral-500">{item.invoice_counter}</div>
                  </div>
                  <div>
                    <div className="text-neutral-500">{moment(item.order_date_time).format("MMMM Do YYYY")}</div>
                  </div>
                </div>
                <div className="flex flex-col gap-[10px] 2xl:flex-row 2xl:gap-0 justify-between pt-[20px]">
                  <div className="text-center">
                    <div className="font-semibold">Grand Total</div>
                    <div className="text-neutral-500">₹{formatIndianNumber(item?.grand_total || 0)}</div>
                  </div>
                  <div className="text-center">
                    <div className="font-semibold">Paid Amount</div>
                    <div className="text-neutral-500">₹{formatIndianNumber(item?.paid_amount || 0)}</div>
                  </div>
                  <div className="text-center">
                    <div className="font-semibold">Status</div>
                    <div className="text-neutral-500">{item.payment_type}</div>
                  </div>
                </div>
                <div className="flex justify-center mt-[30px]">
                  <button
                    onClick={() => {
                      handleDownloadPDF(item?.Invoice_pdf);
                    }}
                    className="text-white text-sm font-semibold bg-black px-[20px] py-[10px] rounded-full"
                  >
                    View Invoice
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default InventoryList;
