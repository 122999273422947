import React from "react";

const Others = ({ smallScreen }) => {
  const steps = [
    {
      title: "Create a Folder to Save Your File",
      description:
        "Start by creating a folder on your computer to store the exported Inventory Excel file. This will help keep it organized and easy to find.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Open Your ERP System and Log In",
      description:
        "Log into your ERP system with the necessary credentials. Ensure you have access to the Inventory Reports section.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Navigate to the Reports or Inventory Section",
      description:
        "Once logged in, go to the Reports section. Within Reports, locate 'Inventory' or a similar option to view available inventory reports.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Inventory Report",
      description:
        "Choose the Inventory Summary or Stock Summary report. This report provides details on current stock levels, valuations, and other inventory metrics.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Choose Export Option",
      description:
        "Within the Inventory report view, look for an option to export or download the report. Select 'Export' and choose 'Excel' as the format.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Specify Save Location",
      description:
        "In the export dialog, select the folder created in Step 1 as the save location. Confirm the file name and click 'Save'.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Verify the Exported File",
      description:
        "Navigate to the folder you created and check that the Inventory report was successfully saved as an Excel file.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Upload to TheProfit.AI ERP",
      description:
        "Once exported, you can proceed to upload the file to TheProfit.AI ERP for further analysis and processing.",
      imgSrc: null,
    },
  ];

  return (
    <div className="mx-auto p-4">
      {/* {steps.map((step, index) => (
        <div key={index} className="mb-10 flex flex-col items-center">
          <h2 className="text-2xl font-semibold mb-2">{`Step ${index + 1}: ${step.title}`}</h2>
          <p className="text-gray-700 mb-4 max-w-[600px] text-center">{step.description}</p>
          {step.imgSrc && (
            <img
              src={step.imgSrc}
              alt={step.title}
              className={`w-full rounded-lg shadow-md ${smallScreen ? "max-w-[40vw]" : "max-w-[50vw]"}`}
            />
          )}
        </div>
      ))}

      <footer className="text-center text-sm text-gray-500 mt-12">
        By following these steps, you can easily export the Inventory report from your ERP system for further processing
        in our software.
      </footer> */}
      <div>Currently we are working on adding support for other software data migration.</div>
    </div>
  );
};

export default Others;
