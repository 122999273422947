import React, { useState, useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";

// placeholder generated data
// const data = {
//   x_labels: [
//     "Day 01",
//     "Day 02",
//     "Day 03",
//     "Day 04",
//     "Day 05",
//     "Day 06",
//     "Day 07",
//     "Day 08",
//     "Day 09",
//     "Day 10",
//     "Day 11",
//     "Day 12",
//     "Day 13",
//     "Day 14",
//     "Day 15",
//     "Day 16",
//     "Day 17",
//     "Day 18",
//     "Day 19",
//     "Day 20",
//     "Day 21",
//     "Day 22",
//     "Day 23",
//     "Day 24",
//     "Day 25",
//     "Day 26",
//     "Day 27",
//     "Day 28",
//     "Day 29",
//     "Day 30",
//   ],
//   current_month_data: [
//     765000, 774000, 759500, 762500, 768000, 775500, 763000, 769000, 760500, 773000, 764000, 771500, 765500, 758000,
//     772000, 759000, 764500, 768500, 762000, 770000, 761500, 769500, 762500, 774000, 768500, 760000, 765500, 770500,
//     764000, 769000,
//   ],
//   previous_month_data: [
//     760000, 769000, 758000, 764000, 766000, 772500, 764500, 767000, 761000, 774500, 763500, 770000, 766500, 759000,
//     770500, 760500, 763000, 767500, 763500, 771500, 763000, 770000, 761500, 772500, 769000, 759500, 764000, 771000,
//     765000, 770500,
//   ],
// };

// "Profit Margin (Monthly %)": 6.5, // between 6-7%
// "Profit Margin (Monthly Absolute)": 1365000, // 6.5% of total sales
// "Total Sales (Monthly)": 21000000, // Approx. 7 lakh daily sales * 30 days ± 5%
// "Debt Given (Monthly)": 3780000, // 18% of total sales
// "Cash on Hand (Daily)": 406000, // 58% of today's sales value (700000 approx.)
// "Current Stock Value": 2800000, // 4 times today's sales value
// "Expected Profit Margin": 1680000, // 8% of total sales
// "Today's Sales Value (Daily)": 700000 // Approx. 1/30 of total monthly sales

const TotalSales = ({ data, containerDimension }) => {
  const PaddedContainerHeight = containerDimension.height - 40;
  const PaddedContainerWidth = containerDimension.width - 40;

  const [sortedData, setSortedData] = useState({
    x_labels: [],
    current_month_data: [],
    previous_month_data: [],
  });

  useEffect(() => {
    const combinedData = data.x_labels.map((label, index) => ({
      label,
      currentMonth: data.current_month_data[index],
      previousMonth: data.previous_month_data[index],
    }));

    combinedData.sort((a, b) => new Date(a.label) - new Date(b.label));
    console.log(combinedData, "this is the combined data");

    setSortedData({
      x_labels: combinedData.map((item) => item.label),
      current_month_data: combinedData.map((item) => item.currentMonth),
      previous_month_data: combinedData.map((item) => item.previousMonth),
    });
  }, [data]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className="text-center mt-[20px]"
        onClick={() => {
          setOpen(true);
        }}
      >
        Sales
        <LineChart
          width={PaddedContainerWidth}
          height={PaddedContainerHeight}
          sx={{ padding: "10px" }}
          series={[
            {
              data: sortedData.previous_month_data,
              label: "Previous Month",
              showMark: true,
              color: "#ff9900",
            },
            {
              data: sortedData.current_month_data,
              label: "Current Month",
              showMark: true,
              color: "#277494",
            },
          ]}
          xAxis={[{ scaleType: "point", data: sortedData.x_labels }]}
        />
      </div>
      <CustomModal
        isOpen={open}
        handleClose={() => {
          setOpen(false);
        }}
      >
        <LineChart
          width={580}
          height={500}
          sx={{ padding: "20px" }}
          series={[
            {
              data: sortedData.current_month_data,
              label: "Current Month",
              showMark: true, // Always show markers
              color: "#277494",
              markerSize: 5, // Size of the dots
              lineWidth: 0, // Remove the line for a dotted effect
            },
            {
              data: sortedData.previous_month_data,
              label: "Previous Month",
              showMark: true, // Always show markers
              color: "#ff9900",
              markerSize: 5, // Size of the dots
              lineWidth: 0, // Remove the line for a dotted effect
            },
          ]}
          xAxis={[{ scaleType: "point", data: sortedData.x_labels }]}
        />
      </CustomModal>
    </>
  );
};

export default TotalSales;
