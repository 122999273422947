import React from "react";

//Current Stock Guide
import step_1_current from "../../../../assets/images/onboarding/marg/Current Stock/step_1.png";
import step_2_current from "../../../../assets/images/onboarding/marg/Current Stock/step_2.png";
import step_3_current from "../../../../assets/images/onboarding/marg/Current Stock/step_3.png";
import step_4_current from "../../../../assets/images/onboarding/marg/Current Stock/step_4.png";
import step_5_current from "../../../../assets/images/onboarding/marg/Current Stock/step_5.png";

//HSN/SAC Stock Guide
import step_1_hsn from "../../../../assets/images/onboarding/marg/HSN_SAC Stock/step_1.png";
import step_2_hsn from "../../../../assets/images/onboarding/marg/HSN_SAC Stock/step_2.png";
import step_3_hsn from "../../../../assets/images/onboarding/marg/HSN_SAC Stock/step_3.png";
import step_4_hsn from "../../../../assets/images/onboarding/marg/HSN_SAC Stock/step_4.png";
import step_5_hsn from "../../../../assets/images/onboarding/marg/HSN_SAC Stock/step_5.png";
import step_6_hsn from "../../../../assets/images/onboarding/marg/HSN_SAC Stock/step_6.png";
import step_7_hsn from "../../../../assets/images/onboarding/marg/HSN_SAC Stock/step_7.png";
import step_8_hsn from "../../../../assets/images/onboarding/marg/HSN_SAC Stock/step_8.png";
import step_9_hsn from "../../../../assets/images/onboarding/marg/HSN_SAC Stock/step_9.png";

//Batch stock guide
import step_1_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_1.png";
import step_2_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_2.png";
import step_3_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_3.png";
import step_4_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_4.png";
import step_5_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_5.png";
import step_6_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_6.png";
import step_7_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_7.png";
import step_8_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_8.png";
import step_9_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_9.png";
import step_10_batch from "../../../../assets/images/onboarding/marg/Batch Stock/step_10.png";

//ledger stock guide
import step_1_ledger from "../../../../assets/images/onboarding/marg/Ledger Stock/step_1.png";
import step_2_ledger from "../../../../assets/images/onboarding/marg/Ledger Stock/step_2.png";
import step_3_ledger from "../../../../assets/images/onboarding/marg/Ledger Stock/step_3.png";
import step_4_ledger from "../../../../assets/images/onboarding/marg/Ledger Stock/step_4.png";
import step_5_ledger from "../../../../assets/images/onboarding/marg/Ledger Stock/step_5.png";

//Vendor Customer Report
import step_1_vendor_customer from "../../../../assets/images/onboarding/marg/Vendor Customer Report/customer_vendor.png";
import step_2_customer from "../../../../assets/images/onboarding/marg/Vendor Customer Report/customers.png";
import step_2_vendor from "../../../../assets/images/onboarding/marg/Vendor Customer Report/vendors.png";

const MargERP = ({ smallScreen }) => {
  const steps = [
    // {
    //   title: "Navigate to Current Stock Section",
    //   description: "Go to the 'Stocks' menu and select 'Current Stock' to access the current stock report.",
    //   imgSrc: step_1_current,
    // },
    // {
    //   title: "Closing Stock Window",
    //   description:
    //     "A 'Closing Stock' window will appear. At the bottom of this window, locate and click on the 'Excel' icon.",
    //   imgSrc: step_2_current,
    // },
    // {
    //   title: "Specify Export Path",
    //   description:
    //     "In the 'Export to Excel' window, Marg will display the path where the exported Excel file will be saved. Confirm this path or adjust if needed.",
    //   imgSrc: step_3_current,
    // },
    // {
    //   title: "Generate Report in Excel Format",
    //   description:
    //     "Click on the 'Excel' tab to start exporting the current stock report. The software will begin exporting the report in Excel format.",
    //   imgSrc: step_4_current,
    // },
    // {
    //   title: "Confirm and Review the Exported Report",
    //   description:
    //     "Once the export is complete, navigate to the defined location on your computer to verify the current stock report was successfully saved in Excel format.",
    //   imgSrc: step_5_current,
    // },
    // {
    //   title: "You have Successfully Exported Current Stock Report",
    //   description: "Move onto next steps to generate more reports required for migration.",
    //   imgSrc: null,
    // },

    //HSN/SAC reports
    {
      title: "Navigate to Item GST%/HSN/SAC/RATES Updation",
      description: "Go to 'Masters' > 'Ease of GST' > 'Item GST%/HSN/SAC/RATES Updation' to access the update section.",
      imgSrc: step_1_hsn,
    },
    {
      title: "Enter Stock Details",
      description:
        "In the 'Item GST%/HSN/SAC/RATES Updation' window, enter the stock date in 'Stock As On'. Set 'Stock Status' to 'Whole'.",
      imgSrc: step_2_hsn,
    },
    {
      title: "Enable More Options",
      description: "In the main window, set 'More Options' to 'Yes' to proceed with additional options.",
      imgSrc: step_3_hsn,
    },
    {
      title: "Select HSN/SAC Option",
      description:
        "When the 'More Options' window appears, set 'Selected HSN/SAC' to 'Yes' to specify the HSN/SAC for the report.",
      imgSrc: step_4_hsn,
    },
    {
      title: "Choose Specific HSN/SAC",
      description:
        "In the 'HSN/SAC' window, choose the required HSN/SAC code. Use 'Spacebar' or '+' to select specific items, or '*' to select all. Press 'Enter' to confirm.",
      imgSrc: step_5_hsn,
    },
    {
      title: "Proceed to Report Generation",
      description: "Press the 'Pagedown' key to continue and then click on 'Accept' to generate the report.",
      imgSrc: step_6_hsn,
    },
    {
      title: "Report is generated",
      description: "This is generated and not press alt + P to view options",
      imgSrc: step_8_hsn,
    },
    {
      title: "Export to Excel format",
      description: "Click on excel format and save this report at desired location.",
      imgSrc: step_9_hsn,
    },

    //Batch Report
    {
      title: "Access Batch Stock",
      description: "Go to 'Stock' > 'Batch Stock' and press 'Enter' to open the Batch Stock section.",
      imgSrc: step_1_batch,
    },
    {
      title: "Select Store",
      description:
        "In the 'Select Store' window, press 'Spacebar' to select stores. To select all, press 'Spacebar' on 'Whole', then press 'Enter'.",
      imgSrc: step_2_batch,
    },
    {
      title: "Enter Stock Date",
      description:
        "In the 'Batch Stock' window, enter the date for which you want to view the stock report in 'Stock as On'. For example, enter '07-01-19' and press 'Enter'.",
      imgSrc: step_3_batch,
    },
    {
      title: "Select Company Evaluation",
      description:
        "In 'Selected Company', choose 'Yes' to evaluate the stock by company, or 'No' otherwise. If 'Yes', a 'Select Company' window will appear. Press 'Spacebar' to select a company, then press 'Escape'.",
      imgSrc: step_4_batch,
    },
    {
      title: "Choose Stock Status",
      description:
        "In 'Stock Status', select whether to value 'Available' stock or 'All' stock. For example, select 'Available' to view available stock.",
      imgSrc: step_6_batch,
    },
    {
      title: "Select Salt (For Medical Stock)",
      description:
        "If dealing with medical stock, set 'Selected Salt' to 'Yes' to view the 'Salt' window and press 'Spacebar' to select a salt. Otherwise, select 'No'.",
      imgSrc: step_7_batch,
    },
    {
      title: "Enable More Options",
      description:
        "In 'More Options', select 'Yes' to open the 'More Options for Batch Stock' window and enter additional details as needed. Otherwise, select 'No'.",
      imgSrc: step_8_batch,
    },
    {
      title: "Accept and View Report",
      description:
        "Click on 'Accept' to generate the report. The 'Whole Store: Closing Stock' window will display the stock report.",
      imgSrc: step_9_batch,
    },
    {
      title: "Export to Excel format",
      description: "Click on excel button in bottom bar and save this report at desired location.",
      imgSrc: step_10_batch,
    },

    //Ledger Steps:
    {
      title: "Go to All Ledgers",
      description: "Navigate to 'Books' > 'All Ledgers' and press 'Enter' to open the Ledger Accounts window.",
      imgSrc: step_1_ledger,
    },
    {
      title: "Open Print List Window",
      description: "Press 'ALT+P' to open the 'Print List' window.",
      imgSrc: step_2_ledger,
    },
    {
      title: "Select Options",
      description:
        "Choose 'No' if you want to export ledgers with opening balance, or 'Yes' to exclude it, Select 'Yes' to export the ledger list based on account groups, or 'No' for no grouping., Choose 'Yes' to include outstanding details in the export, or 'No' otherwise, Select 'Yes' to export the ledger list with contact details, or 'No' to exclude them, Select 'Yes' if you want to merge debtors in the export file, otherwise keep it as 'No'.",
      imgSrc: null,
    },
    {
      title: "Select Export Format",
      description: "Choose 'View/Print/Excel' based on your preference. For Excel, select 'Excel'.",
      imgSrc: step_3_ledger,
    },
    {
      title: "Export the Ledger List",
      description:
        "After selecting the format, the ledger list will be exported. You can now view the exported file in your chosen format.",
      imgSrc: step_5_ledger,
    },

    //vendor customer
    {
      title: "Customer Telephone/Address/Label report",
      description:
        "Navigate to 'Reports' > 'Other Reports' > 'Telephone/Address/Label' and press 'Enter' to open the group selection screen",
      imgSrc: step_1_vendor_customer,
    },
    {
      title: "Select Group Popup - Export to Excel",
      description:
        "Choose Sundry Debtors and then Press 'ALT+P' to open the 'Print List' window. in that print list select Excel format to download this report in excel format",
      imgSrc: step_2_customer,
    },
    {
      title: "Vendor Telephone/Address/Label report",
      description:
        "Navigate to 'Reports' > 'Other Reports' > 'Telephone/Address/Label' and press 'Enter' to open the group selection screen",
      imgSrc: step_1_vendor_customer,
    },
    {
      title: "Select Group Popup - Export to Excel",
      description:
        "Choose Sundry Creditors and then Press 'ALT+P' to open the 'Print List' window. in that print list select Excel format to download this report in excel format",
      imgSrc: step_2_vendor,
    },
    {
      title: "Move to the next step",
      description: "You are all finished. Click on 'Next' button to proceed.",
      imgSrc: null,
    },
  ];

  return (
    <div className="mx-auto p-4">
      {steps.map((step, index) => (
        <div key={index} className="mb-10 flex flex-col items-center">
          <h2 className="text-2xl font-semibold mb-2">{`Step ${index + 1}: ${step.title}`}</h2>
          <p className="text-gray-700 mb-4 max-w-[600px] text-center">{step.description}</p>
          {step.imgSrc && (
            <img
              src={step.imgSrc}
              alt={step.title}
              loading="lazy"
              className={`w-full rounded-lg shadow-md ${smallScreen ? "max-w-[40vw]" : "max-w-[50vw]"}`}
            />
          )}
        </div>
      ))}

      <footer className="text-center text-sm text-gray-500 mt-12">
        By following these steps, you can easily export all necessary reports from Marg ERP for further processing in
        our software.
      </footer>
    </div>
  );
};

export default MargERP;
