import React, { useState, useEffect } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
// import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import { useDispatch } from "react-redux";
import { updateEmployee } from "../../../reducers/Slices/employeeSlice";
import toast from "react-hot-toast";

function EditEmployee({ employee }) {
  const dispatch = useDispatch();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [formData, setFormData] = useState({
    id: employee.id,
    name: employee.name,
    gender: employee.gender,
    contact_number: employee.contact_number,
    location: employee.location,
    address: employee.address,
    role: employee.role,
    salary: employee.salary,
    date_of_joining: employee.date_of_joining,
    email: employee.email,
    holidays: employee.holidays,
  });
  const [showModal, setShowModal] = useState(false);

  //reset employee value on employee change
  useEffect(() => {
    setFormData({
      id: employee.id,
      name: employee.name,
      gender: employee.gender,
      contact_number: employee.contact_number,
      location: employee.location,
      address: employee.address,
      role: employee.role,
      salary: employee.salary,
      date_of_joining: employee.date_of_joining,
      email: employee.email,
      holidays: employee.holidays,
    });
  }, [employee]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleEditEmployee();
  };

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const handleEditEmployee = () => {
    setDisableSubmitButton(true);
    dispatch(updateEmployee(formData))
      .then((action) => {
        if (action.payload) {
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("employee editing failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const handleEditEmployeeClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto" onSubmit={handleSubmit}>
              <div className="w-full">
                <div className="mb-5">
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                    Name<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    value={formData.name}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter full name"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="gender" className="block mb-2 text-sm font-medium text-gray-900">
                    Gender<span className="pl-1 text-red-500">*</span>
                  </label>
                  <select
                    id="gender"
                    // value={formData.gender}
                    // onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={formData.gender}
                    onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                    required
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="mb-5">
                  <label htmlFor="contact_number" className="block mb-2 text-sm font-medium text-gray-900">
                    Contact Number<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="tel"
                    id="contact_number"
                    value={formData.contact_number}
                    onChange={(e) => setFormData({ ...formData, contact_number: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter contact number"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                    Email<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    id="contact_number"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="NOH" className="block mb-2 text-sm font-medium text-gray-900">
                    Number of Holidays
                  </label>
                  <input
                    type="number"
                    id="NOH"
                    value={formData.holidays}
                    onChange={(e) => setFormData({ ...formData, holidays: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="0"
                    defaultValue={0}
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="location" className="block mb-2 text-sm font-medium text-gray-900">
                    Location<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="location"
                    value={formData.location}
                    onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter location"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900">
                    Address<span className="pl-1 text-red-500">*</span>
                  </label>
                  <textarea
                    id="address"
                    rows="2"
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    value={formData.address}
                    placeholder="Enter address"
                    required
                  ></textarea>
                </div>
                <div className="mb-5">
                  <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900">
                    Role<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="role"
                    value={formData.role}
                    onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter role"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="salary" className="block mb-2 text-sm font-medium text-gray-900">
                    Salary<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    id="salary"
                    value={formData.salary}
                    onChange={(e) => setFormData({ ...formData, salary: parseInt(e.target.value) })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter salary"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="date_of_joining" className="block mb-2 text-sm font-medium text-gray-900">
                    Date of Joining<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    id="date_of_joining"
                    value={formData.date_of_joining}
                    onChange={(e) => setFormData({ ...formData, date_of_joining: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter date of joining"
                    required
                  />
                </div>
                <div className="w-full flex items-center justify-center">
                  <button
                    type="submit"
                    disabled={disableSubmitButton}
                    // onClick={handleSubmit}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Click to Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <button
        className="py-4 border-2 border-black h-[44px] w-full flex items-center justify-center rounded-full"
        onClick={handleEditEmployeeClick}
      >
        Edit Employee
      </button>
    </>
  );
}

export default EditEmployee;
