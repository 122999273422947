import { addDays } from "date-fns";
import axios from "axios";
import { apiUrl } from "../config/appConfig";

export const setSession = ({ token, business_profile }) => {
  if (typeof localStorage !== "undefined") {
    const { access, refresh } = token;
    localStorage.setItem("token", access);
    //Setting cookie as well
    setTokenCookie(access, 2);
    localStorage.setItem("refresh", refresh);
    localStorage.setItem("expireAt", addDays(new Date(), 1).getTime()); //add one day as expiry time
    localStorage.setItem("business_profile", business_profile);
  }
};

export const clearSession = () => {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem("token");
    removeTokenCookie();
    localStorage.removeItem("refresh");
    localStorage.removeItem("expireAt");
    localStorage.removeItem("business_profile");
    localStorage.removeItem("profile_image");
  }
};

//Function to set cookie to show user information in next.js landing page.
export const setTokenCookie = (token, days = 7) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Set the expiration in days
  const expires = `expires=${date.toUTCString()}`;

  // Check if we are in production (secure cookie and cross-domain for production)
  const isProduction = window.location.hostname !== "localhost";

  let cookieString = `token=${token}; path=/; ${expires}; SameSite=None`;

  // Add domain and Secure flag only in production
  if (isProduction) {
    cookieString += `; domain=.theprofit.ai; Secure`;
  }

  document.cookie = cookieString;
};

//Function to remove token cookie
export const removeTokenCookie = () => {
  // Set the token cookie with an expired date to remove it
  const isProduction = window.location.hostname !== "localhost";

  let cookieString = `token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None`;

  // Add domain and Secure flag only in production
  if (isProduction) {
    cookieString += `; domain=.mywebsite.com; Secure`;
  }

  document.cookie = cookieString;
};

//Function to refresh access token
export const refreshTokenFn = async () => {
  const refreshToken = localStorage.getItem("refresh");
  try {
    const response = await axios.post(`${apiUrl}/user_profile/backend/token/refresh`, { refresh: refreshToken });
    const newToken = response.data.access;
    const newRefreshToken = response.data.refresh || null;

    //Check if there is any business profile
    if (!localStorage.getItem("business_profile")) {
      clearSession();
      return;
    }

    //Check for access token if it dosen't exist logout user
    if (!newToken) {
      clearSession();
      return;
    }

    localStorage.setItem("token", newToken);
    //Set cookie as well
    setTokenCookie(newToken);

    //If new refresh token is generated then set it as well
    if (newRefreshToken) {
      localStorage.setItem("refresh", newRefreshToken);
    }

    localStorage.setItem("expireAt", addDays(new Date(), 1).getTime()); //add one day as expiry time

    return newToken;
  } catch (error) {
    console.error("Some Err Occurred", error);
    clearSession();
    return null;
  }
};

export const checkTokenExists = () => {
  const token = localStorage.getItem("token");
  return token !== null;
};
