import React from "react";
import { useNavigate } from "react-router-dom";

function FORM_3_1() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center h-full overflow-y-scroll pt-[5vh]">
      <h1 className="font-bold text-2xl py-[20px] max-w-[60vw] text-center">
        3.1 Details of Outward Supplies and inward supplies liable to reverse charge (other than those covered by Table
        3.1.1)
      </h1>
      <p className="text-neutral-500 py-[20px]">
        Note: Table 3.1(a), (b), (c) and (e) are auto-drafted based on values provided in GSTR-1/1A. Whereas Table
        3.1(d) is auto-drafted based on GSTR-2B.{" "}
      </p>

      <table>
        <thead>
          <tr className="bg-gray-50">
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Nature of Supplies{" "}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Total Taxable value (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Integrated Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Central Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              State/UT Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              CESS (₹)
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-semibold bg-white border-[1px] border-neutral-200">
              (a) Outward taxable supplies (other than zero rated, nil rated and exempted)
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-semibold bg-white border-[1px] border-neutral-200">
              (b) Outward taxable supplies (zero rated )
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-semibold bg-white border-[1px] border-neutral-200">
              (c) Other outward supplies (Nil rated, exempted)
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-semibold bg-white border-[1px] border-neutral-200">
              (d) Inward supplies (liable to reverse charge)
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-semibold bg-white border-[1px] border-neutral-200">
              (e) Non-GST outward supplies{" "}
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
          </tr>
        </tbody>
      </table>

      <div className="flex gap-[10px] py-[30px]">
        <button className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full">Cancel</button>
        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Confirm
        </button>
        <button
          className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default FORM_3_1;
