import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

//Authenticate E-Way bill - no addcase builder
export const authenticateEWayBill = createAsyncThunk("ewaybill/authenticateEWayBill", async (data, extra) => {
  const res = await http.post("/ewaybill/auth/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//generate E-Way Bill
export const generateEWayBill = createAsyncThunk("ewaybill/generateEWayBill", async (data, extra) => {
  const res = await http.post("/ewaybill/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//cancel E-way Bill
export const cancelEWayBill = createAsyncThunk("ewaybill/cancelEWayBill", async (data, extra) => {
  const res = await http.post("/ewaybill/cancel/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// get ewayBillByID
export const getEWayBillByID = createAsyncThunk("ewaybill/getEWayBillByID", async (data, extra) => {
  const res = await http.get(`/ewaybill/${data}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//Reject EWayBill
export const rejectEWayBill = createAsyncThunk("ewaybill/rejectEWayBill", async (data, extra) => {
  const res = await http.post("/ewaybill/reject/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Update Transporter ID
export const updateTransporterID = createAsyncThunk("ewaybill/updateTransporterID", async (data, extra) => {
  const res = await http.put(`/ewaybill/update/transporter/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//extend Ewaybill validity
export const extendEWayBillValidity = createAsyncThunk("ewaybill/extendEWayBillValidity", async (data, extra) => {
  const res = await http.post("/ewaybill/validity-extend/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch all ewaybills
export const fetchAllEWayBills = createAsyncThunk("ewaybill/fetchAllEWayBills", async (data, extra) => {
  const res = await http.get("/ewaybill/all", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// fetch more ewaybills - infinity scroll
export const fetchMoreEWayBills = createAsyncThunk("ewaybill/fetchMoreEWayBills ", async (url, extra) => {
  const res = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const ewaybillSlice = createSlice({
  name: "ewaybill",
  initialState: {
    bills: { data: [], next: null },
    billById: {},
  },
  reducers: {
    // toggleAlert: (state, action) => {
    //   return { ...state, alert: action.payload };
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchDashboard.fulfilled, (state, action) => {
    //   return { ...state, dashboard: action.payload };
    // });
    builder.addCase(generateEWayBill.fulfilled, (state, action) => {
      return { ...state, bills: { ...state.bills, data: [...state.bills.data, action.payload] } };
    });
    builder.addCase(getEWayBillByID.fulfilled, (state, action) => {
      return { ...state, billById: action.payload };
    });
    builder.addCase(fetchAllEWayBills.fulfilled, (state, action) => {
      return { ...state, bills: { ...state.bills, data: action.payload.data, next: action.payload.next } };
    });
    builder.addCase(fetchMoreEWayBills.fulfilled, (state, action) => {
      return {
        ...state,
        bills: { ...state.bills, data: [...state.bills.data, ...action.payload.data], next: action.payload.next },
      };
    });
  },
});

export default ewaybillSlice.reducer;
// export const { toggleSidebar, toggleGlobalSearch } = appSlice.actions;
