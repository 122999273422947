import React, { useEffect, useState } from "react";

import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import IconWrapper from "../../MicroComponents/IconWrapper";
//UI Library
import { Archive } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { fetchWarehouses } from "../../../reducers/Slices/warehouseSlice";
import { Autocomplete, debounce, TextField } from "@mui/material";

function WarehouseChange({ handleFieldChange, index }) {
  const dispatch = useDispatch();
  const [warehouseId, setWarehouseId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    resetInitialValue();
    setShowModal(!showModal);
  };

  const resetInitialValue = () => {
    setWarehouseId(null);
  };

  // Reset Value on new form creation
  useEffect(() => {
    resetInitialValue();
  }, [showModal]);

  const handleAddWarehouseIconClick = () => {
    setShowModal(!showModal);
  };

  //warehouse autocomplete opertions
  const warehouses = useSelector((state) => state.warehouse.warehouses.data);

  //Debounced search term state to call fetch warehouse API
  const [warehouseSearchTerm, setWarehouseSearchTerm] = useState("");
  const setDebouncedWarehouseSearchTerm = debounce((value) => setWarehouseSearchTerm(value), 1000);

  useEffect(() => {
    if (warehouseSearchTerm?.length > 0) {
      dispatch(fetchWarehouses(`?search=${warehouseSearchTerm}`));
    } else {
      dispatch(fetchWarehouses());
    }
    // dispatch(searchProducts(productSearchTerm));
  }, [warehouseSearchTerm]);

  const findWarehouseById = (id) => {
    if (!id) {
      return;
    }
    return warehouses.find((warehouse) => warehouse.id === id);
  };

  const handleConfirm = () => {
    handleFieldChange(warehouseId, index, "warehouse");
    setShowModal(false);
  };

  const handleExit = () => {
    setShowModal(false);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div>
          <h3 className="pb-[20px] font-semibold ml-[2px]">Select Warehouse</h3>
          <Autocomplete
            disablePortal
            id="warehouse"
            options={warehouses || []}
            value={findWarehouseById(warehouseId)}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              if (value?.id) {
                setWarehouseId(value.id);
              } else {
                setWarehouseId(null);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} onChange={(e) => setDebouncedWarehouseSearchTerm(e.target.value)} />
            )}
          />
        </div>
        <div className="pt-[20px] flex justify-center gap-[10px]">
          <button
            className="px-[20px] py-[10px] text-black border-black border-2 rounded-full font-semibold"
            onClick={() => handleConfirm()}
          >
            Confirm
          </button>
          <button
            className="px-[20px] py-[10px] text-red-600 border-red-600 border-2 rounded-full font-semibold"
            onClick={() => handleExit()}
          >
            Exit
          </button>
        </div>
      </CustomModal>
      <button onClick={handleAddWarehouseIconClick}>
        <IconWrapper>
          <Archive className="text-white z-10" />
        </IconWrapper>
      </button>
    </>
  );
}

export default WarehouseChange;
