import React, { useEffect, useState } from "react";
import CustomModal from "../../../SharedComponents/CustomModals/CustomModal";
import ButtonWrapper from "../../../MicroComponents/ButtonWrapper";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { fetchBatchesByProductID, fetchProducts, searchProducts } from "../../../../reducers/Slices/productSlice";
import { debounce } from "@mui/material";
import { fetchVendors } from "../../../../reducers/Slices/vendorSlice";
import { createBreakageLog } from "../../../../reducers/Slices/expiryBreakageSlice";
// import { createBreakageEntry, getBreakageAnalytics } from "../../../../reducers/Slices/breakageSlice";

function AddBreakageEntry() {
  const dispatch = useDispatch();

  // Get today's date for default value of date
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const products = useSelector((state) => state.product.products.data);
  const batches = useSelector((state) => state.product.batchesByProductId.data);

  const rawVendors = useSelector((state) => state.vendor.vendors.data);
  const [vendors, setVendors] = useState([]);

  const [formData, setFormData] = useState({
    product: null,
    batch: null,
    vendor: null,
    quantity: 0,
    date: getTodayDate(),
    note: "",
    amount: 0,
  });

  useEffect(() => {
    setVendors(() => {
      if (!formData?.batch?.vendor?.id) {
        return [...rawVendors];
      }

      if (rawVendors?.length === 0) {
        return [formData?.batch?.vendor];
      }

      if (rawVendors.find((vendor) => vendor?.id === formData?.batch?.vendor?.id)) {
        setFormData((prevState) => {
          return { ...prevState, vendor: formData?.batch?.vendor };
        });
        return [...rawVendors];
      } else {
        setFormData((prevState) => {
          return { ...prevState, vendor: formData?.batch?.vendor };
        });
        return [...rawVendors, formData?.batch?.vendor];
      }
    });
  }, [rawVendors, formData?.batch?.vendor]);

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    handleAddBreakageEntry();
  };

  const handleClose = () => {
    resetInitialValue();
    setShowModal(!showModal);
  };

  const payloadGenerator = (formData) => {
    return {
      batch: formData.batch.id,
      product: formData.product.id,
      vendor: formData.vendor.id,
      date: formData.date,
      note: formData.note,
      quantity: formData.quantity,
      amount: 0,
    };
  };

  const handleAddBreakageEntry = () => {
    //forms are working based on vendor, product and batch obj and response needs to be ID only.
    const payload = payloadGenerator(formData);
    setDisableSubmitButton(true);

    dispatch(createBreakageLog(payload))
      .then((action) => {
        if (action.payload) {
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("Breakage entry creation failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const resetInitialValue = () => {
    const initialValues = {
      product: null,
      batch: null,
      vendor: null,
      quantity: 0,
      date: getTodayDate(),
      note: "",
      amount: 0,
    };
    setFormData(initialValues);
  };

  // Reset Value on new form creation
  useEffect(() => {
    resetInitialValue();
    //eslint-disable-next-line
  }, [showModal]);

  const handleAddBreakageEntryClick = () => {
    setShowModal(!showModal);
  };

  const [productSearchTerm, setProductSearchTerm] = useState("");

  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 1000);

  useEffect(() => {
    if (productSearchTerm.length > 0) {
      dispatch(searchProducts(`?product_search=${productSearchTerm}&is_expiry=0`));
    }
    //eslint-disable-next-line
  }, [productSearchTerm]);

  useEffect(() => {
    dispatch(fetchProducts("?is_expiry=0"));
    //eslint-disable-next-line
  }, []);

  const [batchSearchTerm, setBatchSearchTerm] = useState("");

  const setDebouncedBatchSearchTerm = debounce((value) => setBatchSearchTerm(value), 1000);

  useEffect(() => {
    if (batchSearchTerm.length > 0) {
      dispatch(
        fetchBatchesByProductID({ productId: formData?.product?.id, filters: `?search=${batchSearchTerm}&is_expiry=0` })
      );
    }
    //eslint-disable-next-line
  }, [batchSearchTerm]);

  useEffect(() => {
    if (!formData?.product?.id) {
      return;
    }

    dispatch(fetchBatchesByProductID({ productId: formData?.product?.id, filters: `?is_expiry=0` }));
    // eslint-disable-next-line
  }, [formData?.product?.id]);

  const [vendorSearchTerm, setVendorSearchTerm] = useState("");

  const setDebouncedVendorSearchTerm = debounce((value) => setVendorSearchTerm(value), 500);

  useEffect(() => {
    if (vendorSearchTerm.length > 0) {
      dispatch(fetchVendors(`?search=${vendorSearchTerm}`));
    }

    if (vendorSearchTerm.length === 0) {
      dispatch(fetchVendors());
    }
    //eslint-disable-next-line
  }, [vendorSearchTerm]);

  useEffect(() => {
    dispatch(fetchVendors());
  }, []);

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                {/* Product Autocomplete */}
                <div className="mb-5">
                  <label htmlFor="product" className="block mb-2 text-sm font-medium text-gray-900">
                    Product<span className="pl-1 text-red-500">*</span>
                  </label>
                  <Autocomplete
                    disablePortal
                    id="product"
                    options={products}
                    value={formData?.product || null}
                    getOptionLabel={(option) => option.product_name}
                    onChange={(event, value) => setFormData({ ...formData, product: value, batch: null, vendor: null })}
                    renderInput={(params) => (
                      <TextField {...params} required onChange={(e) => setDebouncedProductSearchTerm(e.target.value)} />
                    )}
                  />
                </div>

                {/* Batch Autocomplete */}
                {formData?.product?.id && (
                  <div className="mb-5">
                    <label htmlFor="batch" className="block mb-2 text-sm font-medium text-gray-900">
                      Batch<span className="pl-1 text-red-500">*</span>
                    </label>
                    <Autocomplete
                      disablePortal
                      id="batch"
                      value={formData?.batch || null}
                      options={batches}
                      getOptionLabel={(option) => option.batch_number}
                      onChange={(event, value) => setFormData({ ...formData, batch: value })}
                      renderInput={(params) => (
                        <TextField {...params} required onChange={(e) => setDebouncedBatchSearchTerm(e.target.value)} />
                      )}
                    />
                  </div>
                )}

                {/* Vendor Autocomplete */}
                <div className="mb-5">
                  <label htmlFor="vendor" className="block mb-2 text-sm font-medium text-gray-900">
                    Vendor<span className="pl-1 text-red-500">*</span>
                  </label>
                  <Autocomplete
                    disablePortal
                    id="vendor"
                    options={vendors}
                    value={formData?.vendor || null}
                    getOptionLabel={(option) => option.vendor_name}
                    onChange={(event, value) => setFormData({ ...formData, vendor: value })}
                    renderInput={(params) => (
                      <TextField {...params} required onChange={(e) => setDebouncedVendorSearchTerm(e.target.value)} />
                    )}
                  />
                </div>

                {/* Quantity Field */}
                <div className="mb-5">
                  <label htmlFor="quantity" className="block mb-2 text-sm font-medium text-gray-900">
                    Quantity<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    id="quantity"
                    value={formData.quantity}
                    onChange={(e) => {
                      if (!formData.batch) {
                        toast.error("First select a batch!");
                        return;
                      }

                      if (parseFloat(e.target.value) > formData?.batch.total_quantity) {
                        toast.error("Entered Quantity is more than available in batch!");
                        return;
                      }
                      setFormData({ ...formData, quantity: parseFloat(e.target.value) });
                    }}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter quantity"
                    required
                  />
                </div>

                {/* Date Field */}
                <div className="mb-5">
                  <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">
                    Date<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    id="date"
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    value={formData.date}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>

                {/* Note Field */}
                <div className="mb-5">
                  <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900">
                    Note
                  </label>
                  <textarea
                    id="note"
                    rows="4"
                    onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                    className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    value={formData.note}
                    placeholder="Enter note"
                  ></textarea>
                </div>

                {/* Submit Button */}
                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit Breakage Entry
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <ButtonWrapper eventCallback={handleAddBreakageEntryClick}>Add Breakage Entry</ButtonWrapper>
    </>
  );
}

export default AddBreakageEntry;
