import React from "react";

function MigrationSoftwareSelection({ migrationSoftware, setMigrationSoftware }) {
  return (
    <div>
      <h1 className="text-[32px] font-semibold">Select Software You Are Migrating From</h1>
      <div className="flex justify-between gap-[10px] py-[50px]">
        <div
          className={`flex-1 flex items-center justify-center font-semibold border-2 ${
            migrationSoftware === "Marg" ? "border-red-500 text-red-500 " : "border-neutral-200 text-neutral-500"
          } rounded-[20px] h-[10vh]`}
          onClick={() => setMigrationSoftware("Marg")}
        >
          Marg ERP
        </div>
        <div
          className={`flex-1 flex items-center justify-center font-semibold border-2 text-center ${
            migrationSoftware === "Tally" ? "border-indigo-500 text-indigo-500" : "border-neutral-200 text-neutral-500"
          } rounded-[20px] h-[10vh]`}
          onClick={() => setMigrationSoftware("Tally")}
        >
          Tally ERP 9 / Prime
        </div>
        <div
          className={`flex-1 flex items-center justify-center font-semibold border-2 ${
            migrationSoftware === "Zoho" ? "border-orange-500 text-orange-500" : "border-neutral-200 text-neutral-500"
          } rounded-[20px] h-[10vh]`}
          onClick={() => setMigrationSoftware("Zoho")}
        >
          Zoho Books
        </div>
        <div
          className={`flex-1 flex items-center justify-center font-semibold border-2 ${
            migrationSoftware === "Others" ? "border-black text-black" : "border-neutral-200 text-neutral-500"
          } rounded-[20px] h-[10vh]`}
          onClick={() => setMigrationSoftware("Others")}
        >
          Others
        </div>
      </div>
    </div>
  );
}

export default MigrationSoftwareSelection;
