import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

//Upload Products - Marg Software
export const uploadProductsMarg = createAsyncThunk("onboarding/uploadProductsMarg", async (data, extra) => {
  const res = await http.post(
    "/onboarding/marg/upload/products/",
    { filename: data },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (res.data.status === "success") {
    const message = "Marg product data uploaded successfully";
    return message;
  }
});

//Upload Batches - Marg Software
export const uploadBatchesMarg = createAsyncThunk("onboarding/uploadBatchesMarg", async (data, extra) => {
  const res = await http.post(
    "/onboarding/marg/upload/batches/",
    { filename: data },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (res.data.status === "success") {
    const message = "Marg Batches data uploaded successfully";
    return message;
  }
});

//Upload Ledger - Marg Software
export const uploadLedgerMarg = createAsyncThunk("onboarding/uploadLedgerMarg", async (data, extra) => {
  const res = await http.post("#", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
  if (res.data.status === "success") {
    const message = "Marg ledger data uploaded successfully";
    return message;
  }
});

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {},
  reducers: {
    // toggleAlert: (state, action) => {
    //   return { ...state, alert: action.payload };
    // },
    // reset: (state) => {
    //   return { ...state, alert: { showAlert: false, message: "", type: "success" } };
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchDashboard.fulfilled, (state, action) => {
    //   return { ...state, dashboard: action.payload };
    // });
    // builder.addCase(fetchGlobalSearchData.fulfilled, (state, action) => {
    //   const { products, customers, vendors } = action.payload;
    //   return {
    //     ...state,
    //     global_search: {
    //       products: products || [],
    //       customers: customers || [],
    //       vendors: vendors || [],
    //     },
    //   };
    // });
  },
});

export default onboardingSlice.reducer;
// export const { sidebarInvoice, toggleGlobalSearch } = onboardingSlice.actions;
