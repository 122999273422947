import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreInvoiceByProductId, fetchMoreInvoicesCustomer } from "../../../reducers/Slices/invoiceSlice";
// import { fetchMoreInvoices } from "../../../reducers/Slices/invoiceSlice";
// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchInvoiceByCustomer } from "../../../reducers/Slices/invoiceSlice";
import moment from "moment";
import { fetchMoreCashLedger } from "../../../reducers/Slices/ledgerSlice";
import CashRegister from "../../CashRegister";
import { formatIndianNumber } from "../../../utils/fomatIndianNumber";
// import { fetchMorePurchaseInvoices } from "../../../reducers/Slices/purchaseInvoiceSlice";

const PaymentList = ({ ledger, title, type }) => {
  const dispatch = useDispatch();
  const ledgerNext = ledger?.next;
  const [hasMoreLedgerData, setHasMoreLedgerData] = useState(() => (ledgerNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (ledgerNext) {
      setHasMoreLedgerData(true);
    } else {
      setHasMoreLedgerData(false);
    }
  }, [ledgerNext]);

  //fetch more ledger on scroll if available
  const fetchNextLedgerData = () => {
    if (ledgerNext) {
      dispatch(fetchMoreCashLedger(ledgerNext));
    }
  };

  //   const handleDownloadInvoice = (invoice) => {
  //     const fileUrl = invoice.Invoice_pdf;

  //     const link = document.createElement("a");
  //     link.href = fileUrl;
  //     link.target = "_blank"; // Open in a new tab
  //     document.body.appendChild(link);

  //     link.click();

  //     document.body.removeChild(link);
  //   };

  return (
    //   <InfiniteScroll
    //   dataLength={expenses.length}
    //   next={fetchNextExpenses}
    //   hasMore={hasMoreExpenses}
    //   height={"70vh"}
    //   loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
    //   endMessage={
    //     <p style={{ textAlign: "center", paddingTop: "10px" }}>
    //       <b>Expenses List End</b>
    //     </p>
    //   }
    // >
    //   <ul className="flex flex-col p-[15px] gap-[10px]">
    //     {expenses.map((expense, index) => (
    //       <li
    //         key={index}
    //         className="w-full flex justify-between p-[12px] border-2 border-neutral-200 rounded-full cursor-pointer"
    //         onClick={() => setSelectedExpense(expense)}
    //       >
    //         <span>
    //           <span>{index + 1}.</span>
    //           <span className="ml-[5px]">{expense.expense_name}</span>
    //         </span>
    //         <span>{expense.category}</span>
    //       </li>
    //     ))}
    //   </ul>
    // </InfiniteScroll>
    <div className="p-[15px] h-full overflow-y-scroll">
      <div className="text-center text-sm">
        <span>{`Payment Log of ${title}`}</span>
      </div>
      <InfiniteScroll
        dataLength={ledger.data.length}
        next={fetchNextLedgerData}
        hasMore={hasMoreLedgerData}
        height={"72vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
        endMessage={
          <p style={{ textAlign: "center", paddingTop: "10px" }}>
            <b>Payment log list end.</b>
          </p>
        }
      >
        {ledger?.data?.map((item, index) => {
          return (
            <div key={index} className="border-2 border-neutral-200 hover:border-black rounded-[20px] my-[10px]">
              <div className="p-[20px]">
                <div className="flex flex-col gap-[10px] 2xl:flex-row 2xl:gap-0  ">
                  <div className="text-center w-full">
                    {/* <div className="font-semibold">Invoice Number</div>
                    <div className="text-neutral-500">{item.invoice_counter}</div> */}
                    <div className="font-semibold w-full text-xl">
                      {type === "vendor" ? `${item.vendor?.vendor_name}` : ` ${item.customer?.customer_name}`}
                    </div>
                    <div className="py-[10px] flex gap-[5px] justify-center">
                      <span className="font-semibold">Payment Type: </span>
                      <span>{item?.payment_type}</span>
                    </div>
                    <div className="pb-[10px] flex gap-[5px] justify-center">
                      <span className="font-semibold">Amount: </span>
                      <span>₹{formatIndianNumber(item?.amount || 0)}</span>
                    </div>
                    {item?.note?.length > 0 && (
                      <div className="flex flex-col">
                        <span className="font-semibold">Note</span>
                        <span>{item?.note}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default PaymentList;
