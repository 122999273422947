import React from "react";
import { useNavigate } from "react-router-dom";

function FORM_4() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center h-full overflow-y-scroll pt-[5vh]">
      <h1 className="font-bold text-2xl py-[20px] max-w-[60vw] text-center">4. Eligible ITC</h1>
      <p className="text-neutral-500 py-[20px]">
        Note: Tables 4(A)(1), (3), (4), (5) and 4(D)(2) are auto-drafted based on the values in GSTR-2B.
      </p>

      <table>
        <thead>
          <tr className="bg-gray-50">
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Details
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Integrated Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Central Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              State/UT Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              CESS (₹)
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {/* (A). ITC Available (weather in full or part) */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-bold bg-white border-[1px] border-neutral-200">
              (A) ITC Available (whether in full or part)
            </td>
            <td
              colSpan="4"
              className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            ></td>
          </tr>

          {/* 1. */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-neutral-500 font-semibold bg-white border-[1px] border-neutral-200">
              (1) Import of goods
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* 2. */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-neutral-500 font-semibold bg-white border-[1px] border-neutral-200">
              (2) Import of services
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* 3. */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-neutral-500 font-semibold bg-white border-[1px] border-neutral-200">
              (3) Inward supplies liable to reverse charge (other than 1 & 2 above)
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* 4. */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-neutral-500 font-semibold bg-white border-[1px] border-neutral-200">
              (4) Inward supplies from ISD
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>

            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* 5. */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-neutral-500font-semibold bg-white border-[1px] border-neutral-200">
              (5) All other ITC
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              150.36
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              16,093.57
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              16,093.57
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* (B). ITC Reversed */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-bold bg-white border-[1px] border-neutral-200">
              (B) ITC Reversed
            </td>
            <td
              colSpan="4"
              className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            ></td>
          </tr>

          {/* 1. */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-neutral-500 font-semibold bg-white border-[1px] border-neutral-200">
              (1) As per rules 38,42 & 43 of CGST Rules and section 17(5)
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* 2. */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-neutral-500 font-semibold bg-white border-[1px] border-neutral-200">
              (2) Others
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* (C) Net ITC Available (A) - (B)*/}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-bold bg-white border-[1px] border-neutral-200">
              (C) Net ITC Available (A) - (B)
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              150.36
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              16,093.57
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              16,093.57
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* (B). ITC Reversed */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-bold bg-white border-[1px] border-neutral-200">
              (D) Other Details
            </td>
            <td
              colSpan="4"
              className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            ></td>
          </tr>

          {/* 1. */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-neutral-500 font-semibold bg-white border-[1px] border-neutral-200">
              (1) ITC reclaimed which was reversed under Table 4(B)(2) in earlier tax period
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* 2. */}
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-neutral-500font-semibold bg-white border-[1px] border-neutral-200">
              (2) Ineligible ITC under section 16(4) & ITC restricted due to PoS rules
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>
        </tbody>
      </table>

      <div className="flex gap-[10px] py-[30px]">
        <button className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full">Cancel</button>
        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Confirm
        </button>
        <button
          className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default FORM_4;
