import React from "react";
import { useNavigate } from "react-router-dom";

function FORM_6_1() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center overflow-y-scroll pt-[5vh]">
      <h1 className="font-bold text-2xl py-[20px] max-w-[60vw] text-center">6.1 Payment of tax</h1>
      <p className="text-neutral-500 py-[5px]">Note: No pending Liabilities to pay.</p>
      <p className="text-neutral-500 py-[20px] pt-[5px]">
        Note: The cash available as on date and ITC available (considering ITC of current tax period) are shown in this
        table.
      </p>

      <table className="overflow-x-scroll">
        <thead>
          <tr className="bg-gray-50">
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Description
            </th>
            <th
              scope="col"
              colSpan={5}
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Cash Ledger Balance
            </th>
            <th
              scope="col"
              colSpan={5}
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Credit Ledger Balance(including current month's credit)
            </th>
          </tr>
          <tr className="bg-gray-50">
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            ></th>

            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Integrated Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Central Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              State/UT Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              CESS (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Total (₹){" "}
            </th>

            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Integrated Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Central Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              State/UT Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              CESS (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Total (₹){" "}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black bg-white border-[1px] border-neutral-200">
              Tax
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>

            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              150.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              16,094.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              32,338.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black bg-white border-[1px] border-neutral-200">
              Interest
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>

            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black bg-white border-[1px] border-neutral-200">
              Late Fees
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>

            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
          </tr>
        </tbody>
      </table>

      <p className="text-neutral-500 py-[20px] max-w-[60vw] text-center">
        Note: The net tax payable has been calculated after considering the available balance in negative liability
        statement. System has auto-populated “Tax to be paid through ITC” fields for net tax payable with optimum
        utilization amounts based on provisions of the law relating to credit utilization. However, you may edit the ITC
        utilization. As you change ITC utilization, the cash to be paid will also get changed. If available cash balance
        in Electronic cash ledger is not sufficient to offset the liabilities, additional cash required for paying
        liability is being reflected in the last column of the Table (Addition cash required). You may create challan
        for that amount directly by clicking on the “Create Challan” button
      </p>

      <p className="text-neutral-500 py-[20px] max-w-[60vw] text-center">
        Note: T The ITC and Cash utilization information entered will only be available for 2 days. After expiry of 2
        days, the suggested utilization shall be reverted to original system suggested utilization.
      </p>

      <div className="max-w-[80vw] overflow-y-hidden">
        <table>
          <thead>
            <tr className="bg-gray-50">
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Description
              </th>
              <th
                scope="col"
                colSpan={2}
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Tax Payable(₹)
              </th>
              <th
                scope="col"
                colSpan={2}
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200"
              >
                Adjustment of negative liability of previous tax period(₹)
              </th>
              <th
                scope="col"
                colSpan={2}
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Net Tax Payable(₹)
              </th>
              <th
                scope="col"
                colSpan={4}
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Paid through ITC
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200"
              >
                Other than reverse charge Tax to be paid in Cash(₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200"
              >
                Reverse charge Tax to be paid in Cash(₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200"
              >
                Interest payable (₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200"
              >
                Interest to be paid in cash (₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200"
              >
                Late Fee Payable (₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200"
              >
                Late Fee to be paid in cash (₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200"
              >
                Utilizable Cash balance(₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200"
              >
                Additional Cash required(₹)
              </th>
            </tr>
            <tr className="bg-gray-50">
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              ></th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                Reverse charge and supplies made u/s 9(5)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                Other than reverse charge
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                Reverse charge and supplies made u/s 9(5)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                Other than reverse charge
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                Reverse charge and supplies made u/s 9(5)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                Other than reverse charge
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                Integrated Tax (₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                Central Tax (₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                State/UT Tax (₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              >
                CESS (₹)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              ></th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              ></th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              ></th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              ></th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              ></th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              ></th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              ></th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 "
              ></th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                1
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                2
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                3
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                4
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                5
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                6(2-4)
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                7(3-5)
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                8
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                9
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                10
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                11
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                12(7-8-9-10-11)
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                13
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                14
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                15
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                16
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                17
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                18
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                19
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black bg-white border-[1px] border-neutral-200">
                Integrated Tax (₹)
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
            </tr>

            <tr>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black bg-white border-[1px] border-neutral-200">
                Central Tax (₹)
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
            </tr>

            <tr>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black bg-white border-[1px] border-neutral-200">
                State/UT Tax (₹)
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
            </tr>

            <tr>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black bg-white border-[1px] border-neutral-200">
                CESS (₹)
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                0.00
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex gap-[10px] py-[30px] mb-[30px]">
        <button
          className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Create Challan
        </button>

        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Make Payment/Post Credit to Ledger
        </button>

        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Proceed to File
        </button>
      </div>
    </div>
  );
}

export default FORM_6_1;
