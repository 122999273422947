import { subDays } from "date-fns";
import React, { useEffect, useState } from "react";

//re-using pre-existing date range selector from Profit and Loss section - Do not edit for specific use case because it's being used in P&L
import DateRangeSelector from "../../ProfitAndLoss/profitAndLossComponents/DateRangeSelector";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreProducts, fetchProducts } from "../../../reducers/Slices/productSlice";
import { fetchCustomers, fetchMoreCustomers } from "../../../reducers/Slices/customerSlice";
import { changeDateFormat } from "../../../utils/dateFormat";
import { exportEInvoiceJSON } from "../../../reducers/Slices/invoiceSlice";

//Images for E-Invoice step by step guide
import InvoiceBulkUpload from "../../../assets/images/E-Invoice/Invoice_bulk_upload.jpg";
import InvoiceBulkUploadDetails from "../../../assets/images/E-Invoice/Invoice_bulk_upload_details.jpg";
import InvoiceBulkExcelDownload from "../../../assets/images/E-Invoice/Invoice_bulk_excel_download.jpg";

function ExportEInvoice() {
  const dispatch = useDispatch();
  //Search Filter - Debounced to throttle API calls for specific Invoice No. or Payment Type
  const [searchFilter, setSearchFilter] = useState("");

  const debouncedSetSearchFilter = debounce((value) => setSearchFilter(value), 1000);
  //Disable Submit button till API response
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //IRN Settings - with IRN or Without IRN
  const [selectedIRNSettings, setSelectedIRNSettings] = useState(0);

  const handleIRNSettingsChange = (e) => {
    setSelectedIRNSettings(e.target.value);
  };

  //Handle Date Range Change
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [selectedMonth, setSelectedMonth] = useState({ label: "Not Selected", id: "0" });

  const handleChangeSelectedMonth = (id) => {
    const updatedMonth = monthOption.find((option) => option?.id === id);
    setSelectedMonth(updatedMonth);
  };

  //Month option based on ID
  const monthOption = [
    { label: "Select Month", id: "0" },
    { label: "January", id: "1" },
    { label: "February", id: "2" },
    { label: "March", id: "3" },
    { label: "April", id: "4" },
    { label: "May", id: "5" },
    { label: "June", id: "6" },
    { label: "July", id: "7" },
    { label: "August", id: "8" },
    { label: "September", id: "9" },
    { label: "October", id: "10" },
    { label: "November", id: "11" },
    { label: "December", id: "12" },
  ];

  //Products data
  const [selectedProduct, setSelectedProduct] = useState(null);
  const products = useSelector((state) => state.product.products.data);
  const productsNext = useSelector((state) => state.product.products.next);

  //Operation to fetch all products data
  useEffect(() => {
    if (dispatch) {
      dispatch(fetchProducts("?is_expiry=0"));
    }
  }, [dispatch]);

  useEffect(() => {
    if (productsNext) {
      dispatch(fetchMoreProducts(productsNext));
    }
    //eslint-disable-next-line
  }, [productsNext]);

  //customers data
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const customers = useSelector((state) => state.customer.customers.data);
  const customersNext = useSelector((state) => state.customer.customers.next);

  //Operation to fetch all customer data
  useEffect(() => {
    if (dispatch) {
      dispatch(fetchCustomers());
    }
  }, [dispatch]);

  useEffect(() => {
    if (customersNext) {
      dispatch(fetchMoreCustomers(customersNext));
    }
    //eslint-disable-next-line
  }, [customersNext]);

  //Query Builder - based on filter available build query
  const [queryString, setQueryString] = useState("");

  useEffect(() => {
    let updatedQueryString = "";

    updatedQueryString += `?no_irn=${selectedIRNSettings}`;

    if (searchFilter && searchFilter?.length > 0) {
      updatedQueryString += `&search=${searchFilter}`;
    }

    if (selectedCustomer) {
      updatedQueryString += `&customer_id=${selectedCustomer?.id}`;
    }

    if (selectedProduct) {
      updatedQueryString += `&product_id=${selectedProduct?.id}`;
    }

    if (selectedMonth && selectedMonth?.id !== "0") {
      updatedQueryString += `&month=${selectedMonth?.id}`;
    }

    if (dateRange[0]?.startDate && dateRange[0]?.endDate) {
      const startDate = changeDateFormat(dateRange[0]?.startDate);
      const endDate = changeDateFormat(dateRange[0]?.endDate);
      updatedQueryString += `&date_from=${startDate}&date_to=${endDate}`;
    }

    setQueryString(updatedQueryString);
  }, [searchFilter, selectedCustomer, selectedProduct, selectedMonth, dateRange, selectedIRNSettings]);

  useEffect(() => {
    setDisableSubmitButton(true);

    if (queryString?.length === 0) {
      return;
    }

    dispatch(exportEInvoiceJSON(queryString))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
        } else {
          // toast.error("sorry, Invoice cannot be created!");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        // toast.error("error", err);
        setDisableSubmitButton(false);
      });
    //eslint-disable-next-line
  }, [queryString]);

  const handleDownload = () => {
    // if (!EInvoiceDownloadLink) {
    //   return;
    // }
    // const fileUrl = EInvoiceDownloadLink;
    // const link = document.createElement("a");
    // link.href = fileUrl;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  //Date range Selector, MUI Customer Selector, MUI Product Selector, Filter i.e Invoice No., Payment Type, Month Based Selector, IRN Selector
  return (
    <div>
      {/* Controls */}
      <div className="p-[20px] flex gap-[20px] border-b-2 border-neutral-200 flex-wrap">
        <div className="flex gap-[20px] flex-1">
          {/* Segment Selector */}
          <FormControl fullWidth sx={{ minWidth: "200px" }}>
            <InputLabel id="IRNselector">IRN Settings</InputLabel>
            <Select
              labelId="IRN"
              id="IRN"
              value={selectedIRNSettings}
              label="IRN Settings"
              onChange={handleIRNSettingsChange}
            >
              <MenuItem value={0}>Without IRN</MenuItem>
              <MenuItem value={1}>With IRN</MenuItem>
            </Select>
          </FormControl>
          {/* Date Range Selector */}
          <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
          {/* Filter Input */}
          <TextField
            id="filter_input"
            sx={{ minWidth: "20vw" }}
            label="Filter"
            variant="outlined"
            placeholder="Enter Invoice No. or Payment Type"
            onChange={(e) => debouncedSetSearchFilter(e.target.value)}
          />

          <FormControl fullWidth sx={{ minWidth: "200px" }}>
            <InputLabel id="SelectMonth">Optional: Select Month</InputLabel>
            <Select
              labelId="SelectMonth"
              id="SelectMonth"
              value={selectedMonth.id}
              label="Optional: Select Month"
              onChange={(e) => handleChangeSelectedMonth(e.target.value)}
            >
              {monthOption.map((i) => (
                <MenuItem value={i.id}>{i.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="flex gap-[20px] flex-1">
          {/* Product Autocomplete */}
          <Autocomplete
            fullWidth
            options={products}
            getOptionLabel={(option) => option?.product_name || ""}
            value={selectedProduct}
            onChange={(event, newValue) => setSelectedProduct(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Optional: Select Product or Service"
                variant="outlined"
                sx={{ minWidth: "200px" }}
              />
            )}
          />

          {/* Customer Autocomplete */}
          <Autocomplete
            fullWidth
            options={customers}
            getOptionLabel={(option) => option?.customer_name || ""}
            value={selectedCustomer}
            onChange={(event, newValue) => setSelectedCustomer(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Optional: Select Customer" variant="outlined" sx={{ minWidth: "200px" }} />
            )}
          />

          <button
            onClick={handleDownload}
            className="text-white bg-black hover:bg-[rgba(0,0,0,0.8)] px-[50px] rounded-[4px] min-w-fit"
            disabled={disableSubmitButton}
          >
            Download JSON
          </button>
        </div>
      </div>

      {/* Steps to Guide User */}
      <div className="max-h-[72.5vh] overflow-y-scroll p-6 pb-[20vh]">
        <h2 className="text-2xl font-semibold mb-2">Bulk IRN Generation on Invoice Registration Portal</h2>
        <span className="block pb-[20px] text-neutral-500 max-w-[60vw]">
          Generation of IRN is the responsibility of the taxpayer who will be required to report the same to Invoice
          Registration Portal (IRP) for authentication, which in turn will generate a unique reference number (IRN) and
          digital sign the e-invoice and also generate a QR code and will return the invoice to the seller.
        </span>
        <ol className="pl-5 space-y-2">
          <li className="text-neutral-500 max-w-[55vw]">
            <span className="text-black font-semibold">Step 1:</span> Click the "Download JSON" button to obtain the
            E-Invoice JSON file. You may adjust the options as necessary or leave them as default to generate all
            E-Invoices for the past 30 days.
          </li>
          <li className="text-neutral-500 max-w-[55vw]">
            <span className="text-black font-semibold">Step 2:</span> To generate a Bulk Invoice reference numbers, user
            needs to select the sub option ‘Bulk Upload’ option under ‘e-invoice. On clicking this option, the following
            screen will be displayed.
            <img
              src={InvoiceBulkUpload}
              alt="Screen showing invoice bulk upload json on IRN poral"
              className="py-[20px]"
            />
          </li>
          <li className="text-neutral-500 max-w-[55vw]">
            <span className="text-black font-semibold">Step 3:</span> On click of ‘Choose file’ button, the system will
            request to select the JSON file User has to upload the IRN request file (maximum allowed file size for
            upload is 2 MB along with the hash, if generated) to the e-invoice system. After IRN request file is
            uploaded the following screen will be displayed.
            <img
              src={InvoiceBulkUploadDetails}
              alt="Screen showing invoice details on IRN portal after JSON file upload"
              className="py-[20px]"
            />
          </li>
          <li className="text-neutral-500 max-w-[55vw]">
            <span className="text-black font-semibold">Step 4:</span> Next on click of ‘Confirm button the system
            generates the e-invoices and shows the 64 character length Invoice reference number for each request. If it
            is not possible it will show the appropriate error for each request.
            <img
              src={InvoiceBulkExcelDownload}
              alt="Screen showing invoice IRN details with excel download option."
              className="py-[20px]"
            />
          </li>
          <li className="text-neutral-500 max-w-[55vw]">
            <span className="text-black font-semibold">Step 5:</span> The user can export the e-invoices in an Excel
            file by clicking on 'Export to Excel.' The file can then be uploaded in the 'Import E-Invoice' section of
            theprofit.ai ERP, where the IRN numbers will be stored and reflected in the corresponding invoices.
          </li>
          <li className="text-neutral-500 max-w-[55vw]">
            <span className="text-black font-semibold">Summary:</span> Upon completing of the above steps, the E-Invoice
            details, including the IRN numbers, will be successfully integrated and available within the ERP system for
            future reference and tracking.
          </li>
        </ol>
      </div>
    </div>
  );
}

export default ExportEInvoice;
