import React, { useState } from "react";

//P&L components

//Default P&L
import PL from "./profitAndLossComponents/PL";

//Tax P&L
import TaxPL from "./profitAndLossComponents/TaxPL";

//Party Wise P&L
import PartyPL from "./profitAndLossComponents/PartyPL";

//Company wise P&L
import CompanyPL from "./profitAndLossComponents/CompanyPL";

//Item wise P&L
import ItemPL from "./profitAndLossComponents/ItemPL";

//Route based P&L
import RoutePL from "./profitAndLossComponents/RoutePL";
import Joyride from "react-joyride";

const ProfitAndLoss = () => {
  const [selectedPL, setSelectedPL] = useState("default");

  //Render P&L statement based on selected P&L category
  const renderPLComponent = () => {
    switch (selectedPL) {
      case "TaxPL":
        return <TaxPL />;
      case "PartyPL":
        return <PartyPL />;
      case "CompanyPL":
        return <CompanyPL />;
      case "ItemPL":
        return <ItemPL />;
      case "RoutePL":
        return <RoutePL />;
      default:
        return <PL />;
    }
  };

  //React Joyride - Guide user on how to use app
  const steps = [
    {
      target: "#joyride-step-1",
      content: "Select the type of Profit and Loss report you wish to view.",
    },
    {
      target: "#Segment_input",
      content: "Choose the segment relevant to the report context here.",
    },
    {
      target: "#dateRangeSelector",
      content: "Select the date range to filter specific data.",
    },
    {
      target: "#filter_input",
      content: "Search within the selected report using this input.",
    },
    {
      target: "#Download_format",
      content: "Choose a download format, either Excel or CSV, for the Profit and Loss report.",
    },
    {
      target: "#joyride-step-6",
      content: "Click this button to download the report.",
    },
    {
      target: "#joyride-step-7",
      content:
        "This dashboard displays your total sales, taxes, gross profit, and net P&L based on the selected filters.",
    },
    {
      target: "#joyride-step-8",
      content: "This table presents the Profit and Loss data according to the selected filters.",
    },
  ];

  const [localStoragePNLStatus, setLocalStoragePNLStatus] = useState(localStorage.getItem("showed_PNL_guide") || false);

  //handle till final steps
  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      localStorage.setItem("showed_PNL_guide", true);
      setLocalStoragePNLStatus(false);
    }
  };

  return (
    <>
      {/* P&L section */}
      {!localStoragePNLStatus && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          disableBeacon={true}
          callback={handleJoyrideCallback}
          styles={{ options: { primaryColor: "#6366f1" } }}
        />
      )}

      <div className="flex max-w-[96vw]">
        <aside className="p-[15px] border-r-2 border-neutral-200 min-w-[300px] w-[15vw] h-[calc(100vh-80px)]">
          <ul className="flex flex-col gap-[10px] bg-white" id="joyride-step-1">
            {/* <li
              className={`border-[2px] ${
                selectedPL === "default" ? "border-black" : "border-neutral-200"
              }  hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center`}
              onClick={() => setSelectedPL("default")}
            >
              P&L statement
            </li> */}
            <li
              className={`border-[2px] ${
                selectedPL === "TaxPL" ? "border-black" : "border-neutral-200"
              }  hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center`}
              onClick={() => setSelectedPL("TaxPL")}
            >
              P&L statement
            </li>
            <li
              className={`border-[2px] ${
                selectedPL === "PartyPL" ? "border-black" : "border-neutral-200"
              }  hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center`}
              onClick={() => setSelectedPL("PartyPL")}
            >
              Party Wise P&L
            </li>
            <li
              className={`border-[2px] ${
                selectedPL === "CompanyPL" ? "border-black" : "border-neutral-200"
              }  hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center`}
              onClick={() => setSelectedPL("CompanyPL")}
            >
              Company Wise P&L
            </li>
            <li
              className={`border-[2px] ${
                selectedPL === "ItemPL" ? "border-black" : "border-neutral-200"
              }  hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center`}
              onClick={() => setSelectedPL("ItemPL")}
            >
              Item Wise P&L
            </li>
            <li
              className={`border-[2px] ${
                selectedPL === "RoutePL" ? "border-black" : "border-neutral-200"
              }  hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center`}
              onClick={() => setSelectedPL("RoutePL")}
            >
              Route Wise P&L
            </li>
          </ul>
        </aside>

        {/* P&L container */}
        <div className="w-[85vw] max-w-[85vw] overflow-hidden">{renderPLComponent()}</div>
      </div>
    </>
  );
};

export default ProfitAndLoss;
