import React, { useState } from "react";
import { useSelector } from "react-redux";
import get_url_extension from "../../../utils/urlExtension";
import { useNavigate } from "react-router-dom";

function GSTR3B({ financialYear, quarter, month }) {
  const navigate = useNavigate();

  //Download Operations
  //Handle Download Format
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState("json");

  const handleDownload = () => {
    //operation to get download link
    let statementsDownloadLink = null;

    if (!statementsDownloadLink) {
      alert("download button was clicked here");
      return;
    }

    if (get_url_extension(statementsDownloadLink) !== selectedDownloadFormat) {
      return;
    }

    const fileUrl = statementsDownloadLink;

    const link = document.createElement("a");
    link.href = fileUrl;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  //business details
  const businessDetails = useSelector((state) => state.user);

  return (
    <div>
      {/* Nested Bar - Period Selection */}
      <div className="flex justify-between border-b-2 border-neutral-200">
        <div className="flex gap-[10px] h-[75px] items-center pl-[10px]">
          <span>
            <strong>GSTIN: </strong>
          </span>
          <span>{businessDetails?.gst_number ? businessDetails?.gst_number?.toUpperCase() : ""}</span>
        </div>
        <div className="h-[75px] py-[15px] pr-[10px] flex gap-[10px]">
          {/* Date Range Selector */}
          {/* <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} /> */}
          {/* Download Format Selector - excel, csv, json*/}
          <select
            value={selectedDownloadFormat}
            onChange={(e) => setSelectedDownloadFormat(e.target.value)}
            className="bg-white px-[10px] border border-neutral-300 rounded-sm"
          >
            <option value={"json"}>JSON</option>
            <option value={"xlsx"}>Excel</option>
            <option value={"csv"}>CSV</option>
          </select>
          {/* Download Button */}
          <button
            id="joyride-step-6"
            onClick={handleDownload}
            className="text-white bg-black hover:bg-[rgba(0,0,0,0.8)] px-[50px] rounded-[4px]"
          >
            Download
          </button>
        </div>
      </div>

      {/* GSTR-3B Options */}
      <div className="grid grid-cols-3 gap-[15px] p-[15px] border-b-2 border-neutral-200 mb-[20px]">
        <button
          className="rounded-[20px] p-[20px] border-[1px] border-neutral-200 text-center shadow"
          onClick={() => navigate(`/gst-filling/gstr3b/3_1/${financialYear}/${quarter}/${month}`)}
        >
          <h3 className="font-semibold border-b-2 border-neutral-200 pb-[10px]">
            3.1 Tax on outward and reverse charge inward supplies
          </h3>
          <div className="grid grid-cols-2 py-[10px]">
            {/* Integrated Tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Integrated Tax</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* Central Tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Central Tax</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* State/UT tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">State/UT Tax</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* CESS(₹) */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">CESS(₹)</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
          </div>
        </button>
        <button
          className="rounded-[20px] p-[20px] border-[1px] border-neutral-200 text-center shadow"
          onClick={() => navigate(`/gst-filling/gstr3b/3_1_1/${financialYear}/${quarter}/${month}`)}
        >
          <h3 className="font-semibold border-b-2 border-neutral-200 pb-[10px]">
            3.1.1 Supplies notified under section 9(5) of the CGST Act, 2017
          </h3>
          <div className="grid grid-cols-2 py-[10px]">
            {/* Integrated Tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Integrated Tax</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* Central Tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Central Tax</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* State/UT tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">State/UT Tax</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* CESS(₹) */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">CESS(₹)</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
          </div>
        </button>
        <button
          className="rounded-[20px] p-[20px] border-[1px] border-neutral-200 text-center shadow"
          onClick={() => navigate(`/gst-filling/gstr3b/3_2/${financialYear}/${quarter}/${month}`)}
        >
          <h3 className="font-semibold border-b-2 border-neutral-200 pb-[10px]">3.2 Inter-state supplies</h3>
          <div className="grid grid-cols-2 py-[10px]">
            {/* Taxable Value */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Taxable Value</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* Integrated Tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Integrated Tax</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
          </div>
        </button>
        <button
          className="rounded-[20px] p-[20px] border-[1px] border-neutral-200 text-center shadow"
          onClick={() => navigate(`/gst-filling/gstr3b/4/${financialYear}/${quarter}/${month}`)}
        >
          <h3 className="font-semibold border-b-2 border-neutral-200 pb-[10px]">4. Eligible ITC</h3>
          <div className="grid grid-cols-2 py-[10px]">
            {/* Integrated Tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Integrated Tax</span>
              <span className="text-neutral-500 text-sm">₹150.36</span>
            </div>
            {/* Central Tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Central Tax</span>
              <span className="text-neutral-500 text-sm">₹16,093.57</span>
            </div>
            {/* State/UT tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">State/UT Tax</span>
              <span className="text-neutral-500 text-sm">₹16,093.57</span>
            </div>
            {/* CESS(₹) */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">CESS(₹)</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
          </div>
        </button>
        <button
          className="rounded-[20px] p-[20px] border-[1px] border-neutral-200 text-center shadow"
          onClick={() => navigate(`/gst-filling/gstr3b/5/${financialYear}/${quarter}/${month}`)}
        >
          <h3 className="font-semibold border-b-2 border-neutral-200 pb-[10px]">
            5. Exempt, nil and Non GST inward supplies
          </h3>
          <div className="grid grid-cols-2 py-[10px]">
            {/* Inter-state supplies  */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Inter-state supplies</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* Intra-state supplies */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Intra-state supplies</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
          </div>
        </button>
        <button
          className="rounded-[20px] p-[20px] border-[1px] border-neutral-200 text-center shadow"
          onClick={() => navigate(`/gst-filling/gstr3b/5_1/${financialYear}/${quarter}/${month}`)}
        >
          <h3 className="font-semibold border-b-2 border-neutral-200 pb-[10px]">
            5.1 Interest and Late fee for previous tax period
          </h3>
          <div className="grid grid-cols-2 py-[10px]">
            {/* Integrated Tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Integrated Tax</span>
              <span className="text-neutral-500 text-sm">₹150.36</span>
            </div>
            {/* Central Tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Central Tax</span>
              <span className="text-neutral-500 text-sm">₹16,093.57</span>
            </div>
            {/* State/UT tax */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">State/UT Tax</span>
              <span className="text-neutral-500 text-sm">₹16,093.57</span>
            </div>
            {/* CESS(₹) */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">CESS(₹)</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
          </div>
        </button>
        <button
          className="rounded-[20px] p-[20px] border-[1px] border-neutral-200 text-center shadow"
          onClick={() => navigate(`/gst-filling/gstr3b/6_1/${financialYear}/${quarter}/${month}`)}
        >
          <h3 className="font-semibold border-b-2 border-neutral-200 pb-[10px]">6.1 Payment of tax</h3>
          <div className="grid grid-cols-2 py-[10px]">
            {/* Balance Liability  */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Balance Liability</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* Paid through Cash */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Paid through Cash</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
            {/* Paid through Credit */}
            <div className="flex flex-col py-[1px]">
              <span className="text-sm">Paid through Credit</span>
              <span className="text-neutral-500 text-sm">₹0.00</span>
            </div>
          </div>
        </button>
      </div>

      {/* Important Message Section */}
      <div className="border-2 border-neutral-200 mb-[50px] max-w-[50vw] mx-auto">
        <h3 className="w-full bg-black text-white text-center py-[10px]">Important Message</h3>
        <p className="px-[20px] py-[5px]">
          Once you have filled the relevant tables, please follow the following steps for filing:{" "}
        </p>
        <ul className="px-[20px] flex flex-col gap-[10px] pb-[20px]">
          <li>1. Please click on 'Save GSTR3B' on the summary page.</li>
          <li>2. You may download and preview/save the draft GSTR-3B.</li>
          <li>3. Click on 'Proceed to payment' to offset your liabilities.</li>
          <li>
            4. In case of insufficient cash balance to set off the liabilities, challan creation facility has been
            provided on the same screen.
          </li>
          <li>5. After setting off liabilities, GSTR-3B can be filed by attaching DSC/EVC.</li>
        </ul>
      </div>
    </div>
  );
}

export default GSTR3B;
