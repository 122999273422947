import React, { useState } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch } from "react-redux";
import { deleteWarehouse } from "../../../reducers/Slices/warehouseSlice"; // Update with your correct slice path
import toast from "react-hot-toast";

function RemoveWarehouse({ warehouse }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const handleDeleteWarehouse = () => {
    setDisableSubmitButton(true);
    dispatch(deleteWarehouse(warehouse.id))
      .then((action) => {
        if (action.payload) {
          setShowModal(false);
          toast.success("Warehouse entry deleted successfully");
        } else {
          toast.error("Warehouse entry deletion failed");
        }
        setDisableSubmitButton(false);
      })
      .catch((err) => {
        toast.error(err.message || "An error occurred");
        setDisableSubmitButton(false);
      });
  };

  const handleDeleteWarehouseClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <div className="w-full mx-auto">
              <div className="w-full text-center mb-5">
                <p className="text-gray-900 text-sm">Are you sure you want to delete this warehouse entry?</p>
              </div>
              <div className="w-full flex items-center justify-center space-x-4">
                <button
                  type="button"
                  onClick={handleDeleteWarehouse}
                  disabled={disableSubmitButton}
                  className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                >
                  Yes, Delete
                </button>
                <button
                  type="button"
                  onClick={handleClose}
                  className="text-gray-700 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <button
        className="py-4 border-2 border-red-500 h-[44px] w-full flex items-center justify-center text-red-500 rounded-full text-nowrap px-[10px]"
        onClick={handleDeleteWarehouseClick}
      >
        Remove Entry
      </button>
    </>
  );
}

export default RemoveWarehouse;
