import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { detectOS } from "../../../utils/detectOS";

//React Icon Library
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCalculator, faHeadset, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import DraggableCalculator from "./BottomBarComponents/DraggableCalculator";

import supportImg from "../../../assets/images/support.jpg";

// SupportSidebar component
function SupportSidebar({ isOpen, onClose }) {
  return (
    <aside
      className={`fixed top-0 right-0 w-[20vw] h-full bg-white shadow-lg transition-transform duration-300 transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
      style={{ zIndex: 20 }}
    >
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">Support Details</h2>
        <button onClick={onClose} className="text-2xl text-red-500">
          &times;
        </button>
      </div>
      <div className="p-[20px]">
        <h3 className="pb-[20px]">If you need any assistance, please reach out:</h3>
        <div className="flex flex-col gap-[10px]">
          <div className="flex gap-[10px]">
            <span className="flex items-center gap-[4px] text-orange-500">
              <FontAwesomeIcon icon={faEnvelope} />
              <span className="pb-[2px]">Email:</span>
            </span>
            <span className="text-neutral-500">admin@theprofit.ai</span>
          </div>
          <div className="flex gap-[10px]">
            <span className="flex h-fit items-center gap-[4px] text-orange-500">
              <FontAwesomeIcon icon={faPhone} />
              <span className="pb-[2px]">Phone:</span>
            </span>
            <div className="flex flex-col gap-[2px]">
              <span className="text-neutral-500">+91-95320 17976</span>
              <span className="text-neutral-500">+91-92360 96170</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[10vh]">
        <img src={supportImg} alt="Support Representative Graphic" />
      </div>
    </aside>
  );
}

function BottomBar() {
  const location = useLocation();

  const [hotkeysInfo, setHotkeysInfo] = useState("");

  const [showCalculator, setShowCalculator] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const pathHotkeysObj = {
    "/invoice/einvoice": [
      { name: "Sales Invoices", hotkey: ["Alt + N", "Alt + N"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Customer", hotkey: ["Ctrl + Alt + C", "Ctrl + Option + C"] },
    ],
    "/invoice/estimate_invoice": [
      { name: "Sales Invoices", hotkey: ["Alt + N", "Alt + N"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Customer", hotkey: ["Ctrl + Alt + C", "Ctrl + Option + C"] },
    ],
    "/invoice/Cancel_Invoice": [
      { name: "Sales Invoices", hotkey: ["Alt + N", "Alt + N"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Customer", hotkey: ["Ctrl + Alt + C", "Ctrl + Option + C"] },
    ],
    "/invoice/debit_note": [
      { name: "Sales Invoices", hotkey: ["Alt + N", "Alt + N"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Customer", hotkey: ["Ctrl + Alt + C", "Ctrl + Option + C"] },
    ],
    "/invoice/credit_note": [
      { name: "Sales Invoices", hotkey: ["Alt + N", "Alt + N"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Customer", hotkey: ["Ctrl + Alt + C", "Ctrl + Option + C"] },
    ],
    "/invoice/": [
      { name: "Sales Invoices", hotkey: ["Alt + N", "Alt + N"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Customer", hotkey: ["Ctrl + Alt + C", "Ctrl + Option + C"] },
    ],
    "/invoice/all_invoice/": [
      { name: "Sales Invoices", hotkey: ["Alt + N", "Alt + N"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Customer", hotkey: ["Ctrl + Alt + C", "Ctrl + Option + C"] },
    ],
    "/purchase_invoice/create/": [
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Product", hotkey: ["Ctrl + Alt + P", "Ctrl + Option + P"] },
    ],
    "/purchase": [
      { name: "Purchase Invoices", hotkey: ["Alt + P", "Alt + P"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Vendor", hotkey: ["Ctrl + Alt + V", "Ctrl + Option + V"] },
    ],
    "/purchase/purchase_demand": [
      { name: "Purchase Invoices", hotkey: ["Alt + P", "Alt + P"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Vendor", hotkey: ["Ctrl + Alt + V", "Ctrl + Option + V"] },
    ],
    "/purchase/Cancel_Invoice": [
      { name: "Purchase Invoices", hotkey: ["Alt + P", "Alt + P"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Vendor", hotkey: ["Ctrl + Alt + V", "Ctrl + Option + V"] },
    ],
    "/purchase/all_invoice/": [
      { name: "Purchase Invoices", hotkey: ["Alt + P", "Alt + P"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Vendor", hotkey: ["Ctrl + Alt + V", "Ctrl + Option + V"] },
    ],
    "/purchase-book": [
      { name: "Purchase Book", hotkey: ["Alt + F2", "Option + F2"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Vendor", hotkey: ["Ctrl + Alt + V", "Ctrl + Option + V"] },
    ],
    "/sales-book": [
      { name: "Sales Book", hotkey: ["Alt + F1", "Option + F1"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Customer", hotkey: ["Ctrl + Alt + C", "Ctrl + Option + C"] },
    ],
    "/inventory": [
      { name: "Inventory", hotkey: ["Alt + I", "Option + I"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "Add Product", hotkey: ["Ctrl + Alt + P", "Ctrl + Option + P"] },
    ],
    "/": [
      { name: "Home", hotkey: ["Shift + H", "Shift + H"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
    ],
    "/ledger-book": [
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
      { name: "ledger-book shortcut", hotkey: ["Alt + F3", "Option + F3"] },
    ],
    "/expiry_return": [
      { name: "Expiry & Return shortcut", hotkey: ["Shift + E", "Shift + E"] },
      { name: "Global Search", hotkey: ["Shift + F", "Shift + F"] },
    ],
    "*": [{ name: "Global Search", hotkey: ["Shift + F", "Shift + F"] }],
  };

  function stripToTwoSegments(pathname) {
    //Edge case for home route
    if (pathname === "/") {
      return "/";
    }

    const originalPath = pathname;
    pathname = pathname.replace(/\/+$/, "");

    // Split the pathname into segments
    const segments = pathname.split("/").filter((string) => string.length !== 0);

    // Get the last two segments
    const basePath = segments.slice(0, 2).join("/");

    // Check if the original path had a trailing slash or more than two segments
    const hasTrailingSlash = originalPath.endsWith("/");
    const hasMoreThanTwoSegments = segments.length > 2;

    return hasTrailingSlash || hasMoreThanTwoSegments ? `/${basePath}/` : `/${basePath}`;
  }

  useEffect(() => {
    const detectedOS = detectOS();
    const strippedPath = stripToTwoSegments(location.pathname);
    let hotkeyArr = pathHotkeysObj[strippedPath];

    // console.log(hotkeyArr, "this is the hotkey array");

    if (!pathHotkeysObj[strippedPath]) {
      hotkeyArr = pathHotkeysObj["*"];
    }

    let updatedHotkeysInfo = "";

    hotkeyArr.forEach((hotkeyObj) => {
      updatedHotkeysInfo += `${hotkeyObj.name} - ${
        detectedOS === "MacOS" ? `${hotkeyObj.hotkey[1]}` : `${hotkeyObj.hotkey[0]}`
      }, `;
    });

    function stripLastComma(str) {
      const lastIndex = str.lastIndexOf(",");
      return lastIndex !== -1 ? str.slice(0, lastIndex) : str;
    }

    updatedHotkeysInfo = stripLastComma(updatedHotkeysInfo);

    setHotkeysInfo(updatedHotkeysInfo);

    //eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <div className="w-full fixed bottom-0 right-0 z-10 border-t-2 border-neutral-200 bg-white pl-[100px] flex gap-[10px] justify-between">
        <div className="font-semibold">
          <span className="font-medium text-neutral-500">{hotkeysInfo}</span>
        </div>
        <div className="flex gap-[10px] pr-[20px] ">
          <button className="border-x border-neutral-200 px-[5px]" onClick={() => setShowCalculator(!showCalculator)}>
            <FontAwesomeIcon icon={faCalculator} />
          </button>
          {showCalculator && <DraggableCalculator setShowCalculator={setShowCalculator} />}
          <div>
            <button
              className="flex items-center justify-center gap-[10px]"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <span className="mb-[2px] text-neutral-500">Contact Support</span>
              <span>
                <FontAwesomeIcon icon={faHeadset} />
              </span>
            </button>
          </div>
        </div>
      </div>
      <SupportSidebar isOpen={showSidebar} onClose={() => setShowSidebar(false)} /> {/* Sidebar component */}
    </>
  );
}

export default BottomBar;
