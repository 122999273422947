import React, { useState } from "react";
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { changeDateFormat } from "../../../utils/dateFormat";

function DateRangeSelector({ dateRange, setDateRange }) {
  const [modalShow, setModalShow] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setModalShow(false);
  };

  //Custom static range to add current financial year to date range picker
  const customStaticRanges = createStaticRanges([
    {
      label: "Previous Financial Year",
      range: () => {
        const today = new Date();
        const currentMonth = today.getMonth() + 1;
        const currentYear = today.getFullYear();
        let start, end;
        if (currentMonth >= 4) {
          start = new Date(currentYear - 1, 3, 1); // April 1 of last year
          end = new Date(currentYear, 2, 31); // March 31 of this year
        } else {
          start = new Date(currentYear - 2, 3, 1); // April 1 of year before last
          end = new Date(currentYear - 1, 2, 31); // March 31 of last year
        }
        return { startDate: start, endDate: end };
      },
    },
    {
      label: "Current Financial Year",
      range: () => {
        const today = new Date();
        const currentMonth = today.getMonth() + 1;
        const currentYear = today.getFullYear();
        let start, end;
        if (currentMonth >= 4) {
          start = new Date(currentYear, 3, 1); // April 1 of current year
          end = new Date(currentYear + 1, 2, 31); // March 31 of next year
        } else {
          start = new Date(currentYear - 1, 3, 1); // April 1 of last year
          end = new Date(currentYear, 2, 31); // March 31 of current year
        }
        return { startDate: start, endDate: end };
      },
    },
  ]);

  return (
    <div className="relative z-10">
      <div className={`${modalShow ? "absolute" : "hidden"} -translate-x-[30%] z-10`}>
        <form onSubmit={handleSubmit} className="border-[1px] border-neutral-300 flex flex-col items-center bg-white">
          <DateRangePicker
            ranges={dateRange}
            onChange={(item) => setDateRange([item.selection])}
            staticRanges={[...defaultStaticRanges, ...customStaticRanges]}
          />
          <button
            type="submit"
            className="text-center bg-blue-500 text-white py-[10px] px-[20px] rounded-full max-w-[150px] my-[10px]"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </form>
      </div>
      <div className="h-full relative z-0">
        <label
          htmlFor="dateRangeSelector"
          className="absolute top-0 left-[8%] bg-white text-xs text-[rgba(0,0,0,0.7)] translate-y-[-55%] px-[5px]"
        >
          Date Range
        </label>
        <button
          id="dateRangeSelector"
          onClick={(e) => {
            e.preventDefault();
            setModalShow(true);
          }}
          className="border-[1.5px] border-stone-300 text-nowrap px-[10px] h-full rounded-[4px] bg-white"
        >
          {`${changeDateFormat(dateRange[0].startDate)} - ${changeDateFormat(dateRange[0].endDate)}`}
        </button>
      </div>
    </div>
  );
}

export default DateRangeSelector;
