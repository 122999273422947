import React, { useEffect, useState } from "react";
// import DateRangeSelector from "../components/DateRangeSelector";
import get_url_extension from "../../../utils/urlExtension";
// import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

function GSTR1({ financialYear, quarter, month }) {
  // // Get today's date
  // const today = new Date();
  // // First day of the last month
  // const defaultStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  // // last day of the last month
  // const defaultEndDate = new Date(today.getFullYear(), today.getMonth(), 0);

  //Handle Date Change
  // const [dateRange, setDateRange] = useState([
  //   {
  //     startDate: defaultStartDate,
  //     endDate: defaultEndDate,
  //     key: "selection",
  //   },
  // ]);

  //Download Operations
  //Handle Download Format
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState("json");

  const handleDownload = () => {
    //operation to get download link
    let statementsDownloadLink = null;

    if (!statementsDownloadLink) {
      alert("download button was clicked here");
      return;
    }

    if (get_url_extension(statementsDownloadLink) !== selectedDownloadFormat) {
      return;
    }

    const fileUrl = statementsDownloadLink;

    const link = document.createElement("a");
    link.href = fileUrl;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  //business details
  const businessDetails = useSelector((state) => state.user);

  return (
    <div className="overflow-y-scroll pb-[25px]">
      {/* Nested Bar - GSTIN and Download Option*/}
      <div className="flex justify-between border-b-2 border-neutral-200">
        <div className="flex gap-[10px] h-[75px] items-center pl-[10px]">
          <span>
            <strong>GSTIN: </strong>
          </span>
          <span>{businessDetails?.gst_number ? businessDetails?.gst_number?.toUpperCase() : ""}</span>
        </div>
        <div className="h-[75px] py-[15px] pr-[10px] flex gap-[10px]">
          {/* Date Range Selector */}
          {/* <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} /> */}
          {/* Download Format Selector - excel, csv, json*/}
          <select
            value={selectedDownloadFormat}
            onChange={(e) => setSelectedDownloadFormat(e.target.value)}
            className="bg-white px-[10px] border border-neutral-300 rounded-sm"
          >
            <option value={"json"}>JSON</option>
            <option value={"xlsx"}>Excel</option>
            <option value={"csv"}>CSV</option>
          </select>
          {/* Download Button */}
          <button
            id="joyride-step-6"
            onClick={handleDownload}
            className="text-white bg-black hover:bg-[rgba(0,0,0,0.8)] px-[50px] rounded-[4px]"
          >
            Download
          </button>
        </div>
      </div>

      {/* Voucher Details  */}
      <div className="flex-1 rounded-sm p-[20px] mx-[10px] my-[10px] border-[1px] border-neutral-200">
        <div>
          {/* All Vouchers */}
          <div className="flex justify-between pb-[10px]">
            <span className="font-semibold">Total Vouchers</span>
            <span>1</span>
          </div>

          <div className=" border-t border-b border-neutral-200 p-[10px]">
            {/* Included in Return */}
            <div className="flex justify-between pb-[10px]">
              <span className="text-neutral-700">Included in Return</span>
              <span className="text-neutral-700">1</span>
            </div>

            <div className="px-[10px] text-sm">
              <div className="flex justify-between pb-[5px]">
                <span className="text-neutral-500 italic">Included in HSN/SAC Summary</span>
                <span className="text-neutral-500">1</span>
              </div>
              <div className="flex justify-between pb-[10px]">
                <span className="text-neutral-500 italic">
                  Incomplete Information in HSN/SAC Summary (Corrections Needed)
                </span>
                <span className="text-neutral-500">0</span>
              </div>
            </div>

            {/* Not Relevant in this Return  */}
            <div className="flex justify-between pb-[10px]">
              <span className="text-neutral-700">Not relevant in this Return</span>
              <span className="text-neutral-700">0</span>
            </div>

            {/* Uncertain Transactions  */}
            <div className="flex justify-between pb-[10px]">
              <span className="text-neutral-700">Uncertain Transaction (Corrections Needed)</span>
              <span className="text-neutral-700">0</span>
            </div>
          </div>
        </div>
      </div>

      {/* GSTR-1 Table  */}
      <div className="w-full bg-white px-[10px]">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                SI No.
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Particulars
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Voucher Count
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Taxable Amount
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Integrated Tax Amount
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Central Tax Amount
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                State Tax Amount
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Cess Amount
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Tax Amount
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              >
                Invoice Amount
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {/* B2B Invoices */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                1.
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button className="text-blue-600 underline" onClick={() => alert("B2B was clicked")}>
                  B2B Invoices - 4A, 4B, 4C, 6B, 6C
                </button>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                1
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                5,00,000.00
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                90,000.00
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                90,000.00
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                5,90,000.00
              </td>
            </tr>

            {/* B2C - Large  */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                2.
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button className="text-blue-600 underline" onClick={() => alert("B2C Large was clicked")}>
                  B2C(Large) Invoices - 5A, 5B
                </button>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            </tr>

            {/* B2C - Small */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                3.
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button className="text-blue-600 underline" onClick={() => alert("B2C Small was clicked")}>
                  B2C(Small) Invoices - 7
                </button>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            </tr>

            {/* Credit/Debit - Registered  */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                4.
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button
                  className="text-blue-600 underline"
                  onClick={() => alert("Credit/Debit Registered was clicked")}
                >
                  Credit/Debit Notes(Registered) - 9B
                </button>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            </tr>

            {/* Credit/Debit - Unregistered  */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                5.
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button
                  className="text-blue-600 underline"
                  onClick={() => alert("Credit Debit - Unregistered was clicked")}
                >
                  Credit/Debit(unregistered) - 9B
                </button>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            </tr>

            {/* Export Invoice */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                6.
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button className="text-blue-600 underline" onClick={() => alert("Export Invoice was clicked")}>
                  Export Invoices
                </button>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            </tr>

            {/* Tax Liability  */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                7.
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button className="text-blue-600 underline" onClick={() => alert("Tax Liability was clicked")}>
                  Tax Liability(Advances received) - 11A(1), 11A(2)
                </button>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            </tr>

            {/* Adjustment of Advances */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                8.
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button className="text-blue-600 underline" onClick={() => alert("Adjustment of Advances was clicked")}>
                  Adjustment of Advances - 11B(1), 11B(2)
                </button>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            </tr>

            {/* Nil Rated Invoices */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                9.
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button className="text-blue-600 underline" onClick={() => alert("Nil Rated Invoices was clicked")}>
                  Nil Rated Invoices - 8A, 8B, 8C, 8D
                </button>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            </tr>

            {/* Total Row */}
            <tr>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <strong>Total</strong>
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                1
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                5,00,000.00
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                90,000.00
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                90,000.00
              </td>
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                5,90,000.00
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* HSN/SAC Summary & Document Summary*/}
      <div className="flex flex-col gap-[5px] py-[10px] mx-[10px] px-[10px] ">
        <div className="flex gap-[20px] justify-center">
          <div>
            <span>
              <strong>HSN/SAC Summary: </strong> 12
            </span>
          </div>
          <div>
            <span>
              <strong>Document Summary: </strong> 13
            </span>
          </div>
        </div>

        <button
          className="my-[20px] text-lg text-white bg-black px-[20px] py-[10px] max-w-fit mx-auto rounded-full"
          onClick={() => alert("File Return was clicked!")}
        >
          File Return
        </button>

        <span className="text-red-500 text-sm text-center ">
          Note: Voucher count and values are not provided for HSN/SAC Summary and Document Summary.
        </span>
      </div>
    </div>
  );
}

export default GSTR1;
