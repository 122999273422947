import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { importEInvoiceExcel } from "../../../reducers/Slices/invoiceSlice";
import toast from "react-hot-toast";

const ExcelFileInput = () => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file extension
      if (file.name.endsWith(".xlsx")) {
        dispatch(importEInvoiceExcel(file))
          .then(() => {
            setError(null);
            toast.success("File uploaded successfully.");
          })
          .catch((error) => {
            setError("Failed to upload file. Please try again.");
          });
      } else {
        setError("Please upload a valid Excel file (.xlsx).");
      }
    }
  };
  return (
    <div>
      {/* Hidden file input */}
      <input id="file-upload-excel" type="file" accept=".xlsx" onChange={handleFileChange} className="hidden" />
      {/* Custom button */}
      <label htmlFor="file-upload-excel" className="bg-black text-white py-[10px] px-[20px] rounded cursor-pointer">
        Select Excel File
      </label>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default ExcelFileInput;
