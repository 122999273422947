import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Suspense } from "react";
import PublicRoute from "./components/PublicRoutes";
import Login from "./components/Login/Login";
import LoadScreen from "./components/LoadScreen";
import Desktopapp from "./components/DesktopApp";
import PlanSelection from "./components/PlanSelection";
import CheckoutPage from "./components/CheckoutPage";
import OtherSoftwareOnboarding from "./components/Onboarding/OtherSoftwareOnboarding";

const Approuter = () => {
  return (
    <Router>
      <Routes>
        <Route
          index
          path="/*"
          element={
            <Suspense fallback={<LoadScreen />}>
              <Desktopapp />
            </Suspense>
          }
        />

        <Route element={<PublicRoute />}>
          <Route
            path="/login"
            element={
              <Suspense fallback={<LoadScreen />}>
                <Login />
              </Suspense>
            }
          />
        </Route>

        <Route element={<PublicRoute />}>
          <Route
            path="/plan_selection"
            element={
              <Suspense fallback={<LoadScreen />}>
                <PlanSelection />
              </Suspense>
            }
          />
        </Route>

        <Route element={<PublicRoute />}>
          <Route
            path="/checkout_page/:planId"
            element={
              <Suspense fallback={<LoadScreen />}>
                <CheckoutPage />
              </Suspense>
            }
          />
        </Route>

        <Route element={<PublicRoute />}>
          <Route
            path="/onboarding"
            element={
              <Suspense fallback={<LoadScreen />}>
                <OtherSoftwareOnboarding />
              </Suspense>
            }
          />
        </Route>

        {/* <Route element={<PublicRoute />}>
          <Route
            path="/forgetPassword"
            element={
              <Suspense fallback={<LoadScreen />}>
                <ForgetPassword />
              </Suspense>
            }
          />
        </Route> */}
      </Routes>
    </Router>
  );
};

export default Approuter;
