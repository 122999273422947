import React, { useState } from "react";

function GSTR2A({ financialYear, quarter, month }) {
  const [selectedGSTRForm, setSelectedGSTRForm] = useState("PartA");

  return (
    <div className="flex">
      <aside className="p-[15px] border-r-2 border-neutral-200 min-w-[300px] w-[15vw] h-[calc(100vh-175px)]">
        <ul className="flex flex-col gap-[10px] bg-white" id="joyride-step-1">
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedGSTRForm("PartA")}
          >
            PART-A
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedGSTRForm("PartB")}
          >
            PART-B
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedGSTRForm("PartC")}
          >
            PART-C
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedGSTRForm("PartD")}
          >
            PART-D
          </li>
        </ul>
      </aside>

      {/* GSTR-2A container */}
      <div className="w-[85vw] px-[20px] max-w-[85vw] overflow-hidden">
        {/* Part-A */}
        {/* B2B Invoices, Credit/Debit Notes, Amendments to B2B Invoices, Amendments to Credit/Debit Notes, ECO Documents, Amendments to ECO Documents */}
        {selectedGSTRForm === "PartA" && (
          <div className="grid grid-cols-2 gap-[20px] pt-[20px]">
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("B2B invoice was clicked")}
            >
              B2B Invoices
            </button>
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("Credit/Debit Notes was clicked")}
            >
              Credit/Debit Notes
            </button>
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("Amendments to B2B Invoices was clicked")}
            >
              Amendments to B2B Invoices
            </button>
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("Amendments to Credit/Debit Notes was clicked")}
            >
              Amendments to Credit/Debit Notes
            </button>
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("ECO documents was clicked")}
            >
              ECO Documents
            </button>
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("Amendments to ECO Documents was clicked")}
            >
              Amendments to ECO Documents
            </button>
          </div>
        )}

        {/* Part-B */}
        {/* ISD Credits, Amendments to ISD Credits */}
        {selectedGSTRForm === "PartB" && (
          <div className="grid grid-cols-2 gap-[20px] pt-[20px]">
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("ISD Credits was clicked")}
            >
              ISD Credits
            </button>
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("Amendments to ISD Credits was clicked")}
            >
              Amendments to ISD Credits
            </button>
          </div>
        )}

        {/* Part-C */}
        {/* TDS Credits, Amendments to TDS Credits */}
        {selectedGSTRForm === "PartC" && (
          <div className="grid grid-cols-3 gap-[20px] pt-[20px]">
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("TDS Credits was clicked")}
            >
              TDS Credits
            </button>
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("Amendments to TDS Credits was clicked")}
            >
              Amendments to TDS Credits
            </button>
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("TCS Credits was clicked")}
            >
              TCS Credits
            </button>
          </div>
        )}

        {/* Part-D */}
        {/* Import of goods from overseas on bill of entry, Import of goods from SEZ units / developers on bill of entry*/}
        {selectedGSTRForm === "PartD" && (
          <div className="grid grid-cols-2 gap-[20px] pt-[20px]">
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("Import of goods from overseas on bill of entry was clicked")}
            >
              Import of goods from overseas on bill of entry
            </button>
            <button
              className="rounded-[20px] px-[20px] border-[1px] border-neutral-200 text-center py-[40px] shadow"
              onClick={() => alert("import of goods from SEZ units / developers on bill of entry was clicked")}
            >
              Import of goods from SEZ units / developers on bill of entry
            </button>
          </div>
        )}
        <div className="w-full text-center mb-[20px] pt-[40px]">
          <span className="text-red-500 text-sm max-w-[50vw] block mx-auto">
            NOTE: You can only view details of inward supplies in GSTR-2A & In case supplier is regular taxpayer, the
            filing status and date will be based on data saved or filed in GSTR1A otherwise based on GSTR1.
          </span>
        </div>
      </div>
    </div>
  );
}

export default GSTR2A;
