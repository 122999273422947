import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import toast from "react-hot-toast";
import { updateStore } from "../../../reducers/Slices/onlineStoreSlice";

function EditStore({ store }) {
  const dispatch = useDispatch();

  console.log(store, "this is the store here");

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [formData, setFormData] = useState({
    name: store?.name || "",
    id: store?.id,
  });

  useEffect(() => {
    if (!store?.id) return;
  }, [store]);

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    handleEditStore();
  };

  const handleClose = () => {
    resetInitialValue();
    setShowModal(!showModal);
  };

  const handleEditStore = () => {
    setDisableSubmitButton(true);

    const alreadySelectedProductIds = store?.products?.map((product) => product.id);

    dispatch(updateStore({ id: formData?.id, name: formData?.name, product_ids: alreadySelectedProductIds }))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          handleClose();
        } else {
          toast.error("Store Updation failed here!");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const resetInitialValue = () => {
    const initialValues = {
      name: store?.name || "",
      id: store?.id || null,
    };

    setFormData(initialValues);
  };

  // Reset Value on new form creation
  useEffect(() => {
    resetInitialValue();
  }, [showModal]);

  const handleEditStoreClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                <div className="mb-5">
                  <label htmlFor="expense_name" className="block mb-2 text-sm font-medium text-gray-900">
                    Store Name<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="expense_name"
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    value={formData.name}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter Store Name"
                    required
                  />
                </div>

                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-black  focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <button
        className="px-[20px] py-[10px] border-2 bg-white border-black text-black rounded-full text-lg"
        onClick={handleEditStoreClick}
      >
        Edit Store
      </button>
    </>
  );
}

export default EditStore;
