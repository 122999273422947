import React, { useEffect } from "react";
import toast from "react-hot-toast";

const SearchComponent = ({ financialYear, setFinancialYear, quarter, setQuarter, month, setMonth }) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  let currentQuarter = 1; //defaults to 1 here

  //Quarter are different on GST portal other wise formula + 3 / 3 would've worked
  if (currentMonth > 0 && currentMonth <= 2) {
    currentQuarter = 4;
  } else if (currentMonth > 2 && currentMonth <= 5) {
    currentQuarter = 1;
  } else if (currentMonth > 5 && currentMonth <= 8) {
    currentQuarter = 2;
  } else if (currentMonth > 8 && currentMonth <= 11) {
    currentQuarter = 3;
  } else {
    currentQuarter = 1;
    toast.error("Unable to detect current quarter");
  }

  const generateFinancialYears = () => {
    const years = [];
    for (let i = 0; i < 3; i++) {
      const startYear = currentYear - i;
      const endYear = startYear + 1;
      years.push(`${startYear}-${endYear.toString().slice(-2)}`);
    }
    return years;
  };

  const generateQuarters = (year) => {
    const quarters = [
      { label: "Quarter 1 - (Apr - Jun)", value: 1 },
      { label: "Quarter 2 - (Jul - Sep)", value: 2 },
      { label: "Quarter 3 - (Oct - Dec)", value: 3 },
      { label: "Quarter 4 - (Jan - Mar)", value: 4 },
    ];

    // If it’s the current financial year, only show up to the current quarter
    if (year === `${currentYear}-${String(currentYear + 1).slice(-2)}`) {
      return quarters.slice(0, currentQuarter);
    }
    return quarters;
  };

  const generateMonths = (quarter) => {
    const monthsByQuarter = {
      1: ["April", "May", "June"],
      2: ["July", "August", "September"],
      3: ["October", "November", "December"],
      4: ["January", "February", "March"],
    };

    let selectedMonths = monthsByQuarter[quarter] || [];

    // If it’s the current quarter, limit months to the current month
    if (quarter === currentQuarter) {
      selectedMonths = selectedMonths.slice(0, (currentMonth % 3) + 1);
    }
    return selectedMonths;
  };

  useEffect(() => {
    setFinancialYear(generateFinancialYears()[0]);
  }, []);

  useEffect(() => {
    setQuarter(currentQuarter);
    setMonth(generateMonths(currentQuarter)[0]);
  }, [financialYear]);

  return (
    <div className="flex gap-4 items-center rounded-md">
      {/* Financial Year */}
      <select
        value={financialYear}
        onChange={(e) => setFinancialYear(e.target.value)}
        className="px-[20px] py-[10px] bg-white border-2 border-black rounded-[20px]"
      >
        {generateFinancialYears().map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>

      {/* Quarter */}
      <select
        value={quarter}
        onChange={(e) => setQuarter(Number(e.target.value))}
        className="px-[20px] py-[10px] bg-white border-2 border-black rounded-[20px]"
      >
        {generateQuarters(financialYear).map((qtr) => (
          <option key={qtr.value} value={qtr.value}>
            {qtr.label}
          </option>
        ))}
      </select>

      {/* Month */}
      <select
        value={month}
        onChange={(e) => setMonth(e.target.value)}
        className="px-[20px] py-[10px] bg-white border-2 border-black rounded-[20px]"
      >
        {generateMonths(quarter).map((mth) => (
          <option key={mth} value={mth}>
            {mth}
          </option>
        ))}
      </select>

      {/* Search Button */}
      <button
        onClick={() => console.log("Search initiated")}
        className="px-[20px] py-[10px] text-white bg-black rounded-full font-semibold"
      >
        Search
      </button>
    </div>
  );
};

export default SearchComponent;
