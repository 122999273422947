import React, { useState } from "react";
import JsonFileInput from "./JsonFileInput";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { importEInvoiceJSON } from "../../../reducers/Slices/invoiceSlice";
import ExcelFileInput from "./ExcelFileInput";

function ImportEInvoice() {
  const dispatch = useDispatch();
  // Local State to hold JSON data
  const [jsonData, setJsonData] = useState(null);
  const [invalidJSONError, setInvalidJSONError] = useState(false);
  const [APIResponseArr, setAPIResponseArr] = useState([]);

  //Disable Submit button till API response
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  // Handle the change in textarea to update jsonData
  const handleTextareaChange = (e) => {
    try {
      const updatedData = JSON.parse(e.target.value); // Parse to ensure valid JSON
      setJsonData(updatedData); // Update state with valid JSON
      setInvalidJSONError(false);
    } catch (error) {
      // Handle JSON parsing errors
      setInvalidJSONError(true);
    }
  };

  const handleClearEInvoiceData = () => {
    setJsonData(() => {
      return null;
    });
    setAPIResponseArr([]);
    setInvalidJSONError(false);
  };

  const handleSubmitEInvoiceJSON = () => {
    //Disable submit button till valid response.
    setDisableSubmitButton(true);

    //Don't call API till valid data is received.
    if (invalidJSONError || jsonData?.length === 0) {
      setDisableSubmitButton(false);
      return;
    }

    dispatch(importEInvoiceJSON(jsonData))
      .then((action) => {
        if (action.payload) {
          setAPIResponseArr(action?.payload?.data || []);
          setDisableSubmitButton(false);
        } else {
          toast.error("sorry, Invoice cannot be created!");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error("error", err);
        setDisableSubmitButton(false);
      });
  };

  return (
    <div className="p-[20px]">
      {/* JSON file input to take JSON file */}
      <span className="block mb-[20px]">
        Select E-Invoice file with extension .json or paste data manually in E-invoice data field to update IRN in
        invoices.
      </span>

      <div className="flex gap-[10px]">
        <JsonFileInput setJsonData={setJsonData} />
        <ExcelFileInput />
      </div>

      {/* JSON file Data Preview & Textarea for editing */}
      <h2 className="mt-[20px] mb-[10px] text-neutral-500">E-Invoice JSON Data Field: </h2>
      <textarea
        className="w-full h-[30vh] bg-stone-100 p-2 border border-gray-300 rounded-md"
        value={jsonData ? JSON.stringify(jsonData, null, 2) : ""} // Beautify JSON
        onChange={handleTextareaChange} // Handle textarea change
      />
      {invalidJSONError && <span className="block text-red-500">Invalid JSON Data, Please enter valid JSON Data!</span>}

      <div className="mt-[15px] flex gap-[10px]">
        <button
          className=" bg-black text-white px-[20px] py-[10px] rounded-md"
          onClick={handleSubmitEInvoiceJSON}
          disabled={disableSubmitButton}
        >
          Submit Data
        </button>
        <button
          className=" bg-black text-white px-[20px] py-[10px] rounded-md"
          onClick={handleClearEInvoiceData}
          disabled={disableSubmitButton}
        >
          Clear Data
        </button>
      </div>
      {/* Log to show status based on API response */}
      <h2 className="mt-[20px] mb-[10px] text-neutral-500">E-invoice Status Log</h2>
      <pre className="bg-stone-100 h-[30vh] w-full overflow-scroll border border-gray-300 rounded-md p-2">
        {APIResponseArr.map((response, index) => (
          <span key={index} className={`block ${response?.status_code !== 200 ? "text-red-500" : "text-green-500"}`}>
            {response?.status_code !== 200 ? "Error:" : "Success:"} {response?.message}
          </span>
        ))}
      </pre>
    </div>
  );
}

export default ImportEInvoice;
