// import { FilledInput, FormControl, InputAdornment } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductByBarcode, fetchBatchesByProductID } from "../../../reducers/Slices/productSlice";
// import { estimateInvoiceGenerator } from "../../../reducers/Slices/invoiceSlice";
import toast from "react-hot-toast";

//UI Components
import IconWrapper from "../../MicroComponents/IconWrapper";
import { X as Cross } from "react-feather";
import BarcodeReader from "../../Invoices/InvoiceComponents/BarcodeReader";
import { debounce } from "lodash";
import { fetchMoreVendors, fetchVendors } from "../../../reducers/Slices/vendorSlice";
import { http } from "../../../service/http";
import { createReturnVoucher, fetchExpiredProducts } from "../../../reducers/Slices/expiryBreakageSlice";

function CreateExpiryReturnVoucher() {
  const navigate = useNavigate();
  const [items, setItems] = useState([{}]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const params = useParams();

  //Fetch vendor based on ID provided
  const vendorsNext = useSelector((state) => state.vendor.vendors.next);
  const vendor = useSelector((state) => state.vendor.vendors.data.find((v) => v.id === parseInt(params.id)));

  const products = useSelector((state) => state.expiryBreakage.expiredProducts.data);

  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);

  const [formData, setFormData] = useState(() => {
    return { vendor: parseInt(params.id) };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendors());
  }, [dispatch]);

  //Fetch vendors till end
  useEffect(() => {
    if (vendorsNext) {
      dispatch(fetchMoreVendors(vendorsNext));
    }
  }, [vendorsNext, dispatch]);

  //   const navigate = useNavigate();

  //Fetch all products
  // useEffect(() => {
  //   if (!vendor?.id) {
  //     return;
  //   }

  //   if (dispatch) {
  //     dispatch(fetchExpiredProducts(`?vendor_id=${vendor?.id}`));
  //   }
  // }, [dispatch, vendor]);

  useEffect(() => {
    handleCalculate();
  }, [items, calculatedAmountValue]);

  useEffect(() => {
    handleCalculateAmount(items);
  }, [items]);

  const handleChangeItem = (value, index) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item, i) => {
        if (i === index) {
          return value ? { ...value, batches: value.batches } : {};
        }
        return item;
      });
      return updatedItems;
    });
  };

  const handleCalculate = () => {
    if (items?.length <= 0 || !items) {
      return;
    }

    const { total, tax } = items.reduce(
      (accumulator, currentItem, index) => {
        if (currentItem?.purchase_price || currentItem?.purchase_price == 0) {
          const quantity = parseInt(currentItem.quantity) || 0;
          let item_total = parseFloat(currentItem.purchase_price || 0) * quantity;
          let item_tax = item_total * (parseFloat(currentItem.tax || 0) / 100);

          accumulator.total += item_total;
          accumulator.tax += item_tax;
        }
        return accumulator;
      },
      { total: 0, tax: 0 }
    );

    //Changes here
    const productAndQuantity = [];

    items.forEach((i, index) => {
      productAndQuantity.push({
        purchase_invoice_item: null,
        product: i.id,
        quantity: parseInt(i.quantity) || 0,
        batch: i.batchId,
        reason: "EXPIRED",
        amount: calculatedAmountValue[index]?.calculated_amount,
        item_name: i.product_name,
        batch_number: i.batch_number,
        hsn_number: i.hsn_number,
        purchase_price: i.purchase_price,
        tax: (((parseFloat(i?.purchase_price) * parseFloat(i.tax)) / 100) * parseInt(i.quantity)).toFixed(2),
      });
    });

    setFormData({
      ...formData,
      purchase_invoice: null,
      received_amount: calculatedAmountValue.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.calculated_amount);
      }, 0),
      adjustment_type: "CASH",
      sub_total: total.toFixed(2),
      grand_total: calculatedAmountValue.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.calculated_amount);
      }, 0),
      tax: tax.toFixed(2),
      product_and_quantity: productAndQuantity,
      reason: "EXPIRED",
    });
  };
  const handleFieldChange = (value, index, field) => {
    setItems((prevItems) => {
      return prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }; // Update the specified field of the item
        }
        return item;
      });
    });
  };

  const handleRemoveItem = (index) => {
    if (items.length > 1) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }

    if (calculatedAmountValue.length > 1) {
      setCalculatedAmountValue((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }
  };

  const handleCalculateAmount = (items) => {
    if (items?.length <= 0) {
      return;
    }

    if (!items[0]?.id) {
      return;
    }

    setCalculatedAmountValue((prevValue) => {
      const updatedCalculatedAmountValue = prevValue?.map((_, index) => {
        if (_?.checkedByUser === true) {
          return _;
        }

        // ~~ changes here
        let purchase_price, quantity, tax;

        // ~~ changes here
        //Assign values based on nature of product i.e service or goods
        purchase_price = items[index].purchase_price;
        quantity = items[index].quantity;
        tax = items[index].tax;

        if (!purchase_price || !quantity || !tax) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        const parsedQuantity = parseInt(quantity);

        if (purchase_price === 0 || parsedQuantity === 0) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        // let amount = (parseFloat(mrp_price) - parseFloat(mrp_price) * (discountOnMRPPresent / 100)) * parsedQuantity;
        let amount =
          (parseFloat(purchase_price) + (parseFloat(purchase_price) * parseFloat(tax)) / 100) * parsedQuantity;

        //Going the extra mile because toFixed(2) is rounding numbers resulting in wrong value.
        const truncatedAmount = (Math.floor(amount * 1000) / 1000).toFixed(3).slice(0, -1);

        // const differencedAmount = amount - amount * (discountDifference / 100);
        return { calculated_amount: truncatedAmount, checkedByUser: false };
      });
      return updatedCalculatedAmountValue;
    });
  };

  //Change amount value when user manually changes it based on index
  const handleAmountValueChange = (value, index) => {
    setCalculatedAmountValue((prevItems) => {
      const updatedCalculatedAmount = prevItems.map((item, i) => {
        if (index === i) {
          return { calculated_amount: value, checkedByUser: true };
        }
        return item;
      });
      return updatedCalculatedAmount;
    });
  };

  //handleDownloadPDF
  const handleDownloadPDF = async (endpoint) => {
    if (!endpoint) {
      toast.error("No PDF available for this invoice!");
      return;
    }

    if (endpoint) {
      try {
        const response = await http.get(`/storage/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
        // Create a URL for the PNG blob
        const imageURL = URL.createObjectURL(response.data);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `${endpoint.split("/")[1]}`;
        document.body.appendChild(link);
        link.click();
        navigate(-1);
        document.body.removeChild(link);
      } catch (error) {
        toast.error("PDF not found!");
      }
    }
  };

  const handleDownload = async () => {
    setDisableSubmitButton(true);

    if (items[0]?.id) {
      await dispatch(createReturnVoucher(formData))
        .then((action) => {
          if (action.payload) {
            if (!action.payload) {
              setDisableSubmitButton(false);
              toast.error("Sorry, Return voucher cannot be downloaded try again!");
              return;
            }

            handleDownloadPDF(action.payload);
          } else {
            toast.error("sorry, Return voucher cannot be created");
            setDisableSubmitButton(false);
          }
        })
        .catch((err) => {
          toast.error("error", err);
          setDisableSubmitButton(false);
        });
    } else {
      toast.error("Add more products");
      setDisableSubmitButton(false);
    }
  };

  //Find product by product id for autocomplete
  const findProductByProductID = (id) => {
    if (!id) {
      return;
    }
    return products.find((product) => product.id === id);
  };

  //Move to next field on enter button
  const inputRefs = useRef([]);
  const submitButton = useRef(null);
  const addAnotherItemRef = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items?.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        if (focusedElement === submitButton.current) {
          submitButton.current.click();
          return;
        }
        if (focusedElement === addAnotherItemRef.current) {
          addAnotherItemRef.current.click();

          setTimeout(() => {
            const lastRowIndex = inputRefs.current.length - 1;
            // After clicking, shift focus to the first element of the last row
            if (inputRefs.current[lastRowIndex] && inputRefs.current[lastRowIndex][0]) {
              inputRefs.current[lastRowIndex][0].querySelector("input").focus();
            }
          }, 300); // Adding a 300ms delay before shifting focus
          return;
        }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row
              if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
                if (window.confirm("want to add another item?")) {
                  addAnotherItemRef.current.focus(); // Focus on add another item button
                } else {
                  submitButton.current.focus(); //Focus on submit button
                }
              }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [items]);

  const [scannedBarcode, setScannedBarcode] = useState("");
  const [capturedProduct, setCapturedProduct] = useState({});

  /*Just here to workaround react's nature of not re-updating state if similar data causing this api to not fetch data if 
    some barcode is already present. */
  const [scanTimestamp, setScanTimestamp] = useState(Date.now());

  useEffect(() => {
    if (scannedBarcode?.length > 0) {
      dispatch(fetchProductByBarcode(scannedBarcode))
        .then((action) => {
          if (action) {
            setCapturedProduct(action.payload);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong! Product dosen't exist or there are two products with same barcode!");
        });
    }
    //eslint-disable-next-line
  }, [scannedBarcode, scanTimestamp]);

  const handleAddCapturedProductsInItems = (product) => {
    if (!product?.id) {
      return;
    }

    //Get batches from inventory
    const inventoryBatchesData = products.find((productInventory) => productInventory?.id === product?.id)?.batches;

    const productWithBatches = inventoryBatchesData
      ? { ...product, batches: inventoryBatchesData }
      : { ...product, batches: [] };

    // const ItemAlreadyPresent = items.findIndex((item) => item?.id === product?.id);
    const BatchAlreadyPresent = items.findIndex((item) => item?.batchId === product?.batchId);

    let updatedItems = items;

    //Case one product is present but is service then add quantity, if it is present and has batch add quantity
    //otherwise add new product.

    //if it is present and has batch add quantity, otherwise add new product.
    if (BatchAlreadyPresent >= 0 && updatedItems[BatchAlreadyPresent].is_service === "N") {
      if (!updatedItems[BatchAlreadyPresent]?.quantity) {
        //Add quantity variable if not already present
        updatedItems[BatchAlreadyPresent] = {
          ...updatedItems[BatchAlreadyPresent],
          quantity: 0,
        };
      }
      const previousQuantity = parseInt(updatedItems[BatchAlreadyPresent].quantity);
      updatedItems[BatchAlreadyPresent].quantity = previousQuantity + 1;
    } else {
      if (!items[0]?.id) {
        updatedItems = [productWithBatches];
      } else {
        updatedItems = [...items, productWithBatches];
        setCalculatedAmountValue((prev) => [...prev, {}]);
      }
    }

    setItems(updatedItems);
  };

  //Side effect to rearrange data of captured product;
  useEffect(() => {
    //If product dosen't exist then return
    if (!capturedProduct?.id) {
      return;
    }

    const batchData = capturedProduct?.batch;

    let rearrangedProductData = {};

    if (batchData) {
      rearrangedProductData = {
        ...capturedProduct,
        quantity: 1,
        ...batchData,
        batchId: batchData.id,
        id: capturedProduct.id,
      };
    }
    handleAddCapturedProductsInItems(rearrangedProductData);
  }, [capturedProduct]);

  //batch for autocomplete - pass batch if you have batch or null
  const handleBatchForAutoComplete = (product) => {
    const productBatches = getBatchesByProductId(product?.id);
    const specificBatch = productBatches?.find((batch) => batch?.id === product?.batchId);
    if (specificBatch) {
      return specificBatch;
    } else {
      return null;
    }
  };

  //Searched products
  // const searchedProducts = useSelector((state) => state.product.searchedProducts.data);

  //Debounced search term state to call searched product API
  const [productSearchTerm, setProductSearchTerm] = useState("");

  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 1000);

  useEffect(() => {
    if (!vendor?.id) {
      return;
    }
    dispatch(fetchExpiredProducts(`?product_search=${productSearchTerm}&vendor_id=${vendor?.id}`));
  }, [productSearchTerm, vendor]);

  //Debounced batch search term state to call batches search API
  const [batchSearchTerm, setBatchSearchTerm] = useState("");
  const [productIdForSearchedBatch, setProductIdForSearchedBatch] = useState(null);

  const handleBatchSearch = (productId, value) => {
    setDebouncedBatchSearchTerm(value);
    setProductIdForSearchedBatch(productId);
  };

  const setDebouncedBatchSearchTerm = debounce((value) => setBatchSearchTerm(value), 1000);

  useEffect(() => {
    if (!productIdForSearchedBatch) {
      return;
    }

    if (!batchSearchTerm) {
      return;
    }

    dispatch(
      fetchBatchesByProductID({
        productId: productIdForSearchedBatch,
        filters: `?batch_search=${batchSearchTerm}&is_expiry=1&vendor_id=${vendor?.id}`,
      })
    );
  }, [batchSearchTerm]);

  const getBatchesByProductId = (id) => {
    const product = products?.find((product) => product?.id === id);
    if (product) {
      return product?.batches;
    }
  };

  //adjust liability amount, received amount based changes in one another
  const handleChangeLiabilityAmount = (value) => {
    setFormData({
      ...formData,
      liability_amount: parseFloat(value),
      received_amount: parseFloat(formData?.grand_total) - parseFloat(value),
    });
  };

  const handleChangeReceivedAmount = (value) => {
    setFormData({
      ...formData,
      received_amount: parseFloat(value),
      liability_amount: parseFloat(formData?.grand_total) - parseFloat(value),
    });
  };

  return (
    <div>
      <BarcodeReader barcode={scannedBarcode} setBarcode={setScannedBarcode} setScanTimestamp={setScanTimestamp} />
      <div className="mx-[20px] my-[20px]">
        <h1 className="text-2xl font-bold"> New Expiry Return Voucher</h1>
        <div className="mt-8 w-full flex md:justify-start border-b-2 border-neutral-200">
          <div className="md:w-3/6 w-full">
            <h3 className="font-semibold text-xl text-black">{vendor?.vendor_name}</h3>
            <span className="font-semibold text-neutral-500">{`${vendor?.address} ${vendor?.zipcode}`}</span>

            <div className="flex text-base gap-2 my-5">
              <h3 className="font-semibold text-black">GST Number: </h3>
              <span className="font-semibold text-neutral-500">
                {vendor?.gst_number ? vendor?.gst_number?.toUpperCase() : "Not Provided"}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-[20px] overflow-y-scroll">
            <table cellPadding={5} cellSpacing={10}>
              <tr>
                <th>
                  Items Details<span className="pl-1 text-red-500">*</span>
                </th>
                <th>
                  Batch Number<span className="pl-1 text-red-500">*</span>
                </th>
                <th>
                  HSN Number<span className="pl-1 text-red-500">*</span>
                </th>
                <th>
                  Quantity<span className="pl-1 text-red-500">*</span>
                </th>
                <th>
                  Purchase Price<span className="pl-1 text-red-500">*</span>
                </th>
                <th>
                  Tax %<span className="pl-1 text-red-500">*</span>
                </th>
                <th>
                  Amount<span className="pl-1 text-red-500">*</span>
                </th>
              </tr>
              {items?.map((item, index) => (
                <tr key={item?.id || index} className="mb-4">
                  <td>
                    <Autocomplete
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                        }
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={products || []}
                      value={findProductByProductID(item.id)}
                      getOptionLabel={(option) =>
                        option?.total_remaining_quantity
                          ? `${option?.product_name} - Qty: ${option?.total_remaining_quantity}`
                          : `${option?.product_name}`
                      }
                      onChange={(event, value) => {
                        handleChangeItem(value, index);
                      }}
                      sx={{ width: 350 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Products"
                          onChange={(e) => setDebouncedProductSearchTerm(e.target.value)}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                        }
                      }}
                      disablePortal
                      id="batch-number"
                      value={handleBatchForAutoComplete(items[index])}
                      //due to nested objects fields react is not rendering batches correctly, so this is an workaround function
                      options={getBatchesByProductId(items[index]?.id) || []}
                      getOptionLabel={(option) =>
                        option?.batch_number
                          ? `${option?.batch_number} - Qty: ${option?.remaining_quantity}`
                          : `${option?.batch_number}`
                      }
                      onChange={(event, value) => {
                        handleFieldChange(value?.batch_number, index, "batch_number");
                        handleFieldChange(value?.id, index, "batchId"); // Store batch ID
                        handleFieldChange(value?.mrp_price, index, "mrp_price");
                        handleFieldChange(value?.tax, index, "tax");
                        handleFieldChange(value?.purchase_price, index, "purchase_price");
                        handleFieldChange(value?.add_margin, index, "margin");
                        handleFieldChange(value?.remaining_quantity, index, "remaining_quantity");
                        handleFieldChange(value?.remaining_quantity, index, "quantity");
                      }}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Batch Number"
                          onChange={(e) => handleBatchSearch(items[index]?.id, e.target.value)}
                          y
                        />
                      )}
                    />
                  </td>
                  <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      disabled
                      value={items[index]?.hsn_number}
                      defaultValue={""}
                      type="text"
                      variant="outlined"
                    />
                  </td>
                  <td>
                    <TextField
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][2] = el; // Assign the ref if both checks pass
                        }
                      }}
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                        min: 1,
                      }}
                      onChange={(e) => {
                        if (items[index].remaining_quantity < e.target.value) {
                          toast.error("can't add more than remaining quanitity");
                          handleFieldChange(items[index].remaining_quantity, index, "quantity");
                          return;
                        }
                        if (e.target.value && e.target.value < 0) {
                          toast.error("value can't be negative or 0");
                          handleFieldChange(1, index, "quantity");
                          return;
                        }
                        handleFieldChange(e.target.value, index, "quantity");
                      }}
                      value={items[index]?.quantity}
                      defaultValue={0}
                      type="number"
                      variant="outlined"
                    />
                  </td>
                  <td>
                    <TextField
                      id="outlined-basic"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      onChange={(e) => handleFieldChange(e.target.value, index, "purchase_price")}
                      value={items[index]?.purchase_price}
                      type="number"
                      variant="outlined"
                      disabled
                    />
                  </td>
                  <td>
                    <TextField
                      id="filled-adornment-weight"
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      onChange={(e) => handleFieldChange(e.target.value, index, "tax")}
                      value={items[index]?.tax}
                      type="number"
                      variant="outlined"
                      disabled
                    />
                  </td>
                  <td>
                    <TextField
                      id="amount"
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][5] = el; // Assign the ref if both checks pass
                        }
                      }}
                      inputProps={{
                        style: {
                          padding: "16.5px",
                          paddingLeft: "10px",
                        },
                      }}
                      onChange={(e) => handleAmountValueChange(e.target.value, index)}
                      value={calculatedAmountValue[index]?.calculated_amount || 0}
                      type="text"
                      variant="outlined"
                      // disabled
                    />
                  </td>
                  <td
                    onClick={() => {
                      handleRemoveItem(index);
                    }}
                  >
                    <IconWrapper>
                      <Cross className="text-white z-10" />
                    </IconWrapper>
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div>
            <button
              ref={addAnotherItemRef}
              className="text-black p-2 pb-[20px]"
              onClick={() => {
                setItems((prev) => [...prev, {}]);
                setCalculatedAmountValue((prev) => [...prev, {}]);
              }}
            >
              + Add another item
            </button>
            <div className="w-full flex border-t-2 border-neutral-200">
              <div className="w-full">
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">SubTotal</h3>
                  <h3 className="font-semibold text-gray-500">{formData.sub_total || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">SGST @tax%</h3>
                  <h3 className="font-semibold text-gray-500">{(formData.tax / 2).toFixed(3).slice(0, -1) || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">CGST @tax%</h3>
                  <h3 className="font-semibold text-gray-500">{(formData.tax / 2).toFixed(3).slice(0, -1) || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Total</h3>
                  <h3 className="font-semibold text-gray-500">{formData.grand_total || 0}</h3>
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Amount Received</h3>
                  <input
                    type="number"
                    className="py-[10px] px-[10px] border border-black rounded-sm"
                    placeholder="Amount Received"
                    value={parseFloat(formData.received_amount)}
                    onChange={(e) => {
                      if (e.target.value > formData?.grand_total) {
                        toast.error("Amount received cannot be more than grand total!");
                        return;
                      }
                      handleChangeReceivedAmount(e.target.value);
                    }}
                  />
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Liability amount</h3>
                  <input
                    type="number"
                    className="py-[10px] px-[10px] border border-black rounded-sm"
                    placeholder="Liability_amount"
                    value={parseFloat(formData?.liability_amount)}
                    onChange={(e) => {
                      if (e.target.value > formData?.grand_total) {
                        toast.error("Liability Amount cannot be more than grand total!");
                        return;
                      }

                      handleChangeLiabilityAmount(e.target.value);
                    }}
                  />
                </div>
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">Description</h3>
                  <h3 className="font-semibold text-gray-500">
                    <textarea
                      className="w-full min-w-[300px] h-full min-h-[80px] p-2 border-2"
                      placeholder=""
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      value={formData.description}
                    ></textarea>
                  </h3>
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-center my-4">
              <button
                type="button"
                onClick={handleDownload}
                disabled={disableSubmitButton}
                ref={submitButton}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleDownload();
                  }
                }}
                className="fixed bottom-[5vh] text-white bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg min-[1124px]:text-sm text-xs px-5 py-2.5 text-center"
              >
                Download Expiry Return Voucher
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateExpiryReturnVoucher;
