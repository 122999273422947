import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import axios from "axios";

//Get all both vendor and customer
export const fetchCustomerAndVendor = createAsyncThunk("ledger/fetchCustomerAndVendor", async (data, extra) => {
  const res = await http.get(`/user_profile/backend/customer_vendor${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch more customers and vendors - react infinity scroll
export const fetchMoreCustomerAndVendor = createAsyncThunk("ledger/fetchMoreCustomerAndVendor", async (url, extra) => {
  const res = await axios.get(url, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// API to delete vendor - redundant for ledgerbook
export const deleteVendor = createAsyncThunk("ledger/deleteVendor", async (data, extra) => {
  const res = await http.delete("/user_profile/backend/vendor/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  });
  if (res.data.status === "success") {
    return data;
  }
});

// API to delete customer - redundant for Ledgerbook
export const deleteCustomer = createAsyncThunk("ledger/deleteCustomer", async (data, extra) => {
  const res = await http.delete("/user_profile/backend/customer/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  });
  if (res.data.status === "success") {
    return data;
  }
});

// API to update Vendor - redundant for ledgerbook
export const updateVendor = createAsyncThunk("ledger/updateVendor", async (data, extra) => {
  const res = await http.put("/user_profile/backend/vendor/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

// API to update customer - redundant for ledgerbook
export const updateCustomer = createAsyncThunk("ledger/updateCustomer", async (data, extra) => {
  const res = await http.put("/user_profile/backend/customer/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

// API to create cash ledger - To account of outstanding balance of user - dosen't have addcase builder
export const createCashLedger = createAsyncThunk("ledger/createCashLedger", async (data, extra) => {
  const res = await http.post("/cash_ledger/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//API to fetch cash ledger data
export const fetchCashLedger = createAsyncThunk("ledger/fetchCashLedger", async (data, extra) => {
  const res = await http.get(`/cash_ledger/${data ?? ""}`, {
    header: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.results.status === "success") {
    return res.data.results;
  }
});

//API to fetch more cash ledger data
export const fetchMoreCashLedger = createAsyncThunk("ledger/fetchMoreCashLedger", async (url, extra) => {
  const res = await http.get(url, {
    header: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.results;
  }
});

//APIs for future purposes if needed
//API to update Cash Ledger - PUT - /cash_ledger/<id here>/
//API to get all Cash Ledger - GET - /cash_ledger/
//API to get Cash Ledger by id - GET - /cash_ledger/<id here>/
//API to delete Cash Ledger by id - DELETE - /cash_ledger/<id here>/

export const ledgerSlice = createSlice({
  name: "ledger",
  initialState: {
    users: { data: [], next: "" },
    cashLedger: { data: [], next: "" },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerAndVendor.fulfilled, (state, action) => {
      return { ...state, users: { data: action.payload.data, next: action.payload.next } };
    });
    builder.addCase(fetchMoreCustomerAndVendor.fulfilled, (state, action) => {
      return { ...state, users: { data: [...state.users.data, ...action.payload.data], next: action.payload.next } };
    });
    builder.addCase(deleteCustomer.fulfilled, (state, action) => {
      return {
        ...state,
        users: { ...state.users, data: state.users.data.filter((user) => user.id !== action.payload.customer_id) },
      };
    });
    builder.addCase(deleteVendor.fulfilled, (state, action) => {
      return {
        ...state,
        users: { ...state.users, data: state.users.data.filter((user) => user.id !== action.payload.vendor_id) },
      };
    });
    builder.addCase(updateVendor.fulfilled, (state, action) => {
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map((user) => {
            if (user.id === action.payload.id && user.type === "vendor") {
              const updatedUser = { ...action.payload, name: action.payload.vendor_name, type: user.type };
              return updatedUser;
            } else {
              return user;
            }
          }),
        },
      };
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map((user) => {
            if (user.id === action.payload.id && user.type === "customer") {
              const updatedUser = { ...action.payload, name: action.payload.customer_name, type: user.type };
              return updatedUser;
            } else {
              return user;
            }
          }),
        },
      };
    });
    builder.addCase(fetchCashLedger.fulfilled, (state, action) => {
      return {
        ...state,
        cashLedger: {
          data: action.payload.data,
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchMoreCashLedger.fulfilled, (state, action) => {
      return {
        ...state,
        cashLedger: {
          data: [...state.cashLedger.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
  },
});

export default ledgerSlice.reducer;
