import React, { useState } from "react";
import GSTScreen from "../SharedComponents/GSTScreen";
import Customer from "./Customer";
import Product from "./Product";

function AddUser(props) {
  const { handleCloseModal, currentCustomer } = props;
  const [formData, setFormData] = useState(currentCustomer);
  const [data, setData] = useState({});
  const [toggleGST, setToggleGST] = useState(!currentCustomer?.id);
  const [showTab, setShowTab] = useState("customer");
  const renderForm = (params) => {
    switch (params) {
      case "product":
        return <Product />;
      default:
        return (
          <Customer
            isNew={!currentCustomer?.id}
            handleCloseModal={handleCloseModal}
            data={data}
            setData={setData}
            formData={formData}
            setFormData={setFormData}
          />
        );
    }
  };
  return (
    <div className="w-[22rem] mx-auto">
      {toggleGST ? (
        <GSTScreen setFormData={setFormData} formData={formData} setShowGstTab={setToggleGST} type={"customer"} />
      ) : (
        renderForm(showTab)
      )}
    </div>
  );
}

export default AddUser;
