import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import { clearSession } from "../../utils/session";
// import { apiUrl } from "../../config/appConfig";

export const userDelete = createAsyncThunk("user/userDelete", async (data, extra) => {
  const res = await http.delete(`/user_profile/backend/delete_account`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

// Upload and update user profile image
export const uploadUserProfileImage = createAsyncThunk("/user/uploadUserProfileImage", async (profileImage, extra) => {
  const formData = new FormData();
  formData.append("profile_image", profileImage);

  const res = await http.post(`/user_profile/backend/upload-profile-image/`, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Update User Profile
export const updateUserProfile = createAsyncThunk("user/updateUserProfile", async (data, extra) => {
  const res = await http.put(`/user_profile/backend/business-all/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//Find location based on Zipcode
export const searchZipcode = createAsyncThunk("user/searchZipcode", async (data, extra) => {
  const res = await http.get(`/user_profile/api/zipcode/${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.result;
  }
});

// Fetch subscription plans
export const fetchSubscriptionPlans = createAsyncThunk("user/fetchSubscriptionPlans", async () => {
  const res = await http.get(`/transaction/subscription-plans/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data; // Return the subscription plans data
  }
});

// Activate free trial
export const activateFreeTrial = createAsyncThunk("user/activateFreeTrial", async () => {
  const res = await http.post(
    `/transaction/free-trial/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  if (res.data.status === "success") {
    return res.data.data;
  }
});

// Create Razorpay order
export const createRazorpayOrder = createAsyncThunk("user/createRazorpayOrder", async (data) => {
  const res = await http.post(`/transaction/create-razorpay-order/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (res.data.status === "success") {
    return res.data;
  }
});

// // Verify Razorpay payment
export const verifyRazorpayPayment = createAsyncThunk("user/verifyRazorpayPayment", async (data) => {
  const res = await http.post(`/transaction/verify-razorpay-payment/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    localStorage.removeItem("razorpay_payment_details");
    return res.data;
  }
});

//Check Promo Code
export const checkPromoCode = createAsyncThunk("user/checkPromoCode", async (data) => {
  const res = await http.post("/transaction/check-promo-code/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//Fetch purchase subscription plan
export const fetchPurchasedSubscriptionPlans = createAsyncThunk(
  "user/fetchPurchasedSubscriptionPlans",
  async (data, extra) => {
    const res = await http.get("/transaction/purchased-subscription-plans/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    id: 0,
    business_number: "4530944",
    business_name: "Demo name",
    gst_number: "09AAACH7409R1ZZ",
    zipcode: "452010",
    city: "indore",
    state: "mp",
    address1: "indore",
    address2: "vijay nagar",
    email: "ShreeJi@gmail.com",
    is_active: true,
    created_at: "2024-03-28T14:19:44.745385",
    updated_at: "2024-03-28T14:19:44.745412",
    user_profile: 5,
    business_type: 1,
    other_business_type: 8,
    industry: 1,
    other_industry_type: 8,
    business_type_name: "test_business_test",
    industry_type_name: "test_industry",
    profile_picture: null,
    subscriptionPlans: [],
    subscription_expiry: null,
    activePlans: [],
  },
  reducers: {
    setBusinessDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateData: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setProfileImage: (state, action) => {
      localStorage.setItem("profile_image", `${action.payload}`);
      return { ...state, profile_picture: action.payload };
    },
    localStorgeProfileCache: (state, action) => {
      const Image_url = localStorage.getItem("profile_image");
      return { ...state, profile_picture: Image_url ?? null };
    },
    //TODO - remove if no dependency
    // updateBusinessDetails: (state, action) => {
    //   return { ...state, ...action.payload };
    // },
    removeBusinessDetails: (state, action) => {
      state = {}; // initial value here
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userDelete.fulfilled, (state, action) => {
      clearSession();
      window.location.reload();
    });
    builder.addCase(uploadUserProfileImage.fulfilled, (state, action) => {
      localStorage.setItem("profile_image", action.payload?.image_url);
      return { ...state, profile_picture: action.payload?.image_url };
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(fetchSubscriptionPlans.fulfilled, (state, action) => {
      state.subscriptionPlans = action.payload;
    });
    builder.addCase(activateFreeTrial.fulfilled, (state, action) => {
      state.subscription_expiry = action.payload.subscription_expiry;
    });
    builder.addCase(fetchPurchasedSubscriptionPlans.fulfilled, (state, action) => {
      state.activePlans = action.payload;
    });
    // builder.addCase(createRazorpayOrder.fulfilled, (state, action) => {
    //   state.razorpayPaymentDetails = action.payload.payment;
    //   state.transactionDetails = action.payload.transaction;
    // });
    // builder.addCase(verifyRazorpayPayment.fulfilled, (state, action) => {
    //   // Clear payment details from local storage after successful verification
    //   localStorage.removeItem("razorpay_payment_details");
    // });
  },
});

export default userSlice.reducer;
export const { setBusinessDetails, setProfileImage, updateData, removeBusinessDetails, localStorgeProfileCache } =
  userSlice.actions;
// export const { updateBusinessDetails } = userSlice.actions;
