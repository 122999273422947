import React from "react";
import { useDispatch } from "react-redux";

import { deleteProduct } from "../../../reducers/Slices/productSlice";

// UI components
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import toast from "react-hot-toast";

function RemoveProduct({ product }) {
  const dispatch = useDispatch();

  const handleDeleteProduct = (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      dispatch(deleteProduct({ id }))
        .then((action) => {
          if (action.payload) {
          } else {
            toast.error("Sorry, delete not successful!");
          }
        })
        .catch(() => {
          toast.error("some error occured!");
        });
    }
  };

  return (
    <ButtonWrapper eventCallback={() => handleDeleteProduct(product?.id)} themeColor="red">
      Delete
    </ButtonWrapper>
  );
}

export default RemoveProduct;
