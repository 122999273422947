import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

// Fetch invoices - customer and vendor - deprecated
// export const fetchInvoices = createAsyncThunk("invoice/fetchInvoices", async (data, extra) => {
//   const res = await http.get(`/invoice/api/invoice/listcreate${data}`, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

// Fetch more invoices for react infinity scroll - deprecated
// export const fetchMoreInvoices = createAsyncThunk("invoice/fetchMoreInvoices", async (url, extra) => {
//   const res = await http.get(url, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

// Fetch invoice for customers or with customer ID
export const fetchInvoicesCustomer = createAsyncThunk("invoice/fetchInvoicesCustomer", async (data, extra) => {
  const res = await http.get(`/invoice/api/invoice/customer/list${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// Fetch more invoices for customers or with customer ID
export const fetchMoreInvoicesCustomer = createAsyncThunk("invoice/fetchMoreInvoicesCustomer", async (url, extra) => {
  const res = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const createInvoice = createAsyncThunk("invoice/createInvoice", async (data, extra) => {
  const res = await http.post("/invoice/api/order/create/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.invoice;
  }
});

export const createEInvoice = createAsyncThunk("invoice/createEInvoice", async (data, extra) => {
  console.log(data, "this is the data here");
  const res = await http.post("/invoice/api/order/create/", data.formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      username: data.authData.username,
      password: data.authData.password,
      // ip_address: data.authData.ip_address,
    },
  });
  if (res.data.status === "success") {
    return res.data.invoice;
  } else {
    return { message: res.data.message };
  }
});

//bulk create e-invoice
export const bulkCreateEInvoice = createAsyncThunk("invoice/bulkCreateEInvoice", async (data, extra) => {
  const res = await http.post(
    "/invoice/api/einvoice/bulk/generate/",
    { invoices: data.invoices },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        username: data.authData.username,
        password: data.authData.password,
        // ip_address: data.authData.ip_address,
      },
    }
  );
  if (res.data.status === "success") {
    return res.data;
  } else {
    return null;
  }
});

export const updateInvoice = createAsyncThunk("invoice/updateInvoice", async (data, extra) => {
  const res = await http.put(`/invoice/api/order/create/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.invoice;
  } else if (res.data.status === "error") {
    throw new Error(res.data.message);
  }
});

//Fetch invoice by product ID
export const fetchInvoiceByProductId = createAsyncThunk("invoice/fetchInvoiceByProductId", async (data, extra) => {
  const res = await http.get(`/invoice/api/invoice/customer/list?product_id=${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch more invoices by Product ID - react infinity scroll
export const fetchMoreInvoiceByProductId = createAsyncThunk(
  "invoice/fetchMoreInvoiceByProductId",
  async (url, extra) => {
    const res = await http.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

export const fetchSalesCompareAnalytics = createAsyncThunk(
  "invoice/fetchSalesCompareAnalytics",
  async (data, extra) => {
    const res = await http.get(`/invoice/api/invoice/monthly-sales-analytics?customerId=${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

//Invoice analytics for invoice section line chart
export const fetchInvoiceMonthWiseSalesAnalytics = createAsyncThunk(
  "invoice/fetchInvoiceMonthWiseSalesAnalytics",
  async (data, extra) => {
    const res = await http.get(
      `/invoice/api/invoice/invoice_month_wise-sales-analytics${data ? `?customerId=${data.id}` : ""}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

export const getInvoiceDetailsById = createAsyncThunk("invoice/getDetailsById", async (data, extra) => {
  const res = await http.get(`/invoice/api/invoice/${data.id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//More detailed version of above with full product and batch objects - no addcase handler
export const getCompleteInvoiceDetailsById = createAsyncThunk(
  "invoice/getCompleteInvoiceDetailsById",
  async (data, extra) => {
    const res = await http.get(`/invoice/api/invoice/${data}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

//cancel invoice
export const cancelInvoice = createAsyncThunk("invoice/cancelInvoice", async (data, extra) => {
  const res = await http.delete(`/invoice/api/order/create/${data.id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return data;
  }
});

//Estimate invoice - Quotation generator
export const estimateInvoiceGenerator = createAsyncThunk("invoice/estimateInvoice", async (data, extra) => {
  const res = await http.post(`/request_invoices/api/estimate/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Credit note operations
//Retrive credit notes
export const fetchCreditNotes = createAsyncThunk("invoice/fetchCreditNotes", async (data, extra) => {
  const res = await http.get(`/credit_note${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch more creditNotes for react infinity scroll
export const fetchMoreCreditNotes = createAsyncThunk("invoice/fetchMoreCreditNotes", async (url, extra) => {
  const res = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const createCreditNote = createAsyncThunk("invoice/createCreditNote", async (data, extra) => {
  const res = await http.post("/credit_note/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//Update Credit Note
export const updateCreditNote = createAsyncThunk("invoice/updateCreditNote", async (data, extra) => {
  const res = await http.put(`/credit_note/${data.id}/`, data.data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//Export bulk E-Invoice JSON data
export const exportEInvoiceJSON = createAsyncThunk("invoice/exportEInvoiceJSON", async (data, extra) => {
  const res = await http.get(`/invoice/api/einvoice/bulk/export-json${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  //JSON downloadable File
  return res;
});

//Import Bulk Verified E-Invoice JSON data
export const importEInvoiceJSON = createAsyncThunk("invoice/importEInvoiceJSON", async (data, extra) => {
  //TODO - handle xlss extension...
  const res = await http.put(`/invoice/api/einvoice/bulk/update-irn`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  //Array of success messages for verified invoices
  return res;
});

//Import Bulk Verified E-Invoice Excel
export const importEInvoiceExcel = createAsyncThunk("invoice/importEInvoiceExcel", async (file, extra) => {
  const formData = new FormData();
  formData.append("file", file); // Append the file to FormData

  // Replace this URL with your API endpoint for file uploads
  const res = await http.put("/invoice/api/einvoice/bulk/update-irn", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  return res;
});

//Retrive Credit note based on ID - currently not required but here
// export const getCreditNoteByID = createAsyncThunk("invoice/getCreditNoteByID", async (id, extra) => {
//   const res = await http.get(`/credit_note/${id}`, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    sales_analytics: {
      current_month_data: [],
      previous_month_data: [],
      x_labels: [],
    },
    month_sales_analytics: {},
    customer_invoices: { data: [], next: null },
    product_invoices: { data: [], next: null },
    invoiceDetails: {},
    estimateInvoiceFile: null,
    // invoices: {
    //   data: [],
    //   next: null,
    // },
    creditNotes: {
      data: [],
      next: null,
    },
  },
  reducers: {
    getInvoiceById: (state, action) => {
      return state.customer_invoices.find((invoice) => invoice.id === action.payload);
    },
    addInvoice: (state, action) => {
      return { ...state, customer_invoices: [...state.customer_invoices, action.payload] };
    },
  },
  extraReducers: (builder) => {
    //Deprecated
    // builder.addCase(fetchInvoices.fulfilled, (state, action) => {
    //   return { ...state, invoices: { data: action.payload.data, next: action.payload.next } };
    // });
    //Deprecated
    // builder.addCase(fetchMoreInvoices.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     invoices: {
    //       data: [...state.invoices.data, ...action.payload.data],
    //       next: action.payload.next,
    //     },
    //   };
    // });
    builder.addCase(fetchInvoicesCustomer.fulfilled, (state, action) => {
      return { ...state, customer_invoices: { data: action.payload.data, next: action.payload.next } };
    });
    builder.addCase(fetchMoreInvoicesCustomer.fulfilled, (state, action) => {
      return {
        ...state,
        customer_invoices: {
          data: [...state.customer_invoices.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    // builder.addCase(fetchInvoicesVendor.fulfilled, (state, action) => {
    //   return { ...state, vendor_invoices: { data: action.payload.data, next: action.payload.next } };
    // });
    // builder.addCase(fetchMoreInvoicesVendor.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     vendor_invoices: {
    //       data: [...state.invoices.data, ...action.payload.data],
    //       next: action.payload.next,
    //     },
    //   };
    // });
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      return { ...state, customer_invoices: { data: [action.payload, ...state.customer_invoices.data] } };
    });
    builder.addCase(createEInvoice.fulfilled, (state, action) => {
      return { ...state, customer_invoices: { data: [action.payload, ...state.customer_invoices.data] } };
    });
    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      const updatedInvoice = action.payload;
      const updatedData = state.customer_invoices.data.map((invoice) => {
        if (invoice.id === updatedInvoice.id) {
          return updatedInvoice;
        }
        return invoice;
      });
      return { ...state, customer_invoices: { ...state.customer_invoices, data: updatedData } };
    });
    // builder.addCase(fetchInvoiceByCustomerId.fulfilled, (state, action) => {
    //   return { ...state, customer_invoices: action.payload };
    // });
    // builder.addCase(fetchInvoiceByVendorId.fulfilled, (state, action) => {
    //   return { ...state, vendor_invoices: action.payload };
    // });
    builder.addCase(fetchInvoiceByProductId.fulfilled, (state, action) => {
      return { ...state, product_invoices: { data: action.payload.data, next: action.payload.next } };
    });
    builder.addCase(fetchMoreInvoiceByProductId.fulfilled, (state, action) => {
      return {
        ...state,
        product_invoices: { data: [...state.product_invoices.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(fetchSalesCompareAnalytics.fulfilled, (state, action) => {
      return { ...state, sales_analytics: action.payload };
    });
    builder.addCase(fetchInvoiceMonthWiseSalesAnalytics.fulfilled, (state, action) => {
      return { ...state, month_sales_analytics: action.payload };
    });
    builder.addCase(getInvoiceDetailsById.fulfilled, (state, action) => {
      return { ...state, invoiceDetails: action.payload };
    });
    // builder.addCase(getVendorInvoices.fulfilled, (state, action) => {
    //   return { ...state, invoices: { data: action.payload.data, next: action.payload.next } };
    // });
    // builder.addCase(getCustomerInvoices.fulfilled, (state, action) => {
    //   return { ...state, invoices: { data: action.payload.data, next: action.payload.next } };
    // });
    builder.addCase(cancelInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        customer_invoices: {
          ...state.customer_invoices,
          data: state.customer_invoices.data.filter((invoice) => invoice.id !== action.payload.id),
        },
      };
    });
    builder.addCase(estimateInvoiceGenerator.fulfilled, (state, action) => {
      return {
        ...state,
        estimateInvoiceFile: action.payload.file_url,
      };
    });
    builder.addCase(createCreditNote.fulfilled, (state, action) => {
      return { ...state, creditNotes: { data: [action.payload, ...state.creditNotes.data] } };
    });
    builder.addCase(fetchCreditNotes.fulfilled, (state, action) => {
      return { ...state, creditNotes: { data: action.payload.data, next: action.payload.next } };
    });
    builder.addCase(fetchMoreCreditNotes.fulfilled, (state, action) => {
      return {
        ...state,
        creditNotes: {
          data: [...state.creditNotes.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(updateCreditNote.fulfilled, (state, action) => {
      const updatedCreditNote = action.payload;
      const updatedData = state.creditNotes.data.map((creditNote) => {
        if (creditNote.id === updatedCreditNote.id) {
          return updatedCreditNote;
        }
        return creditNote;
      });
      return { ...state, creditNotes: { ...state.creditNotes, data: updatedData } };
    });
  },
});

export default invoiceSlice.reducer;
export const { getInvoiceById, addInvoice } = invoiceSlice.actions;
