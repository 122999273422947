import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { createCashLedger } from "../../../reducers/Slices/ledgerSlice";
// import { fetchSalesCompareAnalytics } from "../../../reducers/Slices/invoiceSlice";
import { purchaseMonthlyAnalyticsData } from "../../../reducers/Slices/purchaseInvoiceSlice";
import toast from "react-hot-toast";

function LogPaymentVendor({ vendor }) {
  const dispatch = useDispatch();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  // Get today's date for default value of date
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [formData, setFormData] = useState({
    payment_type: "cash",
    amount: 0,
    vendor: vendor?.id,
    note: "",
    date: getTodayDate(),
  });

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitButton(true);
    dispatch(createCashLedger(formData))
      .then((action) => {
        if (action.payload) {
          dispatch(purchaseMonthlyAnalyticsData(`?vendorId=${vendor.id}`));
          setShowModal(false);
          setDisableSubmitButton(false);
        } else {
          toast.error("Payment logging failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const handleClose = () => {
    setFormData({
      payment_type: "cash",
      amount: 0,
      vendor: vendor?.id,
      note: "",
      date: getTodayDate(),
    });
    setShowModal(!showModal);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto" onSubmit={handleSubmit}>
              <div className="mb-5">
                <label htmlFor="payment_type" className="block mb-2 text-sm font-medium text-gray-900">
                  Payment Type<span className="pl-1 text-red-500">*</span>
                </label>
                <select
                  id="payment_type"
                  value={formData.payment_type}
                  onChange={(e) => setFormData({ ...formData, payment_type: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                >
                  <option value="upi">UPI</option>
                  <option value="cash">Cash</option>
                  <option value="bank_transfer">RTGS / NEFT / IMPS</option>
                  <option value="check">Check</option>
                </select>
              </div>
              <div className="mb-5">
                <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">
                  Amount<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="amount"
                  value={formData.amount}
                  onChange={(e) => setFormData({ ...formData, amount: parseFloat(e.target.value) })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter amount"
                  required
                />
              </div>
              <div className="mb-5">
                <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900">
                  Note
                </label>
                <textarea
                  id="note"
                  rows="4"
                  value={formData.note}
                  onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                  className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter note"
                ></textarea>
              </div>
              <div className="mb-5">
                <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">
                  Date<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="date"
                  value={formData.date}
                  onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div className="w-full flex items-center justify-center py-4">
                <button
                  type="submit"
                  disabled={disableSubmitButton}
                  className="bg-black text-white rounded-full px-5 py-2.5"
                >
                  Submit Payment
                </button>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <button
        id="joyride-step-5"
        className="bg-black px-5 py-2.5 text-white rounded-full font-semibold"
        onClick={() => setShowModal(!showModal)}
      >
        Log Payment
      </button>
    </>
  );
}

export default LogPaymentVendor;
