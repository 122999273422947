import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { changeDateFormat, humanDateFormat } from "../../../utils/dateFormat";
import IconWrapper from "../../MicroComponents/IconWrapper";
import { Search } from "react-feather";
import DateRangeSelector from "../../OnlineOrders/OnlineOrderComponents/DateRangeSelector";
import { subDays } from "date-fns";

//Functional Components
import AddBreakage from "./BreakageComponents/AddBreakage";
import EditBreakage from "./BreakageComponents/EditBreakage";
import RemoveBreakage from "./BreakageComponents/RemoveBreakage";
import { fetchBreakageLogs, fetchMoreBreakageLogs } from "../../../reducers/Slices/expiryBreakageSlice";
import toast from "react-hot-toast";
import { fetchBatchesByProductID } from "../../../reducers/Slices/productSlice";

// const breakageEntries = [
//   {
//     id: 1,
//     product_name: "Product A", // Different from name
//     name: "Product A Details", // This can be used for more specific details
//     vendor: "Vendor X",
//     batch_number: "BATCH001",
//     quantity: 10,
//     date: "2024-09-01",
//     note: "Damaged during transportation.",
//     total_purchase_price: 500, // Example total purchase price
//     total_sales_price: 700, // Example total sales price
//   },
//   {
//     id: 2,
//     product_name: "Product B", // Different from name
//     name: "Product B Details", // This can be used for more specific details
//     vendor: "Vendor Y",
//     batch_number: "BATCH002",
//     quantity: 5,
//     date: "2024-09-05",
//     note: "Expired on shelf.",
//     total_purchase_price: 250, // Example total purchase price
//     total_sales_price: 350, // Example total sales price
//   },
//   {
//     id: 3,
//     product_name: "Product C", // Different from name
//     name: "Product C Details", // This can be used for more specific details
//     vendor: "Vendor Z",
//     batch_number: "BATCH003",
//     quantity: 2,
//     date: "2024-09-10",
//     note: "Broken packaging.",
//     total_purchase_price: 100, // Example total purchase price
//     total_sales_price: 150, // Example total sales price
//   },
//   {
//     id: 4,
//     product_name: "Product D", // Different from name
//     name: "Product D Details", // This can be used for more specific details
//     vendor: "Vendor X",
//     batch_number: "BATCH004",
//     quantity: 8,
//     date: "2024-09-15",
//     note: "Incorrect item received.",
//     total_purchase_price: 400, // Example total purchase price
//     total_sales_price: 600, // Example total sales price
//   },
//   {
//     id: 5,
//     product_name: "Product E", // Different from name
//     name: "Product E Details", // This can be used for more specific details
//     vendor: "Vendor Y",
//     batch_number: "BATCH005",
//     quantity: 12,
//     date: "2024-09-20",
//     note: "Product damaged by water.",
//     total_purchase_price: 600, // Example total purchase price
//     total_sales_price: 800, // Example total sales price
//   },
// ];

const Breakage = () => {
  const dispatch = useDispatch();

  const breakageEntries = useSelector((state) => state.expiryBreakage.breakageLogs.data);
  const breakageEntriesNext = useSelector((state) => state.expiryBreakage.breakageLogs.next);

  const [hasMoreBreakageEntries, setHasMoreBreakageEntries] = useState(() => (breakageEntriesNext ? true : false));

  // Days filter
  const [daysFilter, setDaysFilter] = useState("last_7_days");

  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // From date and to date
  const [fromDate, setFromDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - 7);
    return changeDateFormat(pastDate);
  });

  const [toDate, setToDate] = useState(() => {
    return changeDateFormat(new Date());
  });

  // Effect for setting date range
  useEffect(() => {
    setFromDate(changeDateFormat(dateRange[0].startDate));
    setToDate(changeDateFormat(dateRange[0].endDate));
  }, [dateRange]);

  // Effect to handle filter selection
  useEffect(() => {
    const today = new Date();
    const pastDate = new Date();

    if (daysFilter === "custom") {
      return;
    } else if (daysFilter === "last_7_days") {
      pastDate.setDate(today.getDate() - 7);
    } else if (daysFilter === "last_30_days") {
      pastDate.setDate(today.getDate() - 30);
    }

    setFromDate(changeDateFormat(pastDate));
    setToDate(changeDateFormat(today));
  }, [daysFilter]);

  // Effect to check if more entries exist
  useEffect(() => {
    if (breakageEntriesNext) {
      setHasMoreBreakageEntries(true);
    } else {
      setHasMoreBreakageEntries(false);
    }
  }, [breakageEntriesNext]);

  // Fetch more entries on scroll
  const fetchNextBreakageEntries = () => {
    if (breakageEntriesNext) {
      fetchMoreBreakageLogs(breakageEntriesNext);
    }
  };

  // Initial data fetch
  useEffect(() => {
    dispatch(
      fetchBreakageLogs(
        `?data_from=${changeDateFormat(dateRange[0]?.startDate)}&date_to=${changeDateFormat(dateRange[0]?.endDate)}`
      )
    );
    // dispatch(fetchBreakageEntries());
    // dispatch(getBreakageAnalytics());
  }, [dispatch, dateRange]);

  const [selectedBreakageEntry, setSelectedBreakageEntry] = useState({});
  const [searchBreakage, setSearchBreakage] = useState("");

  // Debounce search to throttle API calls
  const debouncedBreakageSearch = debounce((value) => setSearchBreakage(value), 1000);

  // Effect to handle search input and date filters
  useEffect(() => {
    if (searchBreakage.length > 0) {
      dispatch(
        fetchBreakageLogs(
          `?data_from=${changeDateFormat(dateRange[0]?.startDate)}&date_to=${changeDateFormat(
            dateRange[0]?.endDate
          )}&search=${searchBreakage}`
        )
      );
    } else {
      dispatch(
        fetchBreakageLogs(
          `?data_from=${changeDateFormat(dateRange[0]?.startDate)}&date_to=${changeDateFormat(
            dateRange[0]?.endDate
          )}&search=${searchBreakage}`
        )
      );
    }
  }, [searchBreakage, dispatch, fromDate, toDate]);

  // Set default selected breakage entry
  useEffect(() => {
    if (breakageEntries?.length > 0) {
      setSelectedBreakageEntry(breakageEntries[0]);
    }
  }, [breakageEntries]);

  return (
    <>
      <div className="flex">
        {/* Breakage entries section */}
        <div className="flex-1 max-w-[30%] border-r-2 border-neutral-200 h-[82.3vh]">
          <div className="flex gap-[10px] border-b-2 border-neutral-200 p-[15px]">
            {/* Search Bar */}
            <div className="flex w-full">
              <input
                type="text"
                className="h-[44px] w-full rounded-full border-2 border-neutral-200 text-neutral-500 px-[15px]"
                placeholder="Search Breakage..."
                onChange={(e) => debouncedBreakageSearch(e.target.value)}
              />
              <button className="-ml-[40px]">
                <IconWrapper>
                  <Search className="z-10 text-white"></Search>
                </IconWrapper>
              </button>
            </div>

            {/* Add Breakage button */}
            <AddBreakage />
          </div>

          <div className="bg-white">
            {/* Days Filter */}
            <div className="flex px-[10px] pt-[20px] pb-[10px] justify-between">
              <label htmlFor="days_filter" className="sr-only">
                Select Date
              </label>
              <select
                name="days_filter"
                id="days_filter"
                value={daysFilter}
                onChange={(e) => setDaysFilter(e.target.value)}
                className="px-[20px] py-[10px] bg-white border-[1px] border-black rounded-full max-w-fit"
              >
                <option value="last_7_days">Last 7 Days</option>
                <option value="last_30_days">Last 30 Days</option>
                <option value="custom">Custom</option>
              </select>

              {daysFilter === "custom" && <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />}
            </div>

            {/* Breakage entries list */}
            {breakageEntries?.length === 0 && <div className="text-center py-10 font-semibold">No Entries found!</div>}
            {breakageEntries?.length > 0 && (
              <div className="overflow-y-scroll">
                <InfiniteScroll
                  dataLength={breakageEntries.length}
                  next={fetchNextBreakageEntries}
                  hasMore={hasMoreBreakageEntries}
                  height={"65vh"}
                  loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center", paddingTop: "10px" }}>
                      <b>Breakage List End</b>
                    </p>
                  }
                >
                  <ul className="flex flex-col p-[15px] gap-[10px]">
                    {breakageEntries.map((breakageEntry, index) => (
                      <li
                        key={index}
                        className={`w-full flex justify-between p-[12px] border-2 ${
                          selectedBreakageEntry?.id === breakageEntry?.id ? "border-black" : "border-neutral-200 "
                        } rounded-full cursor-pointer`}
                        onClick={() => setSelectedBreakageEntry(breakageEntry)}
                      >
                        <span>
                          <span>{index + 1}.</span>
                          <span className="ml-[5px]">{`${breakageEntry?.product?.product_name} - ${breakageEntry?.batch?.batch_number}`}</span>
                        </span>
                        <span>Purchased for ₹{breakageEntry?.batch?.purchase_price * breakageEntry?.quantity}</span>
                      </li>
                    ))}
                  </ul>
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>

        {/* Breakage Details */}
        <div className="flex-1">
          <div className="flex-1 px-[40px] py-[20px] border-b-2 border-neutral-200 bg-white">
            <h1 className="text-2xl mt-[10px]">Breakage Estimates</h1>
            <div className="flex gap-[10px] mt-[20px] text-xl">
              <h3>Total Purchase Price value: </h3>
              <span className="text-neutral-500">
                ₹{selectedBreakageEntry?.batch?.purchase_price * selectedBreakageEntry?.quantity}
              </span>
            </div>
            <div className="flex gap-[10px] mt-[10px] text-xl">
              <h3>Total Sales Price Value: </h3>
              <span className="text-neutral-500">
                ₹{selectedBreakageEntry?.batch?.sales_price * selectedBreakageEntry?.quantity}
              </span>
            </div>
          </div>

          <div className="flex flex-col p-[30px]">
            <div className="flex justify-between">
              <h2 className="text-2xl text-black font-semibold">Breakage Details</h2>
              <div className="flex gap-[10px]">
                <EditBreakage breakageEntry={selectedBreakageEntry} />
                <RemoveBreakage breakageEntry={selectedBreakageEntry} />
              </div>
            </div>

            {/* Selected breakage entry details */}
            {selectedBreakageEntry && (
              <div className="flex-1 py-[20px] bg-white" id="joyride-step-5">
                <div>
                  <h3 className="text-lg">Product Name</h3>
                  <span className="text-neutral-500">{selectedBreakageEntry?.product?.product_name}</span>
                </div>
                <div>
                  <h3 className="mt-[10px] text-lg">Vendor</h3>
                  <span className="text-neutral-500">{selectedBreakageEntry?.vendor?.vendor_name}</span>
                </div>
                <div className="mt-[10px]">
                  <h3 className="text-lg">Batch Number</h3>
                  <span className="text-neutral-500">{selectedBreakageEntry?.batch?.batch_number}</span>
                </div>
                <div className="mt-[10px]">
                  <h3 className="text-lg">Quantity</h3>
                  <span className="text-neutral-500">{selectedBreakageEntry?.quantity}</span>
                </div>
                <div className="mt-[10px]">
                  <h3 className="text-lg">Date</h3>
                  <span className="text-neutral-500">{humanDateFormat(selectedBreakageEntry?.date)}</span>
                </div>
                <div className="mt-[10px]">
                  <h3 className="text-lg">Note</h3>
                  <div>
                    <p className="text-body text-neutral-500 max-w-[500px]">{selectedBreakageEntry?.note}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Breakage;
