import React from "react";

const TallyERP = ({ smallScreen }) => {
  const steps = [
    {
      title: "Create a Folder to Save Your Files",
      description:
        "Start by creating a new folder on your computer to save all the exported Excel files from Tally ERP. This will help you keep everything organized in one place.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Open Tally ERP and Access the Reports",
      description:
        "Open Tally ERP, and navigate to the section where you want to export reports. You can export reports like Sales, Inventory, and Financial Statements.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Balance Sheet Report",
      description:
        "Go to the Balance Sheet section to export the Balance Sheet report. This is essential for tracking assets, liabilities, and equity.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Profit & Loss Account Report",
      description:
        "Navigate to the Profit & Loss Account section to export a report on revenues and expenses for a given period.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Stock Summary Report",
      description: "Access the Stock Summary report to analyze stock movement and inventory valuation.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Sales Register",
      description: "Navigate to the Sales Register to export records of all sales transactions.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Purchase Register",
      description: "Go to the Purchase Register to export records of all purchase transactions.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Export Option for Each Report",
      description:
        "Once you're on the report page, press Alt + E to bring up the export options. Select 'Excel' as the export format.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Set Export Details for Each Report",
      description:
        "Choose the path where you want to save the Excel file, select the folder created in Step 1, confirm the file name and format, and proceed.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Confirm Export and Check Your Folder",
      description:
        "Click 'Yes' or confirm to export the file. Go to the folder you created to check that the report was saved as an Excel file.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Repeat for Other Reports if any",
      description:
        "If you need to export more reports, repeat the export process for each one. Save all reports in the same folder for easy access.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Upload Reports to TheProfit.AI ERP",
      description: "After exporting, proceed to upload these reports to TheProfit.AI ERP for further processing.",
      imgSrc: null,
    },
  ];

  return (
    <div className="mx-auto p-4">
      {/* {steps.map((step, index) => (
        <div key={index} className="mb-10 flex flex-col items-center">
          <h2 className="text-2xl font-semibold mb-2">{`Step ${index + 1}: ${step.title}`}</h2>
          <p className="text-gray-700 mb-4 max-w-[600px] text-center">{step.description}</p>
          {step?.imgSrc && (
            <img
              src={step.imgSrc}
              alt={step.title}
              className={`w-full rounded-lg shadow-md ${smallScreen ? "max-w-[40vw]" : "max-w-[50vw]"}`}
            />
          )}
        </div>
      ))}

      <footer className="text-center text-sm text-gray-500 mt-12">
        By following these steps, you can easily export all necessary reports from Tally ERP for further processing in
        our software.
      </footer> */}
      <div>Currently we are working on adding support for Tally ERP data migration.</div>
    </div>
  );
};

export default TallyERP;
