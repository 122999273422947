import React from "react";
import "./LoadScreen.scss";

const LoadScreen = () => {
  return (
    <div className="main-loader">
      <div className="custom-loader"></div>
    </div>
  );
};

export default LoadScreen;
