import React from "react";
import { useDispatch } from "react-redux";
import { deleteBatch, fetchProducts } from "../../../reducers/Slices/productSlice";
import toast from "react-hot-toast";

function RemoveBatch({ product, batch }) {
  const dispatch = useDispatch();

  function handleDeleteBatchClick() {
    const { id: product_id } = product;
    const { id: batch_id } = batch;

    if (window.confirm("Are you sure you want to delete this batch?")) {
      dispatch(deleteBatch({ batch_id, product_id }))
        .then((action) => {
          console.log(action.payload, "this is the delete action here");
          if (action.payload) {
            dispatch(fetchProducts());
          } else {
            toast.error("Sorry, batch cannot be deleted");
          }
        })
        .catch(() => {
          toast.error("Error occurred while deleting batch");
        });
    }
  }

  return (
    <div>
      <button className="text-red-500 font-bold" onClick={handleDeleteBatchClick}>
        Delete
      </button>
    </div>
  );
}

export default RemoveBatch;
