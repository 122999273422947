import React, { useEffect, useState } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { createWarehouse } from "../../../reducers/Slices/warehouseSlice";
import { searchZipcode } from "../../../reducers/Slices/userSlice";

function AddWarehouse() {
  const dispatch = useDispatch();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    address1: "",
    address2: "",
    zipcode: "",
    state: "",
    city: "",
    contact: "",
  });
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    handleAddWarehouse();
  };

  const handleClose = () => {
    resetInitialValue();
    setShowModal(!showModal);
  };

  const handleSearchZipcode = (e) => {
    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            state: action.payload.state,
            city: action.payload.city,
            address: action.payload.address,
            state_code:
              `${action.payload.state_code}`.length > 1 ? action.payload.state_code : `0${action.payload.state_code}`,
            country_code: action.payload.country_code,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleAddWarehouse = () => {
    setDisableSubmitButton(true);
    dispatch(createWarehouse(formData))
      .then((action) => {
        if (action.payload) {
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("Warehouse entry creation failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err.message || "An error occurred");
        setDisableSubmitButton(false);
      });
  };

  const resetInitialValue = () => {
    const initialValues = {
      name: "",
      address1: "",
      address2: "",
      zipcode: "",
      state: "",
      city: "",
      contact: "",
    };
    setFormData(initialValues);
  };

  // Reset Value on new form creation
  useEffect(() => {
    resetInitialValue();
  }, [showModal]);

  const handleAddWarehouseClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                <div className="mb-5">
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                    Warehouse Name<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    value={formData.name}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter warehouse name"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="address1" className="block mb-2 text-sm font-medium text-gray-900">
                    Address 1<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="address1"
                    onChange={(e) => setFormData({ ...formData, address1: e.target.value })}
                    value={formData.address1}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter address line 1"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="address2" className="block mb-2 text-sm font-medium text-gray-900">
                    Address 2
                  </label>
                  <input
                    type="text"
                    id="address2"
                    onChange={(e) => setFormData({ ...formData, address2: e.target.value })}
                    value={formData.address2}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter address line 2 (optional)"
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="address2" className="block mb-2 text-sm font-medium text-gray-900">
                    Manager Contact No.<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={10}
                    id="address2"
                    onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
                    value={formData.contact}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter Phone Number"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Zipcode<span className="pl-1 text-red-500">*</span>
                  </label>
                  <div className="relative z-0 flex gap-[10px] mb-5 group">
                    <input
                      type="text"
                      id="zipcode"
                      onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                      value={formData.zipcode}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Enter zipcode"
                      required
                    />
                    <button
                      type="button"
                      onClick={handleSearchZipcode}
                      className="bg-black text-white px-[20px] py-[10px] rounded-lg"
                    >
                      Find
                    </button>
                  </div>
                </div>

                <div className="mb-5">
                  <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900">
                    State<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="state"
                    onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                    value={formData.state}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter state"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900">
                    City<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                    value={formData.city}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter city"
                    required
                  />
                </div>
                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit Warehouse
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <ButtonWrapper eventCallback={handleAddWarehouseClick}>Add Warehouse</ButtonWrapper>
    </>
  );
}

export default AddWarehouse;
