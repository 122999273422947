import React from "react";
import { useNavigate } from "react-router-dom";

function FORM_3_1_1() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center  h-full overflow-y-scroll pt-[5vh]">
      <h1 className="font-bold text-2xl py-[20px] max-w-[60vw] text-center">
        3.1.1 Details of supplies notified under section 9(5) of the CGST Act, 2017 and corresponding provision in
        IGST/UTGST/SGST Acts
      </h1>
      <p className="text-neutral-500 py-[20px]">
        Note: Table 3.1.1 (i) and (ii) are auto-drafted based on values provided in GSTR-1/1A.
      </p>

      <table>
        <thead>
          <tr className="bg-gray-50">
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Total Taxable value (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Integrated Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Central Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              State/UT Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              CESS (₹)
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black bg-white border-[1px] border-neutral-200">
              (i) Taxable supplies on which electronic commerce operator pays tax u/s 9(5) [to be furnished by
              electronic commerce operator]
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black bg-white border-[1px] border-neutral-200">
              (ii) Taxable supplies made by registered person through electronic commerce operator, on which electronic
              commerce operator is required to pay tax u/s 9(5) [to be furnished by registered person making supplies
              through electronic commerce operator]
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
          </tr>
        </tbody>
      </table>

      <div className="flex gap-[10px] py-[30px]">
        <button className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full">Cancel</button>
        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Confirm
        </button>
        <button
          className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default FORM_3_1_1;
