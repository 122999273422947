import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
const AttendanceHeatmap = ({ attendanceData }) => {
  const attendanceMap = attendanceData.reduce((acc, day) => {
    acc[new Date(day.date).toDateString()] = day.present;
    return acc;
  }, {});

  const tileContent = ({ date }) => {
    const present = attendanceMap[date.toDateString()];

    const day = date.getDate();
    return (
      <div className="relative flex justify-center items-center">
        {/* Green Dot for Present */}
        {present && (
          <div className="bg-green-400 rounded-full w-6 h-6 absolute top-1/2 transform -translate-y-6 flex items-center justify-center">
            {day}
          </div>
        )}
        {/* Red Dot for Absent */}
        {present === false && (
          <div className="bg-red-400 rounded-full w-6 h-6 absolute top-1/2 transform -translate-y-6 flex items-center justify-center">
            {day}
          </div>
        )}
      </div>
    );
  };

  const tileClassName = ({ date }) => {
    const today = new Date();
    const isToday = date.toDateString() === today.toDateString();

    return isToday ? "!bg-white rounded-full z-20" : "";
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Calendar
        tileContent={tileContent}
        tileClassName={tileClassName}
        value={null}
        onClickDay={() => {}}
        onActiveDateChange={() => {}}
      />
    </div>
  );
};

export default AttendanceHeatmap;
