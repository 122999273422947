import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowRight, ArrowLeft } from "react-feather";
import InitialWelcome from "./OnboardingComponents/InitialWelcome";
import MigrationSoftwareSelection from "./OnboardingComponents/MigrationSoftwareSelection";
import toast from "react-hot-toast";
import SelectedSoftwareSteps from "./OnboardingComponents/SelectedSoftwareSteps";
import FileUploadOnboarding from "./OnboardingComponents/FileUploadOnboarding";

function OtherSoftwareOnboarding() {
  const navigate = useNavigate();

  const handleSkipButtonClick = () => {
    navigate("/");
  };

  const [currentStep, setCurrentStep] = useState(1);

  //Software selected in step 2
  const [migrationSoftware, setMigrationSoftware] = useState(null);

  //handle step change
  const handleStepChange = (operation) => {
    if (operation === "next") {
      setCurrentStep((prevStep) => {
        if (prevStep >= 2 && migrationSoftware === null) {
          toast.error("First Select a Migration Software!");
          return 2;
        }

        if (
          prevStep >= 3 &&
          (migrationSoftware === "Tally" || migrationSoftware === "Zoho" || migrationSoftware === "Others")
        ) {
          return 1;
        }

        return prevStep + 1;
      });
    }

    if (operation === "back") {
      setCurrentStep((prevStep) => {
        return prevStep - 1;
      });
    }
  };

  //Finish Migration process
  const handleFinishMigration = () => {
    navigate("/");
  };

  //disable or enable finish step button
  const [disableFinishStepButton, setDisableFinishStepButton] = useState(true);

  return (
    <div className="h-[100vh] flex flex-col items-center justify-center">
      {/* Step 1 - Welcome Screen */}
      <div>{currentStep === 1 && <InitialWelcome />}</div>
      {/* step 2 - Migration Software Selection  */}
      <div>
        {currentStep === 2 && (
          <MigrationSoftwareSelection
            migrationSoftware={migrationSoftware}
            setMigrationSoftware={setMigrationSoftware}
          />
        )}
      </div>

      {/* step 3 - Migration Software Steps */}
      <div className="overflow-y-hidden max-h-[90vh]">
        {currentStep === 3 && <SelectedSoftwareSteps migrationSoftware={migrationSoftware} />}
      </div>

      {/* step 4 - File Upload */}
      <div>
        {currentStep === 4 && (
          <FileUploadOnboarding
            migrationSoftware={migrationSoftware}
            setDisableFinishStepButton={setDisableFinishStepButton}
          />
        )}
      </div>

      {/* Skip and Continue button on step 1  */}
      {currentStep === 1 && (
        <div className="w-full max-w-[600px] flex justify-between pt-[20px]">
          <button
            onClick={handleSkipButtonClick}
            className="flex items-center gap-[2px] py-[10px] rounded-full text-neutral-500 underline text-lg"
          >
            Skip For Now <ArrowRight />
          </button>
          <button
            className="bg-black text-white px-[20px] py-[10px] rounded-full font-semibold flex items-center text-lg"
            onClick={() => handleStepChange("next")}
          >
            Continue
          </button>
        </div>
      )}

      {/* Next and Back button on rest of the steps  */}
      {currentStep > 1 && currentStep < 4 && (
        <div className="w-full max-w-[600px] flex justify-center gap-[20px] pt-[20px]">
          <button
            className="bg-black text-white px-[20px] py-[10px] rounded-full font-semibold flex items-center gap-[2px] text-lg"
            onClick={() => handleStepChange("back")}
          >
            <ArrowLeft /> Back
          </button>
          <button
            className="bg-black text-white px-[20px] py-[10px] rounded-full font-semibold flex items-center text-lg"
            onClick={() => handleStepChange("next")}
          >
            Next <ArrowRight />
          </button>
        </div>
      )}

      {/* Finish Setup */}
      {currentStep === 4 && (
        <div className="w-full max-w-[600px] flex justify-center pt-[20px]">
          <button
            className={`${
              disableFinishStepButton ? "bg-stone-800" : "bg-black"
            } text-white px-[20px] py-[10px] rounded-full font-semibold flex items-center text-lg`}
            onClick={() => handleFinishMigration()}
            disabled={disableFinishStepButton}
          >
            Finish Migration
          </button>
        </div>
      )}
    </div>
  );
}

export default OtherSoftwareOnboarding;
