import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

const Inventory = ({ data }) => {
  const filteredData = data
    ?.filter((item) => item.value > 0 && item.product__brand !== null) // Filter out entries with value 0 and product__brand null.
    ?.sort((a, b) => b.value - a.value); // Sort data by value in descending order

  const topFiveData = filteredData?.slice(0, 4).map((item, index) => ({
    label: item?.product__brand?.length > 15 ? item?.product__brand?.substring(0, 10) + "..." : item?.product__brand,
    value: item.value,
    id: index,
  }));

  // Sum the values of remaining items for the "Others" category
  const othersValue = filteredData?.slice(4).reduce((acc, item) => acc + item.value, 0);

  // Add "Others" category if there are more than 5 items
  if (filteredData.length > 4) {
    topFiveData.push({
      label: "Others",
      value: othersValue,
      id: 4,
    });
  }

  return (
    <div className="h-[250px] w-[360px]">
      <span className="block py-[10px] text-center font-semibold">Inventory</span>
      <PieChart
        colors={["#002F5D", "#004B95", "#519DE9", "#A9ACA9", "#CCCCCC"]}
        series={[
          {
            data: topFiveData,
            highlightScope: { faded: "global", highlighted: "item" },
          },
        ]}
        height={180}
        width={350}
        margin={{ top: 10, right: 200, bottom: 10, left: 20 }} // Adjusted margins
        sx={{
          "& .MuiChart-tooltip": {
            maxWidth: 360,
          },
        }}
      />
    </div>
  );
};

export default Inventory;
