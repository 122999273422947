import React, { useState } from "react";

//Imported sections
import Filed from "./sections/Filed";
import GSTR1 from "./sections/GSTR1";
import GSTR2A from "./sections/GSTR2A";
import GSTR2B from "./sections/GSTR2B";
import GSTR3B from "./sections/GSTR3B";
import FinancialSearch from "./components/FinancialSearch";

const GSTFilings = () => {
  //financial years, Quarter, Month
  const [financialYear, setFinancialYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [month, setMonth] = useState("");

  const [selectedSection, setSelectedSection] = useState("GSTR1");

  const renderGSTFilingSection = () => {
    switch (selectedSection) {
      case "GSTR1":
        return <GSTR1 financialYear={financialYear} quarter={quarter} month={month} />;
      case "GSTR2A":
        return <GSTR2A financialYear={financialYear} quarter={quarter} month={month} />;
      case "GSTR2B":
        return <GSTR2B financialYear={financialYear} quarter={quarter} month={month} />;
      case "GSTR3B":
        return <GSTR3B financialYear={financialYear} quarter={quarter} month={month} />;
      case "Filed":
        return <Filed financialYear={financialYear} quarter={quarter} month={month} />;
      default:
        return <Filed financialYear={financialYear} quarter={quarter} month={month} />;
    }
  };

  return (
    <div>
      {/* Context Switcher Section - GSTR1, GSTR2A, GSTR2B, GSTR3B, Filed*/}
      <div className="py-[10px] px-[20px] flex justify-between border-b-2 border-neutral-200">
        <div className="flex gap-[15px]">
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "GSTR1" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("GSTR1")}
          >
            GSTR1
          </button>
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "GSTR2A" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("GSTR2A")}
          >
            GSTR2A
          </button>
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "GSTR2B" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("GSTR2B")}
          >
            GSTR2B
          </button>
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "GSTR3B" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("GSTR3B")}
          >
            GSTR3B
          </button>
          <button
            className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
              selectedSection === "Filed" ? "text-white bg-black" : "text-black"
            } rounded-full hover:bg-black hover:text-white`}
            onClick={() => setSelectedSection("Filed")}
          >
            Filed
          </button>
        </div>

        <FinancialSearch
          financialYear={financialYear}
          setFinancialYear={setFinancialYear}
          quarter={quarter}
          setQuarter={setQuarter}
          month={month}
          setMonth={setMonth}
        />
      </div>
      <div className="h-[82vh]">{renderGSTFilingSection()}</div>
    </div>
  );
};

export default GSTFilings;
