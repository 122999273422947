import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

//pHaniver - TODO - delete toggle and menu related code when reference 0
export const fetchDashboard = createAsyncThunk("app/fetchDashboard", async (data, extra) => {
  const res = await http.get("/user_profile/backend/dashboard", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//Global Search Functionality
export const fetchGlobalSearchData = createAsyncThunk("app/globalSearch", async (data, extra) => {
  const res = await http.get(`/user_profile/backend/global_search?query=${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//subscribe to firebase notification
export const subscribeFirebaseNotification = createAsyncThunk(
  "app/subscribeFirebaseNotification",
  async (data, extra) => {
    const res = await http.post(`/notification/subscribe_topic/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//unsubscribe to firebase notification
export const unsubscribeFirebaseNotification = createAsyncThunk(
  "app/unsubscribeFirebaseNotification",
  async (data, extra) => {
    const res = await http.post(`/notification/unsubscribe_topic/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//Updates Sales Target Value
// Endpoint: PUT /user_profile/backend/update-sales-target/
export const updateSalesTarget = createAsyncThunk("app/updateSalesTarget", async (data, extra) => {
  const res = await http.put("/user_profile/backend/update-sales-target/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

export const appSlice = createSlice({
  name: "app",
  initialState: {
    dashboard: {
      top_selling_items: [],
      sales_graph: {
        current_month_data: [],
        previous_month_data: [],
        x_labels: [],
      },
      profit_margin: 0,
      absolute_profit_margin: 0,
      total_sales_price: 0,
      total_stock_price: 0,
    },
    // alert: { showAlert: false, message: "", type: "success" },
    sidebarInvoice: false,
    global_search: { products: [], customers: [], vendors: [] },
    global_search_show: false,
  },
  reducers: {
    // toggleAlert: (state, action) => {
    //   return { ...state, alert: action.payload };
    // },
    // reset: (state) => {
    //   return { ...state, alert: { showAlert: false, message: "", type: "success" } };
    // },
    sidebarInvoice: (state, action) => {
      return { ...state, sidebarInvoice: action.payload };
    },
    toggleGlobalSearch: (state, action) => {
      return { ...state, global_search_show: !state.global_search_show };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboard.fulfilled, (state, action) => {
      return { ...state, dashboard: action.payload };
    });
    builder.addCase(fetchGlobalSearchData.fulfilled, (state, action) => {
      const { products, customers, vendors } = action.payload;
      return {
        ...state,
        global_search: {
          products: products || [],
          customers: customers || [],
          vendors: vendors || [],
        },
      };
    });
  },
});

export default appSlice.reducer;
export const { sidebarInvoice, toggleGlobalSearch } = appSlice.actions;
