export default function convertTimestamp(timestamp) {
  // Create a new Date object from the timestamp
  const date = new Date(timestamp);

  // Options for formatting the date and time
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // For 12-hour format, set to false for 24-hour format
  };

  // Convert the date to a string with the specified options
  return date.toLocaleString("en-US", options);
}
