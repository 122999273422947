import React, { useState, useEffect, useRef } from "react";
import { getInvoiceDetailsById, updateCreditNote } from "../../../reducers/Slices/invoiceSlice";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { TextField, FormControl, FilledInput, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import BarcodeReader from "../../Invoices/InvoiceComponents/BarcodeReader";
import {
  fetchBatchesByProductID,
  fetchProductByBarcode,
  fetchProductByID,
} from "../../../reducers/Slices/productSlice";

function UpdateCreditNote({ invoice, products }) {
  const dispatch = useDispatch();
  const creditNote = invoice;

  const [showModal, setShowModal] = useState(false);
  const [creditNoteId, setCreditNoteId] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);

  const [items, setItems] = useState([{}]);
  const [invoiceInfo, setInvoiceInfo] = useState([]);
  const [invoiceDimension, setInvoiceDimension] = useState({});
  const [quantityLimitArr, setQuantityLimitArr] = useState([]);
  const [dealQuantityLimitArr, setDealQuantityLimitArr] = useState([]);
  const [discountOnMRPValue, setDiscountOnMRPValue] = useState([{}]);
  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //Selectors
  const invoiceDetails = useSelector((state) => state.invoice.invoiceDetails);

  const [customer, setCustomer] = useState(null);

  //business details
  const businessDetails = useSelector((state) => state.user);

  const [isIgst, setIsIgst] = useState(false);

  useEffect(() => {
    if (customer?.gst_number?.length > 1 && businessDetails?.gst_number?.length > 1) {
      if (customer?.gst_number?.slice(0, 2) === businessDetails?.gst_number?.slice(0, 2)) {
        setIsIgst(false);
      } else {
        setIsIgst(true);
      }
    } else {
      setIsIgst(false);
    }
  }, [customer]);

  useEffect(() => {
    setCustomer(invoiceDetails?.customer);
    // Only run if there is something in invoiceDetails
    if (!invoiceDetails || invoiceDetails?.sales_invoice_items?.length === 0) {
      return;
    }

    let updatedInvoiceInfo = [];
    let updatedQuantityLimitArr = [];
    let updatedDealQuantityLimitArr = [];
    let updatedDiscountOnMRPValue = [];
    let updatedCalculatedAmountValue = [];

    const productsWithoutService = invoiceDetails?.sales_invoice_items?.filter((product) => product.is_service === "N");

    productsWithoutService?.map((data, index) => {
      // const quantity = data.quantity;
      // const deal_quantity = data.deal_quantity;
      updatedInvoiceInfo.push({ id: data.id, productId: data.product.id, batchId: data.batch });
      updatedQuantityLimitArr.push(data.quantity);
      updatedDealQuantityLimitArr.push(data.deal_quantity);
      updatedDiscountOnMRPValue.push({ discountOnMRP: data.mrp_discount });
      updatedCalculatedAmountValue.push({});
      return data;
    });

    setInvoiceInfo(updatedInvoiceInfo);
    setQuantityLimitArr(updatedQuantityLimitArr);
    setDealQuantityLimitArr(updatedDealQuantityLimitArr);
    setDiscountOnMRPValue(updatedDiscountOnMRPValue);
    setCalculatedAmountValue(updatedCalculatedAmountValue);
  }, [invoiceDetails]); // Include items as a dependency to update correctly

  useEffect(() => {
    const updateItems = async () => {
      if (invoiceInfo?.length <= 0) {
        return;
      }

      let updatedItems = await Promise.all(
        invoiceInfo.map(async (info, index) => {
          const product = await findProductByProductID(info.productId);
          const batch = await findBatchByBatchID(product, info.batchId);

          return {
            ...product,
            ...batch,
            hsn_number: product?.hsn_number,
            id: info.id,
            productId: info.productId,
            batchId: info.batchId,
            sales_price: calculateNetRate(batch?.mrp_price, discountOnMRPValue[index]?.discountOnMRP, batch?.tax),
          };
        })
      );

      setItems(updatedItems);
    };

    updateItems();
  }, [invoiceInfo, products]);

  //Function to reverse calculate netRate based on new discountOnMRPercent
  const calculateNetRate = (mrp, discountOnMRPPercent, taxPercent) => {
    //calculate the rate after discount
    const discountedRate = parseFloat(mrp) - parseFloat(mrp) * ((parseFloat(discountOnMRPPercent) || 0) / 100);
    const netRate = discountedRate / (1 + (parseFloat(taxPercent) || 0) / 100);

    return netRate.toFixed(3).slice(0, -1);
  };

  const [formData, setFormData] = useState({});

  useEffect(() => {
    handleCalculate();
  }, [items, discountOnMRPValue, calculatedAmountValue]);

  useEffect(() => {
    handleCalculateAmount(items);
  }, [items, discountOnMRPValue]);

  const handleCalculate = () => {
    const { total, discount, tax } = items.reduce(
      (accumulator, currentItem, index) => {
        if (currentItem?.sales_price) {
          const quantity = parseInt(currentItem.quantity) || 0;
          let item_total = parseFloat(currentItem.sales_price || 0) * quantity;
          let item_mrp_total = parseFloat(currentItem.mrp_price) * quantity;
          let item_discount = (parseFloat(discountOnMRPValue[index]?.discountOnMRP || 0) / 100) * item_mrp_total;
          let item_tax = item_total * (parseFloat(currentItem.tax || 0) / 100);

          accumulator.total += item_total;
          accumulator.discount += item_discount;
          accumulator.tax += item_tax;
        }
        return accumulator;
      },
      { total: 0, discount: 0, tax: 0 }
    );

    setInvoiceDimension({ total: total + discount, discount, tax });

    setFormData({
      ...formData,
      invoice: invoiceId,
      total_amount: calculatedAmountValue.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.calculated_amount);
      }, 0),
      liability_amount: calculatedAmountValue.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.calculated_amount);
      }, 0),
      received_amount: 0,
      items: items.map((i, index) => ({
        invoice_item: i.id,
        return_quantity: parseInt(i.quantity) || 0,
        deal_quantity: parseInt(i.deal_quantity) || 0,
        return_amount: parseFloat(calculatedAmountValue[index]?.calculated_amount),
      })),
    });
  };

  const handleFieldChange = (value, index, field) => {
    setItems((prevItems) => {
      return prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }; // Update the specified field of the item
        }
        return item;
      });
    });
  };

  const handleCalculateAmount = (items) => {
    if (items?.length <= 0) {
      return;
    }

    if (!items[0]?.id) {
      return;
    }

    setCalculatedAmountValue((prevValue) => {
      const updatedCalculatedAmountValue = prevValue?.map((_, index) => {
        if (_?.checkedByUser === true) {
          return _;
        }

        const { mrp_price, sales_price, quantity, tax } = items[index];
        const discountOnMRPPresent = parseFloat(discountOnMRPValue[index]?.discountOnMRP ?? 0);

        if (!sales_price || !quantity || !tax) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        const parsedQuantity = parseInt(quantity);

        if (sales_price === 0 || parsedQuantity === 0) {
          return { calculated_amount: 0, checkedByUser: false };
        }
        // let amount = (parseFloat(mrp_price) - parseFloat(mrp_price) * (discountOnMRPPresent / 100)) * parsedQuantity;
        let amount = (parseFloat(sales_price) + (parseFloat(sales_price) * parseFloat(tax)) / 100) * parsedQuantity;

        //Going the extra mile because toFixed(2) is rounding numbers resulting in wrong value.
        const truncatedAmount = (Math.floor(amount * 1000) / 1000).toFixed(3).slice(0, -1);

        return { calculated_amount: truncatedAmount, checkedByUser: false };
      });
      return updatedCalculatedAmountValue;
    });
  };

  const findProductByProductID = async (id) => {
    if (!id) {
      return;
    }

    let product = products?.find((product) => product.id === id);

    if (!product) {
      const response = await dispatch(fetchProductByID(id));
      if (response?.payload) {
        product = response.payload;
      }
    }

    return product;
  };

  const findBatchByBatchID = async (product, id) => {
    if (!id) {
      return;
    }

    if (!product) {
      return;
    }

    let batch = product?.batches?.find((batch) => batch.id === id);
    if (!batch) {
      const response = await dispatch(fetchBatchesByProductID({ productId: product?.id, filters: `?batch_id=${id}` }));
      batch = response.payload.batchData.data[0];
    }

    return batch;
  };
  const handleSubmit = async () => {
    setDisableSubmitButton(true);
    await dispatch(updateCreditNote({ id: creditNoteId, data: formData }))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          setShowModal(false);
        } else {
          toast.error("sorry, Credit note cannot be updated");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  //Function to close custom modal and perform actions during closing of modal
  function handleClose() {
    setShowModal(false);
  }

  function handleEditClick() {
    if (creditNote?.id) {
      setCreditNoteId(creditNote?.id);
      setInvoiceId(creditNote?.invoice);
      dispatch(getInvoiceDetailsById({ id: creditNote?.invoice }));
    }
    setShowModal(true);
  }

  //Move to next field on enter button
  const inputRefs = useRef([]);
  // const submitButton = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        // if (focusedElement === submitButton.current) {
        //   submitButton.current.click();
        //   return;
        // }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row
              // if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
              //   submitButton.current.focus(); // Focus on the submit button
              // }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [items]);

  //Barcode related operations
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [capturedProduct, setCapturedProduct] = useState({});

  /*Just here to workaround react's nature of not re-updating state if similar data causing this api to not fetch data if 
    some barcode is already present. */
  const [scanTimestamp, setScanTimestamp] = useState(Date.now());

  useEffect(() => {
    if (scannedBarcode?.length > 0) {
      dispatch(fetchProductByBarcode(scannedBarcode))
        .then((action) => {
          if (action) {
            setCapturedProduct(action.payload);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong! Product dosen't exist or there are two products with same barcode!");
        });
    }
    //eslint-disable-next-line
  }, [scannedBarcode, scanTimestamp]);

  const handleAddCapturedProductsInItems = (productId, batchId) => {
    if (!batchId) {
      return;
    }

    if (!productId) {
      return;
    }

    const ItemAlreadyPresent = items.findIndex((item) => item?.productId === productId);
    const BatchAlreadyPresent = items.findIndex((item) => item?.batchId === batchId);

    let updatedItems = items;

    if (ItemAlreadyPresent >= 0 && BatchAlreadyPresent >= 0) {
      if (!updatedItems[BatchAlreadyPresent]?.quantity) {
        //Add quantity variable if not already present
        updatedItems[BatchAlreadyPresent] = {
          ...updatedItems[BatchAlreadyPresent],
          quantity: 1,
        };
      } else {
        const previousQuantity = parseInt(updatedItems[BatchAlreadyPresent].quantity);
        if (previousQuantity >= quantityLimitArr[BatchAlreadyPresent]) {
          toast.error("value can't be more than present in invoice");
        } else {
          updatedItems[BatchAlreadyPresent].quantity = previousQuantity + 1;
        }
      }
    }

    handleCalculateAmount(updatedItems);

    setItems(updatedItems);
  };

  //Side effect to rearrange data of captured product;
  useEffect(() => {
    //If product dosen't exist then return
    if (!capturedProduct?.id) {
      return;
    }

    const batchId = capturedProduct?.batch?.id;

    handleAddCapturedProductsInItems(capturedProduct?.id, batchId);
  }, [capturedProduct]);

  //adjust liability amount, received amount based changes in one another
  const handleChangeLiabilityAmount = (value) => {
    setFormData({
      ...formData,
      liability_amount: parseFloat(value),
      received_amount: parseFloat(formData?.total_amount) - parseFloat(value),
    });
  };

  const handleChangeReceivedAmount = (value) => {
    setFormData({
      ...formData,
      received_amount: parseFloat(value),
      liability_amount: parseFloat(formData?.total_amount) - parseFloat(value),
    });
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <BarcodeReader barcode={scannedBarcode} setBarcode={setScannedBarcode} setScanTimestamp={setScanTimestamp} />
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center text-xl">Edit Credit Note</div>
          {items?.length > 0 &&
            items.map((item, index) => (
              <div key={item?.id || index} className="flex flex-col gap-[20px] border-b-2 border-neutral-200 py-[20px]">
                <div className="flex flex-col gap-[10px]">
                  <label htmlFor="products">
                    Product<span className="pl-1 text-red-500">*</span>
                  </label>
                  <TextField
                    id="outlined-basic"
                    disabled
                    value={items[index]?.product_name}
                    defaultValue={""}
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label htmlFor="batch-number">
                    Batch Number<span className="pl-1 text-red-500">*</span>
                  </label>
                  <TextField
                    hiddenLabel
                    id="outlined-basic"
                    value={items[index]?.batch_number}
                    defaultValue={""}
                    type="text"
                    variant="outlined"
                    disabled
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label htmlFor="hsn-number">
                    HSN number<span className="pl-1 text-red-500">*</span>
                  </label>
                  <TextField
                    hiddenLabel
                    id="outlined-basic"
                    value={items[index]?.hsn_number}
                    defaultValue={""}
                    type="text"
                    variant="outlined"
                    disabled
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label htmlFor="quantity">
                    Quantity<span className="pl-1 text-red-500">*</span>
                  </label>
                  <TextField
                    ref={(el) => {
                      if (el && inputRefs.current[index]) {
                        inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                      }
                    }}
                    hiddenLabel
                    id="outlined-basic"
                    onChange={(e) => {
                      if (e.target.value && e.target.value < 0) {
                        toast.error("value can't be negative or 0");
                        handleFieldChange(1, index, "quantity");
                        return;
                      }

                      if (e.target.value > quantityLimitArr[index]) {
                        toast.error("value can't be more then present in invoice");
                        handleFieldChange(quantityLimitArr[index], index, "quantity");
                        return;
                      }
                      handleFieldChange(e.target.value, index, "quantity");
                    }}
                    value={items[index]?.quantity}
                    defaultValue={0}
                    type="number"
                    variant="outlined"
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label htmlFor="deal_quantity">Deal / Free</label>
                  <TextField
                    ref={(el) => {
                      if (el && inputRefs.current[index]) {
                        inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                      }
                    }}
                    hiddenLabel
                    id="outlined-basic"
                    onChange={(e) => {
                      if (e.target.value && e.target.value < 0) {
                        toast.error("value can't be negative or 0");
                        handleFieldChange(0, index, "deal_quantity");
                        return;
                      }

                      if (e.target.value > dealQuantityLimitArr[index]) {
                        toast.error("value can't be more then present in invoice");
                        handleFieldChange(dealQuantityLimitArr[index], index, "deal_quantity");
                        return;
                      }

                      handleFieldChange(e.target.value, index, "deal_quantity");
                    }}
                    value={items[index]?.deal_quantity}
                    defaultValue={0}
                    type="number"
                    variant="outlined"
                  />
                </div>

                <div className="flex flex-col gap-[10px]">
                  <label htmlFor="retail_without_tax">
                    Retail without TAX<span className="pl-1 text-red-500">*</span>
                  </label>
                  <TextField
                    hiddenLabel
                    id="outlined-basic"
                    onChange={(e) => handleFieldChange(e.target.value, index, "sales_price")}
                    value={items[index]?.sales_price}
                    // defaultValue={items[index]?.rate}
                    type="number"
                    variant="outlined"
                    disabled
                  />
                </div>

                <div className="flex flex-col gap-[10px]">
                  <label htmlFor="tax">
                    Tax %<span className="pl-1 text-red-500">*</span>
                  </label>
                  <TextField
                    hiddenLabel
                    id="tax"
                    value={items[index]?.tax}
                    // defaultValue={items[index]?.rate}
                    type="number"
                    variant="outlined"
                    disabled
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label htmlFor="discount_on_mrp">Discount on MRP %</label>
                  <TextField
                    hiddenLabel
                    id="discount_on_mrp"
                    value={discountOnMRPValue[index]?.discountOnMRP ?? ""}
                    // defaultValue={items[index]?.rate}
                    type="number"
                    variant="outlined"
                    disabled
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="retail_without_tax">
                    Amount<span className="pl-1 text-red-500">*</span>
                  </label>
                  <TextField
                    hiddenLabel
                    id="amount"
                    value={calculatedAmountValue[index]?.calculated_amount || 0}
                    type="text"
                    variant="outlined"
                    disabled
                  />
                </div>
              </div>
            ))}
          <div className="w-full flex border-t-2 border-neutral-200 mt-[20px]">
            <div className="w-full">
              <div className="flex justify-between my-5">
                <h3 className="font-semibold text-gray-500">SubTotal</h3>
                <h3 className="font-semibold text-gray-500">{(invoiceDimension?.total || 0).toFixed(2)}</h3>
              </div>
              <div className="flex justify-between my-5">
                <h3 className="font-semibold text-gray-500">Discount</h3>
                <h3 className="font-semibold text-gray-500">{(invoiceDimension?.discount || 0).toFixed(2)}</h3>
              </div>

              {!isIgst && (
                <>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">SGST @tax%</h3>
                    <h3 className="font-semibold text-gray-500">{(invoiceDimension?.tax / 2 || 0).toFixed(2)}</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">CGST @tax%</h3>
                    <h3 className="font-semibold text-gray-500">{(invoiceDimension?.tax / 2 || 0).toFixed(2)}</h3>
                  </div>
                </>
              )}
              {isIgst && (
                <div className="flex justify-between my-5">
                  <h3 className="font-semibold text-gray-500">IGST @tax%</h3>
                  <h3 className="font-semibold text-gray-500">{(invoiceDimension?.tax || 0).toFixed(2)}</h3>
                </div>
              )}
              <div className="flex justify-between my-5">
                <h3 className="font-semibold text-gray-500">Total</h3>
                <h3 className="font-semibold text-gray-500">{(formData?.total_amount || 0).toFixed(2)}</h3>
              </div>
              <div className="flex justify-between my-5">
                <h3 className="font-semibold text-gray-500">Amount Received</h3>
                <input
                  type="number"
                  className="py-[10px] px-[10px] border border-black rounded-sm"
                  placeholder="Amount Received"
                  value={parseFloat(formData.received_amount)}
                  onChange={(e) => {
                    if (e.target.value > formData?.grand_total) {
                      toast.error("Amount received cannot be more than grand total!");
                      return;
                    }
                    handleChangeReceivedAmount(e.target.value);
                  }}
                />
              </div>
              <div className="flex justify-between my-5">
                <h3 className="font-semibold text-gray-500">Liability amount</h3>
                <input
                  type="number"
                  className="py-[10px] px-[10px] border border-black rounded-sm"
                  placeholder="Liability_amount"
                  value={parseFloat(formData?.liability_amount)}
                  onChange={(e) => {
                    if (e.target.value > formData?.grand_total) {
                      toast.error("Liability Amount cannot be more than grand total!");
                      return;
                    }

                    handleChangeLiabilityAmount(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-[10px] justify-center">
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={() => handleSubmit()}
              disabled={disableSubmitButton}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              Save
            </button>
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={() => handleClose()}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClose();
                }
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <div>
        <button onClick={() => handleEditClick()}>Edit</button>
      </div>
    </>
  );
}

export default UpdateCreditNote;
