import React, { useState, useEffect } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";

import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  createProduct,
  fetchHSNData,
  // fetchTaxRate,
  flushSearchedProductsArr,
  searchProducts,
} from "../../../reducers/Slices/productSlice";
import { debounce } from "lodash";

//Micro Components
import IconWrapper from "../../MicroComponents/IconWrapper";
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";

//UI Components
import { Search, X as Cross } from "react-feather";
import { useHotkeys } from "react-hotkeys-hook";
import toast from "react-hot-toast";
// import DurationInput from "./DurationInput";
import BarcodeInput from "./BarcodeInput";
import { Autocomplete, TextField } from "@mui/material";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AddProduct = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const product_name = query.get("product_name");

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //Convert days, hours and minutes into seconds
  const [totalSeconds, setTotalSeconds] = useState(0);

  useEffect(() => {
    if (product_name) {
      setSearchTerm(product_name);
      navigate("/inventory", { replace: true });
    }
  }, [product_name]);

  const [showModalProduct, setShowModalProduct] = useState(false);

  const [addProductFormData, setAddProductFormData] = useState({
    product_name: "",
    brand: "",
    hsn_number: "",
    package: "",
    is_service: "N", // Default to 'N'
    service: {
      rate: 0,
      cost: 0,
      duration_time: totalSeconds,
      bar_code_number: null,
      tax: 0,
      mrp: 0,
    },
    batches: [],
  });

  //Side effect to updated formdata based on totalSeconds
  useEffect(() => {
    setAddProductFormData((prevState) => {
      return { ...prevState, service: { ...prevState.service, duration_time: totalSeconds } };
    });
  }, [totalSeconds]);

  const dispatch = useDispatch();

  //function for add item click
  const handleAddItemClick = () => {
    setShowModalProductSelection(true);
    setTotalSeconds(0);
  };

  //Hot key to add items in inventory
  useHotkeys("Ctrl + Alt + P, Ctrl + Option + P", () => setShowModalProduct(!showModalProduct));

  const handleSubmit = () => {
    setDisableSubmitButton(true);
    // Guard - check required fields
    if (
      !addProductFormData?.product_name ||
      !addProductFormData?.brand ||
      !addProductFormData?.hsn_number ||
      (addProductFormData.is_service === "Y" && (!addProductFormData.service.rate || !addProductFormData.service.mrp))
    ) {
      toast.error("please fill all field values in form!");
      setDisableSubmitButton(false);
      return;
    }

    setDisableSubmitButton(false);
    dispatch(createProduct(addProductFormData))
      .then((action) => {
        if (action.payload) {
          setAddProductFormData({});
          setDisableSubmitButton(false);
          setShowModalProduct(false);
        } else {
          toast.error("Sorry, product cannot be added");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(`Error Occured: " ${err}`);
        setDisableSubmitButton(false);
      });
  };

  //Barcode functionality
  const [barcodeNumber, setBarcodeNumber] = useState(null);

  //side effect for barcode.
  useEffect(() => {
    setAddProductFormData((prevState) => {
      return { ...prevState, service: { ...prevState.service, bar_code_number: barcodeNumber } };
    });
  }, [barcodeNumber]);

  //Side effect to set these fields back to empty on is Service false
  useEffect(() => {
    if (addProductFormData.is_service === "N") {
      setAddProductFormData((prevState) => {
        return { ...prevState, service: { rate: 0, purchase_price: 0, duration_time: 0, tax: 0, mrp: 0 } };
      });
    }
  }, [addProductFormData.is_service]);

  //Search functionality
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);

  const debouncedSetSearchTerm = debounce((term) => {
    setSearchTerm(term);
  }, 1000);

  useEffect(() => {
    if (searchTerm.length > 0) {
      dispatch(searchProducts(`?product_search=${searchTerm}`));
    } else {
      dispatch(flushSearchedProductsArr());
    }
  }, [searchTerm, dispatch]);

  const handleClose = () => {
    setAddProductFormData({});
    setShowModalProduct(false);
  };

  const handleCloseSearch = (e) => {
    setSearchTerm("");
    setShowSearch(false);
  };

  //Selected product
  const [selectedOption, setSelectedOption] = useState("");

  const handleChangeProductTypeSelectionOption = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitProductTypeSelectionForm = (event) => {
    event.preventDefault();
    if (selectedOption === "Goods") {
      setAddProductFormData({ ...addProductFormData, is_service: "N" });
    } else {
      setAddProductFormData({ ...addProductFormData, is_service: "Y" });
    }
    setShowModalProduct(true);
    setShowModalProductSelection(false);
  };

  const [showModalProductSelection, setShowModalProductSelection] = useState(false);

  const handleCloseProductSelection = () => {
    setSelectedOption("");
    setShowModalProductSelection(false);
  };

  // //Dummy HSN codes
  // const hsnCodes = ["1205", "1902", "2106", "2402", "3304", "3824", "4202", "4901", "6802", "7306"];

  // //Dummy HSN tax rates
  // const hsnTaxRates = {
  //   1205: [5],
  //   1902: [12, 18],
  //   2106: [18],
  //   2402: [28],
  //   3304: [18, 28],
  //   3824: [5],
  //   4202: [12],
  //   4901: [5, 12],
  //   6802: [28],
  //   7306: [18, 28],
  // };

  const [hSNTaxRates, sethSNTaxRates] = useState([]);

  //HSN codes
  const HSN_data = useSelector((state) => state.product.HSNData.data);

  const handleHSNNumberChange = (value) => {
    const nextAddProductFormData = { ...addProductFormData, hsn_number: value };
    setAddProductFormData(nextAddProductFormData);

    //Early Return if product is not a service
    if (addProductFormData.is_service === "N") {
      return;
    }

    const hsnData = getHSNDataByHSNCode(value);

    const taxPercent = parseFloat(hsnData?.cgst_rate) + parseFloat(hsnData?.sgst_rate);

    const nextHSNTaxRateArr = [taxPercent] || [];

    //if nextHSNTaxRate is a single item then add that item into product details or select first item anyway
    setAddProductFormData((prevState) => {
      return { ...prevState, service: { ...addProductFormData.service, tax: parseFloat(nextHSNTaxRateArr[0]) } };
    });

    sethSNTaxRates(nextHSNTaxRateArr);
  };

  const [hSNSearchTerm, sethSNSearchTerm] = useState("");

  useEffect(() => {
    if (hSNSearchTerm.length > 0) {
      dispatch(fetchHSNData(`?hsn_code=${hSNSearchTerm}`));
    }
  }, [hSNSearchTerm]);

  const setDebouncedHSNSearchTerm = debounce((value) => sethSNSearchTerm(value), 1000);

  const getHSNDataByHSNCode = (hsn_number) => {
    return HSN_data.find((HSNData) => HSNData?.hsn_code === hsn_number);
  };

  return (
    <>
      <CustomModal isOpen={showModalProductSelection} handleClose={handleCloseProductSelection}>
        <form onSubmit={handleSubmitProductTypeSelectionForm}>
          <h2 className="text-center text-xl font-semibold">Select Product Type:</h2>
          <div className="flex justify-center gap-[20px] pt-[20px]">
            <div className="flex gap-[10px]">
              <input
                type="radio"
                value="Goods"
                id="Goods"
                checked={selectedOption === "Goods"}
                onChange={handleChangeProductTypeSelectionOption}
              />
              <label className="text-lg pb-[2px]" htmlFor="Goods">
                Goods
              </label>
            </div>
            <div className="flex gap-[10px]">
              <input
                type="radio"
                value="Services"
                id="Services"
                checked={selectedOption === "Services"}
                onChange={handleChangeProductTypeSelectionOption}
              />
              <label className="text-lg pb-[2px]" htmlFor="Services">
                Service
              </label>
            </div>
          </div>
          <div className="flex w-full justify-center pt-[20px]">
            <button type="submit" className="px-[20px] py-[10px] bg-black text-white rounded-full">
              Submit
            </button>
          </div>
        </form>
      </CustomModal>
      <CustomModal isOpen={showModalProduct} handleClose={handleClose}>
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center text-lg font-semibold">
            {selectedOption === "Goods" ? "Product" : "Service"} description
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="Product-name" className="text-neutral-500">
              {selectedOption === "Goods" ? "Product" : "Service"} Name<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="Product-name"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={addProductFormData.product_name}
              onChange={(e) => {
                const nextAddProductFormData = { ...addProductFormData, product_name: e.target.value };
                setAddProductFormData(nextAddProductFormData);
              }}
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="brand-name" className="text-neutral-500">
              {selectedOption === "Goods" ? "Brand" : "Company"} Name<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="brand-name"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={addProductFormData.brand}
              onChange={(e) => {
                const nextAddProductFormData = { ...addProductFormData, brand: e.target.value };
                setAddProductFormData(nextAddProductFormData);
              }}
              required
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="hsn-number" className="text-neutral-500">
              HSN Number<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="hsn-number"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
              value={addProductFormData.hsn_number}
              onChange={(e) => {
                const nextAddProductFormData = { ...addProductFormData, hsn_number: e.target.value };
                setAddProductFormData(nextAddProductFormData);
              }}
              required
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="hsn-number" className="text-neutral-500">
              {selectedOption === "Goods" ? "HSN Number" : "SAC code"} <span className="pl-1 text-red-500">*</span>
            </label>
            <Autocomplete
              disablePortal
              id="hsn-number"
              options={HSN_data || null}
              style={{ borderColor: "black" }}
              value={getHSNDataByHSNCode(addProductFormData?.hsn_number)}
              getOptionLabel={(option) => option?.hsn_code}
              onChange={(event, value) => {
                handleHSNNumberChange(value?.hsn_code);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      border: "0.5px solid #737373", // Black border
                      borderRadius: "0px", // No border radius
                    },
                  }}
                  onChange={(e) => setDebouncedHSNSearchTerm(e.target.value)}
                />
              )}
            />
          </div>
          {selectedOption === "Goods" && (
            <div className="flex flex-col gap-[10px]">
              <label htmlFor="product-package" className="text-neutral-500">
                {selectedOption === "Goods" ? "Product Packaging" : "Service Type"}
                <span className="pl-1 text-red-500">*</span>
              </label>
              <input
                type="text"
                id="product-package"
                className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                value={addProductFormData.package}
                onChange={(e) => {
                  const nextAddProductFormData = { ...addProductFormData, package: e.target.value };
                  setAddProductFormData(nextAddProductFormData);
                }}
                required
              />
            </div>
          )}

          {/* is_service field */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="is-service" className="text-neutral-500">
              Is Service<span className="pl-1 text-red-500">*</span>
            </label>
            <select
              id="is-service"
              className="border-[1px] border-neutral-500 bg-white px-[10px] py-[10px]"
              value={addProductFormData.is_service}
              onChange={(e) => {
                setAddProductFormData({ ...addProductFormData, is_service: e.target.value });
              }}
              required
            >
              <option value="N">No</option>
              <option value="Y">Yes</option>
            </select>
          </div> */}

          {/* Conditionally render extra fields if is_service is "Y" */}
          {addProductFormData.is_service === "Y" && (
            <>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="mrp-price" className="text-neutral-500">
                  MRP Price<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="mrp-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={addProductFormData?.service?.mrp}
                  onChange={(e) => {
                    setAddProductFormData({
                      ...addProductFormData,
                      service: { ...addProductFormData.service, mrp: parseFloat(e.target.value) },
                    });
                  }}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="sales-price" className="text-neutral-500">
                  Rate<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="sales-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={addProductFormData?.service?.rate}
                  onChange={(e) => {
                    setAddProductFormData({
                      ...addProductFormData,
                      service: { ...addProductFormData.service, rate: parseFloat(e.target.value) },
                    });
                  }}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="purchase-price" className="text-neutral-500">
                  Service Cost to Business / Expense
                </label>
                <input
                  type="number"
                  id="purchase-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={addProductFormData?.service?.cost}
                  onChange={(e) => {
                    setAddProductFormData({
                      ...addProductFormData,
                      service: { ...addProductFormData.service, cost: parseFloat(e.target.value) },
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="tax-percentage" className="text-neutral-500">
                  Tax %<span className="pl-1 text-red-500">*</span>
                </label>
                {hSNTaxRates?.length === 1 && (
                  <input
                    type="number"
                    id="tax-percentage"
                    className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                    value={addProductFormData?.service?.tax}
                    onChange={(e) => {
                      setAddProductFormData({
                        ...addProductFormData,
                        service: { ...addProductFormData.service, tax: parseFloat(e.target.value) },
                      });
                    }}
                    disabled
                    required
                  />
                )}
                {hSNTaxRates?.length > 1 && (
                  <select
                    id="tax-percentage"
                    className="border-[1px] border-neutral-500 px-[10px] py-[10px] bg-white"
                    value={addProductFormData?.service?.tax}
                    onChange={(e) =>
                      setAddProductFormData({
                        ...addProductFormData,
                        service: { ...addProductFormData.service, tax: parseFloat(e.target.value) },
                      })
                    }
                    required
                  >
                    <option value="" disabled>
                      Select Tax Percentage
                    </option>
                    {hSNTaxRates.map((rate) => (
                      <option key={rate} value={rate}>
                        {rate}%
                      </option>
                    ))}
                  </select>
                )}
              </div>
              {/* For Barcode  */}
              <div className="relative">
                <BarcodeInput barcodeNumber={barcodeNumber} setBarcodeNumber={setBarcodeNumber} />
              </div>
              {/* <DurationInput totalSeconds={totalSeconds} setTotalSeconds={setTotalSeconds} /> */}
            </>
          )}

          <div className="flex justify-center gap-[20px]">
            <button
              className="save bg-black px-[20px] py-[10px] text-white rounded-full"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
            >
              Save
            </button>
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>

      {/* Search Bar and Add Product button  */}
      <div className="flex justify-between mb-[20px]">
        {!showSearch ? (
          <button onClick={() => setShowSearch(true)} id="joyride-step-1">
            <IconWrapper>
              <Search className="text-white z-10" />
            </IconWrapper>
          </button>
        ) : (
          <form
            class="flex items-center justify-between w-full gap-2"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <label for="simple-search" class="sr-only">
              Search
            </label>
            <div class="relative w-full">
              <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={(e) => debouncedSetSearchTerm(e.target.value)}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="Search product name..."
                required
              />
            </div>
            <button
              onClick={(e) => {
                handleCloseSearch(e);
              }}
            >
              <IconWrapper>
                <Cross className="text-white z-10" />
              </IconWrapper>
            </button>
          </form>
        )}

        {/* When Search Bar not open show Add Product Button */}
        {!showSearch && (
          <span id="joyride-step-2">
            <ButtonWrapper type="submit" eventCallback={handleAddItemClick}>
              + Add Product
            </ButtonWrapper>
          </span>
        )}
      </div>
    </>
  );
};

export default AddProduct;
