import React, { useState } from "react";
import { Minus, Plus } from "react-feather";
import { useNavigate } from "react-router-dom";

function FORM_3_2() {
  const navigate = useNavigate();
  const [openTab, setOpenTab] = useState(null);
  const [tables, setTables] = useState({
    unregistered: [
      {
        id: Date.now(),
        checked: false,
        placeOfSupply: "",
        taxableValue: 0.0,
        integratedTax: 0.0,
      },
    ],
    composition: [
      {
        id: Date.now(),
        checked: false,
        placeOfSupply: "",
        taxableValue: 0.0,
        integratedTax: 0.0,
      },
    ],
    uin: [
      {
        id: Date.now(),
        checked: false,
        placeOfSupply: "",
        taxableValue: 0.0,
        integratedTax: 0.0,
      },
    ],
  });

  const handleToggleTab = (tab) => {
    setOpenTab((prevTab) => (prevTab === tab ? null : tab));
  };

  const addRow = (tab) => {
    setTables((prevTables) => ({
      ...prevTables,
      [tab]: [
        ...prevTables[tab],
        {
          id: Date.now(),
          checked: false,
          placeOfSupply: "",
          taxableValue: 0.0,
          integratedTax: 0.0,
        },
      ],
    }));
  };

  const removeRows = (tab) => {
    setTables((prevTables) => ({
      ...prevTables,
      [tab]: prevTables[tab].filter((row) => !row.checked),
    }));
  };

  const handleInputChange = (tab, id, field, value) => {
    setTables((prevTables) => ({
      ...prevTables,
      [tab]: prevTables[tab].map((row) => (row.id === id ? { ...row, [field]: value } : row)),
    }));
  };

  const handleCheckboxChange = (tab, id) => {
    setTables((prevTables) => ({
      ...prevTables,
      [tab]: prevTables[tab].map((row) => (row.id === id ? { ...row, checked: !row.checked } : row)),
    }));
  };

  const renderTable = (tab) => (
    <div className="mt-3 ">
      <table className="w-full border border-neutral-300">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap">
              Check
            </th>
            <th className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap">
              Place of Supply (State/UT)
            </th>
            <th className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap">
              Total Taxable Value (₹)
            </th>
            <th className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap">
              Amount of Integrated Tax (₹)
            </th>
          </tr>
        </thead>
        <tbody>
          {tables[tab].map((row) => (
            <tr key={row.id}>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <input type="checkbox" checked={row.checked} onChange={() => handleCheckboxChange(tab, row.id)} />
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <select
                  value={row.placeOfSupply}
                  onChange={(e) => handleInputChange(tab, row.id, "placeOfSupply", e.target.value)}
                  className="w-fill px-[20px] py-[10px] bg-white border border-black rounded-full"
                >
                  <option value="">Select State</option>
                  <option value="27-MH">27-Maharashtra</option>
                  <option value="29-KA">29-Karnataka</option>
                  <option value="07-DL">07-Delhi</option>
                  {/* Add more options as needed */}
                </select>
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <input
                  type="number"
                  value={row.taxableValue}
                  onChange={(e) => handleInputChange(tab, row.id, "taxableValue", e.target.value)}
                  className="border p-1 w-full"
                />
              </td>
              <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <input
                  type="number"
                  value={row.integratedTax}
                  onChange={(e) => handleInputChange(tab, row.id, "integratedTax", e.target.value)}
                  className="border p-1 w-full"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center gap-[10px] py-[10px]">
        <button
          className="px-[20px] py-[10px] rounded-full text-black border-2 border-black"
          onClick={() => addRow(tab)}
        >
          Add
        </button>
        <button
          className="px-[20px] py-[10px] rounded-full text-red-600 border-2 border-red-600"
          onClick={() => removeRows(tab)}
        >
          Remove
        </button>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-center  h-full overflow-y-scroll pt-[5vh]">
      <h1 className="font-bold text-2xl py-[20px] max-w-[60vw] text-center">
        3.2 Of the supplies shown in 3.1(a) and 3.1.1(i), details of inter-state supplies made to unregistered persons,
        composition taxable persons and UIN holders
      </h1>

      <div className="w-full max-w-[50vw] mb-[10px]">
        {/* Unregistered Section */}
        <button
          className="flex w-full justify-between px-[20px] py-[20px] border-2 border-neutral-200"
          onClick={() => handleToggleTab("unregistered")}
        >
          <h3 className="underline">Supplies made to Unregistered Persons</h3>
          <span>{openTab === "unregistered" ? <Minus /> : <Plus />}</span>
        </button>
        <div
          className={`overflow-y-scroll border-2 border-neutral-200 border-y-0 transition-all duration-300 ease-in-out ${
            openTab === "unregistered" ? "max-h-[1000px]" : "max-h-0"
          }`}
        >
          <div className="px-5 py-3">{renderTable("unregistered")}</div>
        </div>

        {/* Composition Section */}
        <button
          className="flex w-full justify-between px-[20px] py-[20px] border-2 border-neutral-200"
          onClick={() => handleToggleTab("composition")}
        >
          <h3 className="underline">Supplies made to Composition Taxable Persons</h3>
          <span>{openTab === "composition" ? <Minus /> : <Plus />}</span>
        </button>
        <div
          className={`overflow-y-scroll border-2 border-neutral-200 border-y-0 transition-all duration-300 ease-in-out ${
            openTab === "composition" ? "max-h-[1000px]" : "max-h-0"
          }`}
        >
          <div className="px-5 py-3">{renderTable("composition")}</div>
        </div>

        {/* UIN Section */}
        <button
          className="flex w-full justify-between px-[20px] py-[20px] border-2 border-neutral-200"
          onClick={() => handleToggleTab("uin")}
        >
          <h3 className="underline">Supplies made to UIN holders</h3>
          <span>{openTab === "uin" ? <Minus /> : <Plus />}</span>
        </button>
        <div
          className={`overflow-y-scroll border-2 border-neutral-200 border-t-0 transition-all duration-300 ease-in-out ${
            openTab === "uin" ? "max-h-[1000px]" : "max-h-0"
          }`}
        >
          <div className="px-5 py-3">{renderTable("uin")}</div>
        </div>
      </div>

      <div className="flex gap-[10px] py-[30px]">
        <button className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full">Cancel</button>
        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Confirm
        </button>
        <button
          className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default FORM_3_2;
