import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function FORM_5_1() {
  const navigate = useNavigate();
  const [interestLiabilities, setInterestLiabilities] = useState(true);

  return (
    <div className="flex flex-col items-center h-full overflow-y-scroll pt-[5vh]">
      <div className="flex flex-col justify-between w-full max-w-[50vw] items-center pb-[10px]">
        <h1 className="font-bold text-2xl py-[20px] max-w-[60vw] text-center">
          5.1 Interest and Late fee for previous tax period
        </h1>
        <button
          className="px-[20px] py-[10px] h-fit text-white bg-black rounded-full"
          onClick={() => alert("view turnover was clicked ")}
        >
          View your Turnover
        </button>
      </div>
      <p className="text-neutral-500 py-[5px]">
        Note: Declare interest payable on tax liabilities on supplies attracting reverse charge as well as other than
        reverse charge
      </p>

      <p className="text-red-500 py-[5px]  max-w-[60vw] text-center">
        Note: Late fee for the month includes late fee charged due to delay in filing of previous month's GSTR-3B. The
        computation is based on the formula: [Date of Filing – Due date of Filing] * ₹25/day (in case of any liability)
        or ₹10/day (in case of nil liability) per Act (CGST/SGST).
      </p>

      <p className="py-[5px] pb-[20px]  max-w-[60vw] text-center">
        <input
          type="checkbox"
          checked={interestLiabilities}
          onChange={() => setInterestLiabilities(!interestLiabilities)}
        />{" "}
        Please select the check box if you wish to declare any Interest liabilities. Please note Interest amounts
        declared here under respective heads need to be paid in cash in addition to tax liabilities for the month. GSTR
        3B can be filed only after complete payment of all liabilities.
      </p>

      <table>
        <thead>
          <tr className="bg-gray-50">
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Integrated Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Central Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              State/UT Tax (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              CESS (₹)
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-semibold bg-white border-[1px] border-neutral-200">
              Interest
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-semibold bg-white border-[1px] border-neutral-200">
              Late Fees
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-stone-200 border-[1px] border-neutral-200"></td>
          </tr>
        </tbody>
      </table>

      <div className="flex gap-[10px] py-[30px]">
        <button className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full">Cancel</button>
        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Confirm
        </button>
        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Re-compute Interest
        </button>
        <button
          className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default FORM_5_1;
