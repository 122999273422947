export function formatIndianNumber(number) {
  const updatedNumber = parseFloat(number);
  if (updatedNumber >= 1e7) {
    return (updatedNumber / 1e7).toFixed(2) + " Cr";
  } else if (updatedNumber >= 1e5) {
    return (updatedNumber / 1e5).toFixed(2) + " lac";
  } else {
    return updatedNumber.toLocaleString("en-IN"); // Adds commas as per Indian numbering
  }
}
