import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

//Images to display
import BillingImage from "../../../assets/images/invoice.png";
import InventoryImage from "../../../assets/images/inventory.png";
import PLImage from "../../../assets/images/pl.png";
import LedgerImage from "../../../assets/images/ledger.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const paginationStyles = {
  bullet: {
    backgroundColor: "white", // Default bullet color
    width: "15px",
    height: "10px",
    borderRadius: "8px",
    margin: "0 4px",
  },
};

function FeatureSwiper() {
  return (
    <Swiper
      slidesPerView={1}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          const style = paginationStyles.bullet;
          return `<span class="${className}" style="background-color: ${style.backgroundColor}; width: ${style.width}; height: ${style.height}; border-radius: ${style.borderRadius}; margin: ${style.margin};"></span>`;
        },
      }}
      modules={[Pagination, Autoplay]}
      className="featureSwiper"
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
      //   onSlideChange={() => console.log("slide change")}
      //   onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <div className="w-full flex flex-col items-center justify-center">
          <img
            src={BillingImage}
            alt="Showing billing section"
            className="block w-[80%] 2xl:w-[80%] 3xl:w-[65%] bg-white h-[40vh] xl:h-[45vh] 2xl:h-[50vh]"
          />
          <p className="text-md xl:text-lg mt-[40px] pb-[7vh] text-white max-w-[50%] text-center font-medium">
            Simplify Your Invoicing with Our Effortless Billing System: Quick, Accurate, and Seamless.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="w-full flex flex-col items-center justify-center">
          <img
            src={InventoryImage}
            alt="Showing inventory section"
            className="block w-[80%] 2xl:w-[80%] 3xl:w-[65%] bg-white h-[40vh] xl:h-[45vh]  2xl:h-[50vh]"
          />
          <p className="text-md xl:text-lg mt-[40px] pb-[7vh] text-white max-w-[50%] text-center font-medium">
            Stay in Control with Our Advanced Inventory Manager: Real-Time Tracking, Efficient Stock Control, and
            Insightful Analytics.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="w-full flex flex-col items-center justify-center">
          <img
            src={PLImage}
            alt="Showing PL section"
            className="block w-[80%] 2xl:w-[80%] 3xl:w-[65%] bg-white h-[40vh] xl:h-[45vh]  2xl:h-[50vh]"
          />
          <p className="text-md xl:text-lg mt-[40px] pb-[7vh] text-white max-w-[50%] text-center font-medium">
            Gain Clear Financial Insights with Our Comprehensive P&L Statements: Easy Analysis of Profitability and
            Performance.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="w-full flex flex-col items-center justify-center">
          <img
            src={LedgerImage}
            alt="Showing ledger section"
            className="block w-[80%] 2xl:w-[80%] 3xl:w-[65%] bg-white h-[40vh] xl:h-[45vh]  2xl:h-[50vh]"
          />
          <p className="text-md xl:text-lg mt-[40px] pb-[7vh] text-white max-w-[50%] text-center font-medium">
            Streamline Your Records with Our Customer Ledger Book: Detailed Tracking, Organized Entries, and Seamless
            Management.
          </p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default FeatureSwiper;
