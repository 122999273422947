import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import { createNewStore } from "../../../reducers/Slices/onlineStoreSlice";
import toast from "react-hot-toast";

function AddStore() {
  const dispatch = useDispatch();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
  });

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    handleAddStore();
  };

  const handleClose = () => {
    resetInitialValue();
    setShowModal(!showModal);
  };

  const handleAddStore = () => {
    setDisableSubmitButton(true);
    dispatch(createNewStore(formData))
      .then((action) => {
        if (action.payload) {
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("Store creation failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const resetInitialValue = () => {
    const initialValues = {
      name: "",
    };

    setFormData(initialValues);
  };

  // Reset Value on new form creation
  useEffect(() => {
    resetInitialValue();
  }, [showModal]);

  const handleAddStoreClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                <div className="mb-5">
                  <label htmlFor="expense_name" className="block mb-2 text-sm font-medium text-gray-900">
                    Store Name<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="expense_name"
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    value={formData.name}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter Store Name"
                    required
                  />
                </div>

                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <ButtonWrapper eventCallback={handleAddStoreClick}>Add Store</ButtonWrapper>
    </>
  );
}

export default AddStore;
