import axios from "axios";
import { apiUrl } from "../config/appConfig";
import { TimeStampPastDate } from "../utils/checkTimeStamp";
import { refreshTokenFn } from "../utils/session";
// import { debounce } from "lodash";

export const http = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "application/json",
  },
});

// Modified custom debounce to handle async nature of refreshTokenFnDebounced
function debounce(fn, delay) {
  let timeoutId;
  let lastArgs;
  return function (...args) {
    lastArgs = args;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    return new Promise((resolve) => {
      timeoutId = setTimeout(async () => {
        const result = await fn.apply(this, lastArgs);
        resolve(result);
      }, delay);
    });
  };
}

const refreshTokenFnDebounced = debounce(async () => {
  return await refreshTokenFn();
}, 1000);

// Request interceptor
http.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("token");
    const expireAt = localStorage.getItem("expireAt");

    // Check if the access token is expired
    if (TimeStampPastDate(expireAt)) {
      // Token is expired, refresh it
      token = await refreshTokenFnDebounced();

      if (token) {
        // Update the config headers with the new access token
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        // Handle the case when the token refresh failed
        return Promise.reject("Some Error Occurred");
      }
    } else {
      // Token is not expired, add it to the request headers
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
