import React, { useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";

const InvoiceGraph = ({ data }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className="text-center mt-[20px]"
        onClick={() => {
          setOpen(true);
        }}
      >
        Invoices Chart
        <LineChart
          sx={{ padding: "30px", paddingRight: "10px" }}
          height={510}
          series={[
            {
              data: data.month_data,
              label: "Invoices",
              showMark: ({ index }) => index % 1 !== 0,
              color: "#277494",
            },
          ]}
          xAxis={[{ scaleType: "point", data: data.x_labels }]}
        />
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "normal",
            fontSize: "16px",
            color: "gray",
          }}
        >
          Month-Wise Amount of Invoices Created
        </div>
      </div>
      <CustomModal
        isOpen={open}
        handleClose={() => {
          setOpen(false);
        }}
      >
        <LineChart
          width={580} // Fixed width for modal chart
          height={500} // Fixed height for modal chart
          sx={{ padding: "20px" }}
          series={[
            {
              data: data.month_data,
              label: "Invoices",
              showMark: ({ index }) => index % 1 !== 0,
              color: "#277494",
            },
          ]}
          xAxis={[{ scaleType: "point", data: data.x_labels }]}
        />
      </CustomModal>
    </>
  );
};

export default InvoiceGraph;
