import React, { useEffect, useState } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { createProduct, fetchHSNData } from "../../../reducers/Slices/productSlice";
import { Autocomplete, TextField } from "@mui/material";
import { debounce } from "lodash";

const AddProduct = ({ showModal, setShowModal }) => {
  const [addProductFormData, setAddProductFormData] = useState({
    product_name: "",
    brand: "",
    hsn_number: "",
    package: "",
    batches: [],
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    //Guard - not product_name & product provided
    if (
      !addProductFormData?.product_name ||
      !addProductFormData?.brand ||
      !addProductFormData?.package ||
      !addProductFormData?.hsn_number
    ) {
      alert("please fill all field values in form!");
      return;
    }

    //eslint-disable-next-line
    dispatch(createProduct(addProductFormData))
      .then((action) => {
        if (action.payload) {
          setAddProductFormData({});
          setShowModal(false);
        } else {
          alert("Sorry, product cannot be added");
        }
      })
      .catch((err) => {
        alert("Error Occured", err);
      });
  };

  const handleClose = () => {
    setAddProductFormData({});
    setShowModal(false);
  };

  //HSN codes
  const HSN_data = useSelector((state) => state.product.HSNData.data);

  const handleHSNNumberChange = (value) => {
    const nextAddProductFormData = { ...addProductFormData, hsn_number: value };
    setAddProductFormData(nextAddProductFormData);
  };

  const [hSNSearchTerm, sethSNSearchTerm] = useState("");

  useEffect(() => {
    if (hSNSearchTerm.length > 0) {
      dispatch(fetchHSNData(`?hsn_code=${hSNSearchTerm}`));
    }
  }, [hSNSearchTerm]);

  const setDebouncedHSNSearchTerm = debounce((value) => sethSNSearchTerm(value), 1000);

  const getHSNDataByHSNCode = (hsn_number) => {
    return HSN_data.find((HSNData) => HSNData?.hsn_code === hsn_number);
  };

  return (
    <CustomModal isOpen={showModal} handleClose={handleClose}>
      <div className="item-container flex flex-col gap-[20px]">
        <div className="item-description text-center">Product description</div>
        <div className="flex flex-col gap-[10px]">
          <label htmlFor="Product-name" className="text-neutral-500">
            Product Name*
          </label>
          <input
            type="text"
            id="Product-name"
            className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
            value={addProductFormData.product_name}
            onChange={(e) => {
              const nextAddProductFormData = { ...addProductFormData, product_name: e.target.value };
              setAddProductFormData(nextAddProductFormData);
            }}
            required
          />
        </div>
        <div className="flex flex-col gap-[10px]">
          <label htmlFor="brand-name" className="text-neutral-500">
            Brand Name*
          </label>
          <input
            type="text"
            id="brand-name"
            className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
            value={addProductFormData.brand}
            onChange={(e) => {
              const nextAddProductFormData = { ...addProductFormData, brand: e.target.value };
              setAddProductFormData(nextAddProductFormData);
            }}
            required
          />
        </div>
        <div className="flex flex-col gap-[10px]">
          <label htmlFor="hsn-number" className="text-neutral-500">
            HSN Number<span className="pl-1 text-red-500">*</span>
          </label>
          <Autocomplete
            disablePortal
            id="hsn-number"
            options={HSN_data || null}
            style={{ borderColor: "black" }}
            value={getHSNDataByHSNCode(addProductFormData?.hsn_number)}
            getOptionLabel={(option) => option?.hsn_code}
            onChange={(event, value) => {
              handleHSNNumberChange(value?.hsn_code);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    border: "0.5px solid #737373", // Black border
                    borderRadius: "0px", // No border radius
                  },
                }}
                onChange={(e) => setDebouncedHSNSearchTerm(e.target.value)}
              />
            )}
          />
        </div>
        {/* <div className="flex flex-col gap-[10px]">
             <label htmlFor="Product-type" className="text-neutral-500">
               Product Type*
             </label>
             <select
               id="product-type"
               // defaultValue="1"
               value={addProductFormData?.product_type}
               onChange={(e) => {
                 const nextAddProductFormData = { ...addProductFormData, product_type: parseInt(e.target.value) };
                 setAddProductFormData(nextAddProductFormData);
               }}
               className="border-[1px] border-neutral-500 px-[10px] py-[10px] bg-white"
               required
             >
               {product_types.map((item) => (
                 <option key={item.label} value={item.id}>
                   {item.label}
                 </option>
               ))}
             </select>
           </div> */}
        <div className="flex flex-col gap-[10px]">
          <label htmlFor="product-package" className="text-neutral-500">
            Product Packaging*
          </label>
          <input
            type="text"
            id="product-package"
            className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
            value={addProductFormData?.package}
            onChange={(e) => {
              const nextAddProductFormData = { ...addProductFormData, package: e.target.value };
              setAddProductFormData(nextAddProductFormData);
            }}
            required
          />
        </div>
        {/* <div>
             <label htmlFor="Quantity">Quantity</label>
             <input
               type="number"
               id="Quantity"
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               value={addProductFormData?.total_quantity}
               onChange={(e) => setAddProductFormData({ ...addProductFormData, total_quantity: parseInt(e.target.value) })}
             />
           </div>
           <div>
             <label htmlFor="Price">MRP Price</label>
             <input
               type="number"
               id="Price"
               value={addProductFormData?.mrp_price}
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               onChange={(e) => handleUpdatePrice(e, "mrp_price")}
             />
           </div>
           <div>
             <label htmlFor="sales_price">Retail Price (With Taxes)</label>
             <input
               type="number"
               id="Price"
               value={addProductFormData?.sales_price}
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               onChange={(e) => handleUpdatePrice(e, "sales_price")}
             />
           </div>
           <div>
             <label htmlFor="purchase_price">Purchase Price</label>
             <input
               type="number"
               id="Price"
               value={addProductFormData?.purchase_price}
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               onChange={(e) => setAddProductFormData({ ...addProductFormData, purchase_price: Number(e.target.value) })}
             />
           </div>
           <div>
             <label htmlFor="Tax">Tax</label>
             <input
               type="number"
               value={addProductFormData.tax}
               onChange={(e) => handleUpdatePrice(e, "tax")}
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               id="Tax"
             />
           </div>
           <div>
             <label htmlFor="Rate">Rate Price (Without Taxes)</label>
             <input
               type="number"
               value={addProductFormData.rate}
               disabled
               className="cursor-not-allowed border bg-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               id="rate"
             />
           </div>
           <div>
             <label htmlFor="BAR_code_number">Barcode Number</label>
             <input
               type="text"
               value={addProductFormData.BAR_code_number}
               onChange={(e) => setAddProductFormData({ ...addProductFormData, BAR_code_number: e.target.value })}
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               id="barcode"
             />
           </div>
           <div>
             <label htmlFor="batch_number">Batch Number</label>
             <input
               type="text"
               value={addProductFormData.batch_number}
               onChange={(e) => setAddProductFormData({ ...addProductFormData, batch_number: e.target.value })}
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               id="batch_number"
             />
           </div>
           <div>
             <label htmlFor="batch_number">Expiry Date</label>
             <input
               type="date"
               value={addProductFormData?.expiry_date?.slice(0, 10)}
               onChange={(e) => setAddProductFormData({ ...addProductFormData, expiry_date: e.target.value })}
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               id="batch_number"
             />
           </div>
           <div>
             <label htmlFor="hsn_number">HSN Number</label>
             <input
               type="text"
               value={addProductFormData.hsn_number}
               onChange={(e) => setAddProductFormData({ ...addProductFormData, hsn_number: e.target.value })}
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               id="batch_number"
             />
           </div>
           <div>
             <label htmlFor="Discount">Discount</label>
             <input
               type="number"
               value={addProductFormData.discount}
               onChange={(e) => setAddProductFormData({ ...addProductFormData, discount: parseInt(e.target.value) })}
               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               id="Discount"
             />
           </div> */}

        <div className="flex justify-center gap-[20px]">
          <button className="save bg-black px-[20px] py-[10px] text-white rounded-full" onClick={handleSubmit}>
            Save
          </button>
          <button className="cancel" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </CustomModal>
  );
};
export default AddProduct;
