import React, { useState, useRef } from "react";

const BarcodeInput = ({ barcodeNumber, setBarcodeNumber }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const barcodeInputRef = useRef(null);
  const timer = useRef(null);

  // Handle manually entering the barcode
  const handleInputChange = (e, isManual = false) => {
    if (isManual) {
      setBarcodeNumber(e.target.value);
    }

    if (isManual) {
      // Clear the previous timer if it's still running
      if (timer.current) {
        clearTimeout(timer.current);
      }

      // Set a new timer to update the state after a delay
      timer.current = setTimeout(() => {
        handleClosePopup();
        barcodeInputRef.current.blur();
      }, 500);
    }
  };

  // Function to generate a random 12-digit barcode number (UPC-A)
  const generateRandomBarcode = () => {
    const randomElevenDigits = Math.floor(Math.random() * 1e11)
      .toString()
      .padStart(11, "0");

    const checkDigit = calculateCheckDigit(randomElevenDigits);
    const randomBarcode = randomElevenDigits + checkDigit;
    setBarcodeNumber(randomBarcode);
  };

  // Function to calculate the check digit for UPC-A (12th digit)
  const calculateCheckDigit = (barcode) => {
    let sumOdd = 0;
    let sumEven = 0;

    for (let i = 0; i < barcode.length; i++) {
      if (i % 2 === 0) {
        sumOdd += parseInt(barcode[i], 10);
      } else {
        sumEven += parseInt(barcode[i], 10);
      }
    }

    const totalSum = sumOdd * 3 + sumEven;
    const checkDigit = (10 - (totalSum % 10)) % 10; // Mod 10 to ensure it's a single digit
    return checkDigit;
  };

  // Handle closing the popup and cleaning up the event listener
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  // Handle opening the popup and setting up the event listener
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    setBarcodeNumber("");
    barcodeInputRef.current.focus();
  };

  return (
    <div className="flex flex-col">
      {/* Barcode Input Field */}
      <label className="mb-2 font-medium text-neutral-500">Enter Barcode:</label>
      <input
        type="text"
        value={barcodeNumber}
        onChange={(e) => handleInputChange(e, true)}
        className="border border-black px-[20px] py-[10px] mb-2 focus:outline-none focus:ring-2"
        placeholder="Enter or scan barcode"
        ref={barcodeInputRef} // reference to the input field
      />

      {/* Small note for automatic barcode generation */}
      {/* <small className="text-gray-500">Note: Leave empty for automatic barcode generation during creation.</small> */}

      {/* Scan Barcode Button */}
      <div className="mt-2 w-full flex gap-[10px]">
        <button className="px-[20px] py-[10px] bg-black text-white rounded-lg w-full" onClick={() => handleOpenPopup()}>
          Scan Barcode
        </button>

        <button
          className="px-[20px] py-[10px] bg-black text-white rounded-lg w-full"
          onClick={() => generateRandomBarcode()}
        >
          Generate Barcode
        </button>
      </div>

      {/* Modal for Barcode Scanning */}
      {isPopupOpen && (
        <div className="absolute  left-[50%] -translate-x-[50%] flex items-center justify-center bg-opacity-75 z-50 border-2 border-neutral-200 rounded-md">
          <div className="bg-white rounded-lg p-6 w-96 flex flex-col justify-center items-center">
            <h2 className="text-lg font-semibold mb-2">Scan Barcode</h2>
            <p className="text-gray-600 mb-2">Scan barcode using your barcode scanner.</p>
            <button
              className="mt-4 px-4 py-2 border-2 text-red-500 border-red-600 font-semibold rounded-lg hover:bg-red-600 hover:text-white transition-all focus:outline-none w-full"
              onClick={handleClosePopup}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BarcodeInput;
