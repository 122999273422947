import React from "react";
import { useNavigate } from "react-router-dom";

function FORM_5() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center h-full overflow-y-scroll pt-[5vh]">
      <h1 className="font-bold text-2xl py-[20px] max-w-[60vw] text-center">
        5. Values of exempt, nil-rated and non-GST inward supplies
      </h1>

      <table>
        <thead>
          <tr className="bg-gray-50">
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs  text-neutral-500 font-bold uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Nature of Supplies{" "}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Inter-State Supplies (₹)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs text-neutral-500 font-bold  uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
            >
              Intra-State Supplies (₹)
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-semibold bg-white border-[1px] border-neutral-200">
              From a supplier under composition scheme, Exempt and Nil rated supply
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-black font-semibold bg-white border-[1px] border-neutral-200">
              Non GST supply
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center max-w-[300px] text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>
        </tbody>
      </table>

      <div className="flex gap-[10px] py-[30px]">
        <button className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full">Cancel</button>
        <button className={`px-[20px] py-[10px] text-white bg-stone-500 font-semibold rounded-full`} disabled>
          Confirm
        </button>
        <button
          className="px-[20px] py-[10px] text-white bg-black font-semibold rounded-full"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default FORM_5;
