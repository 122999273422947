// import { FilledInput, FormControl, InputAdornment } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBatchesByProductID,
  fetchProductByBarcode,
  fetchProductByID,
  fetchProducts,
  searchProducts,
} from "../../reducers/Slices/productSlice";
import { fetchCustomers, fetchMoreCustomers } from "../../reducers/Slices/customerSlice";
import { createInvoice } from "../../reducers/Slices/invoiceSlice";
import toast from "react-hot-toast";

//UI Components
import IconWrapper from "../MicroComponents/IconWrapper";
import { X as Cross } from "react-feather";
import { getOrderDetailsByID } from "../../reducers/Slices/onlineOrderSlice";
import BarcodeReader from "./InvoiceComponents/BarcodeReader";
import { debounce } from "lodash";
import CustomModal from "../SharedComponents/CustomModals/CustomModal";
import { changeDateFormat } from "../../utils/dateFormat";
import { ShipmentDispatchForm } from "./InvoiceComponents/ShipmentDispatchForm";

function CreateInvoice() {
  const [items, setItems] = useState([{}]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchCustomers());
  }, []);

  //Fetch both customer and vendor based on ID provided
  const customer = useSelector((state) => state.customer.customers.data.find((c) => c.id === parseInt(params.id)));
  const customersNext = useSelector((state) => state.customer.customers.next);
  // const vendor = useSelector((state) => state.vendor.vendors.data.find((v) => v.id === parseInt(params.id)));

  const business = useSelector((state) => state.user);

  const products = useSelector((state) => state.product.products.data);
  // const productsNext = useSelector((state) => state.product.products.next);

  //Dispatch and shipment details
  const [dispatchDetails, setDispatchDetails] = useState({});
  const [shipmentDetails, setShipmentDetails] = useState({});

  //Transporter Details
  const [transporterDetails, setTransporterDetails] = useState({
    transporter_id: null,
    transporter_name: "",
    vehicle_number: "",
  });

  const handleTransporterFieldChange = (value, field) => {
    setTransporterDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  //Side effect to set shipping and dispatch details based on customer and business data
  useEffect(() => {
    if (!customer || !business) {
      return;
    }

    const {
      business_name,
      address1: business_address1,
      address2: business_address2,
      city: business_city,
      zipcode: business_zipcode,
      state_code: business_state_code,
      country_code: business_country_code,
    } = business;
    const {
      customer_name,
      address: customer_address1,
      address2: customer_address2,
      zipcode: customer_zipcode,
      city: customer_city,
      state_code: customer_state_code,
    } = customer;
    // console.log(business, "this is the business data");
    setShipmentDetails(() => {
      return {
        shipment_name: customer_name,
        shipment_address1: customer_address1,
        shipment_address2: customer_address2 || "",
        shipment_city: customer_city,
        shipment_pin: customer_zipcode,
        shipment_state_code: customer_state_code || customer?.gst_number?.slice(0, 2),
      };
    });
    setDispatchDetails(() => {
      return {
        dispatch_name: business_name,
        dispatch_address1: business_address1,
        dispatch_address2: business_address2 || "",
        dispatch_city: business_city,
        dispatch_pin: business_zipcode,
        dispatch_state_code: business_state_code || business?.gst_number?.slice(0, 2),
        country_code: business_country_code || "IN",
      };
    });
  }, [customer, business]);

  //A relic from past currently depricated
  // const [userType, setUserType] = useState((type) => (parseInt(params.type) === 1 ? "vendor" : "customer"));

  //--- Order related operations and functionality ---
  const [orderId, setOrderId] = useState(() => parseInt(params.orderId) || 0);
  const orderDetails = useSelector((state) => state.onlineOrder.orderDetails);
  const [remainingProductQuantity, setRemainingProductQuantity] = useState([]);
  const [ordererdProductQuantity, setOrderedProductQuantity] = useState([]);

  useEffect(() => {
    //if order ID is zero that means it is simple invoice not order converted to invoice
    if (orderId === 0) {
      return;
    }
    dispatch(getOrderDetailsByID(orderId));
  }, [orderId]);

  useEffect(() => {
    const populateItemsBasedOnOrder = async () => {
      if (orderId === 0) {
        return;
      }

      if (!orderDetails && orderDetails?.length === 0) {
        return;
      }

      const orderedQuantity = [];

      setDiscountOnMRPValue((prev) => []);
      setCalculatedAmountValue((prev) => []);

      //Error boundry to handle when there is lack of object during initial loading.
      try {
        const productData = await Promise.all(
          orderDetails?.items?.map(async (item) => {
            orderedQuantity.push({ quantity: item.quantity, productId: item.product.id, batchId: null });
            const product = await findProductByProductIdAsyncAPI(item.product.id);
            setDiscountOnMRPValue((prev) => [...prev, {}]);
            setCalculatedAmountValue((prev) => [...prev, {}]);
            return { ...product, quantity: item.quantity };
          })
        );

        if (!productData) {
          return;
        }

        setOrderedProductQuantity(orderedQuantity);
        setItems(productData);
      } catch (err) {
        // console.log(err);
      }
    };

    populateItemsBasedOnOrder();
  }, [orderDetails]);

  const orderedProductQuantityOperation = (batch, product, index) => {
    const batchQuantity = batch?.remaining_quantity;

    //Check if invoices is ordered invoice and order details are available
    if (orderId === 0) {
      return;
    }

    if (!orderDetails && orderDetails?.length === 0) {
      return;
    }
    // Extra Item options - required
    const itemOptions = {
      discount: batch?.discount,
      batch_number: batch?.batch_number,
      batchId: batch?.id,
      hsnNumber: getHSNNumberByBatchId(batch?.id),
      sales_price: batch?.sales_price,
      mrp_price: batch?.mrp_price,
      tax: batch?.tax,
      purchase_price: batch?.purchase_price,
      margin: batch?.add_margin,
      remaining_quantity: batch?.remaining_quantity,
    };

    //Find product and associated ordered quantity
    const orderedQuantity = ordererdProductQuantity.find((item) => item.productId === product.id);

    let updatedItems = items.map((item, i) => {
      if (index === i) {
        return { ...item, ...itemOptions };
      }
      return item;
    });

    for (let i = 0; i < updatedItems.length; i++) {
      if (updatedItems[i].batchId) {
        handleDiscountOnMRPChange(null, i, false, {
          mrp: updatedItems[i]?.mrp_price,
          tax_percent: updatedItems[i]?.tax,
          sales_price: updatedItems[i]?.sales_price,
        });
      }
    }

    //Check if product already exists in remainging quantity array i.e it contains remaining data
    const remainingProductIndex = remainingProductQuantity.findIndex((item) => item.id === product.id);

    if (remainingProductIndex >= 0) {
      //Check if remaining quantity is satisfied
      if (remainingProductQuantity[remainingProductIndex].remaining_quantity <= 0) {
        return;
      }
      //If product exists then check if new max batch quantity is enough for product
      if (batchQuantity < remainingProductQuantity[remainingProductIndex].remaining_quantity) {
        toast.error("Selected batch dosen't have enough quantity to satisfy order!");
        updatedItems[index].quantity = batchQuantity;

        //Add new product into items array with remaining quantity
        const newProductWithRemainingQuantity = {
          ...product,
          quantity: remainingProductQuantity[remainingProductIndex].remaining_quantity - batchQuantity,
        };
        setDiscountOnMRPValue((prev) => [...prev, {}]);
        setCalculatedAmountValue((prev) => [...prev, {}]);
        updatedItems.splice(index + 1, 0, newProductWithRemainingQuantity);

        // update quantity in remaining quantity array
        setRemainingProductQuantity((prevItems) => {
          return prevItems.map((item) => {
            if (item.id === product.id) {
              return {
                ...item,
                remaining_quantity: remainingProductQuantity[remainingProductIndex].remaining_quantity - batchQuantity,
              };
            }
            return item;
          });
        });
      } else {
        updatedItems[index].quantity = remainingProductQuantity[remainingProductIndex].remaining_quantity;
        // update quantity in remaining quantity array
        setRemainingProductQuantity((prevItems) => {
          return prevItems.map((item) => {
            if (item.id === product.id) {
              return {
                ...item,
                remaining_quantity: 0,
              };
            }
            return item;
          });
        });
      }
      setItems(updatedItems);
      return;
    }

    //Add product to items array and track remaining quantity data
    if (batchQuantity < orderedQuantity.quantity) {
      toast.error("Selected batch dosen't have enough quantity to satisfy order!");
      //add maximum avaible quantity of batch inside product
      updatedItems[index].quantity = batchQuantity;
      //Add new product into items array with remaining quantity
      const newProductWithRemainingQuantity = { ...product, quantity: orderedQuantity.quantity - batchQuantity };
      setDiscountOnMRPValue((prev) => [...prev, {}]);
      setCalculatedAmountValue((prev) => [...prev, {}]);
      updatedItems.splice(index + 1, 0, newProductWithRemainingQuantity);

      //add this product into remaingProductQuantity array for tracking remaining quantity
      setRemainingProductQuantity((prevItems) => {
        return [
          ...prevItems,
          { id: newProductWithRemainingQuantity.id, remaining_quantity: newProductWithRemainingQuantity.quantity },
        ];
      });
    }

    setItems(updatedItems);
  };
  // --- Order Related Operations End ---

  const [discountOnMRPValue, setDiscountOnMRPValue] = useState([{}]);
  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);

  //userType with initializer function to calculate userType on render
  const [formData, setFormData] = useState(() => {
    return { customer: parseInt(params.id), payment_option: "cash", payment_type: "paid" };
  });

  //Fetch customers till end
  useEffect(() => {
    if (customersNext) {
      dispatch(fetchMoreCustomers(customersNext));
    }
  }, [customersNext, dispatch]);

  const navigate = useNavigate();

  //Payment Options
  const paymentOptions = [
    { label: "Cash", value: "cash" },
    // { label: "UPI", value: "upi" },
    { label: "Credit", value: "credit" },
  ];

  //Fetch all products
  useEffect(() => {
    if (dispatch) {
      dispatch(fetchProducts("?is_expiry=0"));
    }
  }, [dispatch]);

  //Fetch infinity products
  // useEffect(() => {
  //   if (productsNext) {
  //     dispatch(fetchMoreProducts(productsNext));
  //   }
  // }, [productsNext]);

  useEffect(() => {
    handleCalculate();
  }, [items, discountOnMRPValue, calculatedAmountValue]);

  //Side effect to reverse calculate rate based on discountOnMRP change
  useEffect(() => {
    // ~~ changes here
    //This side effect is working for both product based item and service based item
    const updatedItems = items.map((item, index) => {
      if (item?.is_service === "N") {
        return {
          ...item,
          sales_price: calculateNetRate(item?.mrp_price, discountOnMRPValue[index]?.discountOnMRP, item?.tax),
        };
      } else {
        return {
          ...item,
          rate: calculateNetRate(item?.mrp, discountOnMRPValue[index]?.discountOnMRP, item?.tax),
        };
      }
    });
    setItems(updatedItems);
  }, [discountOnMRPValue]);

  useEffect(() => {
    handleCalculateAmount(items);
  }, [items, discountOnMRPValue]);

  const handleChangeItem = (value, index) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item, i) => {
        if (i === index) {
          return value
            ? {
                ...value,
                price: value.sales_price,
                batches: value.batches,
                ...value.service,
                hsn_number: value.hsn_number,
              }
            : { price: 0.0, tax: 0.0 };
        }
        return item;
      });
      return updatedItems;
    });
  };

  //Profit Margin for user
  const [profitMargin, setProfitMargin] = useState(0);

  //Function to reverse calculate netRate based on new discountOnMRPercent
  const calculateNetRate = (mrp, discountOnMRPPercent, taxPercent) => {
    //calculate the rate after discount
    const discountedRate = parseFloat(mrp) - parseFloat(mrp) * ((parseFloat(discountOnMRPPercent) || 0) / 100);
    const netRate = discountedRate / (1 + (parseFloat(taxPercent) || 0) / 100);

    return netRate.toFixed(3).slice(0, -1);
  };

  const handleCalculate = () => {
    if (items?.length <= 0 || !items) {
      return;
    }

    const { total, discount, tax, profit } = items.reduce(
      (accumulator, currentItem, index) => {
        if (currentItem?.sales_price || currentItem?.sales_price == 0) {
          const quantity = parseInt(currentItem.quantity) || 0;
          let item_total = parseFloat(currentItem.sales_price || 0) * quantity;
          let item_mrp_total = parseFloat(currentItem.mrp_price || 0) * quantity;
          let item_discount = (parseFloat(discountOnMRPValue[index]?.discountOnMRP || 0) / 100) * item_mrp_total;
          let item_tax = item_total * (parseFloat(currentItem.tax || 0) / 100);
          let deal_quantity = parseInt(currentItem?.deal_quantity || 0);

          let item_profit =
            (calculateNetRate(currentItem?.mrp_price, discountOnMRPValue[index]?.discountOnMRP, currentItem?.tax) -
              (currentItem?.purchase_price ?? 0)) *
            (quantity - deal_quantity);

          accumulator.total += item_total;
          accumulator.discount += item_discount;
          accumulator.tax += item_tax;
          accumulator.profit += item_profit;
        }

        // ~~ changes here
        if (currentItem?.rate || currentItem?.rate == 0) {
          const quantity = 1;
          let item_total = parseFloat(currentItem.rate || 0) * quantity;
          let item_mrp_total = parseFloat(currentItem.mrp || 0) * quantity;
          let item_discount = (parseFloat(discountOnMRPValue[index]?.discountOnMRP || 0) / 100) * item_mrp_total;
          let item_tax = item_total * (parseFloat(currentItem.tax || 0) / 100);

          let item_profit =
            (calculateNetRate(currentItem?.mrp, discountOnMRPValue[index]?.discountOnMRP, currentItem?.tax) -
              (currentItem?.cost ?? 0)) *
            quantity;

          accumulator.total += item_total;
          accumulator.discount += item_discount;
          accumulator.tax += item_tax;
          accumulator.profit += item_profit;
        }
        return accumulator;
      },
      { total: 0, discount: 0, tax: 0, profit: 0 }
    );

    setProfitMargin(profit);

    //Changes here
    const productAndQuantity = [];
    const serviceAndQuantity = [];

    items.forEach((i, index) => {
      if (i?.is_service === "N") {
        productAndQuantity.push({
          productId: i.id,
          quantity: parseInt(i.quantity) || 0,
          deal_quantity: parseInt(i.deal_quantity) || 0,
          total_quantity: (parseInt(i.quantity) || 0) + (parseInt(i.deal_quantity) || 0),
          batchId: i.batchId,
          amount: calculatedAmountValue[index]?.calculated_amount,
          mrp_discount: discountOnMRPValue[index]?.discountOnMRP || 0,
          discount: parseFloat(discountOnMRPValue[index]?.discountOnMRP / 100) * i.mrp_price * i.quantity,
          assessable_amount: parseFloat(i?.sales_price) * parseInt(i.quantity),
          tax: ((parseFloat(i?.sales_price) * parseFloat(i.tax)) / 100) * parseInt(i.quantity),
        });
      } else {
        serviceAndQuantity.push({
          productId: i.id,
          quantity: 1,
          amount: calculatedAmountValue[index]?.calculated_amount,
          mrp_discount: discountOnMRPValue[index]?.discountOnMRP || 0,
          discount: parseFloat(discountOnMRPValue[index]?.discountOnMRP / 100) * i.mrp * 1,
          assessable_amount: parseFloat(i?.rate) * 1,
          other_charges: 0,
          tax: ((parseFloat(i?.rate) * parseFloat(i.tax)) / 100) * 1,
        });
      }
    });

    setFormData({
      ...formData,
      sub_total: (total + discount).toFixed(2),
      discount: discount.toFixed(2),
      grand_total: calculatedAmountValue.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.calculated_amount);
      }, 0),
      tax: tax.toFixed(3),
      paid_amount: calculatedAmountValue.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject?.calculated_amount);
      }, 0),
      payment_type: formData.payment_type,
      remaining_total: 0.0,
      product_and_quantity: productAndQuantity,
      service_and_quantity: serviceAndQuantity,
    });
  };

  const handleFieldChange = (value, index, field) => {
    setItems((prevItems) => {
      return prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }; // Update the specified field of the item
        }
        return item;
      });
    });
  };

  //Calculate discount on MRP
  const handleDiscountOnMRPChange = (value, index, isDiscount, data) => {
    // ~~change here - this comment
    //Same function working for both product and service based invoices
    //If user backspaces all value then make field empty.
    if (!value) {
      setDiscountOnMRPValue((prevItems) => {
        return prevItems.map((item, i) => {
          if (i === index) {
            return { discountOnMRP: nextDiscountOnMRP }; // Update the specified field of the item
          }
          return item;
        });
      });
    }

    let nextDiscountOnMRP;

    //Calculate Discount On MRP if DiscountMRP is not directly given
    if (!isDiscount) {
      const nextValue = calculateDiscountOnMRP(data);
      nextDiscountOnMRP = nextValue ? nextValue : null;
    } else {
      nextDiscountOnMRP = value;
    }

    //Guard to check if nextDiscountOnMRP is not null
    if (!nextDiscountOnMRP) {
      return 0;
    }

    setDiscountOnMRPValue((prevItems) => {
      return prevItems.map((item, i) => {
        if (i === index) {
          return { discountOnMRP: nextDiscountOnMRP }; // Update the specified field of the item
        }
        return item;
      });
    });
  };

  //Function to calculate discount on mrp based on mrp and tax percent
  const calculateDiscountOnMRP = (data) => {
    // ~~change here - this comment
    //Same function working for both product and service based invoices
    if (!data?.tax_percent || !data?.mrp || !data?.sales_price) {
      return null;
    }
    // Convert tax percent to decimal
    const taxDecimal = parseFloat(data?.tax_percent ?? 0) / 100;

    const taxedRate = parseFloat(data?.sales_price ?? 0) + parseFloat(data?.sales_price ?? 0) * taxDecimal;

    // Calculate the discount on MRP
    const discountOnMRPPercent = (parseFloat(data?.mrp ?? 0) - taxedRate) / parseFloat(data?.mrp ?? 0);

    const discountOnMRP = parseFloat((discountOnMRPPercent * 100).toFixed(3));
    return discountOnMRP;
  };

  const handleRemoveItem = (index) => {
    if (items.length > 1) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }

    if (discountOnMRPValue.length > 1) {
      setDiscountOnMRPValue((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }

    if (calculatedAmountValue.length > 1) {
      setCalculatedAmountValue((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }
  };

  const handleCalculateAmount = (items) => {
    if (!items) {
      return;
    }

    if (items?.length <= 0) {
      return;
    }

    if (!items[0]?.id) {
      return;
    }

    setCalculatedAmountValue((prevValue) => {
      const updatedCalculatedAmountValue = prevValue?.map((_, index) => {
        if (_?.checkedByUser === true) {
          return _;
        }

        // ~~ changes here
        let mrp_price, sales_price, quantity, tax;

        // ~~ changes here
        //Assign values based on nature of product i.e service or goods
        if (items[index]?.is_service === "Y") {
          mrp_price = items[index]?.mrp;
          sales_price = items[index]?.rate;
          quantity = 1;
          tax = items[index]?.tax;
        } else {
          mrp_price = items[index]?.mrp_price;
          sales_price = items[index]?.sales_price;
          quantity = items[index]?.quantity;
          tax = items[index]?.tax;
        }

        // const discountOnMRPPresent = parseFloat(discountOnMRPValue[index]?.discountOnMRP ?? 0);

        if (!sales_price || !quantity || !tax) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        const parsedQuantity = parseInt(quantity);
        // const parsedTax = parseFloat(tax) / 100;

        if (sales_price === 0 || parsedQuantity === 0) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        // let amount = (parseFloat(mrp_price) - parseFloat(mrp_price) * (discountOnMRPPresent / 100)) * parsedQuantity;
        let amount = (parseFloat(sales_price) + (parseFloat(sales_price) * parseFloat(tax)) / 100) * parsedQuantity;

        //Going the extra mile because toFixed(2) is rounding numbers resulting in wrong value.
        const truncatedAmount = (Math.floor(amount * 1000) / 1000).toFixed(3).slice(0, -1);

        return { calculated_amount: truncatedAmount, checkedByUser: false };
      });
      return updatedCalculatedAmountValue;
    });
  };

  //Change amount value when user manually changes it based on index
  const handleAmountValueChange = (value, index) => {
    setCalculatedAmountValue((prevItems) => {
      const updatedCalculatedAmount = prevItems.map((item, i) => {
        if (index === i) {
          return { calculated_amount: value, checkedByUser: true };
        }
        return item;
      });
      return updatedCalculatedAmount;
    });
  };

  //Function to calculate total quantities
  function getProductQuantities(data) {
    const productAndQuantity = data.product_and_quantity;

    // Create a map to store the total quantities by productId
    const quantityMap = productAndQuantity.reduce((acc, item) => {
      // If the productId is already in the map, add the current quantity to the total
      if (acc[item.productId]) {
        acc[item.productId] += item.quantity;
      } else {
        // Otherwise, set the initial quantity
        acc[item.productId] = item.quantity;
      }
      return acc;
    }, {});

    // Convert the map to an array of objects with productId and totalQuantity
    return Object.keys(quantityMap).map((productId) => ({
      productId: parseInt(productId, 10),
      totalQuantity: quantityMap[productId],
    }));
  }

  const handleSubmit = async () => {
    let updatedFormData = formData;
    setDisableSubmitButton(true);
    //Check to see both quantity and deal quantity is not zero per product
    const hasInvalidProduct = formData.product_and_quantity.some(
      (item) => item.quantity === 0 && item.deal_quantity === 0
    );

    if (orderId !== 0) {
      updatedFormData = { ...formData, order_id: orderId };
    }

    if (hasInvalidProduct) {
      toast.error("both quantity or deal quantity cannot be 0 in product");
      setDisableSubmitButton(false);
      return;
    }

    const productAndQuantity = updatedFormData.product_and_quantity;

    const hasDuplicateProducts = productAndQuantity.some((item, index) => {
      const duplicates = productAndQuantity.filter((otherItem, otherIndex) => {
        return otherIndex !== index && item.productId === otherItem.productId && item.batchId === otherItem.batchId;
      });

      return duplicates.length >= 1;
    });

    if (hasDuplicateProducts) {
      toast.error("There are two or more products with the same productId and batchId.");
      setDisableSubmitButton(false);
      return;
    }

    if (orderId !== 0) {
      const productQuantities = getProductQuantities(updatedFormData);
      let matchProductStatusMet = true;

      ordererdProductQuantity.forEach((orderedProduct) => {
        const matchedProduct = productQuantities.find((product) => product.productId === orderedProduct.productId);

        if (matchedProduct) {
          if (matchedProduct.totalQuantity > orderedProduct.quantity) {
            toast.error(`Ordered product has more quantity than ordered.`);
            matchProductStatusMet = false;
            setDisableSubmitButton(false);
          } else if (matchedProduct.totalQuantity < orderedProduct.quantity) {
            toast.error(`Ordered product has less quantity than ordered.`);
            matchProductStatusMet = false;
            setDisableSubmitButton(false);
          }
        } else {
          toast.error(`Ordered product is not found in the product quantities.`);
          matchProductStatusMet = false;
          setDisableSubmitButton(false);
        }
      });

      if (!matchProductStatusMet) {
        return;
      }
    }

    //check that neither dispatch or shipping is empty
    // --- Dispatch and Shipping entries check start
    const keysWithEmptyValuesDispatch = Object.keys(dispatchDetails).filter((key) => {
      const value = dispatchDetails[key];
      if (key == "dispatch_address2") {
        return false;
      }
      return value === null || value === undefined || value === "";
    });

    const keysWithEmptyValuesShipping = Object.keys(shipmentDetails).filter((key) => {
      const value = shipmentDetails[key];
      if (key == "shipment_address2") {
        return false;
      }
      return value === null || value === undefined || value === "";
    });

    if (keysWithEmptyValuesDispatch?.length > 0) {
      toast.error(`${keysWithEmptyValuesDispatch.join(", ")} cannot be empty!`);

      setDisableSubmitButton(false);
      return;
    }

    if (keysWithEmptyValuesShipping?.length > 0) {
      toast.error(`${keysWithEmptyValuesShipping.join(", ")} cannot be empty!`);

      setDisableSubmitButton(false);
      return;
    }

    //check to check if user is urp or not and if he/she is urp then switch to B2C or else switch to B2B,
    // also if customer is URP then check if he / she is making invoice greater than 2.5lakh for further B2CS and B3CL check.
    let supplyType = "B2CS";

    if (customer?.gst_number?.length > 0 && business?.gst_number?.length > 0) {
      supplyType = "B2B";
    } else {
      if (updatedFormData.grand_total > 250000) {
        supplyType = "B2CL";
      } else {
        supplyType = "B2CS";
      }
    }

    //Extra details required to make e-invocie work
    const extraDetails = {
      ...dispatchDetails,
      ...shipmentDetails,
      ...transporterDetails,
      is_einvoice: false,
      gst_scheme: "gst",
      supply_type: supplyType,
      ecmTrn: "N",
      documentType: "Inv",
      reverse_charge: 0,
      igst_on_intra: 0,
      tcs_charge_amount: 0,
      tcs_ledger: "",
      cess_value: 0,
      state_cess_value: 0,
      round_off_amount: 0,
    };

    if (dispatchDetails && shipmentDetails) {
      updatedFormData = {
        ...formData,
        ...extraDetails,
      };
    }

    if (items[0]?.id) {
      await dispatch(createInvoice(updatedFormData))
        .then((action) => {
          if (action.payload) {
            navigate(-1);
            setDisableSubmitButton(false);
          } else {
            toast.error("sorry, Invoice cannot be created!");
            setDisableSubmitButton(false);
          }
        })
        .catch((err) => {
          toast.error("error", err);
          setDisableSubmitButton(false);
        });
    } else {
      toast.error("Add more products");
      setDisableSubmitButton(false);
    }
  };

  //Find HSN number based on batch selected
  const getHSNNumberByBatchId = (batchId) => {
    const product = products.find((p) => p.batches.some((batch) => batch.id === batchId));
    if (product) {
      const batch = product.batches.find((batch) => batch.id === batchId);
      return batch ? batch.hsn_number : null;
    }
    return null;
  };

  //Find product by product id for autocomplete
  const findProductByProductID = (id) => {
    if (!id) {
      return;
    }
    return products.find((product) => product.id === id);
  };

  const findProductByProductIdAsyncAPI = async (id) => {
    if (!id) {
      return;
    }

    let product = products?.find((product) => product.id === id);

    if (!product) {
      const response = await dispatch(fetchProductByID(id));
      if (response?.payload) {
        product = response.payload;
      }
    }

    return product;
  };

  //Move to next field on enter button
  const inputRefs = useRef([]);
  const submitButton = useRef(null);
  const addAnotherItemRef = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items?.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        if (focusedElement === submitButton.current) {
          submitButton.current.click();
          return;
        }
        if (focusedElement === addAnotherItemRef.current) {
          addAnotherItemRef.current.click();

          setTimeout(() => {
            const lastRowIndex = inputRefs.current.length - 1;
            // After clicking, shift focus to the first element of the last row
            if (inputRefs.current[lastRowIndex] && inputRefs.current[lastRowIndex][0]) {
              inputRefs.current[lastRowIndex][0].querySelector("input").focus();
            }
          }, 300); // Adding a 300ms delay before shifting focus
          return;
        }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement =
              inputRefs.current[i][j]?.querySelector("input") || inputRefs.current[i][j]?.querySelector("button");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row

              if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
                if (window.confirm("want to add another item?")) {
                  addAnotherItemRef.current.focus(); // Focus on add another item button
                } else {
                  submitButton.current.focus(); //Focus on submit button
                }
              }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [items]);

  //Barcode related operations
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [capturedProduct, setCapturedProduct] = useState({});

  /*Just here as workaround due to react's nature of not re-updating state if similar data is present i.e user scanning barcode of one product twice to change quantity. */
  const [scanTimestamp, setScanTimestamp] = useState(Date.now());

  useEffect(() => {
    if (scannedBarcode?.length > 0) {
      dispatch(fetchProductByBarcode(scannedBarcode))
        .then((action) => {
          if (action) {
            setCapturedProduct(action.payload);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong! Product dosen't exist or there are two products with same barcode!");
        });
    }
    //eslint-disable-next-line
  }, [scannedBarcode, scanTimestamp]);

  const handleAddCapturedProductsInItems = (product) => {
    if (!product?.id) {
      return;
    }

    //Get batches from inventory
    const inventoryBatchesData = products.find((productInventory) => productInventory?.id === product?.id)?.batches;

    const productWithBatches = inventoryBatchesData
      ? { ...product, batches: inventoryBatchesData }
      : { ...product, batches: [] };

    // const ItemAlreadyPresent = items.findIndex((item) => item?.id === product?.id);
    const BatchAlreadyPresent = items.findIndex((item) => item?.batchId === product?.batchId);

    let updatedItems = items;

    //if it is present and has batch add quantity, otherwise add new product.
    if (BatchAlreadyPresent >= 0 && updatedItems[BatchAlreadyPresent].is_service === "N") {
      if (!updatedItems[BatchAlreadyPresent]?.quantity) {
        //Add quantity variable if not already present
        updatedItems[BatchAlreadyPresent] = {
          ...updatedItems[BatchAlreadyPresent],
          quantity: 0,
        };
      }
      const previousQuantity = parseInt(updatedItems[BatchAlreadyPresent].quantity);
      updatedItems[BatchAlreadyPresent].quantity = previousQuantity + 1;
    } else {
      if (!items[0]?.id) {
        updatedItems = [productWithBatches];
      } else {
        updatedItems = [...items, productWithBatches];
        setDiscountOnMRPValue((prev) => [...prev, {}]);
        setCalculatedAmountValue((prev) => [...prev, {}]);
      }
    }

    updatedItems.forEach((value, index) => {
      if (value?.is_service === "Y") {
        handleDiscountOnMRPChange(null, index, false, {
          mrp: value?.mrp,
          tax_percent: value?.tax,
          sales_price: value?.rate,
        });
      } else {
        handleDiscountOnMRPChange(null, index, false, {
          mrp: value?.batch?.mrp_price,
          tax_percent: value?.batch?.tax,
          sales_price: value?.batch?.sales_price,
        });
      }
    });

    setItems(updatedItems);
  };

  //Side effect to rearrange data of captured product;
  useEffect(() => {
    //If product dosen't exist then return
    if (!capturedProduct?.id) {
      return;
    }

    //If batch data exists then extract id because id field is already taken in product
    const batchData = capturedProduct?.batch;
    // const batchId = capturedProduct?.batch?.id;

    //Was causing weird bugs in react, so followed a different approach
    // if (batchData) {
    // delete batchData?.id;
    // delete capturedProduct?.batch;
    // }

    let rearrangedProductData = {};

    if (batchData) {
      rearrangedProductData = {
        ...capturedProduct,
        quantity: 1,
        ...batchData,
        batchId: batchData.id,
        id: capturedProduct.id,
      };
    } else {
      rearrangedProductData = { ...capturedProduct, quantity: 1, ...capturedProduct.service };
    }

    handleAddCapturedProductsInItems(rearrangedProductData);
  }, [capturedProduct]);

  //batch for autocomplete - pass batch if you have batch or null
  const handleBatchForAutoComplete = (product) => {
    const productBatches = getBatchesByProductId(product?.id);
    const specificBatch = productBatches?.find((batch) => batch?.id === product?.batchId);
    if (specificBatch) {
      return specificBatch;
    } else {
      return null;
    }
  };

  //Handle Product Search in Autocomplete

  //Searched products
  // const searchedProducts = useSelector((state) => state.product.searchedProducts.data);

  //Debounced search term state to call searched product API
  const [productSearchTerm, setProductSearchTerm] = useState("");

  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 1000);

  useEffect(() => {
    if (productSearchTerm.length > 0) {
      dispatch(searchProducts(`?product_search=${productSearchTerm}&is_expiry=0`));
    }
  }, [productSearchTerm]);

  //Debounced batch search term state to call batches search API
  const [batchSearchTerm, setBatchSearchTerm] = useState("");
  const [productIdForSearchedBatch, setProductIdForSearchedBatch] = useState(null);

  const handleBatchSearch = (productId, value) => {
    setDebouncedBatchSearchTerm(value);
    setProductIdForSearchedBatch(productId);
  };

  const setDebouncedBatchSearchTerm = debounce((value) => setBatchSearchTerm(value), 1000);

  useEffect(() => {
    if (!productIdForSearchedBatch) {
      return;
    }

    if (!batchSearchTerm) {
      return;
    }

    dispatch(
      fetchBatchesByProductID({
        productId: productIdForSearchedBatch,
        filters: `?batch_search=${batchSearchTerm}&is_expiry=0`,
      })
    );
  }, [batchSearchTerm]);

  const getBatchesByProductId = (id) => {
    const product = products?.find((product) => product?.id === id);
    if (product) {
      return product?.batches;
    }
  };

  //Batch Popup related operations:
  const [showBatchModal, setShowBatchModal] = useState(false);
  const [modalBatchIndex, setModalBatchIndex] = useState(null);

  const handleCloseModal = () => {
    setShowBatchModal(false);
  };

  const batchNumberRef = useRef(null);
  const productRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (productRef.current) {
        productRef.current.focus();
      }
    }, 500);
  }, []);

  const handleBatchPopup = (index) => {
    setShowBatchModal(true);
    setModalBatchIndex(index);

    //focus on batch number field after delay for it to open
    setTimeout(() => {
      if (batchNumberRef.current) {
        batchNumberRef.current.focus();
      }
    }, 100);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && document.activeElement === batchNumberRef.current) {
        event.preventDefault();
        setShowBatchModal(false);
        setTimeout(() => {
          if (productRef.current) {
            productRef.current.focus();
            const enterKeyEvent = new KeyboardEvent("keydown", {
              key: "Enter",
              code: "Enter",
              keyCode: 13, // Legacy support for older browsers
              charCode: 13, // Legacy support for older browsers
              which: 13, // Legacy support for older browsers
              bubbles: true, // So it can propagate in the DOM
            });
            productRef.current.dispatchEvent(enterKeyEvent);
          }
        }, 200);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      <CustomModal isOpen={showBatchModal} handleClose={handleCloseModal}>
        <form className="flex flex-col items-center">
          <h3 className="text-2xl text-neutral-500">Batch Details</h3>
          <div className="w-full pb-[20px] flex flex-col">
            <label htmlFor="batch_number" className="pb-[10px] text-neutral-500">
              Batch Number:
            </label>
            <Autocomplete
              disablePortal
              id="batch-number"
              value={handleBatchForAutoComplete(items[modalBatchIndex])}
              //due to nested objects fields react is not rendering batches correctly, so this is an workaround function
              options={getBatchesByProductId(items[modalBatchIndex]?.id) || []}
              getOptionLabel={(option) =>
                option?.batch_number
                  ? `${option?.batch_number} - Qty: ${option?.remaining_quantity} - Exp: ${
                      option?.expiry_date ? changeDateFormat(option?.expiry_date) : "NIL"
                    }`
                  : `${option?.batch_number}`
              }
              onChange={(event, value) => {
                handleFieldChange(value?.batch_number, modalBatchIndex, "batch_number");
                handleFieldChange(value?.id, modalBatchIndex, "batchId"); // Store batch ID
                handleFieldChange(getHSNNumberByBatchId(value?.id), modalBatchIndex, "hsnNumber");
                handleFieldChange(value?.discount, modalBatchIndex, "discount");
                //Value - for direct discountOnMRP, Index, isDiscount, data, checkedByUser
                handleDiscountOnMRPChange(null, modalBatchIndex, false, {
                  mrp: value?.mrp_price,
                  tax_percent: value?.tax,
                  sales_price: value?.sales_price,
                });
                handleFieldChange(value?.sales_price, modalBatchIndex, "sales_price");
                handleFieldChange(value?.mrp_price, modalBatchIndex, "mrp_price");
                handleFieldChange(value?.tax, modalBatchIndex, "tax");
                handleFieldChange(value?.purchase_price, modalBatchIndex, "purchase_price");
                handleFieldChange(value?.add_margin, modalBatchIndex, "margin");
                handleFieldChange(value?.expiry_date, modalBatchIndex, "expiry_date");
                handleFieldChange(value?.remaining_quantity, modalBatchIndex, "remaining_quantity");
                orderedProductQuantityOperation(value, items[modalBatchIndex], modalBatchIndex);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={batchNumberRef}
                  onChange={(e) => handleBatchSearch(items[modalBatchIndex]?.id, e.target.value)}
                />
              )}
            />
          </div>

          <div className="w-full flex flex-col">
            <label htmlFor="batch_quantity" className="pb-[10px] text-neutral-500">
              Quantity in Batch:
            </label>
            <TextField
              id="batch_quantity"
              inputProps={{
                style: {
                  padding: "16.5px",
                  paddingLeft: "10px",
                },
              }}
              disabled
              value={items[modalBatchIndex]?.batch_number ? items[modalBatchIndex]?.remaining_quantity : null}
              defaultValue={""}
              type="text"
              variant="outlined"
            />
          </div>

          <div className="w-full py-[20px] flex flex-col">
            <label htmlFor="batch_quantity" className="pb-[10px] text-neutral-500">
              Rate:
            </label>
            <TextField
              id="batch_quantity"
              inputProps={{
                style: {
                  padding: "16.5px",
                  paddingLeft: "10px",
                },
              }}
              disabled
              value={items[modalBatchIndex]?.batch_number ? items[modalBatchIndex]?.sales_price : null}
              defaultValue={""}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="w-full mb-[20px] flex flex-col">
            <label htmlFor="batch_quantity" className="pb-[10px] text-neutral-500">
              Expiry Date:
            </label>
            <TextField
              id="batch_quantity"
              inputProps={{
                style: {
                  padding: "16.5px",
                  paddingLeft: "10px",
                },
              }}
              disabled
              value={items[modalBatchIndex]?.expiry_date ? changeDateFormat(items[modalBatchIndex]?.expiry_date) : null}
              defaultValue={""}
              type="date"
              variant="outlined"
            />
          </div>

          <button
            onClick={(e) => {
              e.preventDefault();
              handleCloseModal();
            }}
            className="px-[20px] py-[10px] text-black 0 border border-black rounded-full w-fit"
          >
            Proceed
          </button>
        </form>
      </CustomModal>
      <div>
        <BarcodeReader barcode={scannedBarcode} setBarcode={setScannedBarcode} setScanTimestamp={setScanTimestamp} />
        <div className="mx-[20px] my-[20px]">
          <h1 className="text-2xl font-bold"> New Invoice </h1>
          <div className="mt-8 w-full flex md:justify-start">
            <div className="md:w-3/6 xl:w-full">
              <h3 className="font-semibold text-xl text-black">{customer?.customer_name}</h3>
              <span className="font-semibold text-neutral-500">
                {customer?.address},{customer?.zipcode}
              </span>

              <div className="flex text-base gap-2 my-5">
                <h3 className="font-semibold text-black-500">GST Number: </h3>
                <span className="font-semibold text-neutral-500">
                  {customer?.gst_number ? customer?.gst_number?.toUpperCase() : "Not Provided"}
                </span>
              </div>

              {/* Tax Scheme, Supply Type and Ecom Transaction */}
              {/* <div className="flex gap-[20px] pb-[20px]"> */}
              {/* Tax Scheme Dropdown */}
              {/* <div className="flex gap-[10px] border-r-2 border-neutral-200 pr-[20px] items-center">
                  <label htmlFor="tax_scheme" className="font-semibold">
                    Tax Scheme:
                  </label>
                  <select
                    name="tax_scheme"
                    value={taxScheme}
                    onChange={(e) => setTaxScheme(e.target.value)}
                    className="px-[20px] py-[10px] bg-white border border-neutral-200 rounded-full"
                  >
                    <option value="GST">GST</option>
                  </select>
                </div> */}

              {/* Supply Type Dropdown */}
              {/* <div className="flex gap-[10px] border-r-2 border-neutral-200 pr-[20px] items-center">
                  <label htmlFor="supply_type" className="font-semibold">
                    supplyType:
                  </label>
                  <select
                    name="supply_type"
                    value={supplyType}
                    // onChange={(e) => setSupplyType(e.target.value)} */}
              {/* className="px-[20px] py-[10px] bg-white border border-neutral-200 rounded-full"
                  >
                    <option value="B2B">B2B</option>
                    <option value="SEZWP">SEZWP</option>
                    <option value="SEZWOP">SEZWOP</option>
                    <option value="EXPWP">EXPWP</option>
                    <option value="EXPWOP">EXPWOP</option>
                    <option value="DEXP">DEXP</option>
                  </select>
                </div> */}

              {/* ECM TRN Dropdown */}
              {/* <div className="flex gap-[10px] border-r-2 border-neutral-200 pr-[20px] items-center">
                  <label htmlFor="ecm_trn" className="font-semibold">
                    Ecommerce Transaction:
                  </label>
                  <select
                    name="ecm_trn"
                    value={ecmTrn}
                    onChange={(e) => setEcmTrn(e.target.value)}
                    className="px-[20px] py-[10px] bg-white border border-neutral-200 rounded-full"
                  >
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </div> */}

              {/* Document Type Dropdown */}
              {/* <div className="flex gap-[10px] pr-[20px] items-center">
                  <label htmlFor="document_type" className="font-semibold">
                    Document Type
                  </label>
                  <select
                    name="document_type"
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                    className="px-[20px] py-[10px] bg-white border border-neutral-200 rounded-full"
                  >
                    <option value="INV">INV</option> */}
              {/* <option value="CRN">CRN</option>
                    <option value="DBN">DBN</option> */}
              {/* </select>
                </div> */}

              {/* IGST on Intra Selection */}
              {/* <div className="flex gap-[10px] pr-[20px] items-center">
                  <label htmlFor="igstOnIntra" className="font-semibold">
                    IGST on Intra
                  </label>
                  <select
                    name="igstOnIntra"
                    value={igstOnIntra}
                    onChange={(e) => setIgstOnIntra(parseInt(e.target.value))}
                    className="px-[20px] py-[10px] bg-white border border-neutral-200 rounded-full"
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div> */}
              {/* </div> */}
              <ShipmentDispatchForm
                dispatch={dispatchDetails}
                setDispatch={setDispatchDetails}
                shipment={shipmentDetails}
                setShipment={setShipmentDetails}
                customer={customer}
                business={business}
              />

              <div className="flex border-b-2 pt-[5px] pb-[15px]">
                <div className="pr-[10px] border-r-2 border-neutral-200">
                  <label className="mr-[5px] w-32 text-nowrap font-semibold">Transporter ID:</label>
                  <input
                    type="text"
                    value={transporterDetails.transporter_id || ""}
                    onChange={(e) => handleTransporterFieldChange(e.target.value, "transporter_id")}
                    className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="px-[10px] border-r-2 border-neutral-200">
                  <label className="mr-[5px] w-32 text-nowrap font-semibold">Transporter Name:</label>
                  <input
                    type="text"
                    value={transporterDetails.transporter_name}
                    onChange={(e) => handleTransporterFieldChange(e.target.value, "transporter_name")}
                    className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div className="px-[10px]">
                  <label className="mr-[5px] w-32 text-nowrap font-semibold">Vehicle Number:</label>
                  <input
                    type="text"
                    value={transporterDetails.vehicle_number}
                    onChange={(e) => handleTransporterFieldChange(e.target.value, "vehicle_number")}
                    className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-[20px] overflow-y-scroll">
              <table cellPadding={5} cellSpacing={10}>
                <tr>
                  <th>
                    Items Details<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>
                    Batch Number<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>
                    HSN Number<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>
                    Quantity<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>Deal / Free</th>
                  <th>
                    Retail <br />
                    Without Tax<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>
                    Tax %<span className="pl-1 text-red-500">*</span>
                  </th>
                  <th>Discount on MRP %</th>
                  <th>
                    Amount<span className="pl-1 text-red-500">*</span>
                  </th>
                </tr>
                {items?.map((item, index) => (
                  <tr key={item?.id || index} className="mb-4">
                    <td>
                      <Autocomplete
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                          }
                        }}
                        disablePortal
                        id="combo-box-demo"
                        options={products || []}
                        value={findProductByProductID(item?.id)}
                        getOptionLabel={(option) =>
                          option?.total_remaining_quantity
                            ? `${option?.product_name} - Qty: ${option?.total_remaining_quantity}`
                            : `${option?.product_name}`
                        }
                        onChange={(event, value) => {
                          handleChangeItem(value, index);
                          // ~~ change here
                          value?.is_service === "N" && handleBatchPopup(index);
                          if (value?.is_service === "Y") {
                            handleDiscountOnMRPChange(null, index, false, {
                              mrp: value?.service?.mrp,
                              tax_percent: value?.service?.tax,
                              sales_price: value?.service?.rate,
                            });
                          }
                        }}
                        sx={{ width: 350 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Products"
                            inputRef={productRef}
                            onChange={(e) => setDebouncedProductSearchTerm(e.target.value)}
                          />
                        )}
                      />
                    </td>
                    {/* ~~ change here */}
                    {/* {items[index].is_service === "N" ? (
                    <td>
                    <Autocomplete
                    ref={(el) => {
                      if (el && inputRefs.current[index]) {
                        inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                        }
                        }}
                        disablePortal
                        id="batch-number"
                        value={handleBatchForAutoComplete(items[index])}
                        //due to nested objects fields react is not rendering batches correctly, so this is an workaround function
                        options={getBatchesByProductId(items[index]?.id) || []}
                        getOptionLabel={(option) =>
                          option?.batch_number
                          ? `${option?.batch_number} - Qty: ${option?.remaining_quantity}`
                          : `${option?.batch_number}`
                          }
                          onChange={(event, value) => {
                            handleFieldChange(value?.batch_number, index, "batch_number");
                            handleFieldChange(value?.id, index, "batchId"); // Store batch ID
                            handleFieldChange(getHSNNumberByBatchId(value?.id), index, "hsnNumber");
                            handleFieldChange(value?.discount, index, "discount");
                            //Value - for direct discountOnMRP, Index, isDiscount, data, checkedByUser
                            handleDiscountOnMRPChange(null, index, false, {
                              mrp: value?.mrp_price,
                              tax_percent: value?.tax,
                              sales_price: value?.sales_price,
                              });
                              handleFieldChange(value?.sales_price, index, "sales_price");
                              handleFieldChange(value?.mrp_price, index, "mrp_price");
                              handleFieldChange(value?.tax, index, "tax");
                              handleFieldChange(value?.purchase_price, index, "purchase_price");
                              handleFieldChange(value?.add_margin, index, "margin");
                              handleFieldChange(value?.remaining_quantity, index, "remaining_quantity");
                              orderedProductQuantityOperation(value, items[index], index);
                              }}
                              sx={{ width: 200 }}
                              renderInput={(params) => (
                                <TextField
                            {...params}
                            label="Batch Number"
                            onChange={(e) => handleBatchSearch(items[index]?.id, e.target.value)}
                          />
                        )}
                        />
                        </td>
                        ) : (
                          <td>
                          <span className="w-[200px] py-[15px] rounded-md text-center text-neutral-500 border border-neutral-400 block">
                          N / A
                          </span>
                          </td>
                          )} */}
                    {items[index].is_service === "N" ? (
                      <td>
                        <button
                          className="border-[0.5px] border-neutral-400 p-[15px] rounded-md text-nowrap w-full"
                          onClick={() => handleBatchPopup(index)}
                        >
                          {items[index]?.batch_number
                            ? `${items[index]?.batch_number} - QTY:  ${items[index]?.remaining_quantity}`
                            : `Select Batch`}
                        </button>
                      </td>
                    ) : (
                      <td>
                        <span className="w-[200px] py-[15px] rounded-md text-center text-neutral-500 border border-neutral-400 block">
                          N / A
                        </span>
                      </td>
                    )}
                    <td>
                      <TextField
                        id="outlined-basic"
                        inputProps={{
                          style: {
                            padding: "16.5px",
                            paddingLeft: "10px",
                          },
                        }}
                        disabled
                        value={items[index]?.hsn_number}
                        defaultValue={""}
                        type="text"
                        variant="outlined"
                      />
                    </td>
                    {/* ~~ change here */}
                    {items[index].is_service === "N" ? (
                      <td>
                        <TextField
                          ref={(el) => {
                            if (el && inputRefs.current[index]) {
                              inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                            }
                          }}
                          id="outlined-basic"
                          inputProps={{
                            style: {
                              padding: "16.5px",
                              paddingLeft: "10px",
                            },
                            min: 1,
                          }}
                          onChange={(e) => {
                            if (items[index].remaining_quantity < e.target.value) {
                              toast.error("can't add more than remaining quanitity");
                              handleFieldChange(items[index].remaining_quantity, index, "quantity");
                              return;
                            }
                            if (e.target.value && e.target.value < 0) {
                              toast.error("value can't be negative or 0");
                              handleFieldChange(1, index, "quantity");
                              return;
                            }
                            handleFieldChange(e.target.value, index, "quantity");
                          }}
                          value={items[index]?.quantity}
                          defaultValue={0}
                          type="number"
                          variant="outlined"
                        />
                      </td>
                    ) : (
                      <td>
                        <span className="py-[15px] min-w-[6vw] w-full rounded-md text-center text-neutral-500 border border-neutral-400 block">
                          N / A
                        </span>
                      </td>
                    )}
                    {/* ~~ change here */}
                    {items[index].is_service === "N" ? (
                      <td>
                        <TextField
                          ref={(el) => {
                            if (el && inputRefs.current[index]) {
                              inputRefs.current[index][2] = el; // Assign the ref if both checks pass
                            }
                          }}
                          id="outlined-basic"
                          inputProps={{
                            style: {
                              padding: "16.5px",
                              paddingLeft: "10px",
                            },
                            min: 1,
                          }}
                          onChange={(e) => {
                            if (e.target.value && e.target.value < 0) {
                              toast.error("value can't be negative or 0");
                              handleFieldChange(0, index, "deal_quantity");
                              return;
                            }
                            handleFieldChange(e.target.value, index, "deal_quantity");
                          }}
                          value={items[index]?.deal_quantity}
                          // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                          defaultValue={0}
                          type="number"
                          variant="outlined"
                        />
                      </td>
                    ) : (
                      <td>
                        <span className="py-[15px] min-w-[6vw] w-full rounded-md text-center text-neutral-500 border border-neutral-400 block">
                          N / A
                        </span>
                      </td>
                    )}
                    {/* ~~ change here */}
                    {items[index].is_service === "N" ? (
                      <td>
                        <TextField
                          id="outlined-basic"
                          inputProps={{
                            style: {
                              padding: "16.5px",
                              paddingLeft: "10px",
                            },
                          }}
                          onChange={(e) => handleFieldChange(e.target.value, index, "sales_price")}
                          value={items[index]?.sales_price}
                          type="number"
                          variant="outlined"
                          disabled
                        />
                      </td>
                    ) : (
                      <td>
                        <TextField
                          id="outlined-basic"
                          inputProps={{
                            style: {
                              padding: "16.5px",
                              paddingLeft: "10px",
                            },
                          }}
                          onChange={(e) => handleFieldChange(e.target.value, index, "rate")}
                          value={items[index]?.rate}
                          type="number"
                          variant="outlined"
                          disabled
                        />
                      </td>
                    )}
                    <td>
                      <TextField
                        id="filled-adornment-weight"
                        inputProps={{
                          style: {
                            padding: "16.5px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => handleFieldChange(e.target.value, index, "tax")}
                        value={items[index]?.tax}
                        type="number"
                        variant="outlined"
                        disabled
                      />
                    </td>
                    <td>
                      <TextField
                        id="filled-adornment-weight"
                        inputProps={{
                          style: {
                            padding: "16.5px",
                            paddingLeft: "10px",
                          },
                        }}
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][3] = el; // Assign the ref if both checks pass
                          }
                        }}
                        //value - to directly give discount, index, isDiscount, data, checkedByUser
                        onChange={(e) => handleDiscountOnMRPChange(e.target.value, index, true, {})}
                        type="number"
                        value={discountOnMRPValue[index]?.discountOnMRP ?? ""}
                        variant="outlined"
                      />
                    </td>
                    <td>
                      <TextField
                        id="amount"
                        ref={(el) => {
                          if (el && inputRefs.current[index]) {
                            inputRefs.current[index][4] = el; // Assign the ref if both checks pass
                          }
                        }}
                        inputProps={{
                          style: {
                            padding: "16.5px",
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => handleAmountValueChange(e.target.value, index)}
                        value={calculatedAmountValue[index]?.calculated_amount || 0}
                        type="text"
                        variant="outlined"
                        // disabled
                      />
                    </td>
                    <td
                      onClick={() => {
                        handleRemoveItem(index);
                      }}
                    >
                      <IconWrapper>
                        <Cross className="text-white z-10" />
                      </IconWrapper>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <div>
              <button
                ref={addAnotherItemRef}
                className="text-black p-2 pb-[20px]"
                onClick={() => {
                  setItems((prev) => [...prev, {}]);
                  setDiscountOnMRPValue((prev) => [...prev, {}]);
                  setCalculatedAmountValue((prev) => [...prev, {}]);
                }}
              >
                + Add another item
              </button>
              <div className="w-full flex border-t-2 border-neutral-200">
                <div className="w-full">
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">SubTotal</h3>
                    <h3 className="font-semibold text-gray-500">{formData.sub_total || 0}</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Discount</h3>
                    <h3 className="font-semibold text-gray-500">{formData.discount || 0}</h3>
                  </div>
                  {/* Depending on intra state show appropriate tax value  */}
                  {/* {console.log(
                    dispatchDetails.dispatch_state_code === shipmentDetails.shipment_state_code,
                    "does state code and shipment code match?"
                  )} */}
                  {/*extra-test previously && igstOnIntra !== 1 */}
                  {dispatchDetails?.dispatch_state_code?.toUpperCase() ===
                  shipmentDetails?.shipment_state_code?.toUpperCase() ? (
                    <>
                      <div className="flex justify-between my-5">
                        <h3 className="font-semibold text-gray-500">SGST @tax%</h3>
                        <h3 className="font-semibold text-gray-500">
                          {(formData?.tax / 2).toFixed(3).slice(0, -1) || 0}
                        </h3>
                      </div>
                      <div className="flex justify-between my-5">
                        <h3 className="font-semibold text-gray-500">CGST @tax%</h3>
                        <h3 className="font-semibold text-gray-500">
                          {(formData?.tax / 2).toFixed(3).slice(0, -1) || 0}
                        </h3>
                      </div>
                    </>
                  ) : (
                    <div className="flex justify-between my-5">
                      <h3 className="font-semibold text-gray-500">IGST @tax%</h3>
                      <h3 className="font-semibold text-gray-500">
                        {(formData?.tax / 1).toFixed(3).slice(0, -1) || 0}
                      </h3>
                    </div>
                  )}
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Total</h3>
                    <h3 className="font-semibold text-gray-500">{(formData.grand_total || 0).toFixed(2)}</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Profit Margin</h3>
                    <h3 className="font-semibold text-gray-500">{profitMargin.toFixed(2)}</h3>
                  </div>
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Payment Options</h3>
                    <h3 className="font-semibold text-gray-500">
                      <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white sm:flex">
                        {paymentOptions.map((option, index) => (
                          <li key={option.value} className="w-full">
                            <div className="flex items-center ml-2">
                              <input
                                id="horizontal-list-radio-id"
                                type="radio"
                                value={option.value}
                                onChange={(e) => {
                                  let nextPaymentType = "paid";

                                  if (e.target.value === "credit") {
                                    nextPaymentType = "remain_payment";
                                  }

                                  setFormData({
                                    ...formData,
                                    payment_option: e.target.value,
                                    remaining_total: formData?.grand_total,
                                    paid_amount: 0,
                                    payment_type: nextPaymentType,
                                  });
                                }}
                                checked={formData.payment_option === option.value}
                                name="list-radio"
                                className="w-4 h-4 text-blue-600"
                              />
                              <label for="horizontal-list-radio-id" className="w-full ms-2 px-1 text-base font-medium">
                                {option.label}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </h3>
                  </div>
                  {formData.payment_type === "remain_payment" && (
                    <div className="flex justify-between my-5">
                      <h3 className="font-semibold text-gray-500">Amount Received</h3>
                      <input
                        type="number"
                        className="py-[10px] px-[10px] border border-black rounded-sm"
                        placeholder="Amount Received"
                        value={parseFloat(formData.paid_amount)}
                        onChange={(e) => {
                          if (e.target.value > formData?.grand_total) {
                            toast.error("Amount received cannot be more than grand total!");
                            return;
                          }

                          setFormData({
                            ...formData,
                            remaining_total: parseFloat(formData?.grand_total) - parseFloat(e.target.value),
                            paid_amount: parseFloat(e.target.value),
                          });
                        }}
                      />
                    </div>
                  )}
                  <div className="flex justify-between my-5">
                    <h3 className="font-semibold text-gray-500">Description</h3>
                    <h3 className="font-semibold text-gray-500">
                      <textarea
                        className="w-full min-w-[300px] h-full min-h-[80px] p-2 border-2"
                        placeholder=""
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        value={formData.description}
                      ></textarea>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="w-full flex items-center justify-center my-4">
                <button
                  type="button"
                  disabled={disableSubmitButton}
                  ref={submitButton}
                  onClick={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  className="fixed bottom-[5vh] text-white bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg min-[1124px]:text-sm text-xs px-5 py-2.5 text-center"
                >
                  Create Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateInvoice;
