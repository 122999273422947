import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../service/http";

// Thunks for warehouse operations
export const createWarehouse = createAsyncThunk("warehouse/createWarehouse", async (data, extra) => {
  const response = await http.post("/warehouse/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data;
  }
});

export const updateWarehouse = createAsyncThunk("warehouse/updateWarehouse", async (data, extra) => {
  const response = await http.put(`/warehouse/${data.id}/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data;
  }
});

export const deleteWarehouse = createAsyncThunk("warehouse/deleteWarehouse", async (id, extra) => {
  const response = await http.delete(`/warehouse/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return id;
});

export const getWarehouseById = createAsyncThunk("warehouse/warehousebyId", async (id, extra) => {
  const response = await http.get(`/warehouse/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data.data;
  }
});

export const fetchWarehouses = createAsyncThunk("warehouse/fetchWarehouses", async (data, extra) => {
  const response = await http.get(`/warehouse/all/${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success") {
    return response.data;
  }
});

export const fetchMoreWarehouses = createAsyncThunk("warehouse/fetchMoreWarehouses", async (url, extra) => {
  const response = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (response.data.status === "success ") {
    return response.data;
  }
});

//Get products by warehouse API
export const fetchProductsByWarehouseId = createAsyncThunk(
  "warehouse/fetchProductsByWarehouseId",
  async (data, extra) => {
    const response = await http.get(`/warehouse/products-warehouse/${data.id}${data?.search ?? ""}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.data.results.status === "success") {
      return response.data.results.products;
    }
  }
);

//Get top products by warehouse
export const fetchTopProductsByWarehouseId = createAsyncThunk(
  "warehouse/fetchTopProductsByWarehouseId",
  async (data, extra) => {
    const response = await http.get(`/warehouse/top-products/${data.id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.data.status === "success") {
      return response.data.data;
    }
  }
);

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState: {
    warehouses: { data: [], next: null },
    warehouseById: {},
    warehouseProducts: { data: [], next: null },
    warehouseTopProducts: { data: [], next: null },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createWarehouse.fulfilled, (state, action) => {
      return { ...state, warehouses: { ...state.warehouses, data: [...state.warehouses.data, action.payload.data] } };
    });
    builder.addCase(updateWarehouse.fulfilled, (state, action) => {
      const updatedWarehouses = state.warehouses.data.map((warehouse) => {
        if (warehouse.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return warehouse;
        }
      });
      return { ...state, warehouses: { ...state.warehouses, data: updatedWarehouses } };
    });
    builder.addCase(deleteWarehouse.fulfilled, (state, action) => {
      return {
        ...state,
        warehouses: {
          ...state.warehouses,
          data: state.warehouses.data.filter((warehouse) => warehouse.id !== action.payload),
        },
      };
    });
    builder.addCase(getWarehouseById.fulfilled, (state, action) => {
      return {
        ...state,
        getWarehouseById: action.payload,
        warehouses: {
          ...state.warehouses,
          data: [state.warehouses.data, action.payload],
        },
      };
    });
    builder.addCase(fetchWarehouses.fulfilled, (state, action) => {
      return {
        ...state,
        warehouses: {
          ...state.warehouses,
          data: action.payload?.warehouses,
          next: action.payload?.next,
        },
      };
    });
    builder.addCase(fetchMoreWarehouses.fulfilled, (state, action) => {
      return {
        ...state,
        warehouses: {
          ...state.warehouses,
          data: [...state.warehouses.data, ...action.payload.warehouses],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchProductsByWarehouseId.fulfilled, (state, action) => {
      return {
        ...state,
        warehouseProducts: {
          data: action.payload,
          next: null,
        },
      };
    });
    builder.addCase(fetchTopProductsByWarehouseId.fulfilled, (state, action) => {
      return {
        ...state,
        warehouseTopProducts: {
          data: action.payload,
          next: null,
        },
      };
    });
  },
});

export default warehouseSlice.reducer;
