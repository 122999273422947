import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

//Fetch statement for P&L based on param
export const fetchStatements = createAsyncThunk("profitAndLoss/fetchStatements", async (data, extra) => {
  const res = await http.get(`/profit_and_loss${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch more statements - react infiniity scroll
export const fetchMoreStatements = createAsyncThunk("profitAndLoss/fetchMoreStatements", async (url, extra) => {
  const res = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch expense statements for P&L based on param
export const fetchTaxStatements = createAsyncThunk("profitAndLoss/fetchTaxStatements", async (data, extra) => {
  const res = await http.get(`/profit_and_loss/tax${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Generate CSV or Excel of P&L
export const GenerateOfflineFile = createAsyncThunk("profitAndLoss/generateOfflineFile", async (data, extra) => {
  const res = await http.get(`/profit_and_loss/download-profit_and_loss${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.file_url;
  }
});

//Generate CSV or Excel of Tax P&L
export const GenerateOfflineFileTax = createAsyncThunk("profitAndLoss/generateOfflineFileTax", async (data, extra) => {
  const res = await http.get(`/profit_and_loss/tax/download-profit_and_loss${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.file_url;
  }
});

//Fetch Expenses for P&L
// export const fetchExpenses = createAsyncThunk("profitAndLoss/fetchExpenses", async (data, extra) => {
//   const res = await http.get(`/profit_and_loss/expense${data}`, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

export const profitAndLossSlice = createSlice({
  name: "profitAndLoss",
  initialState: {
    overall_stats: {},
    statements: { data: [], next: "" },
    offlineFileUrl: "",
    // expenses: [],
  },
  reducers: {
    removeOfflineFile: (state, action) => {
      return { ...state, offlineFileUrl: "" };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatements.fulfilled, (state, action) => {
      return {
        ...state,
        overall_stats: action.payload.overall_stats,
        statements: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreStatements.fulfilled, (state, action) => {
      return {
        ...state,
        statements: { data: [...state.statements.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(fetchTaxStatements.fulfilled, (state, action) => {
      return {
        ...state,
        overall_stats: action.payload.overall_stats,
        statements: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(GenerateOfflineFile.fulfilled, (state, action) => {
      return {
        ...state,
        offlineFileUrl: action.payload,
      };
    });

    builder.addCase(GenerateOfflineFileTax.fulfilled, (state, action) => {
      return {
        ...state,
        offlineFileUrl: action.payload,
      };
    });
    // builder.addCase(fetchExpenses.fulfilled, (state, action) => {
    //   return { ...state, expenses: action.payload.data };
    // });
  },
});

export const { removeOfflineFile } = profitAndLossSlice.actions;
export default profitAndLossSlice.reducer;
