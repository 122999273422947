import React from "react";
import InvoiceContainer from "./TransactionComponent/InvoiceContainer";

const PurchaseBook = () => {
  return (
    <div className="p-[10px]">
      <div className="border-2 border-neutral-200 rounded-[20px] p-[20px]">
        <InvoiceContainer infoType={"vendor"} />
      </div>
    </div>
  );
};

export default PurchaseBook;
