import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  // endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  // endOfYear,
} from "date-fns";

//Funtion to change date format to yyyy-mm-dd format
export const changeDateFormat = (unformattedDate) => {
  return format(unformattedDate, "yyyy-MM-dd");
};

export const humanDateFormat = (unformattedDate) => {
  if (!unformattedDate) {
    return;
  }
  return format(unformattedDate, "dd/MM/yyyy");
};

export const weekFormat = (date) => {
  const start = startOfWeek(new Date(date));
  const end = endOfWeek(new Date(date));
  return `${format(start, "MMM dd, yyyy")} - ${format(end, "MMM dd, yyyy")}`;
};

export const monthFormat = (date) => {
  const start = startOfMonth(new Date(date));
  return `${format(start, "MMM yyyy")}`;
};

export const quarterFormat = (date) => {
  const start = startOfQuarter(new Date(date));
  const end = endOfQuarter(new Date(date));
  return `${format(start, "MMM dd, yyyy")} - ${format(end, "MMM dd, yyyy")}`;
};

export const yearFormat = (date) => {
  const start = startOfYear(new Date(date));
  // const end = endOfYear(new Date(date));
  return `${format(start, "yyyy")}`;
};
