import React, { useEffect, useRef } from "react";
import DesktopSideBar from "../SharedComponents/DesktopSideBar";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { Suspense } from "react";
import LoadScreen from "../LoadScreen";
import Home from "../Home";
import NavBar from "../SharedComponents/Navbar";
import Employees from "../Employees";
import Expenses from "../Expenses";
import GSTFilings from "../GSTFilings";
import Inventory from "../Inventory";
import CashRegister from "../CashRegister";
import LedgerBook from "../LedgerBook";
import OnlineStore from "../OnlineStore";
import OnlineOrders from "../OnlineOrders";
import ProfitAndLoss from "../ProfitAndLoss";
import Reports from "../Reports";

//Import Export for onboarding
import ImportExport from "../ImportAndExport";

// import Transactions from "../Transactions/SalesBook";
// import "./DesktopApp.scss";
import { checkTokenExists } from "../../utils/session";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessDetails } from "../../reducers/Slices/userSlice";
import { http } from "../../service/http";

//Generic pages
import UserProfile from "../UserProfile";
import CurrentPlan from "../CurrentPlan";

//For Customer Invoices
import AllInvoice from "../Invoices/All_Invoice";
import Invoice from "../Invoices/Invoice";
import CreateInvoice from "../Invoices/CreateInvoice";
import CreateEInvoice from "../Invoices/CreateEInvoice";
import CancelInvoice from "../Invoices/Cancel_Invoice";
import EstimateInvoice from "../Invoices/EstimateInvoice";
import CreateEstimateInvoice from "../Invoices/CreateEstimateInvoice";
import CreditNote from "../Invoices/Credit_Note";
import DebitNote from "../Invoices/Debit_Note";

//For Purchase Invoices
import AllPurchaseInvoice from "../Purchase/AllPurchaseInvoice";
import Purchase from "../Purchase/PurchaseInvoice";
import PurchaseDemand from "../Purchase/PurchaseDemand";
import CancelPurchaseInvoice from "../Purchase/CancelPurchaseInvoice";
import CreatePurchaseInvoice from "../Purchase/CreatePurchaseInvoice";
import CreatePurchaseDemand from "../Purchase/CreatePurchaseDemand";

// import { navigationItems } from "../SharedComponents/DesktopSideBar/SideBarData";
import SalesBook from "../Transactions/SalesBook";
import PurchaseBook from "../Transactions/PurchaseBook";

//Global Search imports
import GlobalSearch from "../MacroComponents/GlobalSearch";
import {
  sidebarInvoice,
  subscribeFirebaseNotification,
  toggleGlobalSearch,
  unsubscribeFirebaseNotification,
} from "../../reducers/Slices/appSlice";
import CreateCreditNote from "../Invoices/CreateCreditNote";

//UI imports
import toast from "react-hot-toast";
import { isSubscriptionExpired } from "../../utils/checkSubscriptionExpiry";
import { requestForToken } from "../../config/firebase";
import EInvoice from "../Invoices/E_Invoice";
import EwayBill from "../EwayBill";
import CreateEwayBill from "../EwayBill/CreateEwayBill";
import Warehouse from "../Warehouse";
import BottomBar from "../SharedComponents/BottomBar";
import Welcome from "../Onboarding/Welcome";
import ExpiryAndReturn from "../ExpiryAndReturn";
import CreateExpiryReturnVoucher from "../ExpiryAndReturn/ExpiryAndReturnComponents/CreateExpiryReturnVoucher";
import CreateReturnVoucher from "../ExpiryAndReturn/ExpiryAndReturnComponents/CreateReturnVoucher";

//GSTR-3b Forms
import FORM_3_1 from "../GSTFilings/sections/GSTR3BForms/FORM_3_1";
import FORM_3_1_1 from "../GSTFilings/sections/GSTR3BForms/FORM_3_1_1";
import FORM_3_2 from "../GSTFilings/sections/GSTR3BForms/FORM_3_2";
import FORM_4 from "../GSTFilings/sections/GSTR3BForms/FORM_4";
import FORM_5 from "../GSTFilings/sections/GSTR3BForms/FORM_5";
import FORM_5_1 from "../GSTFilings/sections/GSTR3BForms/FORM_5_1";
import FORM_6_1 from "../GSTFilings/sections/GSTR3BForms/FORM_6_1";
import PurchaseInvoice from "../Purchase/PurchaseInvoiceOther";
import CreatePurchaseInvoiceOther from "../Purchase/CreatePurchaseInvoiceOther";
import CreateBreakageReturnVoucher from "../ExpiryAndReturn/ExpiryAndReturnComponents/CreateBreakageReturnVoucher";

const Desktopapp = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();

  //Todo - create a component that will only run on first render and will highlight features and stuff
  //Only use case to avoid called firebase device token twice during react development.
  const timeoutRef = useRef(null);

  //todo - add more conditions to check if account is paid account
  useEffect(() => {
    if (!checkTokenExists() || localStorage.getItem("business_profile") === "false") {
      navigate("/login");
    } else {
      handleProfileDetails();
    }
    // eslint-disable-next-line
  }, []);

  const handleProfileDetails = () => {
    http
      .get("user_profile/backend/business-all/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(setBusinessDetails({ ...res.data.data, phone: res.data?.user_profile_phone }));
          if (res?.data?.data) {
            if (isSubscriptionExpired(res.data.data.subscription_expiry)) {
              navigate("/plan_selection");
            }
          }
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Functionality for Global Search
  const globalSearchShow = useSelector((state) => state.app.global_search_show);

  //On location change close global search toggle
  useEffect(() => {
    if (globalSearchShow === true) {
      dispatch(toggleGlobalSearch());
    }
    //reason -- on every globalSeachShow change it will check if it is true and will close it - react nature.
    //eslint-disable-next-line
  }, [location, dispatch]);

  //Set up global hot keys
  useHotkeys("Alt + N, Option + N", () => navigate("/invoice"));
  useHotkeys("Alt + P, Option + P", () => navigate("/purchase"));
  useHotkeys("Shift + F", () => dispatch(toggleGlobalSearch()));
  useHotkeys("Shift + H", () => navigate("/"));
  useHotkeys("Alt + F1, Option + F1", () => navigate("/sales-book"));
  useHotkeys("Alt + F2, Option + F2", () => navigate("/purchase-book"));
  useHotkeys("Alt + I, Option + I", () => navigate("/inventory"));
  useHotkeys("Alt + F3, Option + F3", () => navigate("/ledger-book"));
  useHotkeys("Shift + E", () => navigate("/expiry_return"));

  //Send token to backend for notifications triggered from backend
  const subscribeTokenGeneratedByFirebase = (token) => {
    dispatch(subscribeFirebaseNotification({ device_token: token }))
      .then((action) => {
        if (action?.payload?.status === "success") {
          localStorage.setItem("device_token", token); // Save the token in localStorage\
          toast.success("Notifications Enabled!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Unsubscribe from notifications if notification permission is disabled.
  const unsubscribeTokenGeneratedByFirebase = (token) => {
    dispatch(unsubscribeFirebaseNotification({ device_token: token }))
      .then((action) => {
        if (action?.payload?.status === "success") {
          localStorage.removeItem("device_token", token); // Save the token in localStorage
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Firebase token config - get token on push notification
  useEffect(() => {
    // Clear any existing timeout to debounce the function call
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const getToken = async () => {
      // Check if the token is already stored in localStorage
      const existingToken = localStorage.getItem("device_token");

      // Request permission to send notifications
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        if (existingToken) {
          return; // If token exists, no need to generate a new one
        }
        // Call the function to generate a new token
        const token = await requestForToken();
        if (token) {
          subscribeTokenGeneratedByFirebase(token);
        }
      } else {
        console.error("Notification permission denied.");
        //If notification permission is disabled then remove token.
        if (existingToken) {
          unsubscribeTokenGeneratedByFirebase(existingToken);
        }
      }
    };

    timeoutRef.current = setTimeout(() => {
      // Call your function here after the debounce period
      getToken();
    }, 1000); // 1 second

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  //Check if user is inside any invoice and if user is in any invoice then minimize sidebar
  // useEffect(() => {
  //   console.log(location.pathname, "this is the location pathname");
  //   const invoicesPathArr = [
  //     "/invoice/create",
  //     "/einvoice/create",
  //     "/purchase_invoice/create",
  //     "/purchase_demand/create",
  //     "/ewaybill/create/",
  //   ];

  //   let invoiceTicker = false;

  //   invoicesPathArr.forEach((invoicePath) => {
  //     if (location.pathname.startsWith(invoicePath)) {
  //       invoiceTicker = true;
  //     }
  //   });

  //   dispatch(sidebarInvoice(invoiceTicker));
  // }, [location.pathname]);

  return (
    <div className="flex w-[100vw]">
      {/* Onboarding - welcome screen - only runs first time  */}
      <Welcome />
      <DesktopSideBar />
      <div className="relative h-screen overflow-y-scroll w-full">
        <NavBar />
        {/* show global search if toggled  */}
        {globalSearchShow && <GlobalSearch />}

        {!globalSearchShow && (
          <div className="md:h-[calc(100vh-105px)]">
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Home />
                  </Suspense>
                }
              />
              <Route
                path="/profile"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <UserProfile />
                  </Suspense>
                }
              />
              <Route
                path="/current_plan"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CurrentPlan />
                  </Suspense>
                }
              />
              <Route
                path="/employees"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Employees />
                  </Suspense>
                }
              />
              <Route
                path="/import_export"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <ImportExport />
                  </Suspense>
                }
              />
              <Route
                path="/invoice/*"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Routes>
                      <Route path="/" element={<Invoice />} />
                      <Route path="/all_invoice" element={<AllInvoice />} />
                      <Route path="/cancel_invoice" element={<CancelInvoice />} />
                      <Route path="/estimate_invoice" element={<EstimateInvoice />}></Route>
                      <Route path="/credit_note" element={<CreditNote />}></Route>
                      <Route path="/debit_note" element={<DebitNote />}></Route>
                      <Route path="/einvoice" element={<EInvoice />}></Route>
                    </Routes>
                  </Suspense>
                }
              />
              <Route
                path="/invoice/create/customer/:id/:type/:orderId"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreateInvoice />
                  </Suspense>
                }
              />{" "}
              <Route
                path="/einvoice/create/customer/:id/:type/:orderId"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreateEInvoice />
                  </Suspense>
                }
              />
              <Route
                path="/invoice/create/estimate/customer/:id/:type"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreateEstimateInvoice />
                  </Suspense>
                }
              />
              <Route
                path="/invoice/create/creditNote/customer/:id/:invoiceId"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreateCreditNote />
                  </Suspense>
                }
              />
              <Route
                path="/purchase/*"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Routes>
                      <Route path="/" element={<Purchase />} />
                      <Route path="/all_invoice" element={<AllPurchaseInvoice />} />
                      <Route path="/cancel_invoice" element={<CancelPurchaseInvoice />} />
                      <Route path="/purchase_invoice" element={<PurchaseInvoice />} />
                      <Route path="/purchase_demand" element={<PurchaseDemand />}></Route>
                    </Routes>
                  </Suspense>
                }
              />
              <Route
                path="/purchase_invoice/create/vendor/:id/:type"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreatePurchaseInvoice />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/purchase_invoice/createOther/vendor/:id/:type"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreatePurchaseInvoiceOther />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/purchase_demand/create/vendor/:id/:type"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreatePurchaseDemand />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/purchase-book"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <PurchaseBook />
                  </Suspense>
                }
              />
              <Route
                path="/expenses"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Expenses />
                  </Suspense>
                }
              />
              <Route
                path="/gst-filing"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <GSTFilings />
                  </Suspense>
                }
              />
              <Route
                path="/gst-filling/gstr3b/*"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Routes>
                      <Route path="/3_1/:financialYear/:quarter/:month" element={<FORM_3_1 />} />
                      <Route path="/3_1_1/:financialYear/:quarter/:month" element={<FORM_3_1_1 />} />
                      <Route path="/3_2/:financialYear/:quarter/:month" element={<FORM_3_2 />} />
                      <Route path="/4/:financialYear/:quarter/:month" element={<FORM_4 />}></Route>
                      <Route path="/5/:financialYear/:quarter/:month" element={<FORM_5 />}></Route>
                      <Route path="/5_1/:financialYear/:quarter/:month" element={<FORM_5_1 />}></Route>
                      <Route path="/6_1/:financialYear/:quarter/:month" element={<FORM_6_1 />}></Route>
                    </Routes>
                  </Suspense>
                }
              />
              <Route
                path="/ewaybill"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <EwayBill />
                  </Suspense>
                }
              />
              <Route
                path="/ewaybill/create/:customerId/:invoiceId"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreateEwayBill />
                  </Suspense>
                }
              />
              <Route
                path="/inventory"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Inventory />
                  </Suspense>
                }
              />
              <Route
                path="/cash-register"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CashRegister />
                  </Suspense>
                }
              />
              <Route
                path="/ledger-book"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <LedgerBook />
                  </Suspense>
                }
              />
              <Route
                path="/online-store"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <OnlineStore />
                  </Suspense>
                }
              />
              <Route
                path="/online-orders"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <OnlineOrders />
                  </Suspense>
                }
              />
              <Route
                path="/pnl"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <ProfitAndLoss />
                  </Suspense>
                }
              />
              <Route
                path="/reports"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Reports />
                  </Suspense>
                }
              />
              <Route
                path="/sales-book"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <SalesBook />
                  </Suspense>
                }
              />
              <Route
                path="/warehouse"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Warehouse />
                  </Suspense>
                }
              />
              <Route
                path="/expiry_return"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <ExpiryAndReturn />
                  </Suspense>
                }
              />
              <Route
                path="/expiryReturnVoucher/create/estimate/vendor/:id"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreateExpiryReturnVoucher />
                  </Suspense>
                }
              />
              <Route
                path="/breakageReturnVoucher/create/estimate/vendor/:id"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreateBreakageReturnVoucher />
                  </Suspense>
                }
              />
              <Route
                path="/returnVoucher/create/estimate/vendor/:id"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <CreateReturnVoucher />
                  </Suspense>
                }
              />
            </Routes>
          </div>
        )}
      </div>
      <BottomBar />
    </div>
  );
};

export default Desktopapp;
