import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
// import filterImage from "../../assets/images/filter.png";
// import gstIcon from "../../../assets/images/gst.jpg";
// import InvoiceBarChart from "../../Invoices/Charts/InvoiceBarChart";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import AddVendor from "../../Purchase/AddVendor";
// import { Checkbox, Tooltip } from "@mui/material";
// import FavoriteIcon from "../../../assets/images/favChecked.png";
// import FavoriteBorderIcon from "../../../assets/images/fav.png";
import { useDispatch, useSelector } from "react-redux";
// import { getVendorInvoices, cancelInvoice, fetchMoreInvoices } from "../../../reducers/Slices/invoiceSlice";
import {
  // getVendorInvoices,
  deletePurchaseInvoice,
  // fetchMorePurchaseInvoices,
  fetchInvoicesVendor,
  fetchMoreInvoicesVendor,
} from "../../../reducers/Slices/purchaseInvoiceSlice";
import moment from "moment";
// import { fetchVendors, setFavouriteVendor } from "../../../reducers/Slices/vendorSlice";
import { debounce } from "lodash";
// import Filter from "./Filter";
// import CommonLists from "../CommonLists";
// import { fetchProductRecommendedList } from "../../../reducers/Slices/productSlice";

//Functional Components
// import DeleteVendor from "./purchaseComponents/DeleteVendor";

//UI Component
import { Filter } from "react-feather";
import IconWrapper from "../../MicroComponents/IconWrapper";
import { useHotkeys } from "react-hotkeys-hook";
import toast from "react-hot-toast";
import { formatIndianNumber } from "../../../utils/fomatIndianNumber";
import { http } from "../../../service/http";

function AllPurchaseInvoice() {
  const [showAddVendor, setShowAddVendor] = useState(false);
  const [filterValue, setFilterValue] = useState("invoices");
  const [isCancelled, setIsCancelled] = useState(false);

  const vendor_invoices = useSelector((state) => state.purchaseInvoice.vendor_invoices.data);
  const vendor_invoicesNext = useSelector((state) => state.purchaseInvoice.vendor_invoices.next);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // const [currentVendor, setCurrentVendor] = useState({});
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [search, setSearch] = useState("");
  // const [tab, setTab] = useState();
  // const vendors = useSelector((state) => state.vendor.vendors) || [];
  // const recommendedProducts = useSelector((state) => state.product.productRecommendedList);
  const [showMenu, setShowMenu] = useState(null);

  //Hot key to add Vendor
  useHotkeys("Ctrl + Alt + V, Ctrl + Option + V", () => setShowAddVendor(!showAddVendor));

  useEffect(() => {
    let baseString = "";

    if (search) {
      baseString = `&search=${search}`;
    } else {
      baseString = "";
    }

    if (filterValue === "invoices") {
      dispatch(fetchInvoicesVendor(`?${baseString}`));
      setIsCancelled(false);
    } else if (filterValue === "cancelled_invoices") {
      dispatch(fetchInvoicesVendor(`?is_cancelled=1${baseString ? `${baseString}` : ""}`));
      setIsCancelled(true);
    }
  }, [filterValue, search, dispatch]);

  // useEffect(() => {
  //   search.length > 0 ? debouncedSearch(search) : dispatch(fetchVendors(tab));
  // }, [dispatch, tab]);

  //Fetch Recommended Products
  // useEffect(() => {
  //   dispatch(fetchProductRecommendedList());
  // }, [dispatch]);

  // const handleCreateInvoice = (id) => {
  //   navigate(`/invoice/create/customer/${id}/1`);
  // };

  // const handleChangeTab = (value) => {
  //   setTab(tab === value ? null : value);
  // };

  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  const handleCloseModal = () => {
    setShowAddVendor(false);
  };

  // const handleUpdateVendor = (vendor) => {
  //   setCurrentVendor(vendor);
  //   setShowAddVendor(true);
  // };

  const handleAddVendor = () => {
    // setCurrentVendor({});
    setShowAddVendor(true);
  };

  const setDebouncedSearch = debounce((search) => {
    // dispatch(fetchVendors(tab ? tab + "&search=" + search : "?search=" + search));
    setSearch(search);
  }, 1000);

  const handleSearch = (e) => {
    setDebouncedSearch(e.target.value);
  };

  //Function to cancel Invoice
  const deleteInvoice = (id) => {
    if (window.confirm("are you sure you want to cancel this invoice?")) {
      dispatch(deletePurchaseInvoice({ id }))
        .then((action) => {
          if (action.payload) {
          } else {
            toast.error("Purchase Invoice cancellation failed");
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };
  const renderModalComponent = () => {
    switch (showAddVendor) {
      case "filter":
        return <Filter handleCloseModal={handleCloseModal} />;
      default:
        return <AddVendor handleCloseModal={handleCloseModal} currentVendor={false} />;
    }
  };

  const [hasMoreInvoices, setHasMoreInvoices] = useState(() => (vendor_invoicesNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (vendor_invoicesNext) {
      setHasMoreInvoices(true);
    } else {
      setHasMoreInvoices(false);
    }
  }, [vendor_invoicesNext]);

  //fetch more expenses on scroll if available
  const fetchNextInvoices = () => {
    if (vendor_invoicesNext) {
      dispatch(fetchMoreInvoicesVendor(vendor_invoicesNext));
    }
  };

  //handleDownloadPDF
  const handleDownloadPDF = async (endpoint) => {
    if (!endpoint) {
      toast.error("No PDF available for this invoice!");
      return;
    }

    if (endpoint) {
      try {
        const response = await http.get(`/storage/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
        // Create a URL for the PNG blob
        const imageURL = URL.createObjectURL(response.data);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `${endpoint.split("/")[1]}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        toast.error("PDF not found!");
      }
    }
  };

  return (
    <>
      <CustomModal isOpen={showAddVendor} handleClose={handleCloseModal}>
        {renderModalComponent()}
      </CustomModal>
      <div className="h-full w-full">
        <ul className="flex flex-row text-nowrap whitespace-nowrap flex-wrap justify-center md:justify-normal font-medium text-center gap-4 py-[25px] px-[20px] border-b-2 border-neutral-200">
          <li className="mt-2 md:mt-0">
            <Link
              to="/purchase/all_invoice/"
              className="bg-black py-[10px] px-[20px] text-white hover:bg-white hover:text-black border-[1px] border-black rounded-full"
            >
              ALL
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/purchase/"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
              aria-current="page"
            >
              STOCK PURCHASE INVOICE
            </Link>
          </li>
          {/* <li className="mt-2 md:mt-0">
            <Link
              to="/purchase/purchase_invoice"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
              aria-current="page"
            >
              PURCHASE INVOICE
            </Link>
          </li> */}
          <li className="mt-2 md:mt-0">
            <Link
              to="/purchase/purchase_demand"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
              aria-current="page"
            >
              PURCHASE DEMAND
            </Link>
          </li>
          <li className="mt-2 md:mt-0">
            <Link
              to="/purchase/Cancel_Invoice"
              className="bg-white py-[10px] px-[20px] text-black hover:bg-black hover:text-white border-[1px] border-black rounded-full"
            >
              CANCEL PURCHASE INVOICE
            </Link>
          </li>
        </ul>

        <div className="flex flex-col md:flex-row content-center h-[83vh]">
          <div className="w-full border-b-2 md:border-b-0 py-[20px] md:border-r-2 border-neutral-200">
            <div className="flex md:w-[40%] items-center gap-[10px] px-[20px]">
              <div className="w-full ">
                <label for="default-search" className="mb-2 xl:text-sm text-xs font-medium text-gray-900 sr-only">
                  Search
                </label>
                <div className="relative">
                  <input
                    type="search"
                    onChange={handleSearch}
                    id="default-search"
                    className="block w-full py-2 px-3 pe-10 xl:text-sm text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Mobile numbers or Username"
                    required
                  />
                  <div className="absolute cursor-pointer z-5 inset-y-0 end-0 flex items-center px-3 ">
                    <svg
                      className="w-4 h-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Filter Button */}
              <div className="relative">
                <button
                  onClick={() => {
                    handleShowMenu("settings");
                  }}
                >
                  <IconWrapper>
                    <Filter className="z-10 text-white" />
                  </IconWrapper>
                </button>
                <div
                  id="dropdownInformation"
                  onMouseLeave={() => handleShowMenu("settings")}
                  className={`${
                    showMenu !== "settings" ? "hidden" : ""
                  } translate-x-[-50%] left-[50%] mt-[10px] absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
                >
                  <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                    <li>
                      <button
                        onClick={() => setFilterValue("invoices")}
                        className="text-lg py-2 w-full border-b-2 border-neutral-200"
                      >
                        All
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setFilterValue("cancelled_invoices")}
                        className="text-lg py-2 w-full border-b-2 border-neutral-200"
                      >
                        Cancelled Invoices
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Add Vendor Button */}
              <div>
                <button
                  type="submit"
                  className="text-white whitespace-nowrap bg-black hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-neutral-200 font-semibold rounded-full xl:text-sm text-xs px-3 py-3"
                  onClick={handleAddVendor}
                >
                  Add Vendor
                </button>
              </div>
            </div>
            {/* <div className="relative flex p-2">
              <div className="">
              <button
              type="button"
              className={`w-18 ml-1 text-xs xl:text-sm border-2 hover:bg-gray-400  border-gray-700 ${
                tab === "?favourite=1" ? "bg-blue-700 text-white" : "bg-transparent text-black"
                }`}
                onClick={() => handleChangeTab("?favourite=1")}
                >
                Favourite
                </button>
                <button
                type="button"
                className={`w-18 ml-1 text-xs xl:text-sm border-2 hover:bg-gray-400  border-gray-700 ${
                  tab === "?most_frequent=1" ? "bg-blue-700 text-white" : "bg-transparent text-black"
                  }`}
                  onClick={() => handleChangeTab("?most_frequent=1")}
                  >
                  Frequent
                  </button>
                  <button
                  type="button"
                  className={`w-18 ml-1 text-xs xl:text-sm border-2 hover:bg-gray-400  border-gray-700 ${
                    tab === "?most_profitable=1" ? "bg-blue-700 text-white" : "bg-transparent text-black"
                    }`}
                    onClick={() => handleChangeTab("?most_profitable=1")}
                    >
                    Top User
                    </button>
                    </div>
                    <div>
                    <button
                    type="submit"
                    className="absolute right-0 m-4 text-white bg-transparent  hover:bg-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-2 py-1 my-auto w-10"
                    >
                    {" "}
                    <img src={filterImage} onClick={() => setShowAddUser("filter")} alt="Filter" srcset="" />
                    </button>
                    </div>
                    </div> */}
            <div className="px-[20px] max-h-[76vh] overflow-y-scroll mt-[20px] border-t-2 border-neutral-200 py-[20px]">
              <InfiniteScroll
                dataLength={vendor_invoices.length}
                next={fetchNextInvoices}
                hasMore={hasMoreInvoices}
                height={"55vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Invoices List End</b>
                  </p>
                }
              >
                <table border="1" className="table-auto w-full">
                  <thead className="bg-gray-50">
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Date
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Invoice No.
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Vendor
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Amount
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Status
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Invoice PDF
                    </th>
                    {!isCancelled && (
                      <th
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                        scope="col"
                      >
                        Invoice Action
                      </th>
                    )}
                    {isCancelled && (
                      <th
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                        scope="col"
                      >
                        Invoice Status
                      </th>
                    )}
                  </thead>
                  <tbody>
                    {vendor_invoices?.map((invoice) => (
                      <tr key={invoice?.id}>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {moment.utc(invoice?.order_date_time).tz(userTimezone).format("LLL")}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {invoice?.invoice_counter}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {invoice?.vendor?.vendor_name}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          Rs {formatIndianNumber(invoice?.grand_total || 0)}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {invoice?.payment_type}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-blue-500 font-bold bg-white border-[1px] border-neutral-200">
                          {/* <Link to={invoice?.Invoice_pdf} target="_blank" rel="noopener noreferrer"> */}
                          <button className="capitalize" onClick={() => handleDownloadPDF(invoice?.Invoice_pdf)}>
                            download
                          </button>
                          {/* </Link> */}
                        </td>
                        {!isCancelled && (
                          <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-red-500 font-semibold bg-white border-[1px] border-neutral-200">
                            <button onClick={() => deleteInvoice(invoice?.id)}>Cancel Invoice</button>
                          </td>
                        )}
                        {isCancelled && (
                          <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-red-500 font-semibold bg-white border-[1px] border-neutral-200">
                            Cancelled
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
          {/* Graph Container */}
          {/* <div className="w-full h-[83vh] md:w-[40%] border-r-2 border-netural-200 flex justify-center pt-[20px]">
            <InvoiceBarChart />
          </div> */}
          {/* Some Container */}
          {/* <div className="w-full h-[83vh] md:w-[20%] flex justify-center pt-[20px]">
            <div className="w-full px-[20px]">
              <CommonLists title="Product Recommended List" data={recommendedProducts} />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default AllPurchaseInvoice;
