import React from "react";

const ZohoBooks = ({ smallScreen }) => {
  const steps = [
    {
      title: "Create a Folder to Save Your Files",
      description:
        "Begin by creating a dedicated folder on your computer to store the exported Excel files from Zoho Books. This will help you keep all reports organized in one location.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Log into Zoho Books and Access the Reports Section",
      description:
        "Log into your Zoho Books account and navigate to the 'Reports' section. Here, you can access various financial, tax, and sales reports.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Balance Sheet Report",
      description:
        "In the 'Reports' section, select 'Balance Sheet' under Financial Reports to review assets, liabilities, and equity. Click the export option to save it as an Excel file.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Profit & Loss Report",
      description:
        "Choose the 'Profit & Loss' report to view revenues and expenses over a specific period. Use the export feature to download this report in Excel format.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Cash Flow Statement",
      description:
        "Access the 'Cash Flow Statement' report to track the movement of cash in and out of your business. Export it to Excel for further analysis.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Accounts Receivable Aging Report",
      description:
        "In the 'Aging Reports' section, choose 'Accounts Receivable Aging' to see unpaid customer invoices. Export this report to Excel.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Accounts Payable Aging Report",
      description:
        "Access the 'Accounts Payable Aging' report to monitor outstanding bills to vendors. Export this report as an Excel file.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Select the Sales by Customer Report",
      description:
        "Under 'Sales Reports', choose 'Sales by Customer' to analyze sales for each customer. Click the export option to save it in Excel format.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Choose Export Settings for Each Report",
      description:
        "When exporting, select 'Excel' as the file format, choose the folder created in Step 1, and confirm the file name. Proceed with the export.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Verify Export and Organize Files",
      description:
        "Check your folder to confirm that each report was saved correctly as an Excel file. Organize all files for easy access.",
      imgSrc: "https://placehold.co/600x400",
    },
    {
      title: "Upload to TheProfit.AI ERP",
      description: "After exporting, proceed to upload these reports to TheProfit.AI ERP for additional processing.",
      imgSrc: null,
    },
  ];

  return (
    <div className="mx-auto p-4">
      {/* {steps.map((step, index) => (
        <div key={index} className="mb-10 flex flex-col items-center">
          <h2 className="text-2xl font-semibold mb-2">{`Step ${index + 1}: ${step.title}`}</h2>
          <p className="text-gray-700 mb-4 max-w-[600px] text-center">{step.description}</p>
          {step.imgSrc && (
            <img
              src={step.imgSrc}
              alt={step.title}
              className={`w-full rounded-lg shadow-md ${smallScreen ? "max-w-[40vw]" : "max-w-[50vw]"}`}
            />
          )}
        </div>
      ))}

      <footer className="text-center text-sm text-gray-500 mt-12">
        By following these steps, you can easily export all necessary reports from Zoho Books for further processing in
        our software.
      </footer> */}
      <div>Currently we are working on adding support for Zoho books data migration.</div>
    </div>
  );
};

export default ZohoBooks;
