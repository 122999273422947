import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { useDispatch, useSelector } from "react-redux";
// import { cancelInvoice, fetchInvoicesCustomer, fetchMoreInvoicesCustomer } from "../../../reducers/Slices/invoiceSlice";
import { fetchReturnVouchers, fetchMoreReturnVouchers } from "../../../../reducers/Slices/expiryBreakageSlice";

import moment from "moment";
import { debounce } from "lodash";

import { Search } from "react-feather";
import toast from "react-hot-toast";
import IconWrapper from "../../../MicroComponents/IconWrapper";

import { subDays } from "date-fns";
import DateRangeSelector from "../../../ProfitAndLoss/profitAndLossComponents/DateRangeSelector";
import { http } from "../../../../service/http";

function PreviousVouchers() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const [filter, setFilter] = useState({ month: "none", date_from: null, date_to: null, status: "" });

  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    setFilter((prevState) => {
      const dateFrom = moment(dateRange[0].startDate).format("YYYY-MM-DD");
      const dateTo = moment(dateRange[0].endDate).format("YYYY-MM-DD");

      const updatedState = { ...prevState, date_from: dateFrom, date_to: dateTo };
      return updatedState;
    });
    //eslint-disable-next-line
  }, [dateRange]);

  useEffect(() => {
    const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
      filter.date_from || ""
    }&date_to=${filter.date_to || ""}`;

    dispatch(fetchReturnVouchers(params));
  }, [dispatch, search, filter]);

  const setDebouncedSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 1000);

  const handleFilter = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  const returnVouchers = useSelector((state) => state.expiryBreakage.returnVouchers.data);
  const returnVouchersNext = useSelector((state) => state.expiryBreakage.returnVouchers.next);

  const [hasMoreReturnVouchers, setHasMoreReturnVouchers] = useState(() => (returnVouchersNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (returnVouchersNext) {
      setHasMoreReturnVouchers(true);
    } else {
      setHasMoreReturnVouchers(false);
    }
  }, [returnVouchersNext]);

  //fetch more expenses on scroll if available
  const fetchNextReturnVouchers = () => {
    if (returnVouchersNext) {
      dispatch(fetchMoreReturnVouchers(returnVouchersNext));
    }
  };

  //handleDownloadPDF
  const handleDownloadPDF = async (endpoint) => {
    if (!endpoint) {
      toast.error("No PDF available for this invoice!");
      return;
    }

    if (endpoint) {
      try {
        const response = await http.get(`/storage/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
        // Create a URL for the PNG blob
        const imageURL = URL.createObjectURL(response.data);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `${endpoint.split("/")[1]}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        toast.error("PDF not found!");
      }
    }
  };

  const handleDownloadExcel = async () => {
    const params = `&search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
      filter.date_from || ""
    }&date_to=${filter.date_to || ""}`;

    try {
      const response = await http.get(`/return_breakage/export/return_voucher/?export=excel${params}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      });

      // Create a URL for the EXCEL blob
      const excelURL = URL.createObjectURL(response.data);

      // Create a temporary anchor element to trigger the download
      const link = document.createElement("a");
      link.href = excelURL;
      link.download = `return_voucher`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("EXCEL not found!");
    }
  };

  return (
    <>
      <div className="h-full w-full">
        <div className="flex flex-col content-center h-[83vh]">
          <h3 className="p-[20px] text-xl border-b-2 border-neutral-200 font-semibold text-neutral-500">
            Previous Return Vouchers
          </h3>
          <div className="flex justify-between items-center p-[20px] pb-[10px]">
            <div className="max-w-fit">
              <div className="flex">
                <div>
                  <label for="default-search" className="sr-only">
                    Search
                  </label>
                  <div className="flex items-center">
                    <input
                      type="search"
                      onChange={(e) => setDebouncedSearch(e.target.value)}
                      id="joyride-step-1"
                      className="border-[1px] border-neutral-400 px-[20px] py-[8px] w-[350px] rounded-full focus:outline-none"
                      placeholder="Search"
                      required
                    />
                    <div className="-translate-x-[103%]">
                      <IconWrapper>
                        <Search className="z-10 text-white" />
                      </IconWrapper>
                    </div>
                  </div>
                </div>
                <div className="flex -ml-[25px]">
                  <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
                </div>
              </div>
            </div>
            <div className="flex gap-[10px]">
              <button
                className="flex gap-[5px] bg-black text-white px-[20px] py-[10px] rounded-full"
                onClick={() => handleDownloadExcel()}
              >
                <span className="capitalize">Export To Excel</span>
              </button>
            </div>
          </div>

          {/* return vouchercontainer  */}
          <div className="w-full border-b-2 md:border-b-0 py-[20px] md:border-r-2 border-neutral-200">
            <div className="px-[20px] max-h-[76vh] overflow-y-scroll border-t-2 border-neutral-200 py-[20px]">
              <InfiniteScroll
                dataLength={returnVouchers.length}
                next={fetchNextReturnVouchers}
                hasMore={hasMoreReturnVouchers}
                height={"55vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Return Vouchers List End</b>
                  </p>
                }
              >
                <table border="1" className="table-auto w-full">
                  <thead className="bg-gray-50">
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Date
                    </th>
                    {/* <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Invoice No.
                    </th> */}
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Category
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Vendor
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Received Amount
                    </th>
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Liability Amount
                    </th>

                    {/* <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Status
                    </th> */}
                    <th
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                      scope="col"
                    >
                      Voucher PDF
                    </th>
                    {/* {!isCancelled && (
                      <th
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                        scope="col"
                      >
                        Invoice Action
                      </th>
                    )}
                    {isCancelled && (
                      <th
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                        scope="col"
                      >
                        Invoice Status
                      </th>
                    )} */}
                  </thead>
                  <tbody>
                    {returnVouchers?.map((voucher) => (
                      <tr key={voucher?.id}>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {moment(voucher.date).format("LLL")}
                        </td>
                        {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {invoice?.invoice_counter}
                        </td> */}
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {voucher?.reason}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {voucher?.vendor?.vendor_name}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          Rs {voucher?.received_amount}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          Rs {voucher?.liability_amount}
                        </td>
                        {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {invoice?.payment_type}
                        </td> */}
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-blue-500 font-bold bg-white border-[1px] border-neutral-200">
                          {/* <Link to={voucher?.return_pdf} target="_blank" rel="noopener noreferrer"> */}
                          <button className="capitalize" onClick={() => handleDownloadPDF(voucher?.return_pdf)}>
                            download
                          </button>
                          {/* </Link> */}
                        </td>
                        {/* {!isCancelled && (
                          <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-red-500 font-semibold bg-white border-[1px] border-neutral-200">
                            <button onClick={() => deleteInvoice(invoice?.id)}>Cancel Invoice</button>
                          </td>
                        )}
                        {isCancelled && (
                          <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-red-500 font-semibold bg-white border-[1px] border-neutral-200">
                            Cancelled
                          </td>
                        )} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreviousVouchers;
