import React, { useEffect, useState } from "react";
// import "./Navbar.scss"; TODO - delete if nothing breaks
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearSession } from "../../../utils/session";
// import { Alert, Snackbar } from "@mui/material";
// import { reset, toggleSidebar } from "../../../reducers/Slices/appSlice";
import profileDefault from "../../../assets/images/profile-default.jpg";
import { localStorgeProfileCache } from "../../../reducers/Slices/userSlice";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../../config/firebase.js";
//pHaniver - Remove - Show menu state and function when reference 0

//UI components and imports
import IconWrapper from "../../MicroComponents/IconWrapper.jsx";
import { Bell, Search } from "react-feather";
import { toggleGlobalSearch, unsubscribeFirebaseNotification } from "../../../reducers/Slices/appSlice.js";
import toast from "react-hot-toast";
import moment from "moment";

const NavBar = () => {
  const data = useSelector((state) => state.user);
  // const alert = useSelector((state) => state.app.alert);
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(null);
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
  const [notifications, setNotifications] = useState([]); // State to hold incoming notifications

  useEffect(() => {
    dispatch(localStorgeProfileCache());

    // Function to save notification with a timestamp
    const saveNotification = (notification) => {
      const notifications = JSON.parse(localStorage.getItem("notifications")) || [];
      const currentDate = new Date().toISOString(); // Get the current date as an ISO string
      notification.receivedAt = currentDate; // Add the timestamp to the notification

      // Add the new notification to the list and update localStorage
      const updatedNotifications = [notification, ...notifications];
      localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
    };

    // Function to retrieve notifications, filtering out those older than 3 days
    const getValidNotifications = () => {
      const now = new Date();
      const threeDaysAgo = now.setDate(now.getDate() - 3);
      const notifications = JSON.parse(localStorage.getItem("notifications")) || [];

      // Filter out expired notifications
      const validNotifications = notifications.filter((notification) => {
        const notificationDate = new Date(notification.receivedAt);
        return notificationDate.getTime() > threeDaysAgo;
      });

      // Update localStorage with valid notifications
      localStorage.setItem("notifications", JSON.stringify(validNotifications));

      return validNotifications;
    };

    // Check for existing valid notifications in localStorage and set state
    const cachedNotifications = getValidNotifications();
    setNotifications(cachedNotifications);

    if (messaging) {
      const unsubscribeOnMessage = onMessage(messaging, (payload) => {
        saveNotification(payload.notification); // Save the new notification to localStorage

        // Update the notifications state with the new message
        setNotifications((prevNotifications) => {
          const newNotifications = [
            { ...payload.notification, receivedAt: new Date().toISOString() },
            ...prevNotifications,
          ];
          return newNotifications;
        });
      });

      return () => {
        unsubscribeOnMessage();
      };
    }
  }, [dispatch]);

  const notificationTimingFormat = (receivedAt) => {
    const formattedDate = moment(receivedAt).format("MMMM Do, YYYY"); // e.g., "August 22nd, 2024"
    const formattedTime = moment(receivedAt).format("h:mm A"); // e.g., "4:35 PM

    return `${formattedDate} at ${formattedTime}`;
  };

  //Unsubscribe from notifications if notification permission is disabled.
  const unsubscribeTokenGeneratedByFirebase = (token) => {
    dispatch(unsubscribeFirebaseNotification({ device_token: token }))
      .then((action) => {
        if (action?.payload?.status === "success") {
          localStorage.removeItem("device_token", token); // Save the token in localStorage
          clearSession();
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleLogout = () => {
    const device_token = localStorage.getItem("device_token");
    if (device_token) {
      unsubscribeTokenGeneratedByFirebase(device_token);
    } else {
      clearSession();
      window.location.reload();
    }
  };

  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  // Moved from here to profile
  // const handleDeleteAccount = () => {
  //   if (window.confirm("Are you sure you want to delete your account?")) {
  //     dispatch(userDelete());
  //   }
  // };

  return (
    <>
      <div className="z-50">
        {/* <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={alert.showAlert}
          autoHideDuration={5000}
          onClose={() => dispatch(reset())}
        >
          <Alert onClose={() => dispatch(reset())} severity={alert.type} variant="filled" sx={{ width: "100%" }}>
            {alert.message}
          </Alert>
        </Snackbar> */}

        {/* <div className="bg-white"> duplicate */}
        <div className="flex-col flex">
          {/* pHaniver - div - Removed md:w-[76.5vw] min-[1124px]:min-w-[84vw] w-screen */}
          <div className="relative border-b-2 border-gray-200 h-[80px] flex items-center">
            <div className="bg-white justify-between w-full items-center mx-auto px-4 flex">
              {/* <img
                src={"http://localhost:3000/menu.png"}
                onClick={() => dispatch(toggleSidebar())}
                className="md:hidden block w-50 h-50 mr-2 bg-transparent"
                alt={"menu"}
              /> */}
              {/* pHaniver - whole div - Removed lg:block lg:block mr-auto mr-4 relative max-w-xs */}
              {/* div */}
              {/* pHaniver - div - Adding hidden lg:block  */}
              {/* phaniver - div - Removed flex flex-row justify-between */}
              {/* <Link
                to={"/"}
                className={`text-[#277494] font-semibold text-2xl lg:hidden`}
                style={{ fontFamily: "Comfortaa, sans-serif" }}
              >
                profit<span className="text-orange-500">.AI</span>{" "}
              </Link> */}

              <div className="hidden lg:block">
                <div>
                  <p className="font-semibold text-lg">{data.business_name}</p>
                  {data?.gst_number && (
                    <p className="font-light text-xs">
                      GST: <span className="text-blue-600"> {data.gst_number.toUpperCase()} </span>
                    </p>
                  )}
                </div>
              </div>
              <div className=" justify-between items-center ml-auto flex space-x-3 ">
                <div className="md:block hidden">
                  {/* phaniver - ul - Removed flex-col p-4 md:p-0 mt-4  md:flex-row md:space-x-4 rtl:space-x-reverse md:mt-0 */}
                  {/* phaniver - ul - Adding flex-row */}
                  <ul className="font-medium flex flex-row gap-6 justify-center">
                    <li>
                      {/* phaniver - link - Removed block bg-transparent py-2 px-3 rounded */}
                      {/* phaniver - link - Adding hover:text-orange-500 */}
                      <Link
                        to="/invoice/"
                        className="text-xs lg:text-sm text-nowrap whitespace-nowrap hover:text-orange-500"
                        aria-current="page"
                      >
                        + Invoice
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/purchase"
                        className="text-xs lg:text-sm text-nowrap whitespace-nowrap hover:text-orange-500"
                        aria-current="page"
                      >
                        + Purchase Invoice
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/ewaybill"
                        className="text-xs lg:text-sm text-nowrap whitespace-nowrap hover:text-orange-500"
                        aria-current="page"
                      >
                        E-way
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/online-orders"
                        className="text-xs lg:text-sm text-nowrap whitespace-nowrap hover:text-orange-500"
                        aria-current="page"
                      >
                        Online Orders
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* pHaniver - whole div - removing code  */}
                {/* <div className="relative">
                  <p
                    className="pt-1 pr-1 pb-1 pl-1 bg-white text-gray-700 rounded-full transition-all duration-200
                hover:text-gray-900 focus:outline-none hover:bg-gray-100"
                  >
                    <span className="items-center justify-center flex">
                      <svg
                        class="w-4 h-4 me-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </span>
                  </p>
                </div>
                <div className="relative">
                  <p
                    className="pt-1 pr-1 pb-1 pl-1 bg-white text-gray-700 rounded-full transition-all duration-200
                hover:text-gray-900 focus:outline-none hover:bg-gray-100"
                  >
                    <span className="justify-center items-center flex">
                      <span className="justify-center items-center flex">
                        <span className="items-center justify-center flex">
                          <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewbox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4
                        0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6
                        0H9"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className="px-1.5 py-0.5 font-semibold text-xs items-center bg-indigo-600 text-white rounded-full inline-flex
                absolute -top-px -right-1"
                  >
                    2
                  </p>
                </div> */}

                {/* pHaniver - div - Added replacement for above code  */}
                <div className="flex flex-row gap-2 px-2 relative ">
                  <button onClick={() => dispatch(toggleGlobalSearch())}>
                    <IconWrapper>
                      <Search className="text-white z-10" />
                    </IconWrapper>
                  </button>

                  <button onClick={() => setShowNotificationBar(!showNotificationBar)}>
                    <IconWrapper notification={notifications.length > 0 ? true : false}>
                      <Bell className="text-white z-10" />
                    </IconWrapper>
                  </button>

                  {/* Show Notifcation Bar */}
                  {showNotificationBar && (
                    <div className="absolute border border-neutral-200 rounded-lg min-h-fit max-h-[50vh] overflow-y-scroll w-[20vw] mt-[60px] z-10 bg-white -translate-x-[30%]">
                      <div className="relative w-full h-full flex flex-col text-sm text-neutral-500 gap-[10px] p-[10px]">
                        {notificationPermission === "granted" ? (
                          notifications.length > 0 ? (
                            notifications.map((notification, index) => (
                              <div
                                key={index}
                                className="border border-neutral-200 w-full flex flex-col px-[20px] py-[20px] rounded-[5px]"
                              >
                                <h3 className="font-semibold text-lg">{notification.title}</h3>
                                <small className="mb-[5px]">{notificationTimingFormat(notification.receivedAt)}</small>
                                <p>{notification.body}</p>
                              </div>
                            ))
                          ) : (
                            <div className="h-full w-full flex items-center justify-center">No New Notification!</div>
                          )
                        ) : (
                          <div className="h-full w-full flex items-center justify-center">
                            Enable Notification Permission to see notifications
                          </div>
                        )}
                        {notifications.length > 0 && (
                          <button
                            className="sticky bottom-[10px] rounded-[5px] font-semibold text-white bg-black py-[10px]"
                            onClick={() => {
                              setNotifications([]);
                              localStorage.removeItem("notifications");
                              setShowNotificationBar(false);
                            }}
                          >
                            Clear Notifications
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="justify-center items-center flex cursor-pointer"
                  onClick={() => handleShowMenu("settings")}
                >
                  <img
                    src={data?.profile_picture ? data?.profile_picture : profileDefault}
                    className="object-cover btn- h-[50px] w-[50px] rounded-full mr-2 bg-gray-300"
                    alt={`profile of ${data.business_name}`}
                  />
                  <p className="font-bold text-xs lg:text-sm pr-2">{data.business_name}</p>
                  <div
                    id="dropdownInformation"
                    onMouseLeave={() => handleShowMenu("settings")}
                    className={`${
                      showMenu !== "settings" ? "hidden" : ""
                    } -ml-16 mt-40 absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
                  >
                    <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                      <li>
                        <Link to="/profile" className="block px-4 py-2 hover:bg-gray-100  ">
                          Profile
                        </Link>
                      </li>
                      {/* moved from here to profile section */}
                      {/* <li>
                        <Link
                          to="#"
                          className="block px-4 py-2 hover:bg-gray-100"
                          onClick={() => handleDeleteAccount()}
                        >
                          Delete account
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/current_plan" className="block px-4 py-2 hover:bg-gray-100">
                          Current Plan
                        </Link>
                      </li>
                    </ul>
                    <div className="py-2">
                      <Link
                        to="#"
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Sign out
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default NavBar;
