function ButtonWrapper({ eventCallback, children, type = "button", themeColor = "black" }) {
  // Set color style of button
  const colorStyle = themeColor === "black" ? "bg-black hover:bg-neutral-800" : "bg-red-600 hover:bg-red-500";

  return (
    <button
      type={type}
      className={`${colorStyle} hover:bg-neutral-800 text-nowrap text-white text-lg py-[8px] px-[20px] max-h-[44px] rounded-full`}
      onClick={eventCallback}
    >
      {children}
    </button>
  );
}

export default ButtonWrapper;
