import React, { useState, useEffect } from "react";
import { salesAnalyticsByProductId } from "../../../reducers/Slices/productSlice";
import { fetchInvoiceByProductId } from "../../../reducers/Slices/invoiceSlice";
import { useDispatch } from "react-redux";
import { fetchMoreProducts, searchMoreProducts } from "../../../reducers/Slices/productSlice";
import InfiniteScroll from "react-infinite-scroll-component";

function ShowProducts({ setProduct, product, products, next, searched }) {
  const dispatch = useDispatch();
  const [hasMoreProducts, setHasMoreProducts] = useState(() => (next ? true : false));

  const handleShowProduct = (productItem) => {
    setProduct(productItem);
    dispatch(fetchInvoiceByProductId(productItem.id));
    dispatch(salesAnalyticsByProductId(productItem.id));
  };

  //Use effect to fetch more products based on value of next
  useEffect(() => {
    if (next) {
      setHasMoreProducts(true);
    } else {
      setHasMoreProducts(false);
    }
  }, [next]);

  //fetch more products on scroll if available
  const fetchNextProducts = () => {
    if (!searched) {
      if (next) {
        dispatch(fetchMoreProducts(next));
      }
    } else {
      if (next) {
        dispatch(searchMoreProducts(next));
      }
    }
  };

  return (
    <div>
      {/* pHaniver - Div - Removed mt-2 h-[79vh] -mt-1 overflow-y-scroll */}
      <InfiniteScroll
        dataLength={products.length}
        next={fetchNextProducts}
        hasMore={hasMoreProducts}
        height={"75vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
        endMessage={
          <p style={{ textAlign: "center", paddingTop: "10px" }}>
            <b>Products List End</b>
          </p>
        }
      >
        {products.map((item, index) => (
          //   pHaniver - Div - Removed
          <div
            key={index}
            onClick={() => handleShowProduct(item)}
            className={`${
              product?.id === item?.id ? "border-black" : ""
            } border-2 hover:border-blue-500 focus:border-black p-2 rounded-lg bg-white cursor-pointer shadow mb-2`}
          >
            <div className="relative flex text-sm items-center justify-between gap-2 pb-2 pl-2 ">
              <div>
                {/* pHaniver - Changed - {item.product_name} to hardcoded value */}
                <h3 className="text-base font-semibold text-black">{item.product_name}</h3>
                {/* pHaniver - Changed - h3 -> span, {item.product_type.category_name} -> Electronics hardcoded value*/}
                <h3 className="text-gray-500">{item.brand}</h3>
              </div>
              {/* <div className="font-bolder">
                      <h3 className="text-gray-500">Qty</h3> */}
              {/* phaniver - Changed h3 -> span, {item.total_quantity} -> 20 hardcoded  */}
              {/* <span className="text-gray-500">20</span>
                    </div>
                  </div>
                  <div className="relative flex justify-between gap-2 pb-2 pl-2">
                    <div className="font-bolder text-sm">
                      <h3 className="font-medium">Retail Price</h3> */}
              {/* phaniver - Changed h3 -> span, {item.mrp-price} -> 200 hardcoded  */}
              {/* <span className="text-gray-500">₹200</span>
                    </div>
                    <div className="font-bolder text-sm">
                      <h3 className="font-medium">Purchase Price</h3> */}
              {/* phaniver - Changed h3 -> span, {item.purchase_price} -> 150 hardcoded  */}
              {/* <span className="text-gray-500">₹150</span>
                    </div> */}

              {/* Replace Status Code */}
              {/* <div className="font-bolder text-sm">
                      <h3 className="font-medium">Status</h3>
                      <div className="w-4 h-4 rounded-full bg-green-800 mx-auto"></div> */}

              {/* New Status Code */}
              <div>
                {item?.is_service === "N" && (
                  <h3 className="text-base flex">
                    <span className="text-gray-500 text-sm px-2">Status: </span>
                    {item?.remaining_quantity > 0 ? (
                      <span className="text-green-500 text-sm">Available</span>
                    ) : (
                      <span className="text-red-500 text-sm">Not Available</span>
                    )}
                  </h3>
                )}
                {item?.is_service === "N" && (
                  <h3 className="px-2 text-gray-500 text-sm">Quantity: {item?.remaining_quantity}</h3>
                )}
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default ShowProducts;
