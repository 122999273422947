import React from "react";

import ZohoBooks from "./SoftwareOnboardingGuides/ZohoBooks";
import MargERP from "./SoftwareOnboardingGuides/MargERP";
import TallyERP from "./SoftwareOnboardingGuides/TallyERP";
import Others from "./SoftwareOnboardingGuides/Others";

function SelectedSoftwareSteps({ migrationSoftware, smallScreen = false }) {
  const softwareGuideRenderer = () => {
    switch (migrationSoftware) {
      case "Marg":
        return <MargERP smallScreen={smallScreen} />;
      case "Tally":
        return <TallyERP smallScreen={smallScreen} />;
      case "Zoho":
        return <ZohoBooks smallScreen={smallScreen} />;
      default:
        return <Others smallScreen={smallScreen} />;
    }
  };

  return (
    <div>
      <h1 className={`text-[32px] font-semibold ${smallScreen ? "pt-[8vh]" : "pt-[10px]"} pb-[20px] text-center`}>
        Migration Guide for {migrationSoftware}.
      </h1>
      <div className={`overflow-y-scroll ${smallScreen ? "max-h-[65vh]" : "max-h-[80vh]"}`}>
        {softwareGuideRenderer()}
      </div>
    </div>
  );
}

export default SelectedSoftwareSteps;
