import React, { useEffect, useState } from "react";

import multiDevicesImage from "../../assets/images/multiple_devices.jpg";
import rocket from "../../assets/images/rocket.jpg";

function Welcome() {
  const [localStorageWelcomeStatus, setLocalStorageWelcomeStatus] = useState(localStorage.getItem("showed_welcome"));

  const handleContinue = () => {
    localStorage.setItem("showed_welcome", true);
    setLocalStorageWelcomeStatus(true);
  };

  return (
    <div
      className={`${
        localStorageWelcomeStatus ? "hidden" : ""
      } absolute z-[999] h-[100vh] w-[100vw] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center -mt-[1px]`}
    >
      <div className="w-[100vw] h-[100vh] bg-black opacity-50 absolute top-0 left-0"></div>
      <div className="bg-white h-[80vh] w-[80vw] flex flex-col items-center z-10 rounded relative">
        <div className="py-[5vh] text-center">
          <h1 className="text-3xl pb-[10px] font-semibold">Welcome to the TheProfit.AI's ERP dashboard</h1>
          <p className="max-w-[50vw]">
            Boost your business with TheProfit.Ai! AI-powered GST billing, smart analytics, inventory, and accounting -
            all in one. Make data-driven decisions today.
          </p>
        </div>
        <div className="grid grid-cols-3 px-[2vmax] gap-[10px]">
          <div className="h-[14vh] 2xl:h-[14vh] w-[25vw] 2xl:w-[20vw] p-[20px] rounded-lg  bg-indigo-50">
            <h3 className="text-center pb-[10px] font-semibold text-sm 2xl:text-base">Inventory Managment</h3>
            <p className="text-center text-xs 2xl:text-base">
              Efficiently track and manage your inventory in real-time, helping you stay organized and prevent
              stockouts.
            </p>
          </div>
          <div className="h-[14vh] 2xl:h-[14vh] w-[25vw] 2xl:w-[20vw] p-[20px] rounded-lg bg-indigo-50 ">
            <h3 className="text-center pb-[10px] font-semibold text-sm 2xl:text-base">Accounting</h3>
            <p className="text-center text-xs 2xl:text-base">
              Simplify your financial operations with automated accounting that keeps your books accurate and
              up-to-date.
            </p>
          </div>
          <div className="h-[14vh] 2xl:h-[14vh] w-[25vw] 2xl:w-[20vw] p-[20px] rounded-lg  bg-indigo-50 ">
            <h3 className="text-center pb-[10px] font-semibold text-sm 2xl:text-base">Ledger Management</h3>
            <p className="text-center text-xs 2xl:text-base">
              Manage all your financial records and transactions seamlessly in one secure location.
            </p>
          </div>
          <div className="h-[14vh] 2xl:h-[14vh] w-[25vw] 2xl:w-[20vw] p-[20px] rounded-lg  bg-indigo-50 ">
            <h3 className="text-center pb-[10px] font-semibold text-sm 2xl:text-base">Sales Invoicing</h3>
            <p className="text-center text-xs 2xl:text-base">
              Create and manage sales invoices with ease, ensuring accurate billing and seamless payment tracking.
            </p>
          </div>
          <div className="h-[14vh] 2xl:h-[14vh] w-[25vw] 2xl:w-[20vw] p-[20px] rounded-lg  bg-indigo-50 ">
            <h3 className="text-center pb-[10px] font-semibold text-sm 2xl:text-base">Purchase Invoicing</h3>
            <p className="text-center text-xs 2xl:text-base">
              Streamline your purchasing process by managing all supplier invoices and payments in one place.
            </p>
          </div>
          <div className="h-[14vh] 2xl:h-[14vh] w-[25vw] 2xl:w-[20vw] p-[20px] rounded-lg  bg-indigo-50 ">
            <h3 className="text-center pb-[10px] font-semibold text-sm 2xl:text-base">Warehouse Management</h3>
            <p className="text-center text-xs 2xl:text-base">
              Optimize your warehouse operations with tools to manage stock levels, shipments, and storage efficiently.
            </p>
          </div>
          <div className="h-[14vh] 2xl:h-[14vh] w-[25vw] 2xl:w-[20vw] p-[20px] rounded-lg  bg-indigo-50 ">
            <h3 className="text-center pb-[10px] font-semibold text-sm 2xl:text-base">
              Online Store and Online Order Management
            </h3>
            <p className="text-center text-xs 2xl:text-base">
              Manage your online store, track orders, and fulfill customer needs from a single dashboard.
            </p>
          </div>
          <div className="h-[14vh] 2xl:h-[14vh] w-[25vw] 2xl:w-[20vw] p-[20px] rounded-lg  bg-indigo-50 ">
            <h3 className="text-center pb-[10px] font-semibold text-sm 2xl:text-base">
              Employee and Payroll Managment
            </h3>
            <p className="text-center text-xs 2xl:text-base">
              Handle payroll and employee data effortlessly, keeping your team’s details organized and accessible.
            </p>
          </div>
          <div className="h-[14vh] 2xl:h-[14vh] w-[25vw] 2xl:w-[20vw] p-[20px] rounded-lg  bg-indigo-50 ">
            <h3 className="text-center pb-[10px] font-semibold text-sm 2xl:text-base">Expenses Tracking</h3>
            <p className="text-center text-xs 2xl:text-base">
              Track expenses accurately, gain insights into spending patterns, and manage budgets effectively.
            </p>
          </div>
        </div>
        <button
          className="mt-[5vh] bg-indigo-500 font-semibold px-[20px] py-[10px] text-white rounded-md"
          onClick={() => handleContinue()}
        >
          Start Your Journey
        </button>
        <img
          src={multiDevicesImage}
          alt="Man on Graph illustration to show growth"
          className="h-[21vh] absolute bottom-0 right-0 -z-10"
        />
      </div>
    </div>
  );
}

export default Welcome;
