import React, { useState } from "react";

const JsonFileInput = ({ setJsonData }) => {
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const parsedData = JSON.parse(event.target.result);
          setJsonData(parsedData);
          setError(null); // Clear any previous errors
        } catch (error) {
          setError("Invalid JSON file. Please upload a valid JSON.");
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div>
      {/* Hidden file input */}
      <input id="file-upload-json" type="file" accept=".json" onChange={handleFileChange} className="hidden" />
      {/* Custom button */}
      <label htmlFor="file-upload-json" className="bg-black text-white py-[10px] px-[20px] rounded cursor-pointer">
        Select JSON File
      </label>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default JsonFileInput;
