import React from "react";
import onboarding from "../../../assets/images/onboarding.jpg";

function InitialWelcome() {
  return (
    <div className="w-full flex flex-col items-center">
      <h1 className="text-[40px] font-semibold">Welcome to TheProfit.AI's dashboard</h1>
      <p className="max-w-[600px] text-center py-[20px] text-lg text-neutral-500">
        Just a few more steps to complete your account setup. If you’re migrating data from another software, click
        ‘Continue’ to proceed, or select ‘Skip for Now’ for new users or to upload data within the software at a later
        time.
      </p>
      <img src={onboarding} className="max-w-[35vw]" alt="Software migration illustration" />
    </div>
  );
}

export default InitialWelcome;
