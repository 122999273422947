import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBatch, fetchHSNData, fetchProducts } from "../../../reducers/Slices/productSlice";

// pHaniver - TODO - move CustomModel to Macro Component
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";

//UI component
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import toast from "react-hot-toast";
import BarcodeInput from "./BarcodeInput";
import { Autocomplete, debounce, TextField } from "@mui/material";
import { fetchWarehouses } from "../../../reducers/Slices/warehouseSlice";
import { fetchVendors } from "../../../reducers/Slices/vendorSlice";

function AddBatch({ product }) {
  //Modal toggler
  const [showModal, setShowModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const vendors = useSelector((state) => state.vendor.vendors.data);

  //State to hold batch form data
  const [batchFormData, setBatchFormData] = useState({
    product_id: null,
    batches: [
      {
        size: null,
        total_quantity: 0,
        remaining_quantity: 0,
        sales_price: null,
        deal_quantity: 0,
        mrp_price: "",
        rate: "",
        purchase_price: "",
        tax: "",
        discount: "",
        add_margin: null,
        status: null,
        bar_code_number: null,
        hsn_number: null,
        batch_number: "",
        package: null,
        expiry_date: "",
        vendor: null,
        is_deleted: false,
      },
    ],
  });

  const getHSNTaxRates = async () => {
    if (product?.hsn_number) {
      const data = await getHSNDataByHSNCodeAsync(product?.hsn_number);

      if (!data) {
        return null;
      }

      const taxPercent = parseFloat(data?.cgst_rate) + parseFloat(data?.sgst_rate);
      const nextHSNTaxRateArr = [taxPercent ? taxPercent.toFixed(2) : 0] || [];
      sethSNTaxRates(nextHSNTaxRateArr);

      setBatchFormData((prevState) => {
        if (nextHSNTaxRateArr?.length === 1) {
          return {
            ...prevState,
            batches: [{ ...batchFormData.batches[0], tax: nextHSNTaxRateArr[0] }],
          };
        } else {
          return prevState;
        }
      });
    }
  };

  function handleAddBatchClick() {
    setShowModal(true);
    getHSNTaxRates();
  }

  function handleClose() {
    resetInitialValue();
    setShowModal(false);
    setDisableSubmitButton(false);
  }

  function resetInitialValue() {
    setBatchFormData({
      product_id: product.id,
      batches: [
        {
          size: null,
          total_quantity: 0,
          deal_quantity: 0,
          remaining_quantity: 0,
          sales_price: null,
          mrp_price: "",
          rate: "",
          purchase_price: "",
          tax: "",
          discount: "",
          add_margin: null,
          status: null,
          bar_code_number: null,
          hsn_number: null,
          batch_number: "",
          package: null,
          expiry_date: "",
          vendor: null,
          is_deleted: false,
        },
      ],
    });
  }

  useEffect(() => {
    const nextBatchFormData = { ...batchFormData, product_id: product.id };
    setBatchFormData(nextBatchFormData);
    //eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    setBatchFormData((prevState) => {
      const updatedBatches = prevState.batches.map((batch) => {
        return {
          ...batch,
          total_quantity: parseInt(batch?.remaining_quantity ?? 0) + parseInt(batch?.deal_quantity ?? 0),
        };
      });
      return { ...prevState, batches: updatedBatches };
    });
    //eslint-disable-next-line
  }, [batchFormData?.batches[0]?.remaining_quantity, batchFormData?.batches[0]?.deal_quantity]);

  const dispatch = useDispatch();

  function validateRequiredFields() {
    const { sales_price, mrp_price, purchase_price, tax, batch_number, expiry_date } = batchFormData.batches[0];
    if (
      batch_number?.length === 0 ||
      mrp_price?.length === 0 ||
      sales_price?.length === 0 ||
      purchase_price?.length === 0 ||
      tax?.length === 0 ||
      batch_number?.length === 0 ||
      expiry_date?.length === 0
    ) {
      toast.error("Please fill in all required fields marked with *.");
      return false;
    }
    return true;
  }

  function handleSubmit() {
    if (!validateRequiredFields()) return;

    setDisableSubmitButton(true);
    dispatch(createBatch(batchFormData))
      .then((action) => {
        if (action.payload) {
          setShowModal(false);
          dispatch(fetchProducts());
          resetInitialValue();
          setDisableSubmitButton(false);
        } else {
          toast.error("Sorry, batch cannot be added");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  }

  function handleDateChange(e) {
    setBatchFormData({
      ...batchFormData,
      batches: [{ ...batchFormData.batches[0], expiry_date: e.target.value }],
    });
  }

  //Barcode functionality
  const [barcodeNumber, setBarcodeNumber] = useState(null);

  //side effect for barcode.
  useEffect(() => {
    setBatchFormData((prevState) => {
      return { ...prevState, batches: [{ ...batchFormData.batches[0], bar_code_number: barcodeNumber }] };
    });
  }, [barcodeNumber]);

  const warehouses = useSelector((state) => state.warehouse.warehouses.data);

  //Debounced search term state to call fetch warehouse API
  const [warehouseSearchTerm, setWarehouseSearchTerm] = useState("");
  const setDebouncedWarehouseSearchTerm = debounce((value) => setWarehouseSearchTerm(value), 1000);

  useEffect(() => {
    if (warehouseSearchTerm?.length > 0) {
      dispatch(fetchWarehouses(`?search=${warehouseSearchTerm}`));
    } else {
      dispatch(fetchWarehouses());
    }
    // dispatch(searchProducts(productSearchTerm));
  }, [warehouseSearchTerm]);

  const findWarehouseById = (id) => {
    if (!id) {
      return;
    }
    return warehouses.find((warehouse) => warehouse.id === id);
  };

  const [hSNTaxRates, sethSNTaxRates] = useState([]);

  //HSN codes
  const HSN_data = useSelector((state) => state.product.HSNData.data);

  const getHSNDataByHSNCodeAsync = async (hsn_number) => {
    let data = HSN_data.find((HSNData) => HSNData?.hsn_code === hsn_number);

    try {
      if (!data) {
        const response = await dispatch(fetchHSNData(`?hsn_code=${hsn_number}`));
        data = response.payload.data[0];
      }
      return data;
    } catch (err) {
      toast.error("some error occurred while fetching HSN data");
      return null;
    }
  };

  useEffect(() => {
    getHSNTaxRates();
  }, [product?.hsn_number]);

  const findVendorById = (id) => {
    if (!id) {
      return;
    }
    return vendors.find((vendor) => vendor.id === id);
  };

  //vendor operations
  const [vendorSearchTerm, setVendorSearchTerm] = useState("");

  const setDebouncedVendorSearchTerm = debounce((value) => setVendorSearchTerm(value), 500);

  useEffect(() => {
    if (vendorSearchTerm.length > 0) {
      dispatch(fetchVendors(`?search=${vendorSearchTerm}`));
    }

    //eslint-disable-next-line
  }, [vendorSearchTerm]);

  // useEffect(() => {
  //   dispatch(fetchVendors());
  // }, []);

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center">Batch Description</div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="batch-number" className="text-neutral-500">
              Batch Number<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="batch-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.batch_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], batch_number: e.target.value }],
                })
              }
              required
            />
          </div>
          {/* <div>
            <label htmlFor="size">Size</label>
            <input
            type="text"
            id="size"
            value={batchFormData.batches[0].size}
            onChange={(e) =>
              setBatchFormData({ ...batchFormData, batches: [{ ...batchFormData.batches[0], size: e.target.value }] })
              }
              />
              </div> */}
          {/* <div>
            <label htmlFor="total-quantity">Total Quantity</label>
            <input
            type="number"
            id="total-quantity"
            value={batchFormData.batches[0].total_quantity}
            onChange={(e) =>
              setBatchFormData({
                ...batchFormData,
                batches: [{ ...batchFormData.batches[0], total_quantity: e.target.value }],
                })
                }
                />
                </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="remaining-quantity" className="text-neutral-500">
              Quantity<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="number"
              id="remaining-quantity"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.remaining_quantity}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], remaining_quantity: parseInt(e.target.value) }],
                })
              }
              required
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="rate" className="text-neutral-500">
            Deal/Free
            </label>
            <input
            type="text"
            id="rate"
            className="px-[20px] py-[10px] border-[1px] border-neutral-500"
            value={batchFormData?.batches[0]?.deal}
            // onChange={(e) =>
              //   setBatchFormData({
                //     ...batchFormData,
                //     batches: [{ ...batchFormData.batches[0], deal: e.target.value }],
                //   })
                // }
                disabled
                />
                </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="mrp-price" className="text-neutral-500">
              MRP Price<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="mrp-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.mrp_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], mrp_price: e.target.value }],
                })
              }
              required
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="sales-price" className="text-neutral-500">
            Sales Price*
            </label>
            <input
            type="text"
            id="sales-price"
            className="px-[20px] py-[10px] border-[1px] border-neutral-500"
            value={batchFormData.batches[0].sales_price}
            onChange={(e) =>
              setBatchFormData({
                ...batchFormData,
                batches: [{ ...batchFormData.batches[0], sales_price: e.target.value }],
                })
                }
                required
                />
                </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="rate" className="text-neutral-500">
              Rate A / Retail<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="rate"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.sales_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], sales_price: e.target.value }],
                })
              }
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="purchase-price" className="text-neutral-500">
              Purchase Price<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="purchase-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.purchase_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], purchase_price: e.target.value }],
                })
              }
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="tax" className="text-neutral-500">
              Tax %<span className="pl-1 text-red-500">*</span>
            </label>
            {hSNTaxRates?.length === 1 && (
              <input
                type="number"
                id="tax-percentage"
                className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                value={batchFormData?.batches[0]?.tax}
                onChange={(e) => {
                  setBatchFormData({
                    ...batchFormData,
                    batches: [{ ...batchFormData.batches[0], tax: e.target.value }],
                  });
                }}
                disabled
                required
              />
            )}
            {hSNTaxRates?.length > 1 && (
              <select
                id="tax-percentage"
                className="border-[1px] border-neutral-500 px-[10px] py-[10px] bg-white"
                value={batchFormData?.batches[0]?.tax}
                onChange={(e) =>
                  setBatchFormData({
                    ...batchFormData,
                    batches: [{ ...batchFormData.batches[0], tax: e.target.value }],
                  })
                }
                required
              >
                <option value="" disabled>
                  Select Tax Percentage
                </option>
                {hSNTaxRates.map((rate) => (
                  <option key={rate} value={rate}>
                    {rate}%
                  </option>
                ))}
              </select>
            )}
          </div>
          <div>
            <div className="flex flex-col gap-[10px]">
              <label htmlFor="vendor" className="text-neutral-500">
                vendor
              </label>
              <Autocomplete
                disablePortal
                id="vendor"
                options={vendors}
                value={findVendorById(batchFormData?.batches[0]?.vendor)}
                getOptionLabel={(option) => option?.vendor_name}
                onChange={(event, value) =>
                  setBatchFormData({
                    ...batchFormData,
                    batches: [{ ...batchFormData?.batches[0], vendor: value?.id }],
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} required onChange={(e) => setDebouncedVendorSearchTerm(e.target.value)} />
                )}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-col gap-[10px]">
              <label htmlFor="warehouse" className="text-neutral-500">
                Warehouse
              </label>
              <Autocomplete
                disablePortal
                id="warehouse"
                options={warehouses || []}
                value={findWarehouseById(batchFormData?.batches[0].warehouse)}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  setBatchFormData({
                    ...batchFormData,
                    batches: [{ ...batchFormData.batches[0], warehouse: value?.id }],
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} onChange={(e) => setDebouncedWarehouseSearchTerm(e.target.value)} />
                )}
              />
            </div>
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="discount" className="text-neutral-500">
            Discount %
            </label>
            <input
            type="text"
            id="discount"
            className="px-[20px] py-[10px] border-[1px] border-neutral-500"
            value={batchFormData?.batches[0]?.discount}
            onChange={(e) =>
              setBatchFormData({
                ...batchFormData,
                batches: [{ ...batchFormData.batches[0], discount: e.target.value }],
                })
                }
                />
                </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="add-margin" className="text-neutral-500">
            Add Margin %
            </label>
            <input
            type="text"
            id="add-margin"
            className="px-[20px] py-[10px] border-[1px] border-neutral-500"
            value={batchFormData.batches[0].add_margin}
            onChange={(e) =>
              setBatchFormData({
                ...batchFormData,
                batches: [{ ...batchFormData.batches[0], add_margin: e.target.value }],
                })
                }
                />
                </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="bar-code-number" className="text-neutral-500">
            Bar Code Number*
            </label>
            <input
            type="text"
            id="bar-code-number"
            className="px-[20px] py-[10px] border-[1px] border-neutral-500"
            value={batchFormData.batches[0].bar_code_number}
            onChange={(e) =>
              setBatchFormData({
                ...batchFormData,
                batches: [{ ...batchFormData.batches[0], bar_code_number: e.target.value }],
                })
                }
                required
                />
                </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="hsn-number" className="text-neutral-500">
            HSN Number*
            </label>
            <input
            type="text"
            id="hsn-number"
            className="px-[20px] py-[10px] border-[1px] border-neutral-500"
            value={batchFormData.batches[0].hsn_number}
            onChange={(e) =>
              setBatchFormData({
                ...batchFormData,
                  batches: [{ ...batchFormData.batches[0], hsn_number: e.target.value }],
                  })
                  }
                  required
                  />
                  </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="package" className="text-neutral-500">
            Package*
            </label>
            <input
            type="text"
            id="package"
            className="px-[20px] py-[10px] border-[1px] border-neutral-500"
            value={batchFormData.batches[0].package}
            onChange={(e) =>
              setBatchFormData({
                ...batchFormData,
                batches: [{ ...batchFormData.batches[0], package: e.target.value }],
                })
                }
                required
                />
                </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="status" className="text-neutral-500">
            Status*
            </label>
            <input
            type="text"
            id="status"
            className="px-[20px] py-[10px] border-[1px] border-neutral-500"
            value={batchFormData.batches[0].status}
            onChange={(e) =>
              setBatchFormData({
                ...batchFormData,
                batches: [{ ...batchFormData.batches[0], status: e.target.value }],
                })
                }
                required
                />
                </div> */}
          {/* For Barcode  */}
          <div className="relative">
            <BarcodeInput barcodeNumber={barcodeNumber} setBarcodeNumber={setBarcodeNumber} />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="expiry-date" className="text-neutral-500">
              Expiry Date<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="date"
              id="expiry-date"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batches[0]?.expiry_date}
              onChange={handleDateChange}
              required
            />
          </div>
          <div className="flex gap-[20px] justify-center">
            <button
              className="save px-[20px] py-[10px] rounded-full bg-black text-white"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
            >
              Save
            </button>
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <div id="joyride-step-8">
        <ButtonWrapper eventCallback={() => handleAddBatchClick(true)}>+ Add Batch</ButtonWrapper>
      </div>
    </>
  );
}

export default AddBatch;
