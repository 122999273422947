import React, { useEffect, useRef, useState } from "react";

function BarcodePopup({ index, setItems, setBarcodePopup }) {
  const [barcodeNumber, setBarcodeNumber] = useState("");
  const timer = useRef(null);

  //Local input ref
  const barcodeInputRef = useRef(null);

  //Focus on this barcode input on mount;
  useEffect(() => {
    barcodeInputRef.current.focus();
  }, []);

  // Handle manually entering the barcode
  const handleInputChange = (e) => {
    setBarcodeNumber(e.target.value);
    // Clear the previous timer if it's still running
    if (timer.current) {
      clearTimeout(timer.current);
    }

    // Set a new timer to update the state after a delay
    timer.current = setTimeout(() => {
      setItems((prevState) => {
        return prevState.map((item, i) => {
          if (i === index) {
            return { ...item, batch: { ...item.batch, bar_code_number: barcodeNumber } };
          }
          return item;
        });
      });

      setBarcodePopup(false);
      barcodeInputRef.current.blur();
    }, 500);
  };

  // Function to generate a random 12-digit barcode number (UPC-A)
  const generateRandomBarcode = () => {
    const randomElevenDigits = Math.floor(Math.random() * 1e11)
      .toString()
      .padStart(11, "0");

    const checkDigit = calculateCheckDigit(randomElevenDigits);
    const randomBarcode = randomElevenDigits + checkDigit;

    setItems((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          return { ...item, batch: { ...item.batch, bar_code_number: randomBarcode } };
        }
        return item;
      });
    });
    setBarcodePopup(false);
  };

  // Function to calculate the check digit for UPC-A (12th digit)
  const calculateCheckDigit = (barcode) => {
    let sumOdd = 0;
    let sumEven = 0;

    for (let i = 0; i < barcode.length; i++) {
      if (i % 2 === 0) {
        sumOdd += parseInt(barcode[i], 10);
      } else {
        sumEven += parseInt(barcode[i], 10);
      }
    }

    const totalSum = sumOdd * 3 + sumEven;
    const checkDigit = (10 - (totalSum % 10)) % 10; // Mod 10 to ensure it's a single digit
    return checkDigit;
  };

  return (
    <div className="fixed left-[50%] -translate-x-[35%] top-[50%] -translate-y-[50%] flex items-center justify-center bg-opacity-75 z-50 border-2 border-neutral-200 rounded-md">
      <input
        type="text"
        value={barcodeNumber}
        onChange={(e) => handleInputChange(e)}
        className="sr-only"
        ref={barcodeInputRef} // reference to the input field
      />
      <div className="bg-white rounded-lg p-6 w-96 flex flex-col justify-center items-center">
        <h2 className="text-xl font-semibold mb-2">Scan or Generate Barcode</h2>
        <p className="text-gray-600 mb-2 text-center">
          Scan barcode using your barcode scanner or generate random code.
        </p>
        <button
          className="mt-4 px-4 py-2 text-white bg-black font-semibold rounded-lg w-full"
          onClick={() => generateRandomBarcode()}
        >
          Generate Barcode
        </button>
        <button
          className="mt-4 px-4 py-2 border-2 text-red-500 border-red-600 font-semibold rounded-lg hover:bg-red-600 hover:text-white transition-all focus:outline-none w-full"
          onClick={() => setBarcodePopup(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default BarcodePopup;
