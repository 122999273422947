import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../../../reducers/Slices/customerSlice";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { fetchProducts, searchProducts } from "../../../../reducers/Slices/productSlice";
import { subDays } from "date-fns";

//Custom Components
import DateRangeSelector from "../../../ProfitAndLoss/profitAndLossComponents/DateRangeSelector";
import StatementsTable from "./StatementsTable";

function CustomerWise() {
  const dispatch = useDispatch();

  const customers = useSelector((state) => state.customer.customers.data) || [];
  const products = useSelector((state) => state.product.products.data) || [];
  const Companies = [
    "Tech Innovations Inc.",
    "Global Solutions Ltd.",
    "EcoSmart Technologies",
    "NextGen Systems LLC",
    "FutureWave Enterprises",
    "Skyline Dynamics",
    "Quantum Leap Industries",
    "Visionary Creations Co.",
    "Pinnacle Consulting Group",
    "Blue Horizon Ventures",
  ];

  //Handle Date Range Operations
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //Customer Selection Operations
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerSearchTerm, setCustomerSearchTerm] = useState("");
  const setDebouncedCustomerSearchTerm = debounce((value) => setCustomerSearchTerm(value));

  useEffect(() => {
    if (customerSearchTerm.length > 0) {
      dispatch(fetchCustomers(`?search=${customerSearchTerm}`));
    } else {
      dispatch(fetchCustomers());
    }
    //eslint-disable-next-line
  }, [customerSearchTerm]);

  //Product Selection Operations - todo - if company is selected then only show products of that company only.
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 1000);

  useEffect(() => {
    if (productSearchTerm.length > 0) {
      dispatch(searchProducts(`?product_search=${productSearchTerm}`));
    } else {
      dispatch(fetchProducts());
    }
    //eslint-disable-next-line
  }, [productSearchTerm]);

  //Company Selection Operation
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companySearchTerm, setCompanySearchTerm] = useState("");
  const setDebouncedCompanySearchTerm = debounce((value) => setCompanySearchTerm(value), 1000);

  useEffect(() => {
    if (productSearchTerm.length > 0) {
      // dispatch(searchProducts(productSearchTerm));
      // console.log(companySearchTerm);
    } else {
      // dispatch(fetchProducts());
    }
    //eslint-disable-next-line
  }, [companySearchTerm]);

  //Download operations
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState("pdf");

  const handleDownload = (e) => {
    e.preventDefault();
    alert(`Selected Download Format is ${selectedDownloadFormat}`);
    // if (!statementsDownloadLink) {
    //   return;
    // }
    // if (get_url_extension(statementsDownloadLink) !== selectedDownloadFormat) {
    //   return;
    // }
    // const fileUrl = statementsDownloadLink;
    // const link = document.createElement("a");
    // link.href = fileUrl;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  //Statement Table Operations

  const columns = [
    ["Date", "date"],
    ["Invoice ID", "invoice_counter"],
    ["Qty", "volume_of_invoice"],
    ["Tax", "tax_absolute"],
    ["Amount", "amount"],
    ["Debit", "total_unpaid"],
    ["Credit", "total_paid"],
    ["Profit margin", "profit_margin_absolute"],
  ];

  const dummyData = [];

  for (let i = 0; i < 10; i++) {
    const date = new Date(Date.now() - Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 30)).toLocaleDateString();

    // Randomly decide if these values should be null (20% chance)
    const shouldBeNull = Math.random() < 0.2; // 20% chance for null values

    const invoiceID = shouldBeNull ? null : `INV${1000 + i}`; // Set to null or generate ID
    const qty = shouldBeNull ? null : Math.floor(Math.random() * 100) + 1; // Set to null or random qty
    const amount = qty !== null ? (qty * 20).toFixed(2) : 200; // Calculate amount if qty is not null
    const tax = !shouldBeNull ? (amount * 0.1).toFixed(2) : null; // Calculate tax if amount is not null
    const totalAmountWithTax = !shouldBeNull
      ? (parseFloat(amount) + parseFloat(tax)).toFixed(2)
      : (parseFloat(amount) + parseFloat(0)).toFixed(2); // Total amount with tax
    const debit = totalAmountWithTax; // Total unpaid amount
    const credit = (Math.random() * (amount ? parseFloat(amount) : 0)).toFixed(2); // Random paid amount based on amount

    const profitMargin = debit !== null ? (parseFloat(debit) - parseFloat(credit)).toFixed(2) : null; // Profit margin calculation

    dummyData.push({
      date,
      invoice_counter: invoiceID,
      volume_of_invoice: qty,
      tax_absolute: tax,
      amount: totalAmountWithTax,
      total_unpaid: debit,
      total_paid: credit,
      profit_margin_absolute: profitMargin,
    });
  }

  return (
    <div>
      {/* Customer Selector, FY, Date, Product selector, Company selector */}
      <div className="border-b-2 border-netural-200">
        <form className="flex px-[20px] gap-[10px]">
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <Autocomplete
              disablePortal
              options={customers || []}
              value={selectedCustomer}
              getOptionLabel={(option) => option.customer_name}
              onChange={(event, value) => {
                setSelectedCustomer(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Customer Name"
                  onChange={(e) => setDebouncedCustomerSearchTerm(e.target.value)}
                />
              )}
            />
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[200px] flex">
            <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <Autocomplete
              disablePortal
              options={products || []}
              value={selectedProduct}
              getOptionLabel={(option) => option.product_name}
              onChange={(event, value) => {
                setSelectedProduct(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product"
                  onChange={(e) => setDebouncedProductSearchTerm(e.target.value)}
                />
              )}
            />
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <Autocomplete
              disablePortal
              options={Companies || []}
              value={selectedCompany}
              getOptionLabel={(option) => option}
              onChange={(event, value) => {
                setSelectedCompany(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  onChange={(e) => setDebouncedCompanySearchTerm(e.target.value)}
                />
              )}
            />
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <FormControl fullWidth>
              <InputLabel id="Download">Format</InputLabel>
              <Select
                labelId="Download"
                id="Download_format"
                value={selectedDownloadFormat}
                label="Download"
                onChange={(e) => setSelectedDownloadFormat(e.target.value)}
                style={{ backgroundColor: "white" }}
              >
                <MenuItem value={"xlsx"}>Excel</MenuItem>
                <MenuItem value={"pdf"}>PDF</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <button
              id="joyride-step-6"
              onClick={handleDownload}
              className="text-white bg-black hover:bg-[rgba(0,0,0,0.8)] h-full w-full rounded-[4px]"
            >
              Download
            </button>
          </div>
        </form>
      </div>
      <div className="p-[20px]">
        <span className="text-center text-neutral-500 block w-full pb-[20px]">Customer-Wise Statement Table</span>
        {/* Statements data Table */}
        <div>
          <StatementsTable columns={columns} data={dummyData} next={null} />
        </div>
        {/* statements total */}
        <div className="border border-neutral-200 h-[45px] flex">
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Total Sales: </span>
            <span>₹1000</span>
          </span>
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Total Credit: </span>
            <span>₹1000</span>
          </span>
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Total Payment: </span>
            <span>₹1000</span>
          </span>
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Outstanding: </span>
            <span>₹1000</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default CustomerWise;
