import React, { useEffect, useState } from "react";

const BarcodeReader = ({ barcode, setBarcode, setScanTimestamp }) => {
  const [capturedInput, setCapturedInput] = useState(""); // Store captured barcode input here
  // const [barcode, setBarcode] = useState(""); // Store the final barcode after Enter

  useEffect(() => {
    // Function to handle keydown and filter unwanted keys
    const handleKeyDown = (event) => {
      // List of unwanted keys (e.g., Shift, Alt, Control)
      const unwantedKeys = ["Shift", "Alt", "Control", "Backspace"];

      // Check if the pressed key is not in the unwanted list
      if (!unwantedKeys.includes(event.key)) {
        if (event.key === "Enter") {
          // When Enter is pressed, finalize the barcode
          setBarcode(capturedInput);
          setScanTimestamp(Date.now()); // Update timestamp to force re-render
          setCapturedInput(""); // Reset the captured input after processing
        } else {
          // Accumulate the input (filtering out unwanted keys)
          setCapturedInput((prevInput) => prevInput + event.key);
        }
      }
    };

    // Add event listener for keydown
    document.addEventListener("keydown", handleKeyDown);

    // Clean up on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [capturedInput]);

  return <></>;
};

export default BarcodeReader;
